import { IMarketPricing, LocationPriceLocationType } from 'types/productCatalogTypes';
import { getProducts } from 'networking/productCatalog/productRequests';
import { getLocationPricesByLocationTypeAndLocationUuid } from 'networking/productCatalog/locationPriceRequests';
import { getGeogroupingById } from 'networking/operations/buildingOperations';
import { IExtendedFetchResult, ResponseStatus } from 'networking/fetchConfig';

export const getMarketPricing = async (
  marketUuid: string,
  catalogUuid: string,
): Promise<IExtendedFetchResult<IMarketPricing>> => {
  const market = await getGeogroupingById(marketUuid);

  if (!market) {
    return {
      data: undefined,
      error: `market ${marketUuid} is not found in Agolia`,
      status: ResponseStatus.NOT_FOUND,
    };
  }

  const [{ data: addOns, error: addOnError }, { data: marketPricing, error: marketPricingError }] = await Promise.all([
    getProducts({ filterByGroupTree: 'addons', catalog_uuid: catalogUuid }),
    getLocationPricesByLocationTypeAndLocationUuid(LocationPriceLocationType.GEOGROUPING, marketUuid, catalogUuid),
  ]);

  const error = [addOnError, marketPricingError].filter((error) => !!error).join(', ');

  if (error) {
    return {
      data: undefined,
      error: error,
      status: ResponseStatus.OK,
    };
  }

  const { data: regionPricing, error: regionPricingError } = await getLocationPricesByLocationTypeAndLocationUuid(
    LocationPriceLocationType.GEOGROUPING,
    market.parent.id,
    catalogUuid,
  );

  if (regionPricingError) {
    return {
      data: undefined,
      error: regionPricingError,
      status: ResponseStatus.OK,
    };
  }

  return {
    data: {
      uuid: marketUuid,
      parentUuid: market.parent.id,
      name: market.name,
      regionName: market.parent.name ?? '',
      addOns: addOns ?? [],
      marketPricing: marketPricing ?? [],
      regionPricing: regionPricing ?? [],
    },
    status: ResponseStatus.OK,
  };
};
