import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { parse, stringify } from 'query-string';
import { useAuth0 } from '@auth0/auth0-react';

import { useFlashMessageContext } from 'contexts/FlashMessageContext';
import Loading from 'components/shared/Loading';
import { UNAUTHORIZED_ROUTE } from 'pages/UnauthorizedPage/UnauthorizedPage';
import { AlchemistEmployeePermission, Auth0User, hasAtLeastOnePermission } from 'utils/auth/authUtils';
import { useGlobalAndOperatorPermissions } from 'hooks/useGlobalAndOperatorPermissions';
import { LOGIN_ROUTE } from 'pages/LoginPage/LoginPage';

interface IRequireAuthorizationProps {
  authPerms?: AlchemistEmployeePermission[];
  redirectPath?: string;
  children?: JSX.Element;
}

export const RequireAuthorization = ({
  authPerms = [],
  redirectPath = `/${UNAUTHORIZED_ROUTE}`,
  children,
}: IRequireAuthorizationProps): JSX.Element => {
  const { flashError } = useFlashMessageContext();
  const { search, pathname } = useLocation();
  const { isAuthenticated, isLoading } = useAuth0<Auth0User>();
  const [permsIsLoading, error, requestedPerms] = useGlobalAndOperatorPermissions(authPerms);

  // authorized if there are no permissions required or the user has one of the required permissions
  const authorized = isEmpty(authPerms) || hasAtLeastOnePermission(requestedPerms, authPerms);

  useEffect(() => {
    if (!permsIsLoading && !authorized) {
      // flashError should not be called during the render cycle
      flashError(`You don't have the required permissions (${authPerms.join(' or ')}) to see '${pathname}' page.`);
    }
  }, [permsIsLoading, authorized]);
  useEffect(() => {
    if (isAuthenticated && !isLoading && !permsIsLoading && !authorized) {
      // flashError should not be called during the render cycle
      flashError(`You don't have the required permissions (${authPerms.join(' or ')}) to see '${pathname}' page.`);
    }
  }, [isAuthenticated, isLoading, permsIsLoading, authorized]);

  // redirect to Login page if user is not authenticated
  if (!isAuthenticated && !isLoading) {
    const { code, state, ...otherParams } = parse(search);
    const nextPath = `${pathname}?${stringify(otherParams)}`;
    const toRedirect = {
      pathname: `/${LOGIN_ROUTE}`,
      search: `?${stringify({ path: nextPath, code, state })}`,
    };
    return <Navigate to={toRedirect} replace />;
  }

  if (permsIsLoading || isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!authorized) {
    return <Navigate to={{ pathname: redirectPath, search }} replace />;
  }
  if (children) {
    return children;
  }
  return <Outlet />;
};
