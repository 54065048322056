import * as Yup from 'yup';
import { IProduct, IProductGroup } from 'types/productCatalogTypes';
import { FormInputType } from 'components/shared/Form/FormConfig';
import { nullifyIfBlank } from 'utils/transformHelpers';

export const schema = Yup.object().shape({
  name: Yup.string().required('Please enter a name for the group.'),
  slug: Yup.string().nullable().transform(nullifyIfBlank),
  parentUuid: Yup.string().notRequired(),
});

export interface IProductGroupForm {
  name: string;
  slug: string | null;
  products: Array<IProduct>;
  groups: Array<IProductGroup>;
  parentUuid?: string;
}

export const initialValues = (parentGroup?: IProductGroup): IProductGroupForm => ({
  name: '',
  products: [],
  groups: [],
  parentUuid: parentGroup?.uuid,
  slug: '',
});

export enum CreateGroupFormFields {
  NAME = 'name',
  SLUG = 'slug',
  PARENT_UUID = 'parentUuid',
}

interface IFormFieldConfig {
  inputType: FormInputType.TEXT | FormInputType.SELECT;
  labelText: string;
  name: string;
  placeholder: string;
}

export const createGroupFormInputConfig: { [key in CreateGroupFormFields]: IFormFieldConfig } = {
  [CreateGroupFormFields.NAME]: {
    inputType: FormInputType.TEXT,
    labelText: 'Group Name',
    name: CreateGroupFormFields.NAME,
    placeholder: 'Enter a group name',
  },
  [CreateGroupFormFields.SLUG]: {
    inputType: FormInputType.TEXT,
    labelText: 'Group Slug',
    name: CreateGroupFormFields.SLUG,
    placeholder: 'Enter a group slug',
  },
  [CreateGroupFormFields.PARENT_UUID]: {
    inputType: FormInputType.SELECT,
    labelText: 'Parent Group',
    name: CreateGroupFormFields.PARENT_UUID,
    placeholder: 'Select a parent group (optional)',
  },
};
