import React from 'react';
import { Button, Modal } from '@wework/dieter-ui';

import 'components/buildings/AvailabilityPriceModal.css';

interface IArchiveProductModalProps {
  onClick: () => void;
  onClose: () => void;
}

export const ArchiveProductModal = ({ onClick, onClose }: IArchiveProductModalProps): JSX.Element => {
  return (
    <Modal open onClose={onClose}>
      <Modal.Content>Are you absolutely sure you wish to archive this product?</Modal.Content>
      <Modal.Actions className="availability-price-modal__actions">
        <Button primary type="button" onClick={onClick} data-test-id="confirm-archive">
          Archive Product
        </Button>
        <Button type="button" onClick={onClose} data-test-id="cancel-archive">
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
