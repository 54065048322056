import React from 'react';
import { faArrowRight, faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Message } from '@wework/dieter-ui';
import { map, isEmpty } from 'lodash';

import { ExpandableText } from 'components/shared/Form/ExpandableText';
import { IProductChanges, IWorkdaySalesItems } from 'types/productCatalogTypes';
import { getNumberOfVisibleLines, getProductFieldLabel, getSalesItemName } from './utils';
import { AttributeLabel } from './AttributeLabel';

import './styles.scss';

interface IChangesListProps {
  changes: IProductChanges[];
  salesItems: IWorkdaySalesItems[];
}

export const ChangesList = ({ changes, salesItems }: IChangesListProps): JSX.Element => {
  if (isEmpty(changes)) {
    return (
      <Message warning>
        <p>This changes are not available</p>
      </Message>
    );
  }

  return (
    <>
      {map(changes, ({ from, to, attr_name }) => {
        if (from || to) {
          const attrLabel = getProductFieldLabel(attr_name) ?? attr_name;
          const valueFrom = attr_name === 'attributes.SALES_ITEM_ID' ? getSalesItemName(salesItems, from) : from;
          const valueTo = attr_name === 'attributes.SALES_ITEM_ID' ? getSalesItemName(salesItems, to) : to;
          return (
            <div key={attr_name}>
              <AttributeLabel name={attrLabel} nativeName={attr_name} />
              <div className="attr-change">
                <span className="changes from">
                  {isEmpty(valueFrom) || !valueFrom ? (
                    <FontAwesomeIcon icon={faBan} color="red" size="sm" />
                  ) : (
                    <ExpandableText text={valueFrom} lines={getNumberOfVisibleLines(valueFrom?.toString())} />
                  )}
                </span>
                <span className="arrow">
                  <FontAwesomeIcon icon={faArrowRight} size="sm" />
                </span>
                <span className="changes to">
                  {isEmpty(valueTo) || !valueTo ? (
                    <FontAwesomeIcon icon={faBan} color="green" size="sm" />
                  ) : (
                    <ExpandableText text={valueTo} lines={getNumberOfVisibleLines(valueTo?.toString())} />
                  )}
                </span>
              </div>
            </div>
          );
        }
      })}
    </>
  );
};
