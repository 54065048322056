import noop from 'lodash/noop';
import { createContext, useContext } from 'react';

export interface IFlashMessageContextValue {
  flashInfo: (text: string) => void;
  flashSuccess: (text: string) => void;
  flashError: (text: string) => void;
}

const defaultContextValue = {
  flashInfo: noop,
  flashSuccess: noop,
  flashError: noop,
};

export const FlashMessageContext = createContext<IFlashMessageContextValue>({ ...defaultContextValue });

export const useFlashMessageContext = (): IFlashMessageContextValue => useContext(FlashMessageContext);
