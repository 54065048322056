import { createElement, FC } from 'react';
import { compact, Dictionary, find, flatMapDeep, forEach, get, isEmpty, isNil, keys, map } from 'lodash';
import { Node } from 'react-checkbox-tree';
import { IBuildingsMap, ILocationsMap, IGeogroupingsMap } from 'types/mappers';
import { IGeogroupingObject, IBuildingObject, ILocationObject, IWorkdaySalesItems } from 'types/productCatalogTypes';
import { locationTitle } from 'utils/locationHelpers';
import { groupBuildingsByGeogrouipings } from 'components/shared/AvailabilityForm/availabilityFormUtils';
import { IEmployee } from 'networking/fetchConfig';

const VISIBLE_CHAR_NUMBER = 200;

export const getNumberOfVisibleLines = (text?: string) =>
  !text || isEmpty(text) || text?.length < VISIBLE_CHAR_NUMBER ? -1 : 2;

export const getProductFieldLabel = (fieldPath: string): string | undefined => {
  const label = get(productFieldLabels, fieldPath);
  if (label) {
    return label;
  } else if (!label) {
    const splitedPath = fieldPath.split('.');
    for (let i = 0; i < splitedPath.length; ++i) {
      const n = get(productFieldLabels, splitedPath.slice(0, i).join('.'));
      if (typeof n === 'string') {
        return i === splitedPath.length ? n : `${n}: ${splitedPath.slice(i, splitedPath.length).join(' ')}`;
      }
    }
  }

  return fieldPath;
};

export const isFieldEmpty = (fieldValue: unknown) => isNil(fieldValue) || fieldValue === '' || fieldValue === 'null';

interface ICheckboxTreeNode extends Node {
  label: JSX.Element;
  children?: ICheckboxTreeNode[];
}

const getChangedLocations = (allLocations: ILocationsMap, changedLocationIds: string[]): ILocationsMap => {
  const changedBuildings: IBuildingsMap = {};
  const changedGeogroupings: IGeogroupingsMap = {};
  const { buildings, geogroupings } = allLocations;
  forEach(changedLocationIds, (locationId) => {
    const building = buildings[locationId];
    const geogrouping = geogroupings[locationId];
    if (building) {
      const { countrygeo, marketgeo } = building;
      changedBuildings[locationId] = building;
      if (countrygeo?.id) {
        changedGeogroupings[countrygeo.id] = geogroupings[countrygeo?.id];
      }
      if (marketgeo?.id) {
        changedGeogroupings[marketgeo?.id] = geogroupings[marketgeo?.id];
      }
    } else if (geogrouping) {
      changedGeogroupings[locationId] = geogrouping;
      if (geogrouping.parent.id) {
        changedGeogroupings[geogrouping.parent.id] = geogroupings[geogrouping.parent.id];
      }
    }
  });

  return { buildings: changedBuildings, geogroupings: changedGeogroupings };
};

// Assembles a tree of CountryGeos > MarketGeos > Buildings

export function assembleChangesNodes<T>(
  allLocations: ILocationsMap,
  changesData: Dictionary<T> | Dictionary<T[]>,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  LabelComponent,
): ICheckboxTreeNode[] {
  const changedLocationIds = keys(changesData);
  const changedLocations = getChangedLocations(allLocations, changedLocationIds);
  const { allCountryGeos, marketGeosByCountryGeo, buildingsByMarketGeo, buildingsByCountryGeo } =
    groupBuildingsByGeogrouipings(changedLocations);
  const nodes = allCountryGeos.map(countryGeoNode);

  return compact(nodes);

  function countryGeoNode(countryGeo: IGeogroupingObject): ICheckboxTreeNode | null {
    const childMarketGeos = marketGeosByCountryGeo[countryGeo.id] || [];
    const childMarketGeoNodes = childMarketGeos.map(marketGeoNode);

    const childBuildings = buildingsByCountryGeo[countryGeo.id] || [];
    const childBuildingNodes = childBuildings.map(buildingNode);

    const childNodes = childMarketGeoNodes.concat(childBuildingNodes);
    return locationToNode(countryGeo, compact(childNodes));
  }

  function marketGeoNode(marketGeo: IGeogroupingObject): ICheckboxTreeNode | null {
    const childBuildings = buildingsByMarketGeo[marketGeo.id] || [];
    const childBuildingNodes = childBuildings.map(buildingNode);
    return locationToNode(marketGeo, compact(childBuildingNodes));
  }

  function buildingNode(building: IBuildingObject): ICheckboxTreeNode | null {
    return locationToNode(building);
  }

  function locationToNode(location: ILocationObject, children?: ICheckboxTreeNode[]): ICheckboxTreeNode {
    const { id } = location;
    const title = locationTitle(location);
    return {
      showCheckbox: false,
      value: id,
      title,
      label: createElement(LabelComponent, { title: title, changesData: changesData[id] }, null),
      children,
    };
  }
}

export const getAllNodeIds = (nodes: ICheckboxTreeNode[]) => [
  ...map(nodes, 'value'),
  ...map(flatMapDeep(nodes, 'children'), 'value'),
];

export const userGetter = (users?: IEmployee[]) => {
  return (userUuid: string): IEmployee | undefined => find(users, (user) => user.uuid === userUuid);
};

export const getSalesItemName = (salesItems: IWorkdaySalesItems[], itemId: string) =>
  salesItems?.find((salesItem) => salesItem.id === itemId)?.name || itemId;

const productFieldLabels = {
  attributes: {
    RESOURCE_ID: 'Resource ID',
    RESTRICT_PROMOCODE_KIND: 'Available for specific promo code(s)',
    FULFILLMENT_EMAIL_TEMPLATE_SLUG: 'Fulfillment Email Slug',
    FULFILLMENT_EMAIL: 'Fulfillment Email',
    CONSULTATION_EMAIL_TEMPLATE_SLUG: 'Consultation Email Slug',
    WELCOME_EMAIL_TEMPLATE_SLUG: 'Welcome Email Slug',
    BILLING_ENTITY_ID: 'Billing Entity Id',
    SALES_ITEM_ID: 'Sales Item Id',
    BANDWIDTH_CAPACITY_LOWER_BOUND: 'Lower range of desk count to recommend this product',
    DAILY_DESK_CREDIT_PRICE: 'DAILY DESK CREDIT PRICE',
    BANDWIDTH_CAPACITY_UPPER_BOUND: 'Upper range of desk count to recommend this product',
    AVAILABILITY_CONFIGURATION: ' AVAILABILITY CONFIGURATION uuid',
    BOOKING_CANCELLATION_POLICY: 'BOOKING CANCELLATION POLICY uuid',
    CREDIT_ALLOTMENT: 'Credit Allotment',
    PRINT_BW_ALLOTMENT: 'Black/White Printer Allotment',
    PRINT_COLOR_ALLOTMENT: 'Color Printer Allotment',
    PURCHASE_IMMEDIATELY: 'This product can be purchased immediately',
    REQUEST_CONSULTATION: 'This product requires consultation',
    LIMITED_AVAILABILITY: 'This product has limited availability',
  },
  notes: 'Notes',
  saleItemGroup: 'Sales Item Group',
  volumeLimit: 'Volume Limit',
  externalVendorUrl: 'External Vendor URL',
  name: 'Name',
  description: 'Description',
  refundable: 'This product is Refundable',
  waivable: 'This product is Waivable',
  descriptionTranslations: 'Description Translations',
  nameTranslations: 'Name Translations',
  tncUrls: 'Terms and Conditions URLs',
  tncUrl: 'Terms and Conditions URL',
  oneTime: 'One Time Charge',
  updatedAt: 'Updated At',
};
