import { stringify } from 'query-string';
import { OperatorServicePermissionsMap } from '@wework/we-auth-react';
import { IExtendedFetchResult, RequestMethods } from 'networking/fetchConfig';
import { operatorServiceFetch } from './operatorServiceFetch';

export function hasPermissionsByOperator(
  employee_uuid: string,
  operator_uuid: string,
  perms: string[],
): Promise<IExtendedFetchResult<OperatorServicePermissionsMap>> {
  const query = stringify({ employee_uuid, operator_uuid, perms });
  return operatorServiceFetch<OperatorServicePermissionsMap>(
    RequestMethods.GET,
    `/v1/authz/has_permissions/by_operator?${query}`,
  );
}
