import { SpacemanFee, SpacemanResource } from 'types/spacemanTypes';
import { IProduct } from 'types/productCatalogTypes';
import { getLocationFees } from 'networking/spaceman/locationRequests';
import { getResources } from 'networking/spaceman/resourceRequests';
import { getProducts } from 'networking/productCatalog/productRequests';
import { IFetchResult, ResponseStatus } from 'networking/fetchConfig';
import { Hash } from 'types/global';

export interface ITaxData {
  fees: SpacemanFee[];
  resources: SpacemanResource[];
  products: IProduct[];
}

const EXCLUDED_RESOURCES: Hash<boolean> = {
  VAT: true,
  'City Tax': true,
  'Country Tax': true,
  'Province Tax': true,
  'State Tax': true,
  Discount: true,
  'Recurring Discount': true,
  'Security Deposit': true,
  'Security Deposit Adjustment': true,
};

const isDnuResource = (name: string): boolean => name.includes('DNU') || !!name.match(new RegExp('do not use', 'i'));
const isResourceExcluded = (name: string): boolean => EXCLUDED_RESOURCES[name];

const filterSpaceManResources = (resources: SpacemanResource[]): SpacemanResource[] =>
  resources.filter((resource) => !isDnuResource(resource.name) && !isResourceExcluded(resource.name));

export const getTaxData = async (buildingId: string, catalogUuid: string): Promise<IFetchResult<ITaxData>> => {
  const [
    { data: fees, error: locationError },
    { data: resourceResponse, error: resourcesError },
    { data: products, error: productsError },
  ] = await Promise.all([getLocationFees(buildingId), getResources(), getProducts({ catalog_uuid: catalogUuid })]);

  const error = [locationError, resourcesError, productsError].filter((error) => !!error).join(',');

  if (error || !fees || !products || !resourceResponse || !resourceResponse.result) {
    return {
      error,
      status: ResponseStatus.UNKNOWN,
      data: undefined,
    };
  }

  return {
    status: ResponseStatus.OK,
    data: {
      fees,
      products,
      resources: filterSpaceManResources(resourceResponse.result),
    },
  };
};
