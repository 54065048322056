import React from 'react';
import { Button, Divider, Form, Modal } from '@wework/dieter-ui';
import { Form as FormikForm, Formik, FormikHelpers as FormikActions } from 'formik';
import * as Yup from 'yup';
import { OperatorServicePermissionsMap } from '@wework/we-auth-react';

import { formCurrencyConfig, FormInputType } from 'components/shared/Form/FormConfig';
import { ILocationListingProduct, LocationPriceLocationType } from 'types/productCatalogTypes';
import { handleChange } from 'components/shared/Form/utils';
import {
  ProductFormCheckboxInput,
  ProductFormSelectInput,
  ProductFormTextInput,
} from 'components/shared/ProductForm/ProductFormInputs';
import { useFlashMessageContext } from 'contexts/FlashMessageContext';
import { BUILDING_AVAILABILITY_PERMISSIONS, PRICING_PERMISSIONS, hasAtLeastOnePermission } from 'utils/auth/authUtils';
import { updateAvailabilityAndPricing } from 'components/buildings/AvailabilityPriceModalActions';
import { nullifyIfBlank } from 'utils/transformHelpers';

import 'components/buildings/AvailabilityPriceModal.css';

interface IAvailabilityPriceModalProps {
  listing: ILocationListingProduct;
  requestedPerms: OperatorServicePermissionsMap;
  onClose: () => void;
  refresh: () => void;
}

const schema = Yup.object().shape({
  available: Yup.boolean().required(),
  currency: Yup.string().required('Please select a currency.'),
  price: Yup.string().required('Please enter a price.'),
  base: Yup.string().nullable().transform(nullifyIfBlank),
});

interface IFormInputs {
  available: boolean;
  currency: string;
  price: string;
  base?: string;
}

export const AvailabilityPriceModal = ({
  listing,
  requestedPerms,
  onClose,
  refresh,
}: IAvailabilityPriceModalProps): JSX.Element => {
  const { flashError, flashSuccess } = useFlashMessageContext();

  const initialValues = {
    price: listing.price?.price ?? '',
    currency: listing.price?.currency ?? '',
    available: listing.available,
  };

  const isPriceEditable = hasAtLeastOnePermission(requestedPerms, PRICING_PERMISSIONS);
  const isBuildingEditable = hasAtLeastOnePermission(requestedPerms, BUILDING_AVAILABILITY_PERMISSIONS);

  const handleSubmit = async (values: IFormInputs, actions: FormikActions<IFormInputs>): Promise<void> => {
    const payload = {
      ...values,
      productUuid: listing.uuid,
      locationUuid: listing.buildingUuid,
      locationType: LocationPriceLocationType.BUILDING,
    };

    actions.setSubmitting(true);

    const result = await updateAvailabilityAndPricing(payload, requestedPerms);
    if (result.error) {
      actions.setErrors({ base: `An error occurred: ${result.error}` });
      flashError(`An error has occurred while processing request: ${result.error}`);
    } else {
      actions.setSubmitting(false);
      flashSuccess('Successfully updated pricing and availability.');
      onClose();
      refresh();
    }
  };

  return (
    <Modal open onClose={onClose}>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        {({ handleSubmit: formikHandleSubmit, setFieldValue, setFieldTouched, errors }): JSX.Element => (
          <Form as={FormikForm} onSubmit={formikHandleSubmit}>
            <Modal.Header as="h3">Update Price and Availability</Modal.Header>
            <Divider />
            <Modal.Content>
              <ProductFormSelectInput
                data-testid="select-input"
                inputType={FormInputType.SELECT}
                name="currency"
                labelText="Currency"
                placeholder="Choose a currency"
                options={formCurrencyConfig}
                disabled={!isPriceEditable}
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
              />
              <ProductFormTextInput
                inputType={FormInputType.TEXT}
                name="price"
                placeholder="Enter a price"
                labelText="Price"
                disabled={!isPriceEditable}
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
              />
              <ProductFormCheckboxInput
                inputType={FormInputType.CHECKBOX}
                name="available"
                labelText="Product is available for sale"
                disabled={!isBuildingEditable}
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
              />
              <p style={{ color: 'red' }}>{errors['base']}</p>
              <p>Need help changing a price? Please reach out to your RevOps director to price this product.</p>
            </Modal.Content>
            <Modal.Actions className="availability-price-modal__actions">
              <Button primary disabled={!isPriceEditable && !isBuildingEditable} type="submit">
                Save
              </Button>
              <Button type="button" onClick={onClose}>
                Cancel
              </Button>
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
