import { FileRejection } from 'react-dropzone';
import { ImagePreview } from '../../../types/productCatalogTypes';

export type FormValue = string | number | boolean | Array<string | number | boolean> | null | undefined;

export enum FormInputType {
  CHECKBOX = 'checkbox',
  SELECT = 'select',
  NUMBER = 'number',
  TEXT = 'text',
  TEXTAREA = 'textarea',
  IMAGE = 'image',
}

export interface IGenericFormInputProps<T> {
  inputType: FormInputType;
  labelText?: string;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  handleOnChange: (name: string, value: T) => void;
  showErrorMessage?: boolean;
  autoSelect?: boolean;
}

export interface IDropZoneImageFormInputProps {
  isCreate: boolean;
  labelText?: string;
  readOnly?: boolean;
  createPlaceholder: string;
  updatePlaceholder: string;
  onDragCreatePlaceholder: string;
  onDragUpdatePlaceholder: string;
  onDropAccepted: <T extends File>(files: T[]) => void;
  onDropRejected: (fileRejections: FileRejection[]) => void;
}

export interface ProductFormImageCarouselInputProps {
  imagePreviews: ImagePreview[];
  onImageRemove: (imageNumber: number) => void;
  onImageTagChange: (imageNumber: number, tagValue: string | undefined) => void;
}

// https://en.wikipedia.org/wiki/ISO_4217
export const formCurrencyConfig = [
  { key: 'AED', value: 'AED', text: 'AED - United Arab Emirates Dirham' },
  { key: 'ARS', value: 'ARS', text: 'ARS - Argentine Peso' },
  { key: 'AUD', value: 'AUD', text: 'AUD - Australian Dollar' },
  { key: 'BRL', value: 'BRL', text: 'BRL - Brazilian Real' },
  { key: 'CAD', value: 'CAD', text: 'CAD - Canadian Dollar' },
  { key: 'CHF', value: 'CHF', text: 'CHF - Swiss franc' },
  { key: 'CLP', value: 'CLP', text: 'CLP - Chilean Peso' },
  { key: 'CNY', value: 'CNY', text: 'CNY - Renminbi (Chinese) Yuan' },
  { key: 'COP', value: 'COP', text: 'COP - Colombian Peso' },
  { key: 'CRC', value: 'CRC', text: 'CRC - Costa Rican Colon' },
  { key: 'CZK', value: 'CZK', text: 'CZK - Czech Koruna' },
  { key: 'DKK', value: 'DKK', text: 'DKK - Danish Krone' },
  { key: 'EUR', value: 'EUR', text: 'EUR - Euro' },
  { key: 'GBP', value: 'GBP', text: 'GBP - Pound Sterling' },
  { key: 'HKD', value: 'HKD', text: 'HKD - Hong Kong Dollar' },
  { key: 'HUF', value: 'HUF', text: 'HUF - Hungarian Forint' },
  { key: 'IDR', value: 'IDR', text: 'IDR - Indonesian Rupiah' },
  { key: 'ILS', value: 'ILS', text: 'ILS - Israeli New Shekel' },
  { key: 'INR', value: 'INR', text: 'INR - Indian Rupee' },
  { key: 'JPY', value: 'JPY', text: 'JPY - Japanese Yen' },
  { key: 'KRW', value: 'KRW', text: 'KRW - South Korean Won' },
  { key: 'MXN', value: 'MXN', text: 'MXN - Mexican Peso' },
  { key: 'MYR', value: 'MYR', text: 'MYR - Malaysian Ringgit' },
  { key: 'NOK', value: 'NOK', text: 'NOK - Norwegian Krone' },
  { key: 'PEN', value: 'PEN', text: 'PEN - Peruvian Sol' },
  { key: 'PHP', value: 'PHP', text: 'PHP - Philippine Peso' },
  { key: 'PLN', value: 'PLN', text: 'PLN - Polish Złoty' },
  { key: 'RON', value: 'RON', text: 'RON - Romanian Leu' },
  { key: 'RUB', value: 'RUB', text: 'RUB - Russian Ruble' },
  { key: 'SEK', value: 'SEK', text: 'SEK - Swedish Krona/Kronor' },
  { key: 'SGD', value: 'SGD', text: 'SGD - Singapore Dollar' },
  { key: 'THB', value: 'THB', text: 'THB - Thai Baht' },
  { key: 'TWD', value: 'TWD', text: 'TWD - New Taiwan Dollar' },
  { key: 'USD', value: 'USD', text: 'USD - United States Dollar' },
  { key: 'VND', value: 'VND', text: 'VND - Vietnamese Đồng' },
  { key: 'ZAR', value: 'ZAR', text: 'ZAR - South African Rand' },
];

export enum RequestType {
  CREATE = 'CREATE',
  READ = 'READ',
  UPDATE = 'UPDATE',
}
