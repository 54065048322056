import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@wework/dieter-ui';
import { LoadingContainer } from 'components/shared/Loading/LoadingContainer';

export const LOGIN_ROUTE = 'login';

export const LoginPage = (): JSX.Element => {
  const location = useLocation();
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  function redirectUrl(): string {
    const query = new URLSearchParams(location.search);
    return decodeURIComponent(query.get('path') || '/');
  }

  if (isAuthenticated) {
    const [pathname, search] = redirectUrl().split('?');
    const toParams = {
      pathname,
      search: search ? `?${search}` : undefined,
    };

    return <Navigate to={toParams} replace />;
  }

  return (
    <section className="alchemist-fade-in">
      <LoadingContainer loading={isLoading}>
        <Button
          color="google plus"
          size="big"
          onClick={(): Promise<void> => loginWithRedirect({ appState: { returnTo: redirectUrl() } })}
        >
          Login with Auth0
        </Button>
      </LoadingContainer>
    </section>
  );
};
