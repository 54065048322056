import { Node } from 'react-checkbox-tree'; // eslint-disable-line import/named
import { faCubes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import React, { createElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IProductGroup } from 'types/productCatalogTypes';
import { WeCheckboxTree } from 'components/shared/WeCheckboxTree/WeCheckboxTree';

import 'components/groups/GroupsTree/groupsTree.scss';

const icon = (opts: FontAwesomeIconProps): JSX.Element => createElement(FontAwesomeIcon, opts);

export interface IGroupsTreeProps {
  groups: IProductGroup[];
}

interface INodeMap {
  [uuid: string]: Node;
}

interface IParent2ChildUuids {
  [parent_uuid: string]: [string];
}

function buildChildren(node: Node, uuid2Node: INodeMap, allChildrenMap: IParent2ChildUuids): void {
  const childUuids = allChildrenMap[node.value];
  if (!childUuids) {
    return;
  }
  delete allChildrenMap[node.value];

  for (const c of childUuids) {
    const childNode = uuid2Node[c];
    node.children = node.children || [];
    node.children.push(childNode);
    buildChildren(childNode, uuid2Node, allChildrenMap);
  }
}

function createTreeNodes(groups: IProductGroup[]): Node[] {
  const uuid2Node: INodeMap = {};
  const allChildrenMap: IParent2ChildUuids = {};
  const rootNodes: Node[] = [];

  for (const g of groups) {
    const node: Node = {
      showCheckbox: false,
      value: g.uuid,
      label: g.slug ? `${g.name} (${g.slug})` : g.name,
    };

    uuid2Node[g.uuid] = node;

    if (!g.parentUuid) {
      // group is a root group
      rootNodes.push(node);
    }

    if (g.groups.length) {
      // group has child groups
      allChildrenMap[g.uuid] = allChildrenMap[g.uuid] || [];
      allChildrenMap[g.uuid].push(...g.groups);
    }
  }

  for (const root of rootNodes) {
    buildChildren(root, uuid2Node, allChildrenMap); // build children recursively
  }

  return rootNodes;
}

const defaultIcons = {
  leaf: icon({ icon: faCubes }),
  parentClose: icon({ icon: faCubes }),
  parentOpen: icon({ icon: faCubes }),
};

const GroupsTree = ({ groups }: IGroupsTreeProps): JSX.Element => {
  const navigate = useNavigate();

  const [nodes, setNodes] = useState<Node[]>([]);
  const [expandedIds, setExpandedIds] = useState<string[]>([]);

  useEffect(() => {
    const rootNodes = createTreeNodes(groups);
    setNodes(rootNodes);
    setExpandedIds(rootNodes.map((node) => node.value)); // expand root nodes by default
  }, [groups]);

  return (
    <div className="groupsTree--container">
      <WeCheckboxTree
        data-test-id="groups"
        nodes={nodes}
        expanded={expandedIds}
        onExpand={setExpandedIds}
        showExpandAll
        onClick={({ value }): void => navigate(`${value}`)}
        icons={{ ...defaultIcons }}
      />
    </div>
  );
};

export { GroupsTree };
