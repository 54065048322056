import React, { useState, useMemo } from 'react';
import { ChipLabel, Divider } from '@wework/dieter-ui';
import { useAsyncMemo } from 'use-async-memo';
import { Link, generatePath, useNavigate, useParams } from 'react-router-dom';
import { useRequestedRoles } from '@wework/we-auth-react';

import { useGlobalAndOperatorPermissions } from 'hooks/useGlobalAndOperatorPermissions';
import { LoadingContainer } from 'components/shared/Loading/LoadingContainer';
import { getBuildingById } from 'networking/operations/buildingOperations';
import { NotFoundPage } from 'pages/NotFoundPage/NotFoundPage';
import { VerticalTabRouter, ITabConfig } from 'components/shared/VerticalTabRouter/VerticalTabRouter';
import { BaseUrlParamsType } from 'types/router';
import { IBuildingObject } from 'types/productCatalogTypes';
import { ALL_PERMISSIONS, SPACEMAN_ROLES } from 'utils/auth/authUtils';
import { PageHeader } from 'components/shared/PageHeader/PageHeader';
import { EDIT_TOGGLE_ID } from 'components/shared/EditToggle/EditToggle';
import { buildingTabsConfig } from './buildingTabs/buildingTabsConfig';

import 'pages/buildings/buildingpage.css';

type ParamsType = BaseUrlParamsType & {
  uuid: string;
};

export const BuildingPage = (): JSX.Element => {
  const navigate = useNavigate();
  const [buildingLoading, setBuildingLoading] = useState(true);
  const { catalogUuid, uuid: buildingUuid } = useParams() as ParamsType;
  const [permsIsLoading, permsError, requestedPerms] = useGlobalAndOperatorPermissions(ALL_PERMISSIONS);
  const [rolesIsLoading, rolesError, requestedRoles] = useRequestedRoles(SPACEMAN_ROLES);

  const isLoading = buildingLoading || permsIsLoading || rolesIsLoading;

  // TODO: replace useAsyncMemo to useApiHook
  const building = useAsyncMemo<IBuildingObject | null>(async () => {
    setBuildingLoading(true);
    const response = await getBuildingById(buildingUuid);
    setBuildingLoading(false);
    return response;
  }, [buildingUuid]);

  const tabsConfig = useMemo(() => {
    return building ? buildingTabsConfig(building, catalogUuid, requestedRoles, requestedPerms) : [];
  }, [building?.id, catalogUuid, requestedPerms, requestedRoles]);

  const tabClickHandler = ({ path }: ITabConfig): void => {
    const goto = generatePath(path, { uuid: building?.id });
    navigate(goto);
  };

  if (!buildingLoading && !building) {
    return <NotFoundPage />;
  }

  return (
    <div className="alchemist-fade-in">
      <section>
        <Link to={'../'}>All buildings</Link>
      </section>
      <Divider hidden />
      <LoadingContainer loading={isLoading} error={permsError || rolesError}>
        <>
          <PageHeader header={building?.default_name ?? ''}>
            <div id={EDIT_TOGGLE_ID} />
          </PageHeader>
          <ChipLabel size="small">{building?.code}</ChipLabel>
          <ChipLabel size="small">{building?.marketgeo?.name}</ChipLabel>
          <ChipLabel size="small">{building?.countrygeo?.name}</ChipLabel>
          <Divider />
          {building && <VerticalTabRouter tabsConfig={tabsConfig} onClick={tabClickHandler} />}
        </>
      </LoadingContainer>
    </div>
  );
};
