import { CheckboxProps, Header, Segment } from '@wework/dieter-ui';
import reject from 'lodash/reject';
import React, { useState } from 'react';
import { useAsyncMemo } from 'use-async-memo';

import { LoadingContainer } from 'components/shared/Loading/LoadingContainer';
import { useFlashMessageContext } from 'contexts/FlashMessageContext';
import { createFee, deleteFee, getFees } from 'networking/productCatalog/feeRequests';
import { getProduct, getProducts } from 'networking/productCatalog/productRequests';
import { IProduct } from 'types/productCatalogTypes';
import { IEditProductTabCommonProps } from 'pages/products/editProductTabs/editProductTabsConfig';
import { FeesList } from 'components/shared/FeesList/FeesList';
import { Autocomplete } from 'components/shared/Autocomplete/Autocomplete';
import { IExtendedFetchResult, IFetchResult } from 'networking/fetchConfig';
import { toProductDropdownItemEnableOnlyActive } from 'components/shared/ProductForm/productFormHelpers';
import { EditToggle } from 'components/shared/EditToggle/EditToggle';

const EditFeesTab = ({ product, catalogUuid, isEditable }: IEditProductTabCommonProps): JSX.Element => {
  // productFees     = fees that belong to the parent product on this page
  const { flashError } = useFlashMessageContext();
  const [productFeeList, setProductFeeList] = useState<IProduct[]>([]);
  const [productFeesLoading, setProductFeesLoading] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const feesRespond = useAsyncMemo<IExtendedFetchResult<IProduct[]> | null | undefined>(async () => {
    setProductFeesLoading(true);
    const respond = await getFees(product, catalogUuid);
    const { data, error } = respond;
    setProductFeesLoading(false);
    if (error) {
      flashError(error);
      return;
    }
    if (data && data?.length !== 0) {
      setProductFeeList(data);
    }
    return respond;
  }, [product.uuid, catalogUuid]);

  const handleCreateFeeSubmit = async (feeUuid: string): Promise<void> => {
    const { error } = await createFee(product, feeUuid, catalogUuid);

    if (error) {
      flashError(error);
      return;
    }
    const { data: fee, error: getFeeError } = await getProduct(feeUuid, catalogUuid);
    if (getFeeError || !fee) {
      flashError(getFeeError || `Failed to retrieve fee details for ${feeUuid}`);
      return;
    }

    setProductFeeList([...productFeeList, fee]);
  };

  const handleDelete = async (feeUuid: IProduct['uuid']): Promise<void> => {
    const { error } = await deleteFee(product, feeUuid, catalogUuid);
    if (error) {
      flashError(error);
      return;
    }
    setProductFeeList(reject(productFeeList, (fee) => fee.uuid === feeUuid));
  };

  return (
    <LoadingContainer loading={productFeesLoading} data={feesRespond?.data}>
      <>
        <Header as="h3">Fees</Header>
        <EditToggle
          disabled={!isEditable}
          isEdit={isEdit}
          onChange={({ checked }: CheckboxProps): void => setIsEdit(Boolean(checked))}
        />
        {feesRespond?.data && <FeesList fees={productFeeList} isEditable={isEdit} onDelete={handleDelete} />}
        <Segment>
          <Header as="h3">Add a fee to this product</Header>
          <Autocomplete
            disabled={!isEdit}
            placeholder="Select Fee"
            fetchFunc={(): Promise<IFetchResult<IProduct[]>> =>
              getProducts({ saleItemGroup: 'fee', catalog_uuid: catalogUuid })
            }
            entityToOption={toProductDropdownItemEnableOnlyActive}
            skipKeys={productFeeList.map((fee) => fee.uuid)}
            onSelectionChange={handleCreateFeeSubmit}
          />
        </Segment>
      </>
    </LoadingContainer>
  );
};

export { EditFeesTab };
