import React from 'react';
import { List } from '@wework/dieter-ui';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFlashMessageContext } from 'contexts/FlashMessageContext';

interface IUserInfoProps {
  userEmail?: string;
  userFullName?: string;
}

export const AuditUserInfo = ({ userEmail, userFullName }: IUserInfoProps) => {
  const { flashInfo } = useFlashMessageContext();

  const userFullNameLabel = userFullName ?? 'N/A';
  const userEmailLabel = userEmail ?? 'N/A';

  return (
    <List>
      <List.Item>
        <a href={userEmail ? `mailto: ${userEmail}` : '#'}>{userFullNameLabel}</a>
      </List.Item>
      {userEmail && (
        <List.Item
          className="copy-bttn"
          onClick={() => {
            navigator.clipboard.writeText(userEmail);
            flashInfo('Copied!');
          }}
        >
          <FontAwesomeIcon icon={faCopy} size="sm" />
          <span className="user-email">{userEmailLabel}</span>
        </List.Item>
      )}
    </List>
  );
};
