import React, { useRef, useEffect, useState } from 'react';
import { useAsyncEffect } from 'use-async-effect';
import { faMinusSquare, faPlusSquare, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty, map } from 'lodash';
import RenderIfVisible from 'react-render-if-visible';
import { Popup } from '@wework/dieter-ui';
import dateFnsFormat from 'date-fns-tz/format';
import cn from 'classnames';
import ago from 'ts-ago';

import { AuditActionType, IAuditEntity, IProductGroupsChanges } from 'types/productCatalogTypes';
import { getProductGroupsAudit } from 'networking/productCatalog/productRequests';
import { getUsers } from 'networking/operatorService/userByUuidRequests';
import { IEmployee } from 'networking/fetchConfig';
import { IPaginationatedResponse } from 'networking/operatorService/operatorServiceTypes';
import { IEditProductTabCommonProps } from '../editProductTabsConfig';
import { userGetter } from './utils';
import { AuditUserInfo } from './AuditUserInfo';

import './styles.scss';

import {
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  columns,
  AuditRecordPlaceholder,
  NoAuditRecordsPlaceholder,
  ESTIMATED_ITEM_HEIGHT,
  LoadMore,
  AuditColumn,
} from './AuditTable';

const PAGE_SIZE = 20;

export const AuditProductGroups = ({ product, catalogUuid }: IEditProductTabCommonProps) => {
  const [usersLoading, setUsersLoading] = useState(false);
  const [usersError, setUsersError] = useState<string | undefined>();
  const [auditLoading, setAuditLoading] = useState(true);
  const [auditError, setAuditError] = useState<string | undefined>();
  const [usersResponse, setUsersResponse] = useState<IPaginationatedResponse<IEmployee> | undefined>();
  const [offset, setOffset] = useState(0);
  const [stop, setStop] = useState<boolean>(false);
  const [data, setData] = useState<IAuditEntity<IProductGroupsChanges>[]>([]);
  const containerRef = useRef(null);

  useEffect(() => {
    const getData = async () => {
      setAuditLoading(true);
      const { data: auditLogs, status, error } = await getProductGroupsAudit(product.uuid, PAGE_SIZE, offset);
      setAuditLoading(false);
      if (error) {
        setAuditError(error);
      }
      if (auditLogs?.length) {
        setData([...data, ...auditLogs]);
      } else if (status === 200) {
        setStop(true);
      }
    };
    getData();
  }, [offset]);

  const loadMore = () => setOffset(offset + PAGE_SIZE);

  useAsyncEffect(
    async (isActive) => {
      const userUuids = map(data, 'user');
      if (!isActive() || isEmpty(userUuids)) return;
      setUsersLoading(true);
      const response = await getUsers(userUuids);
      response.error && setUsersError(response.error);
      response.data && setUsersResponse(response.data);
      setUsersLoading(false);
    },
    [data],
  );

  const getUserData = userGetter(usersResponse?.content);
  const isLoading = auditLoading || usersLoading;

  return (
    <TableContainer>
      <TableHeader>
        {columns.map((column: AuditColumn) => (
          <TableHeaderCell key={column.key} size={column.size}>
            {column.title}
          </TableHeaderCell>
        ))}
      </TableHeader>
      <TableBody ref={containerRef}>
        {data.map((auditItem: IAuditEntity<IProductGroupsChanges>, idx) => {
          const userData = getUserData(auditItem.user);
          const changeDate = dateFnsFormat(new Date(auditItem.timestamp), 'MMMM dd yyyy HH:mm zzz');
          const isCreated = auditItem.action === AuditActionType.CREATED;

          return (
            <div key={auditItem.id} className={cn({ created: isCreated })}>
              <RenderIfVisible key={auditItem.id} defaultHeight={ESTIMATED_ITEM_HEIGHT} placeholderElement={'div'}>
                <TableRow>
                  <TableCell size="md">
                    <Popup
                      hoverablex
                      size="tiny"
                      position="top left"
                      content={changeDate}
                      trigger={<div>{ago(auditItem.timestamp)}</div>}
                    />
                  </TableCell>
                  <TableCell size="lg">
                    <AuditUserInfo userFullName={userData?.full_name} userEmail={userData?.email} />
                  </TableCell>
                  <TableCell size="md">{auditItem.action}</TableCell>
                  <TableCell size="auto">
                    <>
                      {map(auditItem.changes, ({ groupName, action }) => {
                        const feeUuid = auditItem.entityData.groupUuid;
                        const origin = window.location.origin;
                        const productUrl = `${origin}/${catalogUuid}/groups/${feeUuid}`;
                        return (
                          <div key={groupName}>
                            <div className="attr-change">
                              <span className="changes from">
                                {action === AuditActionType.REMOVED ? (
                                  <FontAwesomeIcon icon={faMinusSquare} size="lg" color="red" />
                                ) : (
                                  <FontAwesomeIcon icon={faPlusSquare} size="lg" color="green" />
                                )}
                              </span>
                              <a href={productUrl} target="_blank" rel="noopener, noreferrer">
                                <span className="attr-name">{groupName}</span>
                                <span>
                                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                                </span>
                              </a>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  </TableCell>
                </TableRow>
              </RenderIfVisible>
              {idx === data.length - 1 ? <LoadMore setOffset={loadMore} stop={stop} root={containerRef} /> : null}
            </div>
          );
        })}
        <>
          {isEmpty(data) && isLoading && <AuditRecordPlaceholder />}
          {isEmpty(data) && !isLoading && <NoAuditRecordsPlaceholder />}
        </>
      </TableBody>
    </TableContainer>
  );
};
