import { User } from '@auth0/auth0-spa-js';
import { OperatorServicePermissionsMap } from '@wework/we-auth-react';

export enum SpacemanEmployeeRole {
  city_lead = 'city_lead',
}

export const SPACEMAN_ROLES = [SpacemanEmployeeRole.city_lead];

export enum AlchemistEmployeePermission {
  alchemist_administration = 'alchemist_administration',
  alchemist_archival = 'alchemist_archival',
  alchemist_location_management = 'alchemist_location_management',
  alchemist_product_creation = 'alchemist_product_creation',
  alchemist_product_edit = 'alchemist_product_edit',
  alchemist_groups_management = 'alchemist_groups_management',
  alchemist_price_management = 'alchemist_price_management',
  alchemist_tax_management = 'alchemist_tax_management',
  alchemist_tncs_management = 'alchemist_tncs_management',
  alchemist_translation_management = 'alchemist_translation_management',
}

export const ALL_PERMISSIONS = [
  AlchemistEmployeePermission.alchemist_administration,
  AlchemistEmployeePermission.alchemist_archival,
  AlchemistEmployeePermission.alchemist_location_management,
  AlchemistEmployeePermission.alchemist_product_creation,
  AlchemistEmployeePermission.alchemist_product_edit,
  AlchemistEmployeePermission.alchemist_groups_management,
  AlchemistEmployeePermission.alchemist_price_management,
  AlchemistEmployeePermission.alchemist_tax_management,
  AlchemistEmployeePermission.alchemist_tncs_management,
  AlchemistEmployeePermission.alchemist_translation_management,
];

export const GLOBAL_PERMISSIONS = [AlchemistEmployeePermission.alchemist_administration];

export const OPERATOR_PERMISSIONS = [
  AlchemistEmployeePermission.alchemist_archival,
  AlchemistEmployeePermission.alchemist_location_management,
  AlchemistEmployeePermission.alchemist_product_creation,
  AlchemistEmployeePermission.alchemist_product_edit,
  AlchemistEmployeePermission.alchemist_groups_management,
  AlchemistEmployeePermission.alchemist_price_management,
  AlchemistEmployeePermission.alchemist_tax_management,
  AlchemistEmployeePermission.alchemist_tncs_management,
  AlchemistEmployeePermission.alchemist_translation_management,
];

export const ADMIN_PERMISSIONS = [AlchemistEmployeePermission.alchemist_administration];

export const PRICING_PERMISSIONS = [
  AlchemistEmployeePermission.alchemist_administration,
  AlchemistEmployeePermission.alchemist_price_management,
];

export const BUILDING_AVAILABILITY_PERMISSIONS = [
  AlchemistEmployeePermission.alchemist_administration,
  AlchemistEmployeePermission.alchemist_location_management,
];

export const BUILDING_AND_PRICING_PERMISSIONS = [...PRICING_PERMISSIONS, ...BUILDING_AVAILABILITY_PERMISSIONS];

export const EDITABLE_PERMISSIONS = [
  AlchemistEmployeePermission.alchemist_administration,
  AlchemistEmployeePermission.alchemist_product_edit,
];

export const CREATABLE_PERMISSIONS = [
  AlchemistEmployeePermission.alchemist_administration,
  AlchemistEmployeePermission.alchemist_product_creation,
];

export const TNCS_PERMISSIONS = [
  AlchemistEmployeePermission.alchemist_administration,
  AlchemistEmployeePermission.alchemist_tncs_management,
];

export const EDIT_GROUPS_PERMISSIONS = [
  AlchemistEmployeePermission.alchemist_administration,
  AlchemistEmployeePermission.alchemist_groups_management,
];

export const ARCHIVAL_PERMISSIONS = [
  AlchemistEmployeePermission.alchemist_administration,
  AlchemistEmployeePermission.alchemist_archival,
];

export const TRANSLATIONS_PERMISSIONS = [
  AlchemistEmployeePermission.alchemist_administration,
  AlchemistEmployeePermission.alchemist_translation_management,
];

export const TAXES_PERMISSIONS = [
  AlchemistEmployeePermission.alchemist_administration,
  AlchemistEmployeePermission.alchemist_tax_management,
];

export interface Auth0User extends User {
  'https://wework.com/employee_uuid': string;
  'https://wework.com/member_uuid': string;
  'https://wework.com/spaceman_roles': string[];
}

export interface IEmployeeProfile {
  email?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  roles: string[];
  employeeUuid: string;
  memberUuid: string;
}

export const employeeProfileFromAuth0User = (user: Auth0User): IEmployeeProfile => ({
  email: user.email,
  firstName: user.given_name,
  lastName: user.family_name,
  name: user.name,
  roles: user['https://wework.com/spaceman_roles'],
  employeeUuid: user['https://wework.com/employee_uuid'],
  memberUuid: user['https://wework.com/member_uuid'],
});

export const hasSomePermissions = (requestedPerms: OperatorServicePermissionsMap): boolean =>
  Object.values(requestedPerms).some((booleanValue) => booleanValue);

export const hasAtLeastOnePermission = (
  requestedPerms: OperatorServicePermissionsMap,
  expectedPerms: AlchemistEmployeePermission[],
): boolean => {
  return expectedPerms.some((permName) => requestedPerms[permName]);
};
