import { Button, Modal } from '@wework/dieter-ui';
import React, { useState } from 'react';

import { FeeForm } from 'components/buildings/fees/FeeForm';

interface Props {
  locationUuid: string;
  disabled?: boolean;
  onSuccess: () => void;
}

export const CreateFeeModal = ({ locationUuid, disabled, onSuccess }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClose = (): void => setIsOpen(false);
  const onOpen = (): void => setIsOpen(true);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeIcon
      size="tiny"
      trigger={
        <Button primary disabled={disabled} onClick={onOpen} style={{ marginBottom: '8px' }}>
          Create a new Fee
        </Button>
      }
    >
      <Modal.Header>Create a new fee</Modal.Header>
      <FeeForm locationUuid={locationUuid} onClose={onClose} onSuccess={onSuccess} />
    </Modal>
  );
};
