import React, { useState } from 'react';
import { Modal } from '@wework/dieter-ui';

import { SpacemanFee } from 'types/spacemanTypes';
import { FeeForm } from 'components/buildings/fees/FeeForm';

interface Props {
  fee: SpacemanFee;
  onSuccess: () => void;
}

export const EditFeeModal = ({ fee, onSuccess }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClose = (): void => setIsOpen(false);
  const onOpen = (): void => setIsOpen(true);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeIcon
      size="tiny"
      trigger={
        <div onClick={onOpen} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
          {fee.name}
        </div>
      }
    >
      <Modal.Header>Update fee</Modal.Header>
      <FeeForm fee={fee} onClose={onClose} onSuccess={onSuccess} />
    </Modal>
  );
};
