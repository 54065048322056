import * as Yup from 'yup';
import { LocationPriceLocationType } from 'types/productCatalogTypes';

export enum LocationPriceFormField {
  LOCATION_UUID = 'locationUuid',
  LOCATION_TYPE = 'locationType',
  PRICE = 'price',
  CURRENCY = 'currency',
}

export const LocationPriceFormSchema = Yup.object().shape({
  locationUuid: Yup.string().required(),
  locationType: Yup.string(),
  price: Yup.string()
    .required()
    .matches(/^[0-9\.]+$/, 'must be a number'),
  currency: Yup.string().required(),
});

export const locationPriceDefaultValues = {
  [LocationPriceFormField.LOCATION_UUID]: '',
  [LocationPriceFormField.LOCATION_TYPE]: LocationPriceLocationType.GEOGROUPING,
  [LocationPriceFormField.PRICE]: '',
  [LocationPriceFormField.CURRENCY]: 'USD',
};
