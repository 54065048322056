import { Tag } from '@wework/dieter-ui';
import React from 'react';
import numeral from 'numeral';
import classNames from 'classnames';

import { SpacemanFee } from 'types/spacemanTypes';
import { Hash } from 'types/global';
import { EditFeeModal } from 'components/buildings/fees/EditFeeModal';
import { TaxApplicableType } from 'utils/taxHelpers';

import 'components/buildings/fees/TaxTableRows.scss';

const displayPercent = (rate: string): string => numeral(rate).format('0.00%');

const taxTypeMap: Hash<string> = {
  [TaxApplicableType.PRODUCT]: 'Product Catalog',
  [TaxApplicableType.RESOURCE]: 'Spaceman',
  [TaxApplicableType.RESERVABLE]: 'Spaceman',
};

export const getTaxSourceLabel = (type: TaxApplicableType): string => taxTypeMap[type];

interface HeaderCellProps {
  fee: SpacemanFee;
  refresh: () => void;
  selectAll?: () => void;
}

export const HeaderCell = ({ fee, refresh, selectAll }: HeaderCellProps): JSX.Element => (
  <>
    <EditFeeModal fee={fee} onSuccess={refresh} />
    <br />
    <Tag color="green" width="fit-content">
      {displayPercent(fee.rate)}
    </Tag>
    <div
      onClick={selectAll}
      className={classNames('selectAll', { activeButton: Boolean(selectAll) })}
      data-test-id={`select-all-${fee.id}`}
    >
      Select All
    </div>
  </>
);
