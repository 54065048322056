import React, { createContext, useContext, useState } from 'react';

import { IEmployeeProfile } from 'utils/auth/authUtils';

export interface IUserContextValue {
  isLoaded: boolean;
  setIsLoaded: (isFetching: boolean) => void;
  userProfile: IEmployeeProfile;
  setUserProfile: (profile: IEmployeeProfile) => void;
}

const nullProfile = {
  email: '',
  firstName: '',
  lastName: '',
  name: '',
  roles: [],
  status: '',
  employeeUuid: '',
  memberUuid: '',
};

const defaultContextValue = {
  isLoaded: false,
  setIsLoaded: (val: boolean): void => {
    val;
  },
  userProfile: nullProfile,
  setUserProfile: (profile: IEmployeeProfile): void => {
    profile;
  },
};

export const UserContext = createContext<IUserContextValue>({ ...defaultContextValue });

export const useUserProfile = (): IEmployeeProfile => useContext(UserContext).userProfile;

interface UserContextProviderProps {
  children: JSX.Element;
}

export const UserContextProvider = ({ children }: UserContextProviderProps): JSX.Element => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<IEmployeeProfile>(nullProfile);

  const contextValue = {
    isLoaded,
    setIsLoaded,
    userProfile,
    setUserProfile,
  };

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};
