import { Form as FormikForm, Formik, FormikHelpers as FormikActions } from 'formik';
import { Button, Form, Header, Segment } from '@wework/dieter-ui';
import { DropdownItemProps } from 'semantic-ui-react';
import React from 'react';

import { ProductFormSelectInput, ProductFormTextInput } from 'components/shared/ProductForm/ProductFormInputs';
import { formatErrors, handleChange } from 'components/shared/Form/utils';
import { IProductGroup } from 'types/productCatalogTypes';
import { createGroup } from 'networking/productCatalog/groupRequests';
import { useFlashMessageContext } from 'contexts/FlashMessageContext';
import {
  CreateGroupFormFields,
  createGroupFormInputConfig,
  initialValues,
  IProductGroupForm,
  schema,
} from 'components/groups/CreateGroup/CreateGroupFormConfig';

interface Props {
  onClose: () => void;
  onSuccess: (groupUuid: string) => void;
  existingGroups: Array<IProductGroup>;
  parentGroup?: IProductGroup;
}

const groupToOption = (group: IProductGroup): DropdownItemProps => ({
  key: group.uuid,
  value: group.uuid,
  text: group.name,
});

export const CreateGroupForm = ({ onClose, onSuccess, existingGroups, parentGroup }: Props): JSX.Element => {
  const { flashError, flashSuccess } = useFlashMessageContext();
  const handleSubmit = async (values: IProductGroupForm, actions: FormikActions<IProductGroupForm>): Promise<void> => {
    actions.setSubmitting(true);

    const castedValues = schema.cast(values);
    const { data, errorData } = await createGroup({
      ...initialValues(),
      name: castedValues.name ?? '',
      slug: castedValues.slug ?? null,
      parentUuid: castedValues.parentUuid,
    });

    if (errorData) {
      if (errorData.fieldErrors) {
        const errors = formatErrors<IProductGroupForm>(errorData.fieldErrors);
        actions.setErrors(errors);
      }
      flashError(`An error has occurred while attempting to create group ${values.name}`);
    } else if (data) {
      flashSuccess(`Group ${values.name} was successfully created`);
      onSuccess(data.uuid);
      onClose();
    }

    actions.setSubmitting(false);
  };

  return (
    <Formik initialValues={initialValues(parentGroup)} validationSchema={schema} onSubmit={handleSubmit}>
      {({ handleSubmit: formikHandleSubmit, isSubmitting, setFieldValue, setFieldTouched }): JSX.Element => (
        <Form as={FormikForm} onSubmit={formikHandleSubmit}>
          <Segment>
            <Header as="h3">Group Details</Header>
            <ProductFormTextInput
              handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
              {...createGroupFormInputConfig[CreateGroupFormFields.NAME]}
            />
            <ProductFormTextInput
              handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
              {...createGroupFormInputConfig[CreateGroupFormFields.SLUG]}
            />
            <ProductFormSelectInput
              options={existingGroups.map(groupToOption)}
              {...createGroupFormInputConfig[CreateGroupFormFields.PARENT_UUID]}
              handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
              disabled={!!parentGroup}
            />
          </Segment>
          <Button primary type="submit" disabled={isSubmitting}>
            Save
          </Button>
          <Button onClick={onClose} data-test-id="create-group-form__cancel">
            Cancel
          </Button>
        </Form>
      )}
    </Formik>
  );
};
