import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { ProductsPage } from 'pages/products/ProductsPage';
import { CreateProductPage } from 'pages/products/CreateProductPage';
import { EditProductPage } from 'pages/products/EditProductPage';
import { CREATABLE_PERMISSIONS } from 'utils/auth/authUtils';
import { RequireAuthorization } from 'helpers/RequireAuthorization/RequireAuthorization';
import { NotFoundPage } from 'pages/NotFoundPage/NotFoundPage';

export enum PRODUCT_ROUTES {
  CREATE_PRODUCT = '/:catalog_uuid/products/new',
  EDIT_PRODUCT = '/:catalog_uuid/products/:uuid/edit',
  ROOT = '/:catalog_uuid/products',
}

export const ProductsRouter = (): JSX.Element => {
  return (
    <section>
      <Routes>
        <Route path="/" element={<ProductsPage />} />
        <Route
          path="new"
          element={
            <RequireAuthorization authPerms={CREATABLE_PERMISSIONS}>
              <CreateProductPage />
            </RequireAuthorization>
          }
        />
        <Route path=":uuid/edit/*" element={<EditProductPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </section>
  );
};
