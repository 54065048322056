import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { BuildingsPage } from 'pages/buildings/BuildingsPage';
import { BuildingPage } from 'pages/buildings/BuildingPage';
import { NotFoundPage } from 'pages/NotFoundPage/NotFoundPage';

export enum BUILDING_ROUTES {
  VIEW_BUILDING = '/:catalog_uuid/buildings/:uuid',
  ROOT = '/:catalog_uuid/buildings',
}

export const BuildingsRouter = (): JSX.Element => {
  return (
    <section>
      <Routes>
        <Route path="/" element={<BuildingsPage />} />
        <Route path=":uuid/*" element={<BuildingPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </section>
  );
};
