import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import numeral from 'numeral';

export const nullifyIfBlank = (value: string | null): string | null => (value && value.length > 0 ? value : null);

export const isNumericLooking = (s: string): boolean => !!s.match(/^[0-9]+$/);

export const nullifyNumberIfBlank = (value: number | string | null): string | null => {
  if (isNumber(value)) {
    return String(value);
  } else if (isString(value) && isNumericLooking(value)) {
    return value;
  }
  return null;
};

export const csvToArray = (value: string): string[] =>
  value
    .split(',')
    .map((id) => id.trim())
    .filter((id) => id.length > 0);

export const multiplyBy100 = (value: string): number | null =>
  value && value.length ? Math.round(parseFloat(value) * 100) : null;

export const multiplyBy = (value: string, times: number): string => numeral(value).multiply(times).format('0.0[0000]');

export const divideBy100 = (value: string): string | null =>
  value && value.length ? numeral(value).divide(100).format('0.0[0000]') : null;
