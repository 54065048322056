import { Dropdown } from '@wework/dieter-ui';
import { Input, DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import { Field, FieldProps } from 'formik';
import React, { useState } from 'react';

import { FormValue, IGenericFormInputProps } from 'components/shared/Form/FormConfig';
import { fieldError } from 'components/shared/Form/utils';

interface ILocationAttributeProps extends IGenericFormInputProps<FormValue> {
  options: DropdownItemProps[];
}

const LocationAttributeSelectInput = ({
  options,
  name,
  handleOnChange,
  labelText,
  disabled,
}: ILocationAttributeProps): JSX.Element => (
  <Field name={name} id={name}>
    {({ form, field }: FieldProps): JSX.Element => (
      <div>
        <label htmlFor="dropdown" style={{ marginRight: '15px' }}>
          {labelText}{' '}
        </label>
        <Dropdown
          {...field}
          label={labelText}
          disabled={disabled}
          options={options}
          onChange={(_, { value = '' }: DropdownProps): void => {
            handleOnChange(name, value);
          }}
          error={!!fieldError(form.touched, form.errors, name)}
          compact
        />
      </div>
    )}
  </Field>
);

export { LocationAttributeSelectInput };
