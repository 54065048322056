import { Dropdown } from '@wework/dieter-ui';
import { Input, DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import { Field, FieldProps } from 'formik';
import React, { useEffect, useRef } from 'react';

import { FormValue, IGenericFormInputProps } from 'components/shared/Form/FormConfig';
import { fieldError } from 'components/shared/Form/utils';

const LocationPriceFormPriceInput = ({
  handleOnChange,
  inputType,
  labelText,
  name,
  placeholder,
  disabled,
  readOnly,
  autoSelect,
}: IGenericFormInputProps<FormValue>): JSX.Element => {
  const inputRef = useRef<Input>(null);

  useEffect(() => {
    if (autoSelect && inputRef.current) {
      inputRef.current.select();
    }
  }, []);

  return (
    <Field name={name} id={name}>
      {({ form, field }: FieldProps): JSX.Element => (
        <Input
          {...field}
          autoComplete="off"
          onChange={({ currentTarget }, { value }): void => {
            handleOnChange(currentTarget.name, value);
          }}
          error={!!fieldError(form.touched, form.errors, name)}
          label={labelText}
          type={inputType}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          ref={inputRef}
        />
      )}
    </Field>
  );
};

interface ILocationPriceFormSelectInputProps extends IGenericFormInputProps<FormValue> {
  options: DropdownItemProps[];
}

const LocationPriceFormSelectInput = ({
  options,
  name,
  placeholder,
  handleOnChange,
  labelText,
  disabled,
  readOnly,
}: ILocationPriceFormSelectInputProps): JSX.Element => (
  <Field name={name} id={name}>
    {({ form, field }: FieldProps): JSX.Element => (
      <Dropdown
        {...field}
        selection
        search
        label={labelText}
        icon="dropdown"
        openOnFocus
        closeOnBlur
        closeOnEscape
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        options={options}
        onChange={(_, { value = '' }: DropdownProps): void => {
          handleOnChange(name, Array.isArray(value) ? value[0] : value);
        }}
        error={!!fieldError(form.touched, form.errors, name)}
        compact
      />
    )}
  </Field>
);

export { LocationPriceFormPriceInput, LocationPriceFormSelectInput };
