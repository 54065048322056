import React from 'react';
import { SemanticCOLORS, Tag } from '@wework/dieter-ui';
import { ILocationListingProduct } from 'types/productCatalogTypes';
import { formatPriceCurrency } from 'utils/displayHelpers';

const NamedTag = ({ color, label }: { color: SemanticCOLORS; label: string }): JSX.Element => (
  <Tag color={color}>{label}</Tag>
);

interface ILabelListingProductElementProps {
  listingProduct: ILocationListingProduct;
}
export function LabelListingProductElement({ listingProduct }: ILabelListingProductElementProps): JSX.Element {
  const tags: Array<{ color: SemanticCOLORS; label: string }> = [];

  if (!listingProduct.price) {
    tags.push({ color: 'red', label: 'Not Priced' });
  } else {
    const { price, currency } = listingProduct.price;
    tags.push({ color: 'green', label: formatPriceCurrency(price, currency) });
  }

  if (!listingProduct.available) {
    tags.push({ color: 'red', label: 'Not Available' });
  }

  return (
    <span className="availability-form--tree-text">
      {listingProduct.name}{' '}
      {tags.map((tag, index) => (
        <NamedTag color={tag.color} label={tag.label} key={index} />
      ))}
    </span>
  );
}
