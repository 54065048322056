import React, { createContext, useContext, useState } from 'react';
import { useAsyncEffect } from 'use-async-effect';
import { useAuth0 } from '@auth0/auth0-react';

import { getCatalogs } from 'networking/productCatalog/catalogRequest';
import { ICatalog } from 'types/productCatalogTypes';
import { useFlashMessageContext } from './FlashMessageContext';

export interface ICatalogContextValue {
  isLoading: boolean;
  isInitialized: boolean;
  selectedCatalog: ICatalog | null;
  catalogs: ICatalog[];
  setSelectedCatalog: (selectedCatalog: ICatalog | null) => void;
}

const defaultContextValue = {
  isLoading: true,
  isInitialized: false,
  selectedCatalog: null,
  catalogs: [],
  setSelectedCatalog: (selectedCatalog: ICatalog | null): void => {
    selectedCatalog;
  },
};

export const CatalogContext = createContext<ICatalogContextValue>({ ...defaultContextValue });

export const useCatalog = (): ICatalogContextValue => useContext(CatalogContext);

interface CatalogContextProviderProps {
  children: JSX.Element;
}

export const CatalogContextProvider = ({ children }: CatalogContextProviderProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [catalogs, setCatalogs] = useState<ICatalog[]>([]);
  const [selectedCatalog, setSelectedCatalog] = useState<ICatalog | null>(null);

  const { isAuthenticated } = useAuth0();
  const { flashError } = useFlashMessageContext();

  useAsyncEffect(
    async (isActive) => {
      if (!isInitialized && isAuthenticated) {
        setIsLoading(true);
        const { data, error } = await getCatalogs();
        if (!isActive()) return;

        if (!error) {
          data && setCatalogs(data);
        } else {
          flashError(
            error
              ? `An error has occurred while processing catalogs request. ${error}`
              : 'An error has occurred while processing catalogs request',
          );
        }
        setIsInitialized(true);
        setIsLoading(false);
      }
    },
    [isAuthenticated, isInitialized, setIsInitialized],
  );

  const contextValue = {
    isLoading,
    isInitialized,
    selectedCatalog,
    catalogs,
    setSelectedCatalog,
  };

  return <CatalogContext.Provider value={contextValue}>{children}</CatalogContext.Provider>;
};
