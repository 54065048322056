import React from 'react';
import { createPortal } from 'react-dom';
import { ButtonProps } from 'semantic-ui-react';
import { Button, Container } from '@wework/dieter-ui';

import './formActionDrawer.scss';

const { body } = document;

export interface FormAction extends ButtonProps {
  key: string;
}

type Props = {
  actions: FormAction[];
};

const defaults: ButtonProps = {
  size: 'large',
};

export const FormActionDrawer = ({ actions }: Props): JSX.Element | null => {
  const element = (
    <div className="form-actions-drawer--container alchemist-fade-in">
      <Container textAlign="right">
        {actions.map(({ key, ...props }) => (
          <Button key={key} {...defaults} {...props} />
        ))}
      </Container>
    </div>
  );

  return createPortal(element, body);
};
