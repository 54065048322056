import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import { IProduct } from 'types/productCatalogTypes';
import { getProduct } from 'networking/productCatalog/productRequests';
import { LoadingContainer } from 'components/shared/Loading/LoadingContainer';
import { useCachedCollection } from 'hooks/useCachedCollection';
import { BaseUrlParamsType } from 'types/router';
import { ProductsDraggableTable } from './ProductsDraggableTable/ProductsDraggableTable';

interface IProductsListInputProps {
  productUuids: string[];
  editable?: boolean;
  onChange?: (productUuids: string[]) => void;
}

const ProductsListInput = ({ productUuids, editable, onChange }: IProductsListInputProps): JSX.Element => {
  const [error, setError] = useState<string | undefined>(undefined);
  const { catalogUuid } = useParams() as BaseUrlParamsType;

  const productLoader = async (uuid: string): Promise<IProduct | undefined> => {
    const { data, error } = await getProduct(uuid, catalogUuid);
    if (error || !data) {
      setError(error || 'Failed to retrieve product details');
    }
    return data;
  };

  const [products, loading] = useCachedCollection<IProduct>(productLoader, productUuids);

  return (
    <LoadingContainer loading={loading} data={products} error={error}>
      {products.length ? (
        <ProductsDraggableTable products={products} editable={!!editable} onChange={onChange} />
      ) : (
        <div>Empty</div>
      )}
    </LoadingContainer>
  );
};

export { ProductsListInput };
