import React, { useState, useMemo, useEffect } from 'react';
import { Dropdown } from '@wework/dieter-ui';
import { Field, FieldProps } from 'formik';
import { DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import { useDebouncedCallback } from 'use-debounce';
import { find, isArray, isEmpty, uniqBy, identity } from 'lodash';
import classNames from 'classnames';

import { IGenericFormInputProps } from 'components/shared/Form/FormConfig';
import { fieldError, formValueToStringArray } from 'components/shared/Form/utils';

interface ISearchSelectInputProps extends IGenericFormInputProps<string[] | string> {
  searchQueryResolver: (query: string) => Promise<DropdownItemProps[]>;
  valuesResolver: (values: string[]) => Promise<DropdownItemProps[]>;
  multiple?: boolean;
  externalError?: string;
}

const optionKeys = (options: DropdownItemProps[]): string => options.map((o) => o.key).join('|');

export const SearchSelectInput = ({
  name,
  placeholder,
  handleOnChange,
  labelText,
  searchQueryResolver,
  valuesResolver,
  multiple = false,
  disabled = false,
  externalError,
}: ISearchSelectInputProps): JSX.Element => {
  const [selectedOptions, setSelectedOptions] = useState<DropdownItemProps[]>([]);
  const [searchOptions, setSearchOptions] = useState<DropdownItemProps[]>([]);
  const [loading, setLoading] = useState(true);

  const updateSearchOptions = async (query: string): Promise<void> =>
    setSearchOptions(await searchQueryResolver(query));

  const debouncedUpdateSearchOptions = useDebouncedCallback(updateSearchOptions, 200);

  const allOptions = useMemo(
    () => uniqBy([...searchOptions, ...selectedOptions], 'key'),
    [optionKeys(searchOptions), optionKeys(selectedOptions)],
  );

  const updateSelectedOptions = async (value: string[] | string): Promise<void> => {
    if (!isEmpty(value)) {
      const values = isArray(value) ? value : [value];
      const existingOptions: DropdownItemProps[] = [];
      const missingIds: string[] = [];

      values.forEach((key) => {
        const option = find(selectedOptions, { key }) || find(searchOptions, { key });
        option ? existingOptions.push(option) : missingIds.push(key);
      });

      const missingOptions = missingIds.length === 0 ? [] : await valuesResolver(missingIds);
      setSelectedOptions([...existingOptions, ...missingOptions]);
    }

    if (loading) {
      setLoading(false);
    }
  };

  const labelClass = classNames('product-form__label', { disabled: disabled });

  return (
    <Field name={name} id={name}>
      {({ form, field }: FieldProps): JSX.Element => {
        // Get names for records already in the form field
        useEffect(() => {
          updateSelectedOptions(field.value);
        }, []);

        return (
          <div className="product-form__dropdown">
            {labelText && <label className={labelClass}>{labelText}</label>}
            <Dropdown
              {...field}
              selection
              // Circumvent default Dropdown option filtering since we supply the options already ordered
              search={identity}
              multiple={multiple}
              fluid
              label={labelText}
              icon="dropdown"
              openOnFocus
              closeOnBlur
              closeOnEscape
              loading={loading}
              disabled={disabled}
              placeholder={placeholder}
              options={allOptions}
              onChange={(_, { value }: DropdownProps): void => {
                const arrayValues: string[] = formValueToStringArray(value);
                updateSelectedOptions(arrayValues);
                handleOnChange(name, multiple ? arrayValues : arrayValues[0]);
              }}
              onSearchChange={(_, { searchQuery }): void => {
                debouncedUpdateSearchOptions(searchQuery);
              }}
              error={!!fieldError(form.touched, form.errors, name) || !isEmpty(externalError)}
            />
          </div>
        );
      }}
    </Field>
  );
};
