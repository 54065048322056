import { stringify } from 'query-string';
import { IExtendedFetchResult, RequestMethods } from 'networking/fetchConfig';
import { IProduct } from 'types/productCatalogTypes';

import { productCatalogFetch } from './productCatalogFetch';

export function getFees(product: IProduct, catalog_uuid: string): Promise<IExtendedFetchResult<IProduct[]>> {
  const query = stringify({ catalog_uuid });
  return productCatalogFetch<IProduct[]>(RequestMethods.GET, `/v2/products/${product.uuid}/fees?${query}`);
}

export function createFee(
  product: IProduct,
  feeUuid: IProduct['uuid'],
  catalog_uuid: string,
): Promise<IExtendedFetchResult<null>> {
  const query = stringify({ catalog_uuid });
  return productCatalogFetch<null>(RequestMethods.POST, `/v2/products/${product.uuid}/fees/${feeUuid}?${query}`);
}

export function deleteFee(
  product: IProduct,
  feeUuid: IProduct['uuid'],
  catalog_uuid: string,
): Promise<IExtendedFetchResult<null>> {
  const query = stringify({ catalog_uuid });
  return productCatalogFetch<null>(RequestMethods.DELETE, `/v2/products/${product.uuid}/fees/${feeUuid}?${query}`);
}
