import React from 'react';
import { Button, Header, Segment } from '@wework/dieter-ui';

import { useParams } from 'react-router-dom';
import {
  applyTranslationData,
  refreshSalesItemCache,
  clearOperatorCache,
  refreshLocationApiCache,
  uploadTranslationRawData,
  refreshAlgoliaCache,
} from 'networking/productCatalog/adminRequests';
import { useFlashMessageContext } from 'contexts/FlashMessageContext';
import { AsyncButton } from 'components/shared/AsyncButton/AsyncButton';
import { clear } from 'utils/sessionStore';
import { IExtendedFetchResult } from 'networking/fetchConfig';
import { BaseUrlParamsType } from 'types/router';

function didSucceed<T>(result: IExtendedFetchResult<T>): boolean {
  return !result.error;
}

export const AdminPage = (): JSX.Element => {
  const { flashSuccess, flashError } = useFlashMessageContext();
  const { catalogUuid } = useParams() as BaseUrlParamsType;

  return (
    <div>
      <Header as="h2">Welcome Admin!</Header>
      <Segment>
        <Header as="h3">Manage Translation data</Header>
        <AsyncButton
          primary
          onClick={(): Promise<IExtendedFetchResult<null>> => uploadTranslationRawData(catalogUuid)}
          onSuccess={(): void => flashSuccess('Successfully uploaded data for translation.')}
          onError={(result): void => flashError(`Error uploading translation data: ${result.error}.`)}
          didSucceed={didSucceed}
          disabled={!catalogUuid}
          data-test-id="upload-translations"
        >
          Upload strings for translation
        </AsyncButton>
        <AsyncButton
          color="red"
          onClick={(): Promise<IExtendedFetchResult<null>> => applyTranslationData(catalogUuid)}
          onSuccess={(): void => flashSuccess('Successfully applied translation data.')}
          onError={(result): void => flashError(`Error applying translation data: ${result.error}.`)}
          didSucceed={didSucceed}
          disabled={!catalogUuid}
          data-test-id="apply-translations"
        >
          Apply translations
        </AsyncButton>
      </Segment>
      <Segment>
        <Header as="h3">Clear Cache</Header>
        <AsyncButton
          onClick={clearOperatorCache}
          onSuccess={(): void => flashSuccess('Successfully cleared Operator Service cache.')}
          onError={(result): void => flashError(`Error clearing Operator Service cache: ${result.error}.`)}
          didSucceed={didSucceed}
          data-test-id="clear-operator-service-cache-all"
        >
          Clear Operator Service Cache
        </AsyncButton>
        <Button onClick={clear}>Clear Alchemist Cache</Button>
      </Segment>
      <Segment>
        <Header as="h3">Refresh Cache</Header>
        <AsyncButton
          onClick={refreshLocationApiCache}
          onSuccess={(): void => flashSuccess('Successfully refreshed Location API cache.')}
          onError={(result): void => flashError(`Error refreshing Location API cache: ${result.error}.`)}
          didSucceed={didSucceed}
          data-test-id="clear-location-api-cache"
        >
          Refresh Location API Cache
        </AsyncButton>
        <AsyncButton
          onClick={refreshSalesItemCache}
          onSuccess={(): void => flashSuccess('Successfully refreshed Sales Items cache.')}
          onError={(result): void => flashError(`Error refreshing Sales Items cache: ${result.error}.`)}
          didSucceed={didSucceed}
          data-test-id="clear-sales-items-cache"
        >
          Refresh Sales Items Cache
        </AsyncButton>
        <AsyncButton
          onClick={refreshAlgoliaCache}
          onSuccess={(): void => flashSuccess('Successfully refreshed Algolia cache.')}
          onError={(result): void => flashError(`Error refreshing Algolia cache: ${result.error}.`)}
          didSucceed={didSucceed}
          data-test-id="clear-algolia-cache"
        >
          Refresh Algolia Cache
        </AsyncButton>
      </Segment>
    </div>
  );
};
