import { SETTINGS } from 'configs/settings';
import { IFetchResult, RequestMethods } from 'networking/fetchConfig';
import { getAuthClient } from 'networking/auth/client';
import { parseJSON } from 'utils/parserHelpers';

const { SPACEMAN_URI } = SETTINGS;

export interface ISpacemanFetchError {
  error: string;
}

export async function spacemanFetch<T>(
  method: RequestMethods,
  path: RequestInfo,
  body?: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  options: RequestInit = {},
): Promise<IFetchResult<T>> {
  const authClient = getAuthClient();
  const accessToken = await authClient.getAccessTokenSilently();
  const response = await fetch(SPACEMAN_URI + path, {
    method,
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token token=${accessToken}`,
    },
    ...options,
  });

  let data;
  let errorData;
  if (response.ok) {
    data = await parseJSON<T>(response);
  } else {
    errorData = await parseJSON<SpacemanVerboseResponse<ISpacemanFetchError>>(response);
  }

  return { data, error: errorData?.result?.error, status: response.status };
}

export interface SpacemanVerboseResponse<T> {
  // eslint-disable-next-line  @typescript-eslint/ban-types
  meta: {};
  // eslint-disable-next-line  @typescript-eslint/ban-types
  params: {};
  result: T;
}
