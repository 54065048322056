import { useState } from 'react';
import { OperatorServicePermissionsMap } from '@wework/we-auth-react';
import { useAsyncEffect } from 'use-async-effect';
import { forEach, isEmpty, size, filter } from 'lodash';

import { useCatalog } from 'contexts/CatalogContext';
import { useUserProfile } from 'contexts/UserContext';
import { hasPermissionsByOperator } from 'networking/operatorService/permissionsByOperatorRequests';
import { AlchemistEmployeePermission } from 'utils/auth/authUtils';

const cahedPermissions: Record<string, OperatorServicePermissionsMap> = {};
const setCachedEntries = (operatorUuid: string, permissions: OperatorServicePermissionsMap): void => {
  cahedPermissions[operatorUuid] = cahedPermissions[operatorUuid] || [];
  forEach(permissions, (value, permission) => {
    cahedPermissions[operatorUuid][permission] = value;
  });
};

const getCachedPermissions = (
  operatorUuid: string,
  permissions: AlchemistEmployeePermission[],
): OperatorServicePermissionsMap => {
  const result: OperatorServicePermissionsMap = {};
  forEach(permissions, (permission) => {
    if (cahedPermissions[operatorUuid] && cahedPermissions[operatorUuid][permission]) {
      result[permission] = cahedPermissions[operatorUuid][permission];
    }
  });

  return result;
};

export const resetCachedPermissions = (): void => {
  forEach(cahedPermissions, (_, operatorId) => {
    forEach(cahedPermissions[operatorId], (_, permission) => delete cahedPermissions[operatorId][permission]);
    delete cahedPermissions[operatorId];
  });
};

export function usePermissionsByOperator(
  permissions: AlchemistEmployeePermission[],
): [boolean, string | undefined, OperatorServicePermissionsMap] {
  const { employeeUuid } = useUserProfile();
  const { selectedCatalog } = useCatalog();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [requestedPerms, setRequestedPerms] = useState<OperatorServicePermissionsMap>({});

  useAsyncEffect(
    async (isActive) => {
      if (!isActive()) return;

      if (isEmpty(permissions)) {
        setLoading(false);
        return;
      }

      if (selectedCatalog && employeeUuid) {
        const cache = getCachedPermissions(selectedCatalog?.operatorUuid, permissions);
        if (size(cache) === size(permissions)) {
          setRequestedPerms(cache);
          setLoading(false);
          return;
        }

        const requestPerms = filter(permissions, (permission) => cache[permission] === undefined);
        const { data, error } = await hasPermissionsByOperator(
          employeeUuid,
          selectedCatalog.operatorUuid,
          requestPerms,
        );
        if (!isActive()) return;
        error && setError(error);
        data && setRequestedPerms({ ...cache, ...data });
        data && setCachedEntries(selectedCatalog?.operatorUuid, data);
        setLoading(false);
      }
    },
    [employeeUuid, selectedCatalog?.operatorUuid, permissions],
  );

  return [loading, error, requestedPerms];
}
