import React, { MouseEvent, useState } from 'react';
import Truncate from 'react-truncate';

interface IExpandableTextProps {
  text: string;
  lines: number;
}

const ExpandableText = ({ text, lines }: IExpandableTextProps): JSX.Element => {
  const [expanded, setExpanded] = useState(false);
  const [truncated, setTruncated] = useState(false);

  const toggleLines = (event: MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    setExpanded(!expanded);
  };

  return (
    <div className={'expandable_text'}>
      <Truncate
        lines={!expanded && lines}
        ellipsis={
          <span>
            <a href="#" onClick={toggleLines} className={'expandable_text_toggle'}>
              ... Show more
            </a>
          </span>
        }
        onTruncate={setTruncated}
      >
        {text}
      </Truncate>
      {!truncated && expanded && (
        <span>
          <a href="#" onClick={toggleLines} className={'expandable_text_toggle'}>
            {' Show less'}
          </a>
        </span>
      )}
    </div>
  );
};

export { ExpandableText };
