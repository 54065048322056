import React, { useState, useEffect } from 'react';
import { Form, Header, Segment, DropdownItemProps, Message } from '@wework/dieter-ui';
import { Form as FormikForm, Formik, FormikHelpers as FormikActions, FormikErrors } from 'formik';
import { isEqual, isEmpty, isUndefined, compact } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { FileRejection } from 'react-dropzone';

import { RequestType } from 'components/shared/Form/FormConfig';
import {
  defaultValues,
  IProductFormAttributesInput,
  IProductFormInput,
  ProductFormField,
  productFormProductCategoryConfig,
  ProductFormSchema,
  ProductFormValue,
  productFormValuesToPayload,
} from 'components/shared/ProductForm/productFormConfig';
import { salesItemSearchQueryResolver, salesItemIdsResolver } from 'components/shared/ProductForm/productFormHelpers';
import {
  ProductFormCheckboxInput,
  ProductFormSelectInput,
  ProductFormTextareaInput,
  ProductFormTextInput,
  ProductFormImageCarousel,
  ProductFormDropZoneImageInput,
} from 'components/shared/ProductForm/ProductFormInputs';
import { productFormInputConfig } from 'components/shared/ProductForm/ProductFormInputs/productFormInputConfig';
import { useFlashMessageContext } from 'contexts/FlashMessageContext';

import {
  activateProduct,
  archiveProduct,
  createProduct,
  updateProduct,
  getProductImages,
} from 'networking/productCatalog/productRequests';
import {
  IProduct,
  IImage,
  IWorkdaySalesItems,
  ProductStatus,
  ImagePreview,
  AuditActionType,
} from 'types/productCatalogTypes';
import { formatErrors } from 'components/shared/Form/utils';
import { FormActionDrawer } from 'components/shared/FormActionDrawer/FormActionDrawer';
import { SearchSelectInput } from 'components/shared/Form/SearchSelectInput';
import { BaseUrlParamsType } from 'types/router';
import { ArchiveProductModal } from './ArchiveProductModal';
import AuditTrailInfo from './AuditTrailInfo';

interface IProductFormProps {
  product?: IProduct;
  productImages?: IImage[];
  requestType: RequestType;
  salesItems: IWorkdaySalesItems[];
  salesItemsError?: string;
  canBeActivated?: boolean;
  canBeArchived?: boolean;
  refreshProduct?: () => void;
}

const ProductForm = ({
  product,
  productImages,
  requestType,
  salesItems,
  salesItemsError,
  canBeActivated,
  canBeArchived,
  refreshProduct,
}: IProductFormProps): JSX.Element => {
  const navigate = useNavigate();
  const { catalogUuid } = useParams() as BaseUrlParamsType;
  const { flashError, flashSuccess } = useFlashMessageContext();

  const {
    NAME,
    DESCRIPTION,
    NOTES,
    VOLUME_LIMIT,
    EXTERNAL_VENDOR_URL,
    IMAGES,
    BILLING_ENTITY_ID,
    CREDIT_ALLOTMENT,
    OVERRIDE_DAILY_DESK_CREDIT_PRICE,
    DAILY_DESK_CREDIT_PRICE,
    RESOURCE_ID,
    RESTRICT_PROMOCODE_KIND,
    PRINT_BW_ALLOTMENT,
    PRINT_COLOR_ALLOTMENT,
    WELCOME_EMAIL_TEMPLATE_SLUG,
    FULFILLMENT_EMAIL,
    CONSULTATION_EMAIL_TEMPLATE_SLUG,
    FULFILLMENT_EMAIL_TEMPLATE_SLUG,
    PURCHASE_IMMEDIATELY,
    REQUEST_CONSULTATION,
    LIMITED_AVAILABILITY,
    ONE_TIME,
    REFUNDABLE,
    WAIVABLE,
    SALE_ITEM_GROUP,
    SUBMIT_ERROR,
    UUID,
    BANDWIDTH_CAPACITY_LOWER_BOUND,
    BANDWIDTH_CAPACITY_UPPER_BOUND,
    AVAILABILITY_CONFIGURATION,
    BOOKING_CANCELLATION_POLICY,
    SALES_ITEM_ID,
  } = ProductFormField;

  const initialValues = product ? populateForm(defaultValues, product) : defaultValues;
  const isEditable = !requestTypeIs(RequestType.READ);

  //state vars for current and updating product image
  //ths is to store the image array for the payload to product service
  //this is to store the base64 encoded file string for preview
  const productPayloadImagesDefaultValue = productImages as IImage[];
  const [productPayloadImages, setProductPayloadImages] = useState<IImage[]>(productPayloadImagesDefaultValue);
  useEffect(() => {
    setProductPayloadImages(productPayloadImagesDefaultValue);
  }, [productImages]);

  const productPreviewImagesDefaultValue: ImagePreview[] = productImages
    ? productImages?.map((img) => ({
        url: img.url as string,
        tag: img.tag,
      }))
    : [];
  const [productPreviewImages, setProductPreviewImages] = useState<ImagePreview[]>(productPreviewImagesDefaultValue);
  useEffect(() => {
    setProductPreviewImages(productPreviewImagesDefaultValue);
  }, [productImages]);

  //state vars for archive check
  const [displayArchiveModal, setDisplayArchiveModal] = useState<boolean>(false);
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={ProductFormSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleSubmit: formikHandleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          setSubmitting,
          setErrors,
          submitForm,
          values,
          dirty,
        }): JSX.Element => (
          <Form as={FormikForm} onSubmit={formikHandleSubmit}>
            <Segment>
              <Header as="h3">Description</Header>
              {product?.createdAt ? (
                <AuditTrailInfo
                  action={AuditActionType.CREATED}
                  date={product?.createdAt}
                  userInfo={product?.createdByInfo}
                />
              ) : null}
              {product?.updatedAt ? (
                <AuditTrailInfo
                  action={AuditActionType.UPDATED}
                  date={product?.updatedAt}
                  userInfo={product?.lastModifiedByInfo}
                />
              ) : null}
              <ProductFormTextInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[NAME]}
                disabled={!isEditable}
              />
              <ProductFormTextInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[UUID]}
                disabled={!requestTypeIs(RequestType.CREATE)}
              />
              <ProductFormSelectInput
                options={productFormProductCategoryConfig}
                {...productFormInputConfig[SALE_ITEM_GROUP]}
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                disabled={!isEditable}
              />
              <ProductFormTextInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[DESCRIPTION]}
                disabled={!isEditable}
              />
              <ProductFormTextInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[EXTERNAL_VENDOR_URL]}
                disabled={!isEditable}
              />
            </Segment>

            <Segment>
              <ProductFormDropZoneImageInput
                isCreate={requestTypeIs(RequestType.CREATE)}
                createPlaceholder="Click Or Drag Product Image Here To Add..."
                updatePlaceholder="Click Or Drag Product Image Here To Update..."
                onDragCreatePlaceholder="Drop Image Here To Add"
                onDragUpdatePlaceholder="Drop Image Here To Update"
                //need to specify an empty handleOnChange since it is the onDropAccepted which the value is handled
                onDropRejected={(fileRejections: FileRejection[]): void =>
                  flashError(`${fileRejections[0].errors[0].message}`)
                }
                onDropAccepted={(values): void => handleImageAdded(values, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[IMAGES]}
                readOnly={!isEditable}
              />
              <ProductFormImageCarousel
                imagePreviews={productPreviewImages}
                onImageRemove={(imageNumber: number): void =>
                  handleImageRemoved(imageNumber, setFieldValue, setFieldTouched)
                }
                onImageTagChange={(imageNumber: number, imageTag: string | undefined): void =>
                  handleImageTagChanged(imageNumber, imageTag, setFieldValue, setFieldTouched)
                }
              />
            </Segment>

            <Segment>
              <Header as="h3">Billing Details</Header>
              <ProductFormCheckboxInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[REFUNDABLE]}
                disabled={!isEditable}
              />
              <ProductFormCheckboxInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[WAIVABLE]}
                disabled={!isEditable}
              />
              <ProductFormCheckboxInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[ONE_TIME]}
                disabled={!isEditable}
              />
              <ProductFormTextInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[RESTRICT_PROMOCODE_KIND]}
                disabled={!isEditable}
              />
              <ProductFormTextInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[BILLING_ENTITY_ID]}
                disabled={!isEditable}
              />
              <ProductFormTextInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[RESOURCE_ID]}
                disabled={!isEditable}
              />
              <SearchSelectInput
                searchQueryResolver={(query): Promise<DropdownItemProps[]> =>
                  salesItemSearchQueryResolver(salesItems, query)
                }
                valuesResolver={(values: string[]): Promise<DropdownItemProps[]> =>
                  salesItemIdsResolver(salesItems, values)
                }
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                disabled={!isEditable || !isEmpty(salesItemsError)}
                externalError={salesItemsError}
                {...productFormInputConfig[SALES_ITEM_ID]}
              />
              {salesItemsError && (
                <Message className="sales-items-error" negative size="tiny">
                  {salesItemsError}
                </Message>
              )}
            </Segment>

            <Segment>
              <Header as="h3">Configuration Details</Header>
              <ProductFormTextInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[CREDIT_ALLOTMENT]}
                disabled={!isEditable}
              />
              <ProductFormTextInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[PRINT_BW_ALLOTMENT]}
                disabled={!isEditable}
              />
              <ProductFormTextInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[PRINT_COLOR_ALLOTMENT]}
                disabled={!isEditable}
              />
              <ProductFormCheckboxInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[OVERRIDE_DAILY_DESK_CREDIT_PRICE]}
                disabled={!isEditable}
              />
              {values[OVERRIDE_DAILY_DESK_CREDIT_PRICE] && (
                <ProductFormTextInput
                  handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                  {...productFormInputConfig[DAILY_DESK_CREDIT_PRICE]}
                  disabled={!isEditable}
                />
              )}
            </Segment>

            <Segment>
              <Header as="h3">Schedule Configuration</Header>
              <ProductFormTextInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[AVAILABILITY_CONFIGURATION]}
                disabled={!isEditable}
              />
              <ProductFormTextInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[BOOKING_CANCELLATION_POLICY]}
                disabled={!isEditable}
              />
            </Segment>

            <Segment>
              <Header as="h3">Bandwidth Details</Header>
              <ProductFormTextInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[BANDWIDTH_CAPACITY_LOWER_BOUND]}
                disabled={!isEditable}
              />
              <ProductFormTextInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[BANDWIDTH_CAPACITY_UPPER_BOUND]}
                disabled={!isEditable}
              />
            </Segment>

            <Segment>
              <Header as="h3">Fulfillment Details</Header>
              <ProductFormCheckboxInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[PURCHASE_IMMEDIATELY]}
                disabled={!isEditable}
              />
              <ProductFormCheckboxInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[REQUEST_CONSULTATION]}
                disabled={!isEditable}
              />
              <ProductFormCheckboxInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[LIMITED_AVAILABILITY]}
                disabled={!isEditable}
              />
              <ProductFormTextInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[WELCOME_EMAIL_TEMPLATE_SLUG]}
                disabled={!isEditable}
              />
              <ProductFormTextInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[FULFILLMENT_EMAIL]}
                disabled={!isEditable}
              />
              <ProductFormTextInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[CONSULTATION_EMAIL_TEMPLATE_SLUG]}
                disabled={!isEditable}
              />
              <ProductFormTextInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[FULFILLMENT_EMAIL_TEMPLATE_SLUG]}
                disabled={!isEditable}
              />
              <ProductFormTextInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[VOLUME_LIMIT]}
                disabled={!isEditable}
              />
              <ProductFormTextareaInput
                handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                {...productFormInputConfig[NOTES]}
                disabled={!isEditable}
              />
            </Segment>

            <p>{errors[SUBMIT_ERROR]}</p>

            {(isEditable || canBeArchived || canBeActivated) && (
              <FormActionDrawer
                actions={compact([
                  canBeArchived &&
                    statusIs(ProductStatus.ACTIVE) && {
                      key: 'archive',
                      color: 'red',
                      content: 'Archive product',
                      'data-test-id': 'archive',
                      disabled: isSubmitting,
                      onClick: (): void => setDisplayArchiveModal(true),
                    },
                  canBeActivated &&
                    statusIs(ProductStatus.PENDING) && {
                      key: 'activate',
                      color: 'green',
                      content: 'Activate product',
                      'data-test-id': 'activate',
                      disabled: isSubmitting,
                      onClick: (): Promise<void> => confirmActivate(values.uuid, setErrors, setSubmitting),
                    },
                  canBeActivated &&
                    statusIs(ProductStatus.INACTIVE) && {
                      key: 'reactivate',
                      color: 'green',
                      content: 'Reactivate product',
                      'data-test-id': 'reactivate',
                      disabled: isSubmitting,
                      onClick: (): Promise<void> => confirmReactivate(values.uuid, setErrors, setSubmitting),
                    },
                  isEditable && {
                    key: 'submit',
                    content: submitButtonText(),
                    onClick: submitForm,
                    primary: true,
                    disabled: isSubmitting || !dirty,
                    loading: isSubmitting,
                  },
                ])}
              />
            )}
            {displayArchiveModal && (
              <ArchiveProductModal
                onClick={(): Promise<void> => confirmArchive(values.uuid, setErrors, setSubmitting)}
                onClose={(): void => setDisplayArchiveModal(false)}
              />
            )}
          </Form>
        )}
      </Formik>
    </>
  );

  function requestTypeIs(someType: RequestType): boolean {
    return requestType === someType;
  }

  function statusIs(status: ProductStatus): boolean {
    return product ? product.status === status : false;
  }

  function submitButtonText(): string {
    return requestTypeIs(RequestType.CREATE) ? 'Create draft product' : 'Save changes';
  }

  function handleImageAdded(
    files: File[],
    setFieldValue: (field: string, value: ProductFormValue) => void,
    setFieldTouched: (field: string, isTouched?: boolean) => void,
  ): void {
    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (): void => {
        if (reader.result) {
          const encodedFile = reader.result.toString();

          // Adding the new item to the start of the array
          // to keep deleted items (if any) in the end of the array
          const productPreviewImagesConcated: ImagePreview[] = [{ url: encodedFile }, ...productPreviewImages];
          setProductPreviewImages(productPreviewImagesConcated);

          const imageForPayload = createImageForPayload(encodedFile, file.name);
          const productPayloadImagesConcated: IImage[] = [imageForPayload, ...productPayloadImages];
          setProductPayloadImages(productPayloadImagesConcated);

          handleChange(ProductFormField.IMAGES, productPayloadImagesConcated, setFieldValue, setFieldTouched);
        }
      };
      reader.onerror = (error): void => {
        flashError(`An error has occurred while trying to load image: ${error}`);
      };
    });
  }

  function handleImageRemoved(
    imageNumber: number,
    setFieldValue: (field: string, value: ProductFormValue) => void,
    setFieldTouched: (field: string, isTouched?: boolean) => void,
  ): void {
    const productPreviewImagesUpdated: ImagePreview[] = [...productPreviewImages];
    productPreviewImagesUpdated.splice(imageNumber, 1);
    setProductPreviewImages(productPreviewImagesUpdated);

    const productPayloadImagesUpdated: IImage[] = [...productPayloadImages];
    const removedImage = productPayloadImagesUpdated.splice(imageNumber, 1);
    const imageId = removedImage[0].id;
    if (imageId !== 0) {
      // Adding the removed item to the end of the array
      // to keep remaining items in the same order as they are displayed in the carousel
      productPayloadImagesUpdated.push({
        id: imageId,
        delete: true,
      });
    }
    setProductPayloadImages(productPayloadImagesUpdated);

    handleChange(ProductFormField.IMAGES, productPayloadImagesUpdated, setFieldValue, setFieldTouched);
  }

  function handleImageTagChanged(
    imageNumber: number,
    tagValue: string | undefined,
    setFieldValue: (field: string, value: ProductFormValue) => void,
    setFieldTouched: (field: string, isTouched?: boolean) => void,
  ): void {
    const productPreviewImagesUpdated: ImagePreview[] = [...productPreviewImages];
    const previewItemToUpdate = productPreviewImages[imageNumber];
    previewItemToUpdate.tag = tagValue;
    setProductPreviewImages(productPreviewImagesUpdated);

    const productPayloadImagesUpdated: IImage[] = [...productPayloadImages];
    const imageToUpdate = productPayloadImages[imageNumber];
    imageToUpdate.tag = tagValue;

    setProductPayloadImages(productPayloadImagesUpdated);

    handleChange(ProductFormField.IMAGES, productPayloadImagesUpdated, setFieldValue, setFieldTouched);
  }

  function createImageForPayload(encodedFile: string, fileName: string): IImage {
    //build the image for the payload and set the value
    return {
      id: 0, //setting the id to zero so the backend knows to create the image and generate a proper id
      imageFile: encodedFile?.split(',')[1], //strip image metadata
      name: fileName,
      delete: false,
    };
  }

  async function handleSubmit(values: IProductFormInput, actions: FormikActions<IProductFormInput>): Promise<void> {
    const isCreateMode = requestTypeIs(RequestType.CREATE);
    if (!isCreateMode && isEqual(initialValues, values)) {
      actions.setSubmitting(false);
      return;
    }

    actions.setSubmitting(true);
    const payload = productFormValuesToPayload(values);
    const executor = isCreateMode ? createProduct : updateProduct;

    const { data, error, errorData } = await executor(payload, catalogUuid);

    if (errorData) {
      if (errorData.fieldErrors) {
        const errors = formatErrors<IProductFormInput>(errorData.fieldErrors);
        actions.setErrors(errors);
      } else {
        actions.setErrors({ submitError: error });
      }
      flashError(`An error has occurred while processing request: ${error}`);
    } else if (data) {
      const images = await getProductImages(data.uuid, catalogUuid);
      if (images && images.data && images.data?.length > 0) {
        const imagesData: IImage[] = images.data;
        setProductPayloadImages(images.data);
        const previewImages = imagesData.map((image) => ({ url: image.url as string, tag: image.tag }));
        setProductPreviewImages(previewImages);
      }
      flashSuccess(`Product ${data.name} was successfully ${isCreateMode ? 'created' : 'updated'}`);
      navigate(`/${catalogUuid}/products/${data.uuid}/edit/general`);
    }

    actions.setSubmitting(false);
    // fetch updated product after submiting
    if (refreshProduct && !isCreateMode) {
      refreshProduct();
    }
  }

  async function confirmArchive(
    uuid: string,
    setErrors: (errors: FormikErrors<IProductFormInput>) => void,
    setSubmitting: (value: boolean) => void,
  ): Promise<void> {
    setDisplayArchiveModal(false);
    setSubmitting(true);

    const { data, error } = await archiveProduct(uuid, catalogUuid);

    if (error) {
      flashError(`An error has occurred while attempting to archive product: ${error}`);
      setErrors({ submitError: error });
    } else if (data) {
      flashSuccess(`Product ${data.name} was successfully archived`);
      navigate(`/${catalogUuid}/products`);
    }

    setSubmitting(false);
    refreshProduct && refreshProduct();
  }

  async function confirmActivate(
    uuid: string,
    setErrors: (errors: FormikErrors<IProductFormInput>) => void,
    setSubmitting: (value: boolean) => void,
  ): Promise<void> {
    if (!catalogUuid) {
      return;
    }
    setSubmitting(true);

    const { data, error } = await activateProduct(uuid, catalogUuid);

    if (error) {
      flashError(`An error has occurred while attempting to activate product: ${error}`);
      setErrors({ submitError: error });
    } else if (data) {
      flashSuccess(`Product ${data.name} was successfully activated`);
      navigate(`/${catalogUuid}/products`);
    }

    setSubmitting(false);
    refreshProduct && refreshProduct();
  }

  async function confirmReactivate(
    uuid: string,
    setErrors: (errors: FormikErrors<IProductFormInput>) => void,
    setSubmitting: (value: boolean) => void,
  ): Promise<void> {
    setSubmitting(true);

    const { data, error } = await activateProduct(uuid, catalogUuid);

    if (error) {
      flashError(`An error has occurred while attempting to reactivate product: ${error}`);
      setErrors({ submitError: error });
    } else if (data) {
      flashSuccess(`Product ${data.name} was successfully reactivated`);
    }

    setSubmitting(false);
    refreshProduct && refreshProduct();
  }
};

function handleChange(
  name: string,
  value: ProductFormValue,
  setFieldValue: (field: string, value: ProductFormValue) => void,
  setFieldTouched: (field: string, isTouched?: boolean) => void,
): void {
  setFieldValue(name, value);
  setFieldTouched(name, true);
}

const populateForm = (defaultObject: IProductFormInput, incomingObject: IProduct): IProductFormInput => {
  const nestedObj: IProductFormAttributesInput = { ...defaultValues[ProductFormField.ATTRIBUTES] };

  const formValues = Object.keys(defaultObject).reduce(
    (result, key) => {
      if (key === ProductFormField.ATTRIBUTES) {
        if (incomingObject[key]) {
          for (const property of Object.keys(nestedObj)) {
            nestedObj[property] = populateField(property, incomingObject[key][property], nestedObj[property]);
          }
        }
        return { ...result, [key]: nestedObj };
      }

      result[key] = populateField(key, incomingObject[key], defaultObject[key]);
      return result;
    },
    { ...defaultValues },
  );

  const creditPriceAttribute = incomingObject[ProductFormField.ATTRIBUTES][ProductFormField.DAILY_DESK_CREDIT_PRICE];
  formValues[ProductFormField.OVERRIDE_DAILY_DESK_CREDIT_PRICE] = !!creditPriceAttribute || creditPriceAttribute === 0;

  return formValues;
};

const populateField = (
  field: string,
  incomingValue: ProductFormValue,
  defaultValue: ProductFormValue,
): ProductFormValue => {
  const value = isUndefined(incomingValue) ? defaultValue : incomingValue;
  const transform = productFormInputConfig[field] && productFormInputConfig[field].inboundTransform;
  return transform ? transform(value) : value;
};

export { RequestType, ProductForm };
