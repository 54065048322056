import { faBuilding, faMinusSquare as faMinusSquareOutline, faSquare } from '@fortawesome/free-regular-svg-icons';
import {
  faCheckSquare,
  faChevronDown,
  faChevronRight,
  faGlobe,
  faMinusSquare,
  faPlusSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import CheckboxTree, { CheckboxProps } from 'react-checkbox-tree';
import React, { createElement } from 'react';

const icon = (opts: FontAwesomeIconProps): JSX.Element => createElement(FontAwesomeIcon, opts);

const defaultIcons = {
  expandClose: icon({ icon: faChevronRight, color: 'gray' }),
  expandOpen: icon({ icon: faChevronDown }),
  check: icon({ icon: faCheckSquare }),
  uncheck: icon({ icon: faSquare }),
  leaf: icon({ icon: faBuilding }),
  parentClose: icon({ icon: faGlobe }),
  parentOpen: icon({ icon: faGlobe }),
  halfCheck: icon({ icon: faMinusSquareOutline }),
  expandAll: icon({ icon: faPlusSquare }),
  collapseAll: icon({ icon: faMinusSquare }),
};

export const WeCheckboxTree = ({ icons, ...props }: CheckboxProps): JSX.Element => (
  <CheckboxTree {...props} icons={{ ...defaultIcons, ...icons }} />
);
