import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Select, Divider, Input, Grid } from '@wework/dieter-ui';
import React, { useEffect, useState } from 'react';
import { GroupSelectInput } from 'components/shared/GroupSelectInput/GroupSelectInput';
import { FormValue } from 'components/shared/Form/FormConfig';
import { IGetProductsParams } from 'networking/productCatalog/productRequests';
import { saleItemGroups } from 'components/shared/ProductForm/productFormConfig';

export interface IFilterParams {
  status?: string;
  saleItemGroup?: string;
  groupUuid?: string;
}

const filterMapping: { [key: string]: string } = {
  status: 'status',
  saleItemGroup: 'saleItemGroup',
  groupUuid: 'filterByGroupTree',
};

export const toQueryParams = (filters: IFilterParams): IGetProductsParams =>
  Object.entries(filters).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [filterMapping[key]]: value,
    }),
    {},
  );

interface IFilterComponentProps {
  filters: IFilterParams;
  disabled?: boolean;
  onFilterChange: (filters: IFilterParams) => void;
  onSearchChange: (query: string | undefined) => void;
}

const statusFilterOptions = [
  ['ALL', 'All'],
  ['ACTIVE', 'Active'],
  ['INACTIVE', 'Inactive'],
  ['PENDING', 'Draft'],
];

function getValue(value: FormValue): string | undefined {
  return value ? value.toString() : undefined;
}

const Filter = ({ filters, disabled, onFilterChange, onSearchChange }: IFilterComponentProps): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState<string | undefined>();
  const [status, setStatus] = useState<string | undefined>(filters?.status);
  const [saleItemGroup, setSaleItemGroup] = useState<string | undefined>(filters?.saleItemGroup);
  const [groupUuid, setGroupUuid] = useState<string | undefined>(filters?.groupUuid);

  useEffect(() => {
    onFilterChange({ status, saleItemGroup, groupUuid });
  }, [status, saleItemGroup, groupUuid]);

  useEffect(() => {
    onSearchChange(searchQuery);
  }, [searchQuery]);

  function searchChanged(value: FormValue): void {
    setSearchQuery(getValue(value));
  }

  function statusChanged(value: FormValue): void {
    setStatus(getValue(value));
  }

  function groupChanged(value: FormValue): void {
    setGroupUuid(getValue(value));
  }

  function saleItemGroupChanged(value: FormValue): void {
    setSaleItemGroup(getValue(value));
  }

  const salesItemGroupFilterOptions = [...saleItemGroups];

  return (
    <Form>
      <Grid>
        <Grid.Column width={16}>
          <h3>
            <FontAwesomeIcon icon={faFilter} style={{ marginRight: '0.75rem' }} /> Filters
          </h3>
          <Form.Field
            label="Search"
            control={Input}
            disabled={disabled}
            outline
            data-test-id="search"
            onChange={(_, changeProps): void => changeProps && searchChanged(changeProps.value)}
          />
        </Grid.Column>

        <Grid.Column mobile={16} tablet={5} computer={16} largeScreen={16}>
          <Form.Field
            label="Status"
            control={Select}
            disabled={disabled}
            outline
            data-test-id="status"
            closeOnChange
            options={statusFilterOptions.map(([value, label]) => ({ value, key: value, text: label }))}
            onChange={(_, changeProps): void => changeProps && statusChanged(changeProps.value)}
            defaultValue={status}
          />
        </Grid.Column>

        <Grid.Column mobile={16} tablet={5} computer={16} largeScreen={16}>
          <GroupSelectInput disabled={disabled} data-test-id="group" initialValue={groupUuid} onChange={groupChanged} />
        </Grid.Column>

        <Grid.Column mobile={16} tablet={5} computer={16} largeScreen={16}>
          <Form.Field
            data-test-id="sale_item_group"
            label="Sale Item Group"
            control={Select}
            disabled={disabled}
            outline
            clearable
            closeOnChange
            options={salesItemGroupFilterOptions.map(([value, label]) => ({ value, key: value, text: label }))}
            onChange={(_, changeProps): void => changeProps && saleItemGroupChanged(changeProps.value)}
            defaultValue={saleItemGroup}
          />
        </Grid.Column>
      </Grid>
      <Divider hidden />
    </Form>
  );
};

export { Filter };
