import { Container, Divider } from '@wework/dieter-ui';
import React from 'react';

export const UNAUTHORIZED_ROUTE = 'unauthorized';

export const UnauthorizedPage = (): JSX.Element => {
  return (
    <Container size={'massive'}>
      <Divider hidden />
      <h3>{"You don't have the required permissions to see this page."}</h3>
    </Container>
  );
};
