import React, { useEffect, useState } from 'react';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import includes from 'lodash/includes';
import { CheckboxProps, Form, Header } from '@wework/dieter-ui';

import { isEmpty } from 'lodash';
import { IListingGroup } from 'types/productCatalogTypes';
import { getListingGroup } from 'networking/productCatalog/listingRequests';
import { GroupSelectInput } from 'components/shared/GroupSelectInput/GroupSelectInput';
import { FormValue } from 'components/shared/Form/FormConfig';
import { LoadingContainer } from 'components/shared/Loading/LoadingContainer';
import { getProductGroups } from 'networking/productCatalog/productRequests';
import { appendGroupProducts, deleteGroupProducts } from 'networking/productCatalog/groupRequests';
import { useFlashMessageContext } from 'contexts/FlashMessageContext';
import { FormActionDrawer } from 'components/shared/FormActionDrawer/FormActionDrawer';
import { EditToggle } from 'components/shared/EditToggle/EditToggle';
import { IEditProductTabCommonProps } from './editProductTabsConfig';

const TAXONOMY_GROUP_SLUG = 'addons';

const EditTaxonomyTab = ({ product, catalogUuid, isEditable }: IEditProductTabCommonProps): JSX.Element => {
  const [listingGroup, setListingGroup] = useState<IListingGroup | undefined>(undefined);
  const [currentGroupId, setCurrentGroupId] = useState<string | undefined>(undefined);
  const [formGroupId, setFormGroupId] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const { flashSuccess, flashError } = useFlashMessageContext();
  const isGroupTouched = formGroupId !== currentGroupId;

  useEffect(() => {
    async function getGroups(): Promise<void> {
      const { data: productGroupIds } = await getProductGroups(product.uuid, catalogUuid);

      // TODO: dropdown will make this same call again - how to dedupe?
      const { data: taxonomyGroup } = await getListingGroup(TAXONOMY_GROUP_SLUG);

      if (taxonomyGroup) {
        setListingGroup(taxonomyGroup);
        const flattenedIds = (group: IListingGroup): string[] => {
          const childIds = flatten((group.groups || []).map(flattenedIds));
          return [group.uuid, ...childIds];
        };
        const taxonomyIds = flattenedIds(taxonomyGroup);
        const existingGroupId = find(productGroupIds, (id) => includes(taxonomyIds, id));
        if (existingGroupId) {
          setCurrentGroupId(existingGroupId);
          setFormGroupId(existingGroupId);
        }
      }

      setLoading(false);
    }
    getGroups();
  }, [catalogUuid]);

  const handleChange = (groupId: FormValue): void => {
    groupId = isEmpty(groupId) ? undefined : groupId?.toString();
    setFormGroupId(groupId);
  };

  const handleSave = async (): Promise<void> => {
    if (formGroupId === currentGroupId) {
      return;
    }

    setSaving(true);

    if (currentGroupId) {
      const { error } = await deleteGroupProducts(currentGroupId, [product.uuid], catalogUuid);
      if (error) {
        return flashError(error);
      }
      setCurrentGroupId(undefined);
    }

    if (formGroupId) {
      const { error } = await appendGroupProducts(formGroupId, [product.uuid], catalogUuid);
      if (error) {
        return flashError(error);
      }
      setCurrentGroupId(formGroupId);
    }

    flashSuccess('Product taxonomy updated');
    setSaving(false);
  };

  return (
    <LoadingContainer loading={loading}>
      <>
        <Header as="h3">Taxonomy</Header>
        <EditToggle
          disabled={!isEditable}
          isEdit={isEdit}
          onChange={({ checked }: CheckboxProps): void => setIsEdit(Boolean(checked))}
        />
        <Form>
          <GroupSelectInput
            disabled={!isEdit}
            listingGroup={listingGroup}
            onChange={handleChange}
            initialValue={currentGroupId}
          />
          {isEdit && (
            <FormActionDrawer
              actions={[
                {
                  key: 'submit',
                  content: 'Submit',
                  onClick: handleSave,
                  loading: saving,
                  primary: true,
                  disabled: !isGroupTouched,
                },
              ]}
            />
          )}
        </Form>
      </>
    </LoadingContainer>
  );
};

export { EditTaxonomyTab };
