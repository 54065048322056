import React from 'react';
import { Form, Button } from '@wework/dieter-ui';
import { Formik, FormikHelpers as FormikActions } from 'formik';

import * as Yup from 'yup';
import { IProduct } from 'types/productCatalogTypes';
import { useFlashMessageContext } from 'contexts/FlashMessageContext';
import { ProductFormTextInput } from 'components/shared/ProductForm/ProductFormInputs';
import { productFormInputConfig } from 'components/shared/ProductForm/ProductFormInputs/productFormInputConfig';
import { ProductFormField, ProductFormValue } from 'components/shared/ProductForm/productFormConfig';

import 'components/products/LegalForm/LegalForm.scss';
import { updateTnc } from 'networking/productCatalog/tncRequests';

interface ILegalFormProps {
  product: IProduct;
  disabled?: boolean;
}

type INamedUrl = {
  [ProductFormField.TNC_URL_NAME]: string;
  [ProductFormField.TNC_URL_VALUE]: string;
};

const ProductTncFormSchema = Yup.object().shape({
  [ProductFormField.TNC_URL_NAME]: Yup.string().required('name is required'),
  [ProductFormField.TNC_URL_VALUE]: Yup.string().required('valid url is required').url(),
});

function handleChange(
  name: string,
  value: ProductFormValue,
  setFieldValue: (field: string, value: ProductFormValue) => void,
  setFieldTouched: (field: string, isTouched?: boolean) => void,
): void {
  setFieldValue(name, value);
  setFieldTouched(name, true);
}

export const LegalForm = ({ product, disabled }: ILegalFormProps): JSX.Element => {
  const { flashError, flashSuccess } = useFlashMessageContext();

  const handleSubmit = async (values: INamedUrl, { setSubmitting }: FormikActions<INamedUrl>): Promise<void> => {
    setSubmitting(true);

    const newTncUrls: { [key: string]: string } = product.tncUrls;
    newTncUrls[values[ProductFormField.TNC_URL_NAME]] = values[ProductFormField.TNC_URL_VALUE];
    const { error } = await updateTnc(product.uuid, newTncUrls);
    setSubmitting(false);

    if (error) {
      flashError(`Error saving legal details: ${error}`);
      return;
    }

    flashSuccess('Legal details saved');
  };

  const initialValues = { [ProductFormField.TNC_URL_NAME]: '', [ProductFormField.TNC_URL_VALUE]: '' };

  return (
    <Formik initialValues={initialValues} validationSchema={ProductTncFormSchema} onSubmit={handleSubmit}>
      {({
        handleSubmit: formikHandleSubmit,
        isSubmitting,
        dirty,
        isValid,
        setFieldValue,
        setFieldTouched,
      }): JSX.Element => (
        <Form onSubmit={formikHandleSubmit}>
          <ProductFormTextInput
            handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
            {...productFormInputConfig[ProductFormField.TNC_URL_NAME]}
            disabled={disabled}
          />
          <ProductFormTextInput
            handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
            {...productFormInputConfig[ProductFormField.TNC_URL_VALUE]}
            disabled={disabled}
          />
          <div className="legal-form-submit-button">
            <Button type="submit" loading={isSubmitting} size="large" disabled={!dirty || !isValid} primary>
              Submit
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
