import { useEffect, useRef } from 'react';

export function useScrollToTop(): void {
  const prevLocationRef = useRef<string>();
  useEffect(() => {
    prevLocationRef.current = location.pathname;
  });
  const prevLocation = prevLocationRef.current;

  useEffect(() => {
    if (location.pathname !== prevLocation) {
      window.scrollTo(0, 0);
    }
  });
}
