import { IExtendedFetchResult, RequestMethods } from 'networking/fetchConfig';
import { productCatalogFetch } from './productCatalogFetch';

export function getAvailableBuildings(productUuid: string): Promise<IExtendedFetchResult<string[]>> {
  return productCatalogFetch<string[]>(RequestMethods.GET, `/v2/products/${productUuid}/available_buildings`);
}

export function updateAvailableBuildings(
  productUuid: string,
  buildingIds: string[],
): Promise<IExtendedFetchResult<null>> {
  return productCatalogFetch<null>(RequestMethods.PUT, `/v2/products/${productUuid}/available_buildings`, buildingIds);
}

export function deleteAvailableBuildings(
  productUuid: string,
  buildingIds: string[],
): Promise<IExtendedFetchResult<null>> {
  return productCatalogFetch<null>(
    RequestMethods.DELETE,
    `/v2/products/${productUuid}/available_buildings`,
    buildingIds,
  );
}
