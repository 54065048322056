import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { parse, stringify } from 'query-string';
import { Auth0User } from 'utils/auth/authUtils';
import { LOGIN_ROUTE } from 'pages/LoginPage/LoginPage';

interface IRequireAuthenticationProps {
  children: JSX.Element;
}

export const RequireAuthentication = ({ children }: IRequireAuthenticationProps): JSX.Element => {
  const { isAuthenticated } = useAuth0<Auth0User>();
  const { search, pathname } = useLocation();

  // redirect to Login page if user is not authenticated
  if (!isAuthenticated && pathname !== `/${LOGIN_ROUTE}`) {
    const { code, state, ...otherParams } = parse(search);
    const nextPath = `${pathname}?${stringify(otherParams)}`;
    const toRedirect = {
      pathname: `/${LOGIN_ROUTE}`,
      search: `?${stringify({ path: nextPath, code, state })}`,
    };
    return <Navigate to={toRedirect} replace />;
  }

  return children;
};
