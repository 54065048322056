import React from 'react';

import { OperatorServicePermissionsMap } from '@wework/we-auth-react';
import { ITabConfig } from 'components/shared/VerticalTabRouter/VerticalTabRouter';
import { IProduct, ProductStatus } from 'types/productCatalogTypes';
import {
  ARCHIVAL_PERMISSIONS,
  EDITABLE_PERMISSIONS,
  PRICING_PERMISSIONS,
  TNCS_PERMISSIONS,
  BUILDING_AVAILABILITY_PERMISSIONS,
  TRANSLATIONS_PERMISSIONS,
  hasAtLeastOnePermission,
  EDIT_GROUPS_PERMISSIONS,
} from 'utils/auth/authUtils';
import { EditPricingTab } from './EditPricingTab';
import { EditFeesTab } from './EditFeesTab';
import { EditGeneralTab } from './EditGeneralTab';
import { EditTaxonomyTab } from './EditTaxonomyTab';
import { EditLanguagesTab } from './EditLanguagesTab';
import { EditLegalTab } from './EditLegalTab';
import EditAuditTab from './EditAuditTab';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum TAB_PATHS {
  GENERAL_TAB = '/:catalog_uuid/products/:uuid/edit/general',
  PRICING_TAB = '/:catalog_uuid/products/:uuid/edit/pricing',
  TAXONOMY_TAB = '/:catalog_uuid/products/:uuid/edit/taxonomy',
  FEES_TAB = '/:catalog_uuid/products/:uuid/edit/fees',
  LANGUAGES_TAB = '/:catalog_uuid/products/:uuid/edit/languages',
  LEGAL_TAB = '/:catalog_uuid/products/:uuid/edit/legal',
  AUDIT_TAB = '/products/:uuid/edit/audit',
}

interface IEditProductTabCommonProps {
  product: IProduct;
  catalogUuid: string;
  isEditable?: boolean;
  refreshProduct?: () => void;
}

const editProductTabsConfig = (
  product: IProduct,
  availalblePerms: OperatorServicePermissionsMap,
  catalogUuid: string,
  refreshProduct: () => void,
): ITabConfig[] => {
  const commonProps = { product, catalogUuid, refreshProduct };
  const isProductInactive = product?.status === ProductStatus.INACTIVE;
  const canBeActivated =
    (isProductInactive || product?.status === ProductStatus.PENDING) &&
    hasAtLeastOnePermission(availalblePerms, EDITABLE_PERMISSIONS);
  const isProducEditable = !isProductInactive && hasAtLeastOnePermission(availalblePerms, EDITABLE_PERMISSIONS);
  const canBeArchived = hasAtLeastOnePermission(availalblePerms, ARCHIVAL_PERMISSIONS);
  const isBuildingsEditable =
    !isProductInactive && hasAtLeastOnePermission(availalblePerms, BUILDING_AVAILABILITY_PERMISSIONS);
  const isPricingEditable = !isProductInactive && hasAtLeastOnePermission(availalblePerms, PRICING_PERMISSIONS);
  const isTaxonomyEditable = !isProductInactive && hasAtLeastOnePermission(availalblePerms, EDIT_GROUPS_PERMISSIONS);
  const isTranslationsEditable =
    !isProductInactive && hasAtLeastOnePermission(availalblePerms, TRANSLATIONS_PERMISSIONS);
  const isTncsEditable = !isProductInactive && hasAtLeastOnePermission(availalblePerms, TNCS_PERMISSIONS);

  return [
    {
      text: 'General',
      component: (
        <EditGeneralTab
          {...commonProps}
          isEditable={isProducEditable}
          canBeActivated={canBeActivated}
          canBeArchived={canBeArchived}
        />
      ),
      path: 'general',
    },
    {
      text: 'Pricing',
      component: (
        <EditPricingTab
          product={product}
          isPricingEditable={isPricingEditable}
          isAvailabilityEditable={isBuildingsEditable}
        />
      ),
      path: 'pricing',
    },
    {
      text: 'Taxonomy',
      component: <EditTaxonomyTab {...commonProps} isEditable={isTaxonomyEditable} />,
      path: 'taxonomy',
    },
    {
      text: 'Fees',
      component: <EditFeesTab {...commonProps} isEditable={isProducEditable} />,
      path: 'fees',
    },
    {
      text: 'Languages',
      component: <EditLanguagesTab {...commonProps} isEditable={isTranslationsEditable} />,
      path: 'languages',
    },
    {
      text: 'Legal',
      component: <EditLegalTab {...commonProps} isEditable={isTncsEditable} />,
      path: 'legal',
    },
    {
      text: 'Audit & History Tracking',
      component: <EditAuditTab {...commonProps} />,
      path: 'audit/*',
    },
  ];
};

export { editProductTabsConfig, IEditProductTabCommonProps };
