import { Dictionary, forEach, isEqual, keyBy, keys, uniq } from 'lodash';
import {
  IBuildingObject,
  IGeogroupingObject,
  IListingGroup,
  IListingProduct,
  ILocationListingGroup,
  ILocationListingProduct,
} from 'types/productCatalogTypes';

export const listingProductToLocationListingProduct = (
  listingProduct: IListingProduct,
  available: boolean,
): ILocationListingProduct => ({
  ...listingProduct,
  fees: (listingProduct.fees ?? []).map((fee) => listingProductToLocationListingProduct(fee, available)),
  available,
});

export const listingGroupToLocationListingGroup = (
  listingGroup: IListingGroup,
  availableProducts: Set<string>,
): ILocationListingGroup => ({
  ...listingGroup,
  products: (listingGroup.products ?? []).map((product) =>
    listingProductToLocationListingProduct(product, availableProducts.has(product.uuid)),
  ),
  groups: (listingGroup.groups ?? []).map((group) => listingGroupToLocationListingGroup(group, availableProducts)),
});

export interface IGeogroupingsMap {
  [id: string]: IGeogroupingObject;
}

export interface IBuildingsMap {
  [id: string]: IBuildingObject;
}

export interface ILocationsMap {
  geogroupings: IGeogroupingsMap;
  buildings: IBuildingsMap;
}

export const assembleLocationsMap = (
  geogroupings: IGeogroupingObject[],
  buildings: IBuildingObject[],
): ILocationsMap => ({
  geogroupings: keyBy(geogroupings, 'id'),
  buildings: keyBy(buildings, 'id'),
});

export enum ChangeState {
  REMOVED = 'REMOVED',
  ADDED = 'ADDED',
  EDIT = 'EDIT',
  NONE = 'NONE',
}

export interface IDiff<T> {
  previous: T;
  current: T;
  changeState: ChangeState;
}

export type IDiffMap<T> = Dictionary<IDiff<T>>;

export const assembleDiffMap = <T>(previousMap: Dictionary<T>, currentMap: Dictionary<T>): IDiffMap<T> => {
  const result: IDiffMap<T> = {};
  const allKeys = uniq([...keys(previousMap), ...keys(currentMap)]);

  // const isObjectsEqual___________ = (obj1: object, obj2: object) =>
  //   isEmpty(differenceWith(toPairs(obj2), toPairs(obj1), isEqual));
  const getChangeState = (previous: unknown, current: unknown) => {
    let changeState = ChangeState.NONE;
    if (!previous && current) {
      changeState = ChangeState.ADDED;
    } else if (previous && !current) {
      changeState = ChangeState.REMOVED;
    } else if (previous && current && !isEqual(previous, current)) {
      changeState = ChangeState.EDIT;
    }
    return changeState;
  };

  forEach(allKeys, (key) => {
    result[key] = {
      previous: previousMap[key],
      current: currentMap[key],
      changeState: getChangeState(previousMap[key], currentMap[key]),
    };
  });
  return result;
};
