import React, { useEffect, useState } from 'react';
import { CheckboxProps } from '@wework/dieter-ui';

import { LoadingContainer } from 'components/shared/Loading/LoadingContainer';
import { RequestType } from 'components/shared/Form/FormConfig';
import { ProductForm } from 'components/shared/ProductForm';
import { IEditProductTabCommonProps } from 'pages/products/editProductTabs/editProductTabsConfig';
import { useApiHook } from 'hooks/useApiHook';
import { IWorkdaySalesItems, IImage } from 'types/productCatalogTypes';
import { getSalesItems } from 'utils/sessionStore';
import { getProductImages } from 'networking/productCatalog/productRequests';
import { useFlashMessageContext } from 'contexts/FlashMessageContext';
import { EditToggle } from 'components/shared/EditToggle/EditToggle';

const diplayedSalesItemsError = 'Workday unavailable, no changes to Workday Id field are possible';

interface EditGeneralTabProps extends IEditProductTabCommonProps {
  canBeActivated?: boolean;
  canBeArchived?: boolean;
}

const EditGeneralTab = ({
  product,
  isEditable,
  canBeActivated,
  canBeArchived,
  catalogUuid,
  refreshProduct,
}: EditGeneralTabProps): JSX.Element => {
  const { flashError } = useFlashMessageContext();
  const {
    data: salesItems,
    error: salesItemsError,
    loading: salesItemsLoading,
  } = useApiHook<IWorkdaySalesItems[]>(getSalesItems);
  const { data: images, error: imagesError } = useApiHook<IImage[]>(
    () => getProductImages(product.uuid, catalogUuid),
    [catalogUuid],
  );

  useEffect(() => {
    salesItemsError && flashError(diplayedSalesItemsError);
  }, [salesItemsError]);

  useEffect(() => {
    imagesError && flashError(imagesError);
  }, [imagesError]);

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const handleCheckbox = ({ checked }: CheckboxProps): void => setIsEdit(Boolean(checked));

  return (
    <LoadingContainer loading={salesItemsLoading}>
      <>
        <EditToggle disabled={!isEditable} isEdit={isEdit} onChange={handleCheckbox} />
        <ProductForm
          requestType={isEdit ? RequestType.UPDATE : RequestType.READ}
          canBeActivated={canBeActivated}
          canBeArchived={canBeArchived}
          product={product}
          productImages={images || []}
          salesItems={salesItems || []}
          salesItemsError={salesItemsError && diplayedSalesItemsError}
          refreshProduct={refreshProduct}
        />
      </>
    </LoadingContainer>
  );
};

export { EditGeneralTab };
