import React from 'react';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popup } from '@wework/dieter-ui';

import './styles.scss';

interface IAttributeLabelProps {
  name: string;
  nativeName: string;
}

export const AttributeLabel = ({ name, nativeName }: IAttributeLabelProps): JSX.Element => (
  <div className="label">
    {name}
    <Popup
      hoverable
      size="tiny"
      position="top right"
      content={`Native field name: ${nativeName}`}
      trigger={<FontAwesomeIcon icon={faCircleInfo} size="sm" />}
    />
  </div>
);
