import { Modal } from '@wework/dieter-ui';
import React, { useState } from 'react';
import { useAsyncEffect } from 'use-async-effect';

import { ILocationAttributesRecord, ILocationPrice } from 'types/productCatalogTypes';
import { LocationAttributesForm } from '../LocationAttributesForm/LocationAttributesForm';
import { LocationPriceForm } from './LocationPriceForm';
import { getAllBlacklistedProductUuids } from '../../../networking/productCatalog/adminRequests';
import './locationPriceModal.scss';

interface ILocationPriceModalProps {
  locationName: string;
  locationPrice: ILocationPrice | null;
  locationAttributes: ILocationAttributesRecord | null;
  isPricingEditable?: boolean;
  productUuid: string;
  isAttributesEditable?: boolean;
  onClose: () => void;
  onSaveLocationPrice: (price: ILocationPrice) => void;
  onSaveLocationAttributes: (attributes: ILocationAttributesRecord) => void;
  onDelete: (price: ILocationPrice) => void;
}

export const LocationPriceModal = ({
  locationName,
  locationPrice,
  locationAttributes,
  isPricingEditable,
  productUuid,
  onClose,
  onSaveLocationPrice,
  onSaveLocationAttributes,
  onDelete,
}: ILocationPriceModalProps): JSX.Element | null => {
  const [isProductUuidBlacklisted, setIsProductUuidBlacklisted] = useState<boolean>(false);

  useAsyncEffect(async () => {
    const result = await getAllBlacklistedProductUuids();
    const blacklist = result?.data;
    setIsProductUuidBlacklisted(blacklist?.includes(productUuid) || false);
  }, []);

  if (!locationName || !locationPrice) {
    return null;
  }

  return (
    <Modal open onClose={onClose} closeIcon size="tiny">
      <Modal.Header>Price for {locationName}</Modal.Header>
      {isProductUuidBlacklisted && (
        <div className="cannot-edit-text">
          {"This product's price is managed in Voyager and cannot be modified here."}
        </div>
      )}
      <LocationPriceForm
        disabled={!isPricingEditable || isProductUuidBlacklisted}
        locationPrice={locationPrice}
        onSave={onSaveLocationPrice}
        onDelete={onDelete}
        onClose={onClose}
      />
      {locationAttributes && (
        <>
          <Modal.Header>Attributes for {locationName}</Modal.Header>
          <LocationAttributesForm
            disabled={!isPricingEditable || isProductUuidBlacklisted}
            locationAttributes={locationAttributes}
            onSave={onSaveLocationAttributes}
          />
        </>
      )}
    </Modal>
  );
};
