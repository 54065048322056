import React, { useState } from 'react';
import { CheckboxProps, ChipLabel } from '@wework/dieter-ui';

import { useParams } from 'react-router-dom';
import { LoadingContainer } from 'components/shared/Loading/LoadingContainer';
import { useApiHook } from 'hooks/useApiHook';
import { IMarketPricing } from 'types/productCatalogTypes';
import { NotFoundPage } from 'pages/NotFoundPage/NotFoundPage';
import { getMarketPricing } from 'pages/markets/MarketActions';
import { MarketPricingTable } from 'pages/markets/MarketPricingTable';
import { ResponseStatus } from 'networking/fetchConfig';
import { hasSomePermissions, PRICING_PERMISSIONS } from 'utils/auth/authUtils';
import { useGlobalAndOperatorPermissions } from 'hooks/useGlobalAndOperatorPermissions';
import { EditToggle } from 'components/shared/EditToggle/EditToggle';
import { PageHeader } from 'components/shared/PageHeader/PageHeader';
import { BaseUrlParamsType } from 'types/router';

type ParamsType = BaseUrlParamsType & {
  uuid: string;
};

export const MARKET_PAGE_PATH = '/markets/:uuid';

export const MarketPage = (): JSX.Element => {
  const { catalogUuid, uuid: marketUuid } = useParams() as ParamsType;
  const [permsIsLoading, permsError, availablePerms] = useGlobalAndOperatorPermissions(PRICING_PERMISSIONS);
  const isEditable = hasSomePermissions(availablePerms);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const {
    loading,
    data: marketPricing,
    error: marketPricingError,
    status,
    refresh,
  } = useApiHook<IMarketPricing>(() => getMarketPricing(marketUuid, catalogUuid), [marketUuid, catalogUuid]);

  if (status === ResponseStatus.NOT_FOUND) {
    return <NotFoundPage />;
  }

  return (
    <div>
      <LoadingContainer
        data-test-id="market_page"
        loading={loading || permsIsLoading}
        data={marketPricing}
        error={marketPricingError || permsError}
      >
        {marketPricing && (
          <>
            <div>
              <PageHeader header={marketPricing.name}>
                <EditToggle
                  disabled={!isEditable}
                  isEdit={isEdit}
                  onChange={({ checked }: CheckboxProps): void => setIsEdit(Boolean(checked))}
                />
              </PageHeader>
              <ChipLabel size="medium">{marketPricing?.regionName}</ChipLabel>
              <br />
            </div>

            <MarketPricingTable marketPricing={marketPricing} isEditable={isEdit} onSave={refresh} />
          </>
        )}
      </LoadingContainer>
    </div>
  );
};
