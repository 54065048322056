import React from 'react';
import { ILocationAttributesRecord, ILocationPrice } from 'types/productCatalogTypes';
import { EcommerceLabel } from './EcommerceLabel';
import { PriceLabel } from './PriceLabel';

interface ILocationLabelProps {
  title: string;
  price: ILocationPrice;
  ecommerceFlag: ILocationAttributesRecord;
}
export function LocationLabels({ title, price, ecommerceFlag }: ILocationLabelProps): JSX.Element {
  return (
    <span className="availability-form--tree-text">
      {title}
      <PriceLabel locationPrice={price} />
      <EcommerceLabel ecommerceFlag={ecommerceFlag} />
    </span>
  );
}
