import { IExtendedFetchResult, RequestMethods } from 'networking/fetchConfig';
import { ILocationAttributesRecord, IProduct, LocationAttributesLocationType } from 'types/productCatalogTypes';
import { productCatalogFetch } from './productCatalogFetch';

export function getLocationAttributes(product: IProduct): Promise<IExtendedFetchResult<ILocationAttributesRecord[]>> {
  return productCatalogFetch<ILocationAttributesRecord[]>(
    RequestMethods.GET,
    `/v2/products/${product.uuid}/location_attributes`,
  );
}

export function getLocationAttributesByLocationTypeAndLocationUuid(
  locationType: LocationAttributesLocationType,
  locationUuid: string,
): Promise<IExtendedFetchResult<ILocationAttributesRecord[]>> {
  return productCatalogFetch<ILocationAttributesRecord[]>(
    RequestMethods.GET,
    `/v2/locations/${locationType}/${locationUuid}/location_attributes`,
  );
}

export interface IBulkUpdateLocationAttributesPayload {
  createList: ILocationAttributesRecord[];
  updateList: ILocationAttributesRecord[];
}

export function bulkUpdateLocationAttributes(
  product: IProduct,
  payload: IBulkUpdateLocationAttributesPayload,
): Promise<IExtendedFetchResult<ILocationAttributesRecord[]>> {
  return productCatalogFetch<ILocationAttributesRecord[]>(
    RequestMethods.PUT,
    `/v2/products/${product.uuid}/location_attributes`,
    payload,
  );
}

export const updateLocationAttributes = (
  productUuid: string,
  flag: ILocationAttributesRecord,
): Promise<IExtendedFetchResult<ILocationAttributesRecord>> =>
  productCatalogFetch<ILocationAttributesRecord>(
    RequestMethods.PUT,
    `/v2/products/${productUuid}/location_attributes/${flag.id}`,
    flag,
  );
