import React, { useRef, useEffect, useState } from 'react';
import { useAsyncEffect } from 'use-async-effect';
import { find, isEmpty, map, uniq, size } from 'lodash';
import RenderIfVisible from 'react-render-if-visible';
import { Popup } from '@wework/dieter-ui';
import dateFnsFormat from 'date-fns-tz/format';
import cn from 'classnames';
import ago from 'ts-ago';

import { Collapsible } from 'components/shared/Collapsible';

import { AuditActionType, IProductAudit, IWorkdaySalesItems } from 'types/productCatalogTypes';
import { getProductAudit } from 'networking/productCatalog/productRequests';
import { getUsers } from 'networking/operatorService/userByUuidRequests';
import { IEmployee } from 'networking/fetchConfig';
import { IPaginationatedResponse } from 'networking/operatorService/operatorServiceTypes';
import { useApiHook } from 'hooks/useApiHook';
import { getSalesItems } from 'utils/sessionStore';
import { IEditProductTabCommonProps } from '../editProductTabsConfig';
import { userGetter } from './utils';
import { AuditUserInfo } from './AuditUserInfo';
import { InitialProductValues } from './InitialProductValues';
import { ChangesList } from './ChangesList';

import './styles.scss';

import {
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  columns,
  AuditRecordPlaceholder,
  ESTIMATED_ITEM_HEIGHT,
  LoadMore,
  AuditColumn,
} from './AuditTable';

const PAGE_SIZE = 20;

export const AuditProductTab = ({ product }: IEditProductTabCommonProps) => {
  const [usersLoading, setUsersLoading] = useState(true);
  const [usersError, setUsersError] = useState<string | undefined>();
  const [auditLoading, setAuditLoading] = useState(true);
  const [auditError, setAuditError] = useState<string | undefined>();
  const [usersResponse, setUsersResponse] = useState<IPaginationatedResponse<IEmployee> | undefined>();
  const [offset, setOffset] = useState(0);
  const [stop, setStop] = useState<boolean>(false);
  const [data, setData] = useState<IProductAudit[]>([]);
  const containerRef = useRef(null);

  useEffect(() => {
    const getData = async () => {
      setAuditLoading(true);
      const { data: auditLogs, status, error } = await getProductAudit(product.uuid, PAGE_SIZE, offset);
      setAuditLoading(false);
      if (error) {
        setAuditError(error);
      }
      if (auditLogs?.length) {
        setData([...data, ...auditLogs]);
      } else if (status === 200) {
        setStop(true);
      }
    };
    getData();
  }, [offset]);

  const loadMore = () => setOffset(offset + PAGE_SIZE);

  useAsyncEffect(
    async (isActive) => {
      const userUuids = map(data, 'user');
      if (!isActive() || isEmpty(userUuids)) return;
      setUsersLoading(true);
      const response = await getUsers(userUuids);
      response.error && setUsersError(response.error);
      response.data && setUsersResponse(response.data);
      setUsersLoading(false);
    },
    [data],
  );

  const salesItemsResp = useApiHook<IWorkdaySalesItems[]>(getSalesItems);
  const salesItems = salesItemsResp.data ?? [];
  const getUserData = userGetter(usersResponse?.content);

  return (
    <TableContainer>
      <TableHeader>
        {columns.map((column: AuditColumn) => (
          <TableHeaderCell key={column.key} size={column.size}>
            {column.title}
          </TableHeaderCell>
        ))}
      </TableHeader>
      <TableBody ref={containerRef}>
        {data.map((auditItem: IProductAudit, idx) => {
          const userData = getUserData(auditItem.user);
          const changeDate = dateFnsFormat(new Date(auditItem.timestamp), 'MMMM dd yyyy HH:mm zzz');
          const changesNumber = size(auditItem?.changes);
          const isCreated = auditItem.action === AuditActionType.CREATED;

          return (
            <div key={auditItem.id} className={cn({ created: isCreated })}>
              <RenderIfVisible key={auditItem.id} defaultHeight={ESTIMATED_ITEM_HEIGHT} placeholderElement={'div'}>
                <TableRow>
                  <TableCell size="md">
                    <Popup
                      hoverablex
                      size="tiny"
                      position="top left"
                      content={changeDate}
                      trigger={<div>{ago(auditItem.timestamp)}</div>}
                    />
                  </TableCell>
                  <TableCell size="lg">
                    <AuditUserInfo userFullName={userData?.full_name} userEmail={userData?.email} />
                  </TableCell>
                  <TableCell size="md">{auditItem.action}</TableCell>
                  <TableCell size="auto">
                    <Collapsible
                      title={
                        isCreated
                          ? 'Initial product state'
                          : `${changesNumber} ${changesNumber === 1 ? 'change' : 'changes'}`
                      }
                    >
                      {isCreated ? (
                        <InitialProductValues entityData={auditItem?.entityData} salesItems={salesItems} />
                      ) : (
                        <ChangesList changes={auditItem?.changes} salesItems={salesItems} />
                      )}
                    </Collapsible>
                  </TableCell>
                </TableRow>
              </RenderIfVisible>
              {idx === data.length - 1 ? <LoadMore setOffset={loadMore} stop={stop} root={containerRef} /> : null}
            </div>
          );
        })}
        {!data.length && (auditLoading || usersLoading) ? (
          <>
            <AuditRecordPlaceholder />
          </>
        ) : null}
      </TableBody>
    </TableContainer>
  );
};
