import numeral from 'numeral';
import { ProductFormValue } from 'components/shared/ProductForm/productFormConfig';

export const formatPrice = (value: string | number): string => numeral(value).format('0,0.00');

export const formatPercent = (value: ProductFormValue): string => numeral(value).format('0.000%').replace('%', '');

export const formatPriceCurrency = (value: string | number, currency: string): string =>
  `${new Intl.NumberFormat(window.navigator.language, { style: 'currency', currency: currency }).format(
    Number(value),
  )} (${currency})`;
