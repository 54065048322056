import { Form, Input } from '@wework/dieter-ui';
import { Card } from 'semantic-ui-react';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import React, { useMemo, useState } from 'react';
import sortBy from 'lodash/sortBy';

import { IProduct } from 'types/productCatalogTypes';
import { LOCALES } from 'configs/locales';
import { updateProductTranslation } from 'networking/productCatalog/productRequests';
import { useFlashMessageContext } from 'contexts/FlashMessageContext';
import { LanguagesFormSchema } from 'components/products/LanguagesForm/languagesFormConfig';
import { handleChange } from 'components/shared/Form/utils';
import { LanguageCard } from 'components/products/LanguagesForm/LanguageCard';
import { FormActionDrawer } from 'components/shared/FormActionDrawer/FormActionDrawer';
import { isLanguagesFormTouched } from './LanguagesFormUtils';

import 'components/products/LanguagesForm/languagesForm.scss';

type Props = {
  product: IProduct;
  disabled?: boolean;
  recommendedLocaleCodes: string[];
  refreshProduct?: () => void;
};

export const LanguagesForm = ({ product, disabled, recommendedLocaleCodes, refreshProduct }: Props): JSX.Element => {
  const [filterQuery, setFilterQuery] = useState<string>('');
  const { flashError, flashSuccess } = useFlashMessageContext();

  const recommendedLocaleCodeSet = useMemo(() => new Set(recommendedLocaleCodes), [recommendedLocaleCodes.join()]);

  const orderedLocales = useMemo(
    () => sortBy(LOCALES, ({ code }) => (recommendedLocaleCodeSet.has(code) ? 0 : 1)),
    [recommendedLocaleCodeSet.size],
  );

  const filteredLocales = useMemo(() => {
    if (!filterQuery) return orderedLocales;
    const query = filterQuery.toLowerCase();
    return orderedLocales.filter(({ code, name, countryName, countryFlag }) =>
      [code, name, countryName, countryFlag].join(' ').toLowerCase().includes(query),
    );
  }, [filterQuery, recommendedLocaleCodeSet.size]);

  const handleSubmit = async (values: IProduct, { setSubmitting }: FormikActions<IProduct>): Promise<void> => {
    // user can not submit the form if the product catalog does not select or the product is not edited
    if (!isLanguagesFormTouched(product, values)) {
      setSubmitting(false);
      return;
    }

    const castValues = LanguagesFormSchema.cast(values);
    const newTranslation = {
      nameTranslations: castValues?.nameTranslations,
      descriptionTranslations: castValues?.descriptionTranslations,
    };
    const { error } = await updateProductTranslation(newTranslation, product.uuid);
    setSubmitting(false);

    if (error) {
      flashError(`Error saving translations: ${error}`);
      return;
    }

    flashSuccess('Translations saved');
    refreshProduct && refreshProduct();
  };

  return (
    <Formik initialValues={product} validationSchema={LanguagesFormSchema} enableReinitialize onSubmit={handleSubmit}>
      {({
        handleSubmit: formikHandleSubmit,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        values,
        submitForm,
      }): JSX.Element => {
        const isFormTouched = isLanguagesFormTouched(product, values);
        return (
          <Form onSubmit={formikHandleSubmit}>
            <Input
              value={filterQuery}
              onChange={(event): void => setFilterQuery(event.target.value)}
              placeholder="Type to filter..."
            />
            <Card.Group stackable itemsPerRow={2} className="languages-form__card-group">
              {filteredLocales.map(
                (locale): JSX.Element => (
                  <LanguageCard
                    disabled={disabled}
                    key={locale.code}
                    locale={locale}
                    values={values}
                    recommendedLocaleCodeSet={recommendedLocaleCodeSet}
                    onChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                  />
                ),
              )}
            </Card.Group>
            {!disabled && (
              <FormActionDrawer
                actions={[
                  {
                    key: 'submit',
                    content: 'Submit',
                    onClick: submitForm,
                    loading: isSubmitting,
                    disabled: isSubmitting || !isFormTouched,
                    primary: true,
                  },
                ]}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
