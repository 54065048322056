import mapValues from 'lodash/mapValues';
import { lazy, object, string, number, BaseSchema } from 'yup';

const translationSchema = lazy(
  // eslint-disable-next-line  @typescript-eslint/ban-types
  (obj: {}): BaseSchema =>
    object(
      mapValues(obj, (_value: string, key: string): BaseSchema | undefined => {
        if (key.includes('propertyName')) {
          return string().required();
        }
        if (key.includes('propertyValue')) {
          return number().required();
        }
      }),
    ),
);

export const LanguagesFormSchema = object().shape({
  nameTranslations: translationSchema,
  descriptionTranslations: translationSchema,
});
