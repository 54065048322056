import * as React from 'react';

import Loading from 'components/shared/Loading/Loading';

interface ILoadingContainer<T> {
  loading: boolean;
  error?: Error | string | undefined;
  data?: T;
  children: JSX.Element | null | undefined;
}

export function LoadingContainer<T>({ loading, error, data, children }: ILoadingContainer<T>): JSX.Element | null {
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <div className="alchemist-fade-in error">{JSON.stringify(error)}</div>;
  }

  if (children) {
    const element = React.cloneElement(children, { data });
    return <div className="alchemist-fade-in">{element}</div>;
  }

  return null;
}
