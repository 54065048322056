import { useAsyncMemo } from 'use-async-memo';
import React, { useState } from 'react';

import { CheckboxProps } from '@wework/dieter-ui';
import { LanguagesForm } from 'components/products/LanguagesForm/LanguagesForm';
import { LoadingContainer } from 'components/shared/Loading/LoadingContainer';
import { getLocalesForProduct } from 'networking/operations/productOperations';
import { EditToggle } from 'components/shared/EditToggle/EditToggle';
import { IEditProductTabCommonProps } from './editProductTabsConfig';

export const EditLanguagesTab = ({ product, isEditable, refreshProduct }: IEditProductTabCommonProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const recommendedLocaleCodes = useAsyncMemo<string[]>(
    async (): Promise<string[]> => {
      setLoading(true);
      const codes = getLocalesForProduct(product.uuid);
      setLoading(false);
      return codes;
    },
    [product.uuid],
    [],
  );

  return (
    <LoadingContainer loading={loading}>
      <>
        <EditToggle
          disabled={!isEditable}
          isEdit={isEdit}
          onChange={({ checked }: CheckboxProps): void => setIsEdit(Boolean(checked))}
        />
        <LanguagesForm
          product={product}
          disabled={!isEdit}
          recommendedLocaleCodes={recommendedLocaleCodes}
          refreshProduct={refreshProduct}
        />
      </>
    </LoadingContainer>
  );
};
