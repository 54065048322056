import { Checkbox } from '@wework/dieter-ui';
import { CheckboxProps } from 'semantic-ui-react';
import React, { useState } from 'react';

import { GroupSlugs, ILocationListingGroup } from 'types/productCatalogTypes';
import { LoadingContainer } from 'components/shared/Loading/LoadingContainer';
import { AvailableAddons } from 'components/buildings/AvailableAddons';
import { EditToggle } from 'components/shared/EditToggle/EditToggle';
import { DEFAULT_LOCALE, getBuildingLocaleCode } from 'configs/locales';
import { getListingsWithAvailability } from 'pages/buildings/BuildingActions';
import { IBuildingTabCommonProps } from 'pages/buildings/buildingTabs/buildingTabsConfig';
import { useApiHook } from 'hooks/useApiHook';
import { BUILDING_AND_PRICING_PERMISSIONS, hasAtLeastOnePermission } from 'utils/auth/authUtils';

import 'pages/buildings/buildingpage.css';

export const BuildingPricingTab = ({ building, catalogUuid, requestedPerms }: IBuildingTabCommonProps): JSX.Element => {
  const [selectedLocale, setSelectedLocale] = useState(DEFAULT_LOCALE);
  const [includeUnpriced, setIncludeUnpriced] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const isEditable = hasAtLeastOnePermission(requestedPerms, BUILDING_AND_PRICING_PERMISSIONS);

  const {
    loading: addonsLoading,
    data,
    error,
    refresh,
  } = useApiHook<ILocationListingGroup | null>(
    () =>
      getListingsWithAvailability(GroupSlugs.ADDONS, building.id, catalogUuid, {
        lang: selectedLocale,
        includeUnpriced,
      }),
    [building.id, selectedLocale, includeUnpriced, catalogUuid],
  );

  const onBuildingTranslationToggle = (_event: React.SyntheticEvent, { checked }: CheckboxProps): void =>
    checked && building ? setSelectedLocale(getBuildingLocaleCode(building)) : setSelectedLocale(DEFAULT_LOCALE);
  const onIncludeUnpricedToggle = (_event: React.SyntheticEvent, { checked }: CheckboxProps): void =>
    setIncludeUnpriced(!!checked);
  const onEditToggle = (): void => setIsEdit((prevState) => !prevState);

  return (
    <>
      <div className="available_addons_section">
        <LoadingContainer data-test-id="addons_loading" loading={addonsLoading} data={data} error={error}>
          {data && (
            <>
              <EditToggle disabled={!isEditable} isEdit={isEdit} onChange={onEditToggle} />
              <div className="building_info_section">
                <Checkbox
                  label="Show building locale based translations"
                  onChange={onBuildingTranslationToggle}
                  checked={selectedLocale !== DEFAULT_LOCALE}
                  data-test-id="toggle-locale"
                />
                <br />
                <Checkbox
                  label="Include products not currently on sale at this location"
                  onChange={onIncludeUnpricedToggle}
                  checked={includeUnpriced}
                  data-test-id="toggle-unpriced"
                />
              </div>
              <AvailableAddons
                addonsGroup={data}
                disabled={!isEdit}
                requestedPerms={requestedPerms}
                refresh={refresh}
              />
            </>
          )}
        </LoadingContainer>
      </div>
    </>
  );
};
