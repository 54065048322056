import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import { ReservableType, SpacemanFee, SpacemanResource } from 'types/spacemanTypes';
import { IProduct } from 'types/productCatalogTypes';
import { Hash } from 'types/global';

export enum TaxApplicableType {
  RESOURCE = 'resource',
  PRODUCT = 'product',
  RESERVABLE = 'reservable',
}

export interface ITaxableItem {
  type: TaxApplicableType;
  applies: Hash<boolean>;
  uuid: string;
  name: string;
}

export const resourceToTaxableItem = (resource: SpacemanResource, applies: Hash<boolean>): ITaxableItem => ({
  type: TaxApplicableType.RESOURCE,
  applies,
  uuid: resource.uuid,
  name: resource.name,
});

export const productToTaxableItem = (product: IProduct, applies: Hash<boolean>): ITaxableItem => ({
  type: TaxApplicableType.PRODUCT,
  applies,
  uuid: product.uuid,
  name: product.name,
});

const appliesToAllResources = (fee: SpacemanFee): boolean =>
  fee.reservable_types.includes(ReservableType.Resource) && fee.resource_types.length === 0;

const appliesToResource = (fee: SpacemanFee, resourceUuid: string): boolean =>
  appliesToAllResources(fee) || fee.resource_types.includes(resourceUuid);

const appliesToProduct = (fee: SpacemanFee, productUuid: string): boolean => fee.product_uuids.includes(productUuid);

type FeeApplicabilityTester = (fee: SpacemanFee, id: string) => boolean;

const getFeeApplicability = (fees: SpacemanFee[], id: string, isApplicable: FeeApplicabilityTester): Hash<boolean> =>
  fees.reduce((acc: Hash<boolean>, fee) => {
    acc[fee.id] = isApplicable(fee, id);
    return acc;
  }, {});

export const getTaxableItems = (
  fees: SpacemanFee[],
  resources: SpacemanResource[],
  products: IProduct[],
): ITaxableItem[] => {
  const productsByResourceUuid = keyBy(products, (product: IProduct) => product.attributes.RESOURCE_ID);
  const resourcesWithoutOverlappingProducts = resources.filter((resource) => !productsByResourceUuid[resource.uuid]);

  const allResourcesAsTaxableItems = resourcesWithoutOverlappingProducts.map((resource) =>
    resourceToTaxableItem(resource, getFeeApplicability(fees, resource.uuid, appliesToResource)),
  );
  const allProductsAsTaxableItems = products.map((product) =>
    productToTaxableItem(product, getFeeApplicability(fees, product.uuid, appliesToProduct)),
  );

  return sortBy([...allProductsAsTaxableItems, ...allResourcesAsTaxableItems], 'name');
};
