import { stringify } from 'query-string';
import {
  IBuildingObject,
  IGeogroupingObject,
  ILocationPrice,
  LocationPriceLocationType,
} from 'types/productCatalogTypes';
import { productCatalogFetch } from 'networking/productCatalog/productCatalogFetch';
import { IExtendedFetchResult, RequestMethods } from 'networking/fetchConfig';

export const updateBuildingAvailability = (
  productUuid: string,
  buildingUuid: string,
  available: boolean,
): Promise<IExtendedFetchResult<boolean>> =>
  productCatalogFetch<boolean>(
    RequestMethods.PUT,
    `/v2/products/${productUuid}/available_buildings/${buildingUuid}`,
    available,
  );

export const updateLocationPrice = (
  productUuid: string,
  locationPrice: ILocationPrice,
): Promise<IExtendedFetchResult<ILocationPrice>> => {
  const { price, currency, locationUuid, locationType } = locationPrice;
  return productCatalogFetch<ILocationPrice>(
    RequestMethods.PUT,
    `/v2/products/${productUuid}/location_price/${locationType}/${locationUuid}`,
    { price, currency },
  );
};

export const getProductsAvailableInLocation = (
  locationUuid: string,
  catalogUuid: string,
  locationType: LocationPriceLocationType,
): Promise<IExtendedFetchResult<string[]>> => {
  const query = stringify({ catalog_uuid: catalogUuid });
  return productCatalogFetch<string[]>(
    RequestMethods.GET,
    `/v2/locations/${locationType}/${locationUuid}/available_product_uuids?${query}`,
  );
};

export const getAllPublishedBuildings = (): Promise<IExtendedFetchResult<IBuildingObject[]>> => {
  return productCatalogFetch<IBuildingObject[]>(RequestMethods.GET, `/v2/locations/buildings`);
};

export const getAllPublishedGeogroupings = (): Promise<IExtendedFetchResult<IGeogroupingObject[]>> => {
  return productCatalogFetch<IGeogroupingObject[]>(RequestMethods.GET, `/v2/locations/geogroupings`);
};
