import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Text, Image } from '@wework/dieter-ui';

import chemcat from 'assets/chemcat.jpg';

import './notFoundPage.scss';

export const NotFoundPage = (): JSX.Element => (
  <div style={{ textAlign: 'center' }}>
    <Text negative className="not-found__header">
      404
    </Text>
    <Image rounded src={chemcat} centered />
    <Text size="big" negative className="not-found__subtitle">
      We couldn&rsquo;t find this page.
    </Text>
    <Button primary size="big" as={Link} to={'/'}>
      Return Home
    </Button>
  </div>
);
