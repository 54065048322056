import React from 'react';
import { Grid, Menu } from '@wework/dieter-ui';
import { Navigate, Route, Routes, useMatch, useResolvedPath } from 'react-router-dom';
import { isEmpty } from 'lodash';

export interface ITabConfig {
  text: string;
  // render: RouteProps['render'];
  component: JSX.Element;
  path: string;
}

type tabsOnClick = (config: ITabConfig) => void;

interface ITabsProps {
  tabsConfig: ITabConfig[];
  onClick: tabsOnClick;
}

export const VerticalTabRouter = ({ tabsConfig, onClick }: ITabsProps): JSX.Element => (
  <Grid>
    <Grid.Row>
      <Grid.Column width={4}>
        <TabLinks tabsConfig={tabsConfig} onClick={onClick} />
      </Grid.Column>
      <Grid.Column width={12}>
        <TabContents tabsConfig={tabsConfig} />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

// Tabs menu

interface ITabLinksProps {
  tabsConfig: ITabConfig[];
  onClick: tabsOnClick;
}

const TabLinks = ({ onClick, tabsConfig }: ITabLinksProps): JSX.Element => (
  <Menu vertical pointing secondary fluid>
    {tabsConfig.map((config, i) => (
      <TabLink key={i} onClick={onClick} tabConfig={config} />
    ))}
  </Menu>
);

interface ITabLinkProps {
  tabConfig: ITabConfig;
  onClick: tabsOnClick;
}

const TabLink = ({ tabConfig, onClick }: ITabLinkProps): JSX.Element => {
  const { path, text } = tabConfig;
  const resolvedPath = useResolvedPath(path);
  const match = useMatch(resolvedPath.pathname);

  return <Menu.Item name={text} active={!isEmpty(match)} onClick={(): void => onClick(tabConfig)} />;
};

// Content pane

interface ITabContentsProps {
  tabsConfig: ITabConfig[];
}

const TabContents = ({ tabsConfig }: ITabContentsProps): JSX.Element | null => (
  <Routes>
    {tabsConfig.map((config, i) => (
      <Route key={i} path={config.path} element={config.component} />
    ))}
    <Route path="*" element={<Navigate to={tabsConfig[0].path} replace />} />
  </Routes>
);
