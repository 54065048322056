import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Dropdown, DropdownItemProps, DropdownProps } from '@wework/dieter-ui';
import { isEmpty, size } from 'lodash';

import { useCatalog } from 'contexts/CatalogContext';
import { useFlashMessageContext } from 'contexts/FlashMessageContext';
import { NO_CATALOG_SELECTED_ROUTE } from 'pages/NoCatalogSelected/NoCatalogSelected';
import { BaseUrlParamsType } from 'types/router';
import usePrevious from 'hooks/usePrevious';
import { NO_AVAILABLE_CATALOGS_ROUTE } from 'pages/NoAvailableCatalogsPage/NoAvailableCatalogsPage';

const CatalogSelector = (): JSX.Element => {
  const { isLoading, isInitialized, catalogs, selectedCatalog, setSelectedCatalog } = useCatalog();
  const { flashError } = useFlashMessageContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { catalogUuid: urlCatalogUuid } = useParams() as BaseUrlParamsType;
  const prevUrlCatalogUuid = usePrevious(urlCatalogUuid);

  const options: DropdownItemProps[] = useMemo(() => {
    return catalogs.map(({ uuid, name }) => ({
      key: uuid,
      value: uuid,
      text: name,
    }));
  }, [catalogs]);

  const redirectToNoAvailableCatalogs = () => {
    if (pathname !== NO_AVAILABLE_CATALOGS_ROUTE) {
      navigate(`/${NO_AVAILABLE_CATALOGS_ROUTE}`);
    }
  };

  const redirectToNoCatalogPage = (): void => {
    if (pathname !== NO_CATALOG_SELECTED_ROUTE) {
      navigate(`/${NO_CATALOG_SELECTED_ROUTE}`);
    }
  };

  // update value of 'catalog_uuid' URL parameter when the user navigate to the route
  useEffect(() => {
    if (!isInitialized) return;

    if (urlCatalogUuid && selectedCatalog?.uuid !== urlCatalogUuid) {
      const newSelectedCatalog = catalogs.find((catalog) => catalog.uuid === urlCatalogUuid);
      if (newSelectedCatalog) {
        setSelectedCatalog(newSelectedCatalog);
      } else if (prevUrlCatalogUuid !== urlCatalogUuid) {
        flashError(`Not found catalog ${urlCatalogUuid}`);
        setSelectedCatalog(null);
      }
    } else if (!urlCatalogUuid && !selectedCatalog?.uuid && size(catalogs) === 1) {
      setSelectedCatalog(catalogs[0]);
      navigate(`/${catalogs[0].uuid}/products`);
    } else if (isInitialized && isEmpty(catalogs)) {
      redirectToNoAvailableCatalogs();
    } else if (!selectedCatalog?.uuid && !urlCatalogUuid) {
      redirectToNoCatalogPage();
    }
  }, [selectedCatalog?.uuid, urlCatalogUuid, catalogs, isInitialized]);

  const onChange = useCallback(
    (_, data: DropdownProps): void => {
      const newSelectedCatalog = catalogs.find((catalog) => catalog.uuid === data.value);
      if (newSelectedCatalog) {
        setSelectedCatalog(newSelectedCatalog);
        navigate(`${newSelectedCatalog.uuid}/products`);
      }
    },
    [history, catalogs, setSelectedCatalog],
  );

  return (
    <Dropdown
      placeholder="Select catalog"
      loading={isLoading}
      disabled={isEmpty(catalogs)}
      icon="dropdown"
      options={options}
      selection
      value={selectedCatalog?.uuid ?? ''}
      onChange={onChange}
    />
  );
};

export default CatalogSelector;
