import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import { GroupsPage } from 'pages/groups/GroupsPage';
import { GroupPage } from 'pages/groups/GroupPage';
import { NotFoundPage } from 'pages/NotFoundPage/NotFoundPage';

export enum GROUP_ROUTES {
  VIEW_GROUP = '/:catalog_uuid/groups/:uuid',
  ROOT = '/:catalog_uuid/groups',
}

export const GroupsRouter = (): JSX.Element => {
  return (
    <section>
      <Routes>
        <Route path="/" element={<GroupsPage />} />
        <Route path=":uuid" element={<GroupPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </section>
  );
};
