import { useEffect, useMemo } from 'react';
import { OperatorServicePermissionsMap, useRequestedPermissions } from '@wework/we-auth-react';

import { AlchemistEmployeePermission, GLOBAL_PERMISSIONS, OPERATOR_PERMISSIONS } from 'utils/auth/authUtils';
import { useFlashMessageContext } from 'contexts/FlashMessageContext';
import { usePermissionsByOperator } from './usePermissionsByOperator';

export const useGlobalAndOperatorPermissions = (
  permissions: AlchemistEmployeePermission[],
): [boolean, string | undefined, OperatorServicePermissionsMap] => {
  const globalPerms = useMemo(
    () => permissions.filter((permission) => GLOBAL_PERMISSIONS.includes(permission)),
    [permissions],
  );
  const operatorPermissions = useMemo(
    () => permissions.filter((permission) => OPERATOR_PERMISSIONS.includes(permission)),
    [permissions],
  );

  const { flashError } = useFlashMessageContext();
  const [globalPermsIsLoading, globalPermsError, requestedGlobalPerms] = useRequestedPermissions(globalPerms);
  const [operatorPermsIsLoading, opretarPermsError, requestedOperatorPerms] =
    usePermissionsByOperator(operatorPermissions);
  const error = (globalPermsError || opretarPermsError) && 'An error occurred while getting user permissions.';

  useEffect(() => {
    if (globalPermsError || opretarPermsError) {
      flashError('An error occurred while getting user permissions.');
    }
  }, [globalPermsError, opretarPermsError]);
  const isLoading = globalPermsIsLoading || operatorPermsIsLoading;
  const requestedPermissions = isLoading ? {} : { ...requestedGlobalPerms, ...requestedOperatorPerms };

  return [isLoading, error, requestedPermissions];
};
