import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { configure } from '@wework/we-auth-react';

import { setAuthClient } from 'networking/auth/client';

interface Auth0WrapperProps {
  children: JSX.Element;
}

export const Auth0Wrapper = ({ children }: Auth0WrapperProps): JSX.Element => {
  const auth0 = useAuth0();
  useEffect(() => {
    configure({
      environment: process.env.NODE_ENV || 'development',
      contextClient: {
        getEmployeeUuid: () => auth0.user?.['https://wework.com/employee_uuid'],
        getAccessToken: (): Promise<string> => auth0.getAccessTokenSilently(),
      },
    });
    // This is a bit of a hack to expose the auth0 api outside of react components so we can use it in api methods later
    setAuthClient(auth0);
  }, [auth0.user]);

  return children;
};
