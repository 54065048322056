import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { AdminPage } from 'pages/admin/AdminPage';
import { NotFoundPage } from 'pages/NotFoundPage/NotFoundPage';

export enum ADMIN_ROUTES {
  ROOT = '/:catalog_uuid/admin',
}

export const AdminRouter = (): JSX.Element => (
  <section>
    <Routes>
      <Route index element={<AdminPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  </section>
);
