import React from 'react';
import { Node } from 'react-checkbox-tree';

import { compact, join } from 'lodash';
import { LabelListingProductElement } from 'components/listings/LabelListingProductElement';
import { ILocationListingGroup, ILocationListingProduct } from 'types/productCatalogTypes';

export function getAllListingProducts(group: ILocationListingGroup): ILocationListingProduct[] {
  return [
    ...(group.products ?? []),
    ...(group.groups ?? []).reduce(
      (acc: ILocationListingProduct[], group: ILocationListingGroup) => [...acc, ...getAllListingProducts(group)],
      [],
    ),
  ];
}

function feeProductToNode(feeProduct: ILocationListingProduct, parentUuid?: string): Node {
  const id = join(compact([parentUuid, feeProduct.uuid]), ',');
  return {
    showCheckbox: false,
    value: id,
    label: <LabelListingProductElement listingProduct={feeProduct} />,
  };
}

function listingProductToNode(listingProduct: ILocationListingProduct, parentUuid?: string): Node {
  const id = join(compact([parentUuid, listingProduct.uuid]), ',');
  return {
    showCheckbox: false,
    value: id,
    label: <LabelListingProductElement listingProduct={listingProduct} />,
    children: (listingProduct.fees ?? []).map((fee) => feeProductToNode(fee, id)),
  };
}

export function listingGroupToNode(group: ILocationListingGroup): Node {
  return {
    showCheckbox: false,
    value: group.uuid,
    label: group.name,
    children: [
      ...(group.products ?? []).map((product) => listingProductToNode(product, group.uuid)),
      ...(group.groups ?? []).reduce((acc: Node[], group: ILocationListingGroup) => {
        const node = listingGroupToNode(group);
        return node.children?.length ? [...acc, node] : acc; // Filter out empty groups
      }, []),
    ],
  };
}
