import React, { useMemo, useState } from 'react';
import { Table } from '@wework/dieter-ui';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import { MarketPricingTableRowConfig } from 'pages/markets/MarketTableConfig';
import { ILocationPrice, IMarketPricing, IProduct, LocationPriceLocationType } from 'types/productCatalogTypes';
import { formatPriceCurrency } from 'utils/displayHelpers';
import { Hash } from 'types/global';
import { LocationPriceModal } from 'components/shared/LocationPriceForm/LocationPriceModal';
import { locationPriceDefaultValues } from 'components/shared/LocationPriceForm/locationPriceFormConfig';
import {
  createLocationPrice,
  deleteLocationPrice,
  updateLocationPrice,
} from 'networking/productCatalog/locationPriceRequests';

import './MarketPricingTable.scss';

const getPrice = (pricingMap: Hash<ILocationPrice>, uuid: string): string => {
  const mapPrice = pricingMap[uuid];
  return mapPrice ? formatPriceCurrency(mapPrice.price, mapPrice.currency) : 'No price set';
};

const getLocationPrice = (
  pricingMap: Hash<ILocationPrice>,
  productUuid: string,
  locationUuid: string,
): ILocationPrice =>
  pricingMap[productUuid] || {
    ...locationPriceDefaultValues,
    locationUuid,
    locationType: LocationPriceLocationType.GEOGROUPING,
  };

interface Props {
  marketPricing: IMarketPricing;
  isEditable?: boolean;
  onSave: () => void;
}

interface IModalState {
  locationName: string;
  locationPrice: ILocationPrice;
  productUuid: string;
}

export const MarketPricingTable = ({ marketPricing, isEditable, onSave }: Props): JSX.Element => {
  const { uuid: marketUuid, parentUuid: regionUuid } = marketPricing;

  const marketPricingMap = useMemo(
    () => keyBy(marketPricing.marketPricing, 'productUuid'),
    [marketPricing.marketPricing],
  );
  const regionPricingMap = useMemo(
    () => keyBy(marketPricing.regionPricing, 'productUuid'),
    [marketPricing.regionPricing],
  );

  // move products with price to the beginning of the list
  const sortedProducts = useMemo(
    () =>
      sortBy(
        marketPricing.addOns,
        ({ uuid }) => (uuid in marketPricingMap ? 0 : 1) + (uuid in regionPricingMap ? 0 : 1),
      ),
    [marketPricing.addOns, marketPricing.marketPricing, marketPricing.regionPricing],
  );

  const [editModalProps, setEditModalProps] = useState<IModalState | null>(null);
  const onModalClose = (): void => setEditModalProps(null);
  const onModalSave = async (formData: ILocationPrice): Promise<void> => {
    if (editModalProps) {
      const requestAction = !!formData.id ? updateLocationPrice : createLocationPrice;
      await requestAction(editModalProps.productUuid, formData);
      setEditModalProps(null);
      onSave();
    }
  };
  const onModalDelete = async (): Promise<void> => {
    if (editModalProps) {
      await deleteLocationPrice(editModalProps.productUuid, editModalProps.locationPrice);
      setEditModalProps(null);
      onSave();
    }
  };

  const onRowClick = (locationType: LocationPriceLocationType, productUuid: string): void => {
    if (locationType === LocationPriceLocationType.MARKET) {
      const locationPrice = getLocationPrice(marketPricingMap, productUuid, marketUuid);
      setEditModalProps({ locationName: marketPricing.name, locationPrice, productUuid });
    } else {
      const locationPrice = getLocationPrice(regionPricingMap, productUuid, regionUuid);
      setEditModalProps({ locationName: marketPricing.regionName, locationPrice, productUuid });
    }
  };

  return (
    <Table selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan={3} textAlign="center">
            Addon Pricing
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          {MarketPricingTableRowConfig.map(
            ({ column, heading, columnWidth }): JSX.Element => (
              <Table.HeaderCell width={columnWidth} key={column}>
                {heading}
              </Table.HeaderCell>
            ),
          )}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {sortedProducts.map((product: IProduct) => (
          <Table.Row key={product.uuid}>
            <Table.Cell>
              <span>{product.name}</span>
            </Table.Cell>
            <Table.Cell
              selectable={isEditable}
              onClick={(): void => {
                isEditable && onRowClick(LocationPriceLocationType.MARKET, product.uuid);
              }}
            >
              <span>{getPrice(marketPricingMap, product.uuid)}</span>
            </Table.Cell>
            <Table.Cell
              selectable={isEditable}
              onClick={(): void => {
                isEditable && onRowClick(LocationPriceLocationType.REGION, product.uuid);
              }}
            >
              <span>{getPrice(regionPricingMap, product.uuid)}</span>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      {editModalProps && (
        <LocationPriceModal
          locationName={editModalProps.locationName}
          locationPrice={editModalProps.locationPrice}
          isPricingEditable={isEditable}
          productUuid={editModalProps.productUuid}
          locationAttributes={null}
          onClose={onModalClose}
          onSaveLocationPrice={onModalSave}
          onSaveLocationAttributes={(): null => null}
          onDelete={onModalDelete}
        />
      )}
    </Table>
  );
};
