import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';

import { SortConfig } from 'hooks/useSortable';

type Props = {
  attr: string;
  sortConfig: SortConfig;
};

export const SortArrow = ({ attr, sortConfig }: Props): JSX.Element => {
  const [sortAttr, sortDesc] = sortConfig;
  if (attr === sortAttr) {
    const icon = sortDesc ? faSortDown : faSortUp;
    return <FontAwesomeIcon icon={icon} />;
  }
  return <></>;
};
