import { Dropdown } from '@wework/dieter-ui';
import { DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';

import { FormValue } from 'components/shared/Form/FormConfig';
import { IFetchResult } from 'networking/fetchConfig';
import { useApiHook } from 'hooks/useApiHook';

type PointingType =
  | boolean
  | 'left'
  | 'right'
  | 'top'
  | 'top left'
  | 'top right'
  | 'bottom'
  | 'bottom left'
  | 'bottom right';

export interface IAutocompleteProps<T> {
  skipKeys: string[];
  placeholder?: string;
  disabled?: boolean;
  pointing?: PointingType;
  entityToOption: (t: T) => DropdownItemProps;
  fetchFunc: () => Promise<IFetchResult<T[]>>;
  onSelectionChange: (value: string) => void;
}

function sanitize(value: FormValue): string | undefined {
  return value ? value.toString() : undefined;
}

export function Autocomplete<T>({
  skipKeys,
  placeholder,
  disabled,
  pointing,
  entityToOption,
  fetchFunc,
  onSelectionChange,
}: IAutocompleteProps<T>): JSX.Element {
  const [items, setItems] = useState<DropdownItemProps[]>([]);
  const { loading, data } = useApiHook(fetchFunc);

  useEffect(() => {
    const allItems = (data || []).map(entityToOption);
    setItems(allItems.filter((item: DropdownItemProps) => !skipKeys.includes(item.key)));
  }, [data, skipKeys]);

  return (
    <Dropdown
      placeholder={placeholder}
      loading={loading}
      icon="dropdown"
      search
      selectOnBlur={false}
      options={items}
      onChange={(_, { value }: DropdownProps): void => {
        const entityId = sanitize(value);
        entityId && onSelectionChange(entityId);
      }}
      value=""
      disabled={disabled}
      pointing={pointing}
    />
  );
}
