import { FeeKind, SpacemanFee } from 'types/spacemanTypes';
import { ICreateFeeRequest, IUpdateFeeRequest } from 'networking/spaceman/feeRequests';
import { IFeeFormValues } from 'components/buildings/fees/FeeFormConfig';
import { multiplyBy } from 'utils/transformHelpers';

export const getEmptyFormValues = (): IFeeFormValues => ({
  kind: '',
  name: '',
  rate: '',
  base: '',
});

export const getValuesFromFee = (fee: SpacemanFee): IFeeFormValues => ({
  kind: fee.kind,
  name: fee.name,
  rate: multiplyBy(fee.rate, 100),
  base: '',
});

export const getCreateRequestParams = (formValues: IFeeFormValues, locationUuid: string): ICreateFeeRequest => ({
  location_uuid: locationUuid,
  kind: formValues.kind as FeeKind,
  name: formValues.name,
  rate: formValues.rate,
});

export const getUpdateRequestParams = (formValues: IFeeFormValues): IUpdateFeeRequest => ({
  kind: formValues.kind as FeeKind,
  name: formValues.name,
  rate: formValues.rate,
});
