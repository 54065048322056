import React, { DependencyList, useEffect, useState } from 'react';
import { IFetchResult, ResponseStatus } from 'networking/fetchConfig';

interface IUseApiHookState<T> {
  data: T | undefined;
  error?: string;
  loading: boolean;
  status: ResponseStatus;
}

export interface IUseApiHookResult<T> extends IUseApiHookState<T> {
  refresh: () => void;
}

export function useApiHook<T>(
  func: () => Promise<IFetchResult<T>>,
  deps: DependencyList = [],
  initialValue?: T,
): IUseApiHookResult<T> {
  const [lastFetchedAt, setLastFetchedAt] = useState<Date>(new Date());
  const [state, setState] = useState<IUseApiHookState<T>>({
    data: initialValue,
    loading: true,
    status: ResponseStatus.UNKNOWN,
  });

  useEffect(() => {
    setState({ ...state, loading: true });
    doFetch<T>(func, setState);
  }, [...deps, lastFetchedAt]);

  return {
    refresh: (): void => setLastFetchedAt(new Date()),
    ...state,
  };
}

async function doFetch<T>(
  func: () => Promise<IFetchResult<T>>,
  setState: React.Dispatch<IUseApiHookState<T>>,
): Promise<void> {
  const result = await func();
  setState({
    ...result,
    loading: false,
  });
}
