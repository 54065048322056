import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';
import * as Yup from 'yup';

import { IProductAttributes, IImage, ITranslations } from 'types/productCatalogTypes';
import { nullifyIfBlank, nullifyNumberIfBlank, multiplyBy100 } from 'utils/transformHelpers';
import { FormValue } from 'components/shared/Form/FormConfig';

export enum ProductFormField {
  NAME = 'name',
  DESCRIPTION = 'description',
  NOTES = 'notes',
  VOLUME_LIMIT = 'volumeLimit',
  EXTERNAL_VENDOR_URL = 'externalVendorUrl',
  IMAGES = 'images',
  TRANSLATIONS = 'translations',
  ATTRIBUTES = 'attributes',
  ACCESS_GEOGROUPING_ID = 'ACCESS_GEOGROUPING_ID',
  BILLING_ENTITY_ID = 'BILLING_ENTITY_ID',
  CREDIT_ALLOTMENT = 'CREDIT_ALLOTMENT',
  RESERVATION_DURATION = 'RESERVATION_DURATION',
  RESOURCE_ID = 'RESOURCE_ID',
  DAILY_DESK_CREDIT_PRICE = 'DAILY_DESK_CREDIT_PRICE',
  OVERRIDE_DAILY_DESK_CREDIT_PRICE = 'OVERRIDE_DAILY_DESK_CREDIT_PRICE',
  RESTRICT_PROMOCODE_KIND = 'RESTRICT_PROMOCODE_KIND',
  PRINT_BW_ALLOTMENT = 'PRINT_BW_ALLOTMENT',
  PRINT_COLOR_ALLOTMENT = 'PRINT_COLOR_ALLOTMENT',
  WELCOME_EMAIL_TEMPLATE_SLUG = 'WELCOME_EMAIL_TEMPLATE_SLUG',
  FULFILLMENT_EMAIL = 'FULFILLMENT_EMAIL',
  CONSULTATION_EMAIL_TEMPLATE_SLUG = 'CONSULTATION_EMAIL_TEMPLATE_SLUG',
  FULFILLMENT_EMAIL_TEMPLATE_SLUG = 'FULFILLMENT_EMAIL_TEMPLATE_SLUG',
  PURCHASE_IMMEDIATELY = 'PURCHASE_IMMEDIATELY',
  REQUEST_CONSULTATION = 'REQUEST_CONSULTATION',
  LIMITED_AVAILABILITY = 'LIMITED_AVAILABILITY',
  ONE_TIME = 'oneTime',
  REFUNDABLE = 'refundable',
  WAIVABLE = 'waivable',
  TNC_URL_NAME = 'TNC_URL_NAME',
  TNC_URL_VALUE = 'TNC_URL_VALUE',
  SALE_ITEM_GROUP = 'saleItemGroup',
  UUID = 'uuid',
  SUBMIT_ERROR = 'submitError',
  BANDWIDTH_CAPACITY_LOWER_BOUND = 'BANDWIDTH_CAPACITY_LOWER_BOUND',
  BANDWIDTH_CAPACITY_UPPER_BOUND = 'BANDWIDTH_CAPACITY_UPPER_BOUND',
  SALES_ITEM_ID = 'SALES_ITEM_ID',
  NAME_TRANSLATIONS = 'nameTranslations',
  DESCRIPTION_TRANSLATIONS = 'descriptionTranslations',
  AVAILABILITY_CONFIGURATION = 'AVAILABILITY_CONFIGURATION',
  BOOKING_CANCELLATION_POLICY = 'BOOKING_CANCELLATION_POLICY',
}

export const saleItemGroups = [
  ['private_space', 'Private Space'],
  ['shared_space', 'Shared Space'],
  ['global_access', 'Global Access'],
  ['virtual_office', 'Virtual Office'],
  ['wwlo_addons', 'WWLO Addons'],
  ['addons', 'Addons'],
  ['bandwidth', 'Dedicated Bandwidth'],
  ['fee', 'Fees'],
];

export const productFormProductCategoryConfig = saleItemGroups.map(([value, title]) => ({
  value,
  key: value,
  text: title,
}));

export const imageTags = [
  ['THUMBNAIL', 'Thumbnail'],
  ['PRIMARY', 'Primary'],
];

export const imageTagsConfig = imageTags.map(([value, title]) => ({
  value,
  key: value,
  text: title,
}));

export const ProductFormSchema = Yup.object().shape({
  name: Yup.string().required('Please enter a name for the product.'),
  description: Yup.string(),
  notes: Yup.string(),
  externalVendorUrl: Yup.string().nullable(),
  volumeLimit: Yup.number().nullable(),
  images: Yup.mixed().nullable().transform(nullifyIfBlank),
  attributes: Yup.object().shape({
    [ProductFormField.ACCESS_GEOGROUPING_ID]: Yup.string().nullable().transform(nullifyIfBlank),
    [ProductFormField.BILLING_ENTITY_ID]: Yup.string(),
    [ProductFormField.CREDIT_ALLOTMENT]: Yup.string().nullable().transform(nullifyNumberIfBlank),
    [ProductFormField.RESERVATION_DURATION]: Yup.string().nullable().transform(nullifyIfBlank),
    [ProductFormField.RESOURCE_ID]: Yup.string(),
    [ProductFormField.RESTRICT_PROMOCODE_KIND]: Yup.string().nullable(),
    [ProductFormField.DAILY_DESK_CREDIT_PRICE]: Yup.string().nullable(),
    [ProductFormField.PRINT_BW_ALLOTMENT]: Yup.string().nullable().transform(nullifyNumberIfBlank),
    [ProductFormField.PRINT_COLOR_ALLOTMENT]: Yup.string().nullable().transform(nullifyNumberIfBlank),
    [ProductFormField.WELCOME_EMAIL_TEMPLATE_SLUG]: Yup.string().nullable().transform(nullifyIfBlank),
    [ProductFormField.AVAILABILITY_CONFIGURATION]: Yup.string().uuid().nullable().transform(nullifyIfBlank),
    [ProductFormField.BOOKING_CANCELLATION_POLICY]: Yup.string().uuid().nullable().transform(nullifyIfBlank),
    [ProductFormField.BANDWIDTH_CAPACITY_LOWER_BOUND]: Yup.string().nullable().transform(nullifyNumberIfBlank),
    [ProductFormField.BANDWIDTH_CAPACITY_UPPER_BOUND]: Yup.string().nullable().transform(nullifyNumberIfBlank),
    [ProductFormField.SALES_ITEM_ID]: Yup.string().nullable(),
  }),
  purchaseImmediately: Yup.boolean(),
  requestConsultation: Yup.boolean(),
  limitedAvailability: Yup.boolean(),
  oneTime: Yup.boolean(),
  refundable: Yup.boolean(),
  waivable: Yup.boolean(),
  price: Yup.mixed()
    .transform(multiplyBy100)
    .test(
      'price-validator',
      'Price must be at least 0 or empty',
      (value) => isUndefined(value) || (isNumber(value) && value >= 0),
    ),
  saleItemGroup: Yup.string().required('Please enter a sale item group.'),
  uuid: Yup.string().uuid(),
});

export type ProductFormSchemaType = Yup.InferType<typeof ProductFormSchema>;

export const productFormValuesToPayload = (values: IProductFormInput): ProductFormSchemaType => {
  const payload = ProductFormSchema.cast(values) as ProductFormSchemaType;
  if (!values[ProductFormField.OVERRIDE_DAILY_DESK_CREDIT_PRICE]) {
    // @ts-ignore
    delete payload.attributes[ProductFormField.DAILY_DESK_CREDIT_PRICE];
  }
  return payload;
};

export type ProductFormValue = FormValue | IProductFormAttributesInput | IProductAttributes | IImage[];

export interface IProductFormAttributesInput {
  [ProductFormField.ACCESS_GEOGROUPING_ID]: string;
  [ProductFormField.BILLING_ENTITY_ID]: string;
  [ProductFormField.CREDIT_ALLOTMENT]: number | null;
  [ProductFormField.DAILY_DESK_CREDIT_PRICE]: string | null;
  [ProductFormField.RESERVATION_DURATION]: string | null;
  [ProductFormField.RESOURCE_ID]: string;
  [ProductFormField.RESTRICT_PROMOCODE_KIND]: string | null;
  [ProductFormField.PRINT_BW_ALLOTMENT]: number | null;
  [ProductFormField.PRINT_COLOR_ALLOTMENT]: number | null;
  [ProductFormField.WELCOME_EMAIL_TEMPLATE_SLUG]: string | null;
  [ProductFormField.CONSULTATION_EMAIL_TEMPLATE_SLUG]: string | null;
  [ProductFormField.FULFILLMENT_EMAIL_TEMPLATE_SLUG]: string | null;
  [ProductFormField.PURCHASE_IMMEDIATELY]: boolean | null;
  [ProductFormField.REQUEST_CONSULTATION]: boolean | null;
  [ProductFormField.LIMITED_AVAILABILITY]: boolean | null;
  [ProductFormField.BANDWIDTH_CAPACITY_LOWER_BOUND]: string | null;
  [ProductFormField.BANDWIDTH_CAPACITY_UPPER_BOUND]: string | null;
  [ProductFormField.AVAILABILITY_CONFIGURATION]: string | null;
  [ProductFormField.BOOKING_CANCELLATION_POLICY]: string | null;
  [ProductFormField.SALES_ITEM_ID]: string | null;
  [key: string]: ProductFormValue;
}

export interface IProductFormInput {
  [ProductFormField.NAME]: string;
  [ProductFormField.DESCRIPTION]: string;
  [ProductFormField.VOLUME_LIMIT]: number | null;
  [ProductFormField.EXTERNAL_VENDOR_URL]: string;
  [ProductFormField.IMAGES]: IImage[] | null;
  [ProductFormField.NOTES]: string;
  [ProductFormField.TRANSLATIONS]: ITranslations;
  [ProductFormField.ATTRIBUTES]: IProductFormAttributesInput;
  [ProductFormField.OVERRIDE_DAILY_DESK_CREDIT_PRICE]: boolean;
  [ProductFormField.ONE_TIME]: boolean;
  [ProductFormField.REFUNDABLE]: boolean;
  [ProductFormField.WAIVABLE]: boolean;
  [ProductFormField.TNC_URL_NAME]: string | null;
  [ProductFormField.TNC_URL_VALUE]: string | null;
  [ProductFormField.SALE_ITEM_GROUP]: string;
  [ProductFormField.UUID]: string;
  [ProductFormField.SUBMIT_ERROR]: string;
  [ProductFormField.NAME_TRANSLATIONS]: null;
  [ProductFormField.DESCRIPTION_TRANSLATIONS]: null;
  [key: string]: ProductFormValue;
}

export const defaultValues: IProductFormInput = {
  [ProductFormField.NAME]: '',
  [ProductFormField.DESCRIPTION]: '',
  [ProductFormField.VOLUME_LIMIT]: null,
  [ProductFormField.EXTERNAL_VENDOR_URL]: '',
  [ProductFormField.IMAGES]: [],
  [ProductFormField.NOTES]: '',
  [ProductFormField.TRANSLATIONS]: {},
  [ProductFormField.ATTRIBUTES]: {
    [ProductFormField.ACCESS_GEOGROUPING_ID]: '',
    [ProductFormField.BILLING_ENTITY_ID]: '',
    [ProductFormField.CREDIT_ALLOTMENT]: 0,
    [ProductFormField.DAILY_DESK_CREDIT_PRICE]: '2',
    [ProductFormField.RESERVATION_DURATION]: '',
    [ProductFormField.RESOURCE_ID]: '',
    [ProductFormField.RESTRICT_PROMOCODE_KIND]: '',
    [ProductFormField.PRINT_BW_ALLOTMENT]: 0,
    [ProductFormField.PRINT_COLOR_ALLOTMENT]: 0,
    [ProductFormField.WELCOME_EMAIL_TEMPLATE_SLUG]: '',
    [ProductFormField.FULFILLMENT_EMAIL]: '',
    [ProductFormField.CONSULTATION_EMAIL_TEMPLATE_SLUG]: '',
    [ProductFormField.FULFILLMENT_EMAIL_TEMPLATE_SLUG]: '',
    [ProductFormField.PURCHASE_IMMEDIATELY]: null,
    [ProductFormField.REQUEST_CONSULTATION]: null,
    [ProductFormField.LIMITED_AVAILABILITY]: null,
    [ProductFormField.BANDWIDTH_CAPACITY_LOWER_BOUND]: '',
    [ProductFormField.BANDWIDTH_CAPACITY_UPPER_BOUND]: '',
    [ProductFormField.AVAILABILITY_CONFIGURATION]: '',
    [ProductFormField.BOOKING_CANCELLATION_POLICY]: '',
    [ProductFormField.SALES_ITEM_ID]: '',
  },
  [ProductFormField.OVERRIDE_DAILY_DESK_CREDIT_PRICE]: false,
  [ProductFormField.ONE_TIME]: false,
  [ProductFormField.REFUNDABLE]: false,
  [ProductFormField.WAIVABLE]: false,
  [ProductFormField.TNC_URL_NAME]: '',
  [ProductFormField.TNC_URL_VALUE]: '',
  [ProductFormField.SALE_ITEM_GROUP]: '',
  [ProductFormField.UUID]: '',
  [ProductFormField.SUBMIT_ERROR]: '',
  [ProductFormField.NAME_TRANSLATIONS]: null,
  [ProductFormField.DESCRIPTION_TRANSLATIONS]: null,
};
