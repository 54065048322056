import { FormikErrors, FormikTouched } from 'formik';
import { LabelProps, SemanticShorthandItem } from 'semantic-ui-react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import toString from 'lodash/toString';
import startCase from 'lodash/startCase';
import { IFieldError } from 'networking/fetchConfig';

type StringLikeValues = string | number | boolean | undefined | Array<string | number | boolean>;

export type FieldError = {
  content: string;
};

export const formValueToStringArray = (values: StringLikeValues): string[] => {
  if (!values) {
    return [];
  }
  return isArray(values) ? values.map(toString) : [toString(values)];
};

export function fieldError<T>(
  touched: FormikTouched<T>,
  errors: FormikErrors<T>,
  path: string,
): SemanticShorthandItem<LabelProps> {
  const content = get(touched, path) && get(errors, path);
  if (content) {
    return {
      content,
      pointing: 'above',
    };
  }
}

export function handleChange<T>(
  name: string,
  value: T,
  setFieldValue: (field: string, value: T) => void,
  setFieldTouched: (field: string, isTouched?: boolean) => void,
): void {
  setFieldValue(name, value);
  setFieldTouched(name, true);
}

export function formatErrors<T>(fieldErrors: IFieldError[]): FormikErrors<T> {
  return fieldErrors.reduce(
    (acc, fieldError) => ({
      ...acc,
      [fieldError.field]: `${startCase(fieldError.field)} ${fieldError.message}`,
    }),
    {},
  );
}
