import React from 'react';

import { OperatorServicePermissionsMap, OperatorServiceRolesMap } from '@wework/we-auth-react';
import { ITabConfig } from 'components/shared/VerticalTabRouter/VerticalTabRouter';
import { BuildingPricingTab } from 'pages/buildings/buildingTabs/BuildingPricingTab';
import { BuildingTaxesTab } from 'pages/buildings/buildingTabs/BuildingTaxesTab';
import { IBuildingObject } from 'types/productCatalogTypes';
import { hasAtLeastOnePermission, TAXES_PERMISSIONS } from 'utils/auth/authUtils';

enum BUILDING_TAB_PATHS {
  PRICING_TAB = '/buildings/:uuid/pricing',
  TAXES_TAB = '/buildings/:uuid/taxes',
}

interface IBuildingTabCommonProps {
  building: IBuildingObject;
  catalogUuid: string;
  requestedPerms: OperatorServicePermissionsMap;
}

const buildingTabsConfig = (
  building: IBuildingObject,
  catalogUuid: string,
  requestedRoles: OperatorServiceRolesMap,
  requestedPerms: OperatorServicePermissionsMap,
): ITabConfig[] => {
  const isTaxesAvailable = hasAtLeastOnePermission(requestedPerms, TAXES_PERMISSIONS) && requestedRoles.city_lead;

  const tabs = [
    {
      text: 'Pricing',
      component: <BuildingPricingTab building={building} catalogUuid={catalogUuid} requestedPerms={requestedPerms} />,
      path: 'pricing',
    },
  ];

  if (isTaxesAvailable) {
    tabs.push({
      text: 'Taxes',
      component: <BuildingTaxesTab building={building} catalogUuid={catalogUuid} requestedPerms={requestedPerms} />,
      path: 'taxes',
    });
  }

  return tabs;
};

export { buildingTabsConfig, IBuildingTabCommonProps, BUILDING_TAB_PATHS };
