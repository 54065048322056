export interface SpacemanFee {
  id: number;
  name: string;
  kind: FeeKind;
  rate: string;
  rate_type: string;
  reservable_types: ReservableType[];
  resource_types: string[];
  product_uuids: string[];
}

export interface SpacemanResource {
  uuid: string;
  name: string;
}

export enum ReservableType {
  'Reservable Item' = 'ReservableItem',
  'Virtual Reservable' = 'VirtualReservable',
  'Space' = 'Space',
  'Hot Desk' = 'HotDesk',
  'Shared Office Desk' = 'SharedOfficeDesk',
  'Dedicated Desk' = 'DedicatedDesk',
  'Digital Service' = 'DigitalService',
  'Resource' = 'Resource',
}

export enum FeeKind {
  'Country Tax' = 'country_tax',
  'State Tax' = 'state_tax',
  'Province Tax' = 'province_tax',
  'City Tax' = 'city_tax',
  'Hotel Unit Fee' = 'unit_fee',
  'Hotel Occupancy Tax' = 'occupancy_tax',
}
