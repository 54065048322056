import { DropdownItemProps } from 'semantic-ui-react';
import compact from 'lodash/compact';

import LocaleCode, { LocaleObject } from 'utils/localeCode';
import { IBuildingObject } from 'types/productCatalogTypes';

const codes = [
  'es-MX',
  'zh-CN',
  'fr-CA',
  'de-DE',
  'ko-KR',
  'es-AR',
  'es-PE',
  'es-CO',
  'th-TH',
  'vi-VN',
  'ru-RU',
  'pt-BR',
  'es-CL',
  'it-IT',
  'ja-JP',
  'id-ID',
  'sv-SE',
  'nl-NL',
  'ms-MY',
  'en-GB',
  'pl-PL',
  'cs-CZ',
  'es-ES',
  'fr-FR',
  'zh-TW',
  'es-LA',
  'zh-HK',

  // less used
  'nb-NO',
  'en-AU',
  'en-IN',
  'en-IE',
  'en-CA',
  'en-SG',
  'en-PH',
  'en-AE',
  'es-CR',
  'he-IL',
  'fr-BE',
  'en-ZA',
  'da-DK',
  'en-JP',
  'en-AR',
  'en-BE',
  'en-BR',
  'en-CL',
  'en-CN',
  'en-CO',
  'en-CR',
  'en-CZ',
  'en-DK',
  'en-FR',
  'en-DE',
  'en-HK',
  'en-ID',
  'en-IL',
  'en-IT',
  'en-KR',
  'en-MY',
  'en-MX',
  'en-NL',
  'en-NO',
  'en-PE',
  'en-PL',
  'en-RU',
  'en-ES',
  'en-SE',
  'en-TW',
  'en-TH',
  'en-VN',
  'en-PT',
  'en-HU',
  'en-RO',
  'en-CH',
  'en-GE',
  'en-AT',
];

export const DEFAULT_LOCALE = 'en-US';

export interface LocaleWithCountry extends LocaleObject {
  countryName: string;
  countryFlag: string;
}

const getLocalesAsOptions = (): DropdownItemProps[] => {
  const enUS = 'en-US';
  return [
    { key: 'none', value: '', text: 'None' },
    { key: enUS, value: enUS, text: enUS },
  ].concat(
    codes.map((locale) => {
      return { key: locale, value: locale, text: locale };
    }),
  );
};

function getLocaleWithCountry(locale: LocaleObject): LocaleWithCountry {
  return {
    ...locale,
    countryName: LocaleCode.getCountryName(locale.code),
    countryFlag: LocaleCode.getCountryFlag(locale.code),
  };
}

export function getBuildingLocaleCode(building: IBuildingObject): string {
  const { default_locale, countrygeo } = building;
  if (default_locale.length === 2) {
    return compact([default_locale, countrygeo?.iso]).join('-');
  } else {
    return default_locale;
  }
}

export const LOCALES: LocaleWithCountry[] = LocaleCode.getLanguages(codes).map(getLocaleWithCountry);

export const LOCALES_AS_OPTIONS = getLocalesAsOptions();
