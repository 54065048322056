export enum NodeEnv {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export enum AppEnv {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

interface Setting {
  APP_NAME: string;
  GOOGLE_CLIENT_ID: string;
  PRODUCT_SERVICE_URI: string;
  SPACEMAN_URI: string;
  OPERATOR_SERVICE_URI: string;
  ALGOLIA_GEOGROUPING_INDEX: string;
  ALGOLIA_BUILDING_INDEX: string;
  ALGOLIA_APP_ID: string;
  ALGOLIA_API_KEY: string;
  GA_TRACKINGID: string;
  AUTH0_CLIENT_ID: string;
  AUTH0_CLIENT_DOMAIN: string;
}

declare const process: {
  env: {
    readonly APP_ENV: AppEnv;
    readonly NODE_ENV: NodeEnv;
    readonly SETTINGS: Setting;
  };
};

export const { SETTINGS } = process.env;
