import React, { useEffect, useState } from 'react';

import { CheckboxProps } from '@wework/dieter-ui';
import { AvailabilityForm } from 'components/shared/AvailabilityForm/AvailabilityForm';
import { LoadingContainer } from 'components/shared/Loading/LoadingContainer';
import { useApiHook } from 'hooks/useApiHook';
import { getAvailableBuildings } from 'networking/productCatalog/availableBuildingsRequests';
import { getLocationPrices } from 'networking/productCatalog/locationPriceRequests';
import { getLocationAttributes } from 'networking/productCatalog/locationAttributesRequests';

import {
  ILocationPrice,
  ILocationAttributesRecord,
  IGeogroupingObject,
  IBuildingObject,
  IProduct,
} from 'types/productCatalogTypes';
import { getAllPublishedGeogroupings, getAllPublishedBuildings } from 'networking/productCatalog/buildingRequests';
import { EditToggle } from 'components/shared/EditToggle/EditToggle';

interface IEditPricingTabProps {
  product: IProduct;
  isAvailabilityEditable?: boolean;
  isPricingEditable?: boolean;
}

const EditPricingTab = ({ product, isPricingEditable, isAvailabilityEditable }: IEditPricingTabProps): JSX.Element => {
  const [availableBuildingIds, setAvailableBuildingIds] = useState<string[]>([]);
  const [locationPrices, setLocationPrices] = useState<ILocationPrice[]>([]);
  const [locationAttributes, setLocationAttributes] = useState<ILocationAttributesRecord[]>([]);
  const [geogroupingData, setGeogroupingData] = useState<IGeogroupingObject[]>([]);
  const [publishedBuildingsData, setPublishedBuildingsData] = useState<IBuildingObject[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const isEditable = isPricingEditable || isAvailabilityEditable;

  const buildingsResponse = useApiHook<string[]>(() => getAvailableBuildings(product.uuid), [product.uuid], []);
  const locationPricesResponse = useApiHook<ILocationPrice[]>(() => getLocationPrices(product), [product.uuid], []);
  const locationAttributesResponse = useApiHook<ILocationAttributesRecord[]>(
    () => getLocationAttributes(product),
    [product.uuid],
    [],
  );

  const geogroupingResponse = useApiHook<IGeogroupingObject[]>(getAllPublishedGeogroupings, [], []);
  const publishedBuildingsResponse = useApiHook<IBuildingObject[]>(getAllPublishedBuildings, [], []);

  useEffect(() => {
    setAvailableBuildingIds(buildingsResponse.data || []);
  }, [buildingsResponse.data]);

  useEffect(() => {
    setLocationPrices(locationPricesResponse.data || []);
  }, [locationPricesResponse.data]);

  useEffect(() => {
    setLocationPrices(locationPricesResponse.data || []);
  }, [locationPricesResponse.data]);

  useEffect(() => {
    setLocationAttributes(locationAttributesResponse.data || []);
  }, [locationAttributesResponse.data]);

  useEffect(() => {
    setGeogroupingData(geogroupingResponse.data || []);
  }, [geogroupingResponse.data]);

  useEffect(() => {
    setPublishedBuildingsData(publishedBuildingsResponse.data || []);
  }, [publishedBuildingsResponse.data]);

  const shouldRender = (): ILocationAttributesRecord[] | null => {
    if (!buildingsResponse.data || !locationPricesResponse.data || !locationAttributesResponse.data) {
      return null;
    }
    return locationAttributesResponse.data;
  };

  return (
    <LoadingContainer
      loading={
        (buildingsResponse.loading && locationPricesResponse.loading && locationAttributesResponse.loading) ||
        geogroupingResponse.loading ||
        publishedBuildingsResponse.loading
      }
      error={
        buildingsResponse?.error ||
        locationPricesResponse?.error ||
        locationAttributesResponse?.error ||
        geogroupingResponse?.error ||
        publishedBuildingsResponse?.error
      }
      data={buildingsResponse.data}
    >
      {shouldRender() && (
        <>
          <EditToggle
            disabled={!isEditable}
            isEdit={isEdit}
            onChange={({ checked }: CheckboxProps): void => setIsEdit(Boolean(checked))}
          />
          <AvailabilityForm
            product={product}
            availableBuildingIds={availableBuildingIds}
            locationPrices={locationPrices}
            locationAttributes={locationAttributes}
            geogroupings={geogroupingData}
            buildings={publishedBuildingsData}
            isPricingEditable={isPricingEditable && isEdit}
            isAvailabilityEditable={isAvailabilityEditable && isEdit}
            onBuildingsSave={setAvailableBuildingIds}
            onLocationPricesSave={setLocationPrices}
            onLocationAttributesSave={setLocationAttributes}
          />
        </>
      )}
    </LoadingContainer>
  );
};

export { EditPricingTab };
