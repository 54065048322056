export interface IProduct {
  attributes: IProductAttributes;
  createdAt: string;
  currency: string;
  description: string;
  externalVendorUrl: string;
  images: IImage[];
  name: string;
  notes: string;
  oneTime: boolean;
  price: number;
  refundable: boolean;
  saleItemGroup: string;
  status: ProductStatus;
  tncUrls: ITncUrls;
  translations: ITranslations;
  updatedAt: string;
  lastModifiedBy: string;
  lastModifiedByInfo: UserInfo;
  createdBy: string;
  createdByInfo: UserInfo;
  uuid: string;
  waivable: boolean;
  marketGeogroupingIds: string[];
  nameTranslations: { [locale: string]: string };
  descriptionTranslations: { [locale: string]: string };
  [key: string]: AllProductTypes;
}

export type UserInfo = {
  name: string;
  email: string;
} | null;

export type IProductAttributes = Partial<{
  ACCESS_GEOGROUPING_ID: string | null;
  BILLING_ENTITY_ID: string | null;
  CREDIT_ALLOTMENT: string | null; // it's a string for legacy reasons in spaceman
  RESERVATION_DURATION: string | null;
  RESOURCE_ID: string | null;
  RESTRICT_PROMOCODE_KIND: string | null;
  PRINT_BW_ALLOTMENT: number;
  PRINT_COLOR_ALLOTMENT: number;
  WELCOME_EMAIL_TEMPLATE_SLUG: string | null;
  FULFILLMENT_EMAIL: string | null;
  CONSULTATION_EMAIL_TEMPLATE_SLUG: string | null;
  FULFILLMENT_EMAIL_TEMPLATE_SLUG: string | null;
  BOOKING_CANCELLATION_POLICY: string | null;
  AVAILABILITY_CONFIGURATION: string | null;
  PURCHASE_IMMEDIATELY: boolean | null;
  REQUEST_CONSULTATION: boolean | null;
  LIMITED_AVAILABILITY: boolean | null;
  SALES_ITEM_ID: string | null;
  [key: string]: string | null | number | boolean;
}>;

export interface IImage {
  id: number;
  //on updating a creating images, we have the file and delete flag
  imageFile?: string;
  delete?: boolean;
  name?: string;
  //on the getting of images from the product, we have url of the s3 store of the image and
  productUuid?: string;
  url?: string;
  tag?: string; // tag can be: THUMBNAIL, PRIMARY or empty (null)
}

export interface ImagePreview {
  url: string;
  tag?: string;
}

export interface ITncUrls {
  [key: string]: string;
}

export interface ITranslations {
  [key: string]: string;
}

export enum ProductStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  ALL = 'ALL',
}

export enum GroupSlugs {
  ADDONS = 'addons',
}

export type AllProductTypes =
  | IProductAttributes
  | ITranslations
  | IImage[]
  | number
  | string
  | boolean
  | string[]
  | null;

export interface ILocationAttributesRecord {
  id?: number;
  locationUuid: string;
  locationType: LocationAttributesLocationType;
  productUuid?: string;
  // TODO: when expanding Spaceman Location Tags, considing making ecommerceEnabled optional `?`
  // as this interface can support updating many tags.
  attributes: ILocationAttributes;
}

export interface ILocationAttributes {
  ECOMMERCE_ENABLED: boolean | undefined;
}

export interface ILocationAttributesFormValues {
  id?: number;
  locationUuid: string;
  locationType: LocationAttributesLocationType;
  productUuid?: string;
  ecommerceEnabled: boolean | undefined;
}

export enum LocationAttributesLocationType {
  GEOGROUPING = 'GEOGROUPING',
  BUILDING = 'BUILDING',
  MARKET = 'MARKET',
  REGION = 'REGION',
}

export interface ILocationPrice {
  id?: number;
  locationUuid: string;
  locationType: LocationPriceLocationType;
  productUuid?: string;
  price: string;
  currency: string;
}

export enum LocationPriceLocationType {
  GEOGROUPING = 'GEOGROUPING',
  BUILDING = 'BUILDING',
  MARKET = 'MARKET',
  REGION = 'REGION',
}

export interface ILocationTnc {
  id?: number;
  locationUuid: string;
  locationType: LocationTncLocationType;
  productUuid?: string;
  name: string;
  displayName: string;
  displayNameTranslations: { [locale: string]: string };
  url: string;
  locale: string | null;
}

export enum LocationTncLocationType {
  GEOGROUPING = 'GEOGROUPING',
  BUILDING = 'BUILDING',
  MARKET = 'MARKET',
  REGION = 'REGION',
}

export interface IProductGroup {
  uuid: string;
  name: string;
  slug: string | null;
  description?: string | null;
  parentUuid?: string;
  groups: string[];
  products: string[];
}

export interface IListingGroup {
  uuid: string;
  name: string;
  groups: IListingGroup[] | null;
  products: IListingProduct[] | null;
}

export interface IPrice {
  price: string;
  currency: string;
}

export interface IListingProduct {
  uuid: string;
  name: string;
  description: string;
  buildingUuid: string;
  price?: IPrice;
  oneTime: boolean;
  resourceId: string;
  fees?: IListingProduct[];
}

export interface IWorkdaySalesItems {
  id: string;
  name: string;
}

export interface ILocationListingProduct extends IListingProduct {
  available: boolean;
  fees?: ILocationListingProduct[];
}

export interface ILocationListingGroup {
  uuid: string;
  name: string;
  groups: ILocationListingGroup[];
  products: ILocationListingProduct[];
}

export interface IMarketPricing {
  uuid: string;
  parentUuid: string;
  name: string;
  regionName: string;
  addOns: IProduct[];
  marketPricing: ILocationPrice[];
  regionPricing: ILocationPrice[];
}

export interface ICatalog {
  uuid: string;
  archivedAt?: string;
  archivedBy?: string;
  createdAt: string;
  createdBy?: string;
  description?: string;
  name: string;
  operatorUuid: string;
  updatedAt?: string;
  updatedBy?: string;
}

export interface IGeogroupingParentObject {
  id: string;
  name: string;
  type: string;
  is_published: boolean;
  iso?: string;
}

export interface IGeogroupingObject {
  id: string;
  name: string;
  type: string;
  is_published: boolean;
  iso?: string;
  parent: IGeogroupingParentObject;
}

export interface IBuildingObject {
  id: string;
  address: string;
  default_name: string;
  default_locale: string;
  code: string;
  is_published: boolean;
  is_not_physical: boolean;
  marketgeo?: Partial<IGeogroupingObject>;
  countrygeo?: Partial<IGeogroupingObject>;
}

export interface ILocationObject {
  id: string;
  name?: string;
  default_name?: string;
  code?: string;
}

export interface IProductTranslation {
  nameTranslations: Record<string, string>;
  descriptionTranslations: Record<string, string>;
}

export enum ProductAuditEntityType {
  products = 'products',
  available_buildings = 'available_buildings',
}
export interface IAuditEntity<T> {
  id: string;
  entityId: string;
  entityType: ProductAuditEntityType;
  action: AuditActionType;
  user: string;
  timestamp: string;
  changes: T[];
  entityData: Record<string, string>;
}

export interface IProductChanges {
  attr_name: string;
  from: string;
  to: string;
}

export interface ILocationAvailabilityChanges {
  buildingUuid: string;
  action: AuditActionType;
}

export interface ILocationPricesChanges {
  action: AuditActionType;
  currency: string;
  locationType: LocationPriceLocationType;
  locationUuid: string;
  price: string;
}

export interface IProductGroupsChanges {
  action: AuditActionType;
  groupUuid: string;
  groupName: string;
  order: string;
}

export interface IProductFeesChanges {
  action: AuditActionType;
  feesName: string;
}

export interface ILocationTncsChanges {
  action: AuditActionType;
  locationType: LocationPriceLocationType;
  locationUuid: string;
  displayName: string;
  name: string;
  locale: string;
  displayNameTranslations: string;
  url: string;
}

export type IProductAudit = IAuditEntity<IProductChanges>;

export enum AuditActionType {
  CREATED = 'CREATED',
  REMOVED = 'REMOVED',
  UPDATED = 'UPDATED',
}
