import React from 'react';
import { Divider, Container } from '@wework/dieter-ui';

export const NO_CATALOG_SELECTED_ROUTE = 'no-catalog-selected';

export const NoCatalogSelected = (): JSX.Element => (
  <Container size={'massive'}>
    <Divider hidden />
    <h3>Please select the catalog.</h3>
  </Container>
);
