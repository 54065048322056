import { Grid, Header } from '@wework/dieter-ui';
import React from 'react';

interface IPageHeaderProps {
  header: string;
  children?: JSX.Element;
}

export const PageHeader = ({ header, children }: IPageHeaderProps): JSX.Element => {
  return (
    <Grid>
      <Grid.Column width={10}>
        <Header as="h1">{header}</Header>
      </Grid.Column>
      <Grid.Column width={6} verticalAlign="middle" textAlign="right">
        {children}
      </Grid.Column>
    </Grid>
  );
};
