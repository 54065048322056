import * as Yup from 'yup';
import { LocationTncLocationType } from 'types/productCatalogTypes';

export enum LocationTncFormField {
  LOCATION_UUID = 'locationUuid',
  LOCATION_TYPE = 'locationType',
  NAME = 'name',
  DISPLAY_NAME = 'displayName',
  LOCALE = 'locale',
  URL = 'url',
}

export const LocationTncHeaders = [
  { key: LocationTncFormField.NAME, label: 'Name' },
  { key: LocationTncFormField.DISPLAY_NAME, label: 'Display Name' },
  { key: LocationTncFormField.LOCALE, label: 'Locale' },
  { key: LocationTncFormField.URL, label: 'URL' },
];

export const LocationTncFormSchema = Yup.object().shape({
  locationUuid: Yup.string().required('location uuid required'),
  locationType: Yup.string().required('location type required'),
  name: Yup.string().required('name is required'),
  displayName: Yup.string().required('display name is required'),
  locale: Yup.string().required('locale is required'),
  url: Yup.string().required('valid url is required').url(),
});

export const locationTncDefaultValues = {
  [LocationTncFormField.LOCATION_UUID]: '',
  [LocationTncFormField.LOCATION_TYPE]: LocationTncLocationType.GEOGROUPING,
  [LocationTncFormField.NAME]: '',
  [LocationTncFormField.DISPLAY_NAME]: '',
  [LocationTncFormField.LOCALE]: '',
  [LocationTncFormField.URL]: '',
};
