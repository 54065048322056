import React, { useEffect, useState } from 'react';
import { Button, Divider, Header, List, Modal, Popup } from '@wework/dieter-ui';
import { map, compact, keys, Dictionary, uniq, isEqual, concat, flatMapDeep } from 'lodash';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IBuildingObject, IGeogroupingObject } from 'types/productCatalogTypes';
import { assembleDiffMap, assembleLocationsMap, ChangeState } from 'types/mappers';
import {
  assembleDiffNodes,
  changesStateLabelConfig,
  ICheckboxTreeNode,
  ILocationAttributesRecordMap,
  ILocationPriceMap,
} from './availabilityFormUtils';
import { WeCheckboxTree } from '../WeCheckboxTree/WeCheckboxTree';
import { ActionCounter } from './ActionCounter';

interface IAvailabilityConfirmationModalProps {
  geogroupings: IGeogroupingObject[];
  locationAttributes: ILocationAttributesRecordMap;
  changedLocationAttributes: ILocationAttributesRecordMap;
  prices: ILocationPriceMap;
  changedPrices: ILocationPriceMap;
  addedBuildingIds: string[];
  removedBuildingIds: string[];
  buildings: IBuildingObject[];
  onClose: () => void;
  onSave: () => void;
}

export const AvailabilityConfirmationModal = ({
  geogroupings,
  locationAttributes,
  changedLocationAttributes,
  buildings,
  prices,
  changedPrices,
  addedBuildingIds,
  removedBuildingIds,
  onClose,
  onSave,
}: IAvailabilityConfirmationModalProps): JSX.Element => {
  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const [changedNodes, setChangedNodes] = useState<ICheckboxTreeNode[]>([]);

  // generate nodes for WeCheckboxTree
  useEffect(() => {
    const locationWithPriceIds = uniq(concat(keys(changedPrices), keys(prices)));
    const changedLocationPriceIds = compact(
      map(locationWithPriceIds, (id) => !isEqual(changedPrices[id], prices[id]) && id),
    );

    const locationWithAttributesIds = uniq(concat(keys(locationAttributes), keys(changedLocationAttributes)));
    const changedLocationAttributesIds = compact(
      map(
        locationWithAttributesIds,
        (id) => !isEqual(changedLocationAttributes[id]?.attributes, locationAttributes[id]?.attributes) && id,
      ),
    );

    const availabilityChanges: Dictionary<ChangeState.ADDED | ChangeState.REMOVED> = {};
    removedBuildingIds.forEach((buildingId) => (availabilityChanges[buildingId] = ChangeState.REMOVED));
    addedBuildingIds.forEach((buildingId) => (availabilityChanges[buildingId] = ChangeState.ADDED));

    const changedLocationIdsAvailability = concat(addedBuildingIds, removedBuildingIds);
    const changedLocationIds = uniq(
      concat(changedLocationIdsAvailability, changedLocationPriceIds, changedLocationAttributesIds),
    );

    const nodes = assembleDiffNodes(
      assembleLocationsMap(geogroupings, buildings),
      assembleDiffMap(prices, changedPrices),
      assembleDiffMap(locationAttributes, changedLocationAttributes),
      availabilityChanges,
      changedLocationIds,
    );
    setChangedNodes(nodes);
    setExpandedIds([...map(nodes, 'value'), ...map(flatMapDeep(nodes, 'children'), 'value')]);
  }, [prices, changedPrices, locationAttributes, changedLocationAttributes, geogroupings.length, buildings.length]);
  const addedBuildingsN = addedBuildingIds.length;
  const removedBuildingsN = removedBuildingIds.length;

  return (
    <Modal open onClose={onClose} closeIcon size="fullscreen">
      <Modal.Header>Please confirm your changes below</Modal.Header>
      <Modal.Header>
        <ActionCounter changeState={ChangeState.REMOVED} action="Disabling" counter={removedBuildingsN} />
        <ActionCounter changeState={ChangeState.ADDED} action="Enabling" counter={addedBuildingsN} />
        <Divider />
      </Modal.Header>
      <Modal.Content scrolling>
        <List>
          <WeCheckboxTree nodes={changedNodes} disabled={false} expanded={expandedIds} onExpand={setExpandedIds} />
        </List>
      </Modal.Content>
      <Modal.Actions>
        <Popup
          position="top right"
          content={
            <>
              <Header as="h5">Legend</Header>
              <List>
                <List.Item>{changesStateLabelConfig[ChangeState.ADDED].icon} - added item</List.Item>
                <List.Item>{changesStateLabelConfig[ChangeState.REMOVED].icon} - removed item</List.Item>
                <List.Item>{changesStateLabelConfig[ChangeState.EDIT].icon} - edited item</List.Item>
              </List>
            </>
          }
          trigger={<FontAwesomeIcon icon={faQuestionCircle} size="lg" />}
        />
        <Button onClick={onClose}>Cancel</Button>
        <Button data-test-id="save" primary onClick={onSave}>
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
