import { stringify } from 'query-string';
import { productCatalogFetch } from 'networking/productCatalog/productCatalogFetch';
import { IExtendedFetchResult, RequestMethods } from 'networking/fetchConfig';

export const uploadTranslationRawData = (catalogUuid: string): Promise<IExtendedFetchResult<null>> => {
  const query = stringify({ catalog_uuid: catalogUuid });
  return productCatalogFetch<null>(RequestMethods.POST, `/v2/translations_administration/upload?${query}`);
};

export const applyTranslationData = (catalogUuid: string): Promise<IExtendedFetchResult<null>> => {
  const query = stringify({ catalog_uuid: catalogUuid });
  return productCatalogFetch<null>(RequestMethods.POST, `/v2/translations_administration/download?${query}`);
};

export const refreshLocationApiCache = (): Promise<IExtendedFetchResult<null>> =>
  productCatalogFetch(RequestMethods.POST, `/v2/admin/cache/refresh/location`);

export const refreshSalesItemCache = (): Promise<IExtendedFetchResult<null>> =>
  productCatalogFetch(RequestMethods.POST, `/v2/admin/cache/refresh/sales_items`);

export const clearOperatorCache = (): Promise<IExtendedFetchResult<null>> =>
  productCatalogFetch<null>(RequestMethods.DELETE, `/v2/admin/cache/operators`);

export const getAllBlacklistedProductUuids = (): Promise<IExtendedFetchResult<string[]>> =>
  productCatalogFetch<string[]>(RequestMethods.GET, `/v2/admin/product_pricing_blacklist/fetch_all`);

export const refreshAlgoliaCache = (): Promise<IExtendedFetchResult<null>> =>
  productCatalogFetch(RequestMethods.POST, `/v2/admin/cache/refresh/algolia`);
