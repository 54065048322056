import React from 'react';
import { Tag } from '@wework/dieter-ui';
import { ChangeState } from 'types/mappers';
import { ILocationPrice, IPrice } from 'types/productCatalogTypes';
import { formatPriceCurrency } from 'utils/displayHelpers';
import { changesStateLabelConfig } from './availabilityFormUtils';

interface ITncLabelProps {
  locale: string;
  name: string;
  url: string;
  displayName: string;
  displayNameTranslations: string;
  changeState?: ChangeState;
  warnMissingPrice?: boolean;
}

export function TncLabel({
  locale,
  name,
  url,
  displayName,
  displayNameTranslations,
  changeState = ChangeState.NONE,
}: ITncLabelProps): JSX.Element | null {
  const labelConfig = changesStateLabelConfig[changeState];
  const description = `$Name: {name} - ${locale}`;
  return (
    <>
      {labelConfig && <Tag color={labelConfig.color}>{labelConfig.icon}</Tag>}
      <ul className="tncAttrsList">
        <li>
          <span className="attrLabel">Name:</span> {name}
        </li>
        <li>
          <span className="attrLabel">Display Name:</span> {displayName}
        </li>
        <li>
          <span className="attrLabel">Display Name Translation:</span> {displayNameTranslations}
        </li>
        <li>
          <span className="attrLabel">URL:</span> {url}
        </li>
        <li>
          <span className="attrLabel">Locale:</span> {locale}
        </li>
      </ul>
    </>
  );
}
