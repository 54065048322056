import React from 'react';

import { ChangeState, IDiff } from 'types/mappers';
import { ILocationAttributesRecord, ILocationPrice } from 'types/productCatalogTypes';
import { changesStateLabelConfig } from './availabilityFormUtils';
import { EcommerceLabel } from './EcommerceLabel';
import { PriceLabel } from './PriceLabel';

interface ILocationDiffLabelProps {
  title: string;
  availabilityChangeState?: ChangeState.ADDED | ChangeState.REMOVED;
  priceDiff?: IDiff<ILocationPrice>;
  locationAttributesDiff?: IDiff<ILocationAttributesRecord>;
}

export function LocationDiffLabels({
  title,
  availabilityChangeState,
  priceDiff,
  locationAttributesDiff,
}: ILocationDiffLabelProps): JSX.Element {
  return (
    <span className="availability-form--tree-text">
      {availabilityChangeState && <>{changesStateLabelConfig[availabilityChangeState].icon} </>}
      {title}
      {priceDiff?.changeState && priceDiff.changeState !== ChangeState.NONE && (
        <PriceLabel
          locationPrice={priceDiff?.changeState === ChangeState.REMOVED ? priceDiff?.previous : priceDiff?.current}
          changeState={priceDiff?.changeState}
        />
      )}
      {locationAttributesDiff?.changeState && locationAttributesDiff.changeState !== ChangeState.NONE && (
        <EcommerceLabel
          ecommerceFlag={
            locationAttributesDiff?.changeState === ChangeState.REMOVED
              ? locationAttributesDiff?.previous
              : locationAttributesDiff?.current
          }
          showChangeState
        />
      )}
    </span>
  );
}
