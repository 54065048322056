import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { IProductGroup } from 'types/productCatalogTypes';
import { GroupForm } from 'components/groups/GroupForm/GroupForm';
import { useApiHook } from 'hooks/useApiHook';
import { getGroup } from 'networking/productCatalog/groupRequests';
import { LoadingContainer } from 'components/shared/Loading/LoadingContainer';
import { NotFoundPage } from 'pages/NotFoundPage/NotFoundPage';
import { ResponseStatus } from 'networking/fetchConfig';
import { useGlobalAndOperatorPermissions } from 'hooks/useGlobalAndOperatorPermissions';
import { EDIT_GROUPS_PERMISSIONS } from 'utils/auth/authUtils';
import { BaseUrlParamsType } from 'types/router';

type ParamsType = BaseUrlParamsType & {
  uuid: string;
};

const GroupPage = (): JSX.Element => {
  const [group, setGroup] = useState<IProductGroup | undefined>(undefined);
  const navigate = useNavigate();
  const { uuid: groupUuid, catalogUuid } = useParams() as ParamsType;
  const [isPermsLoading, permsError, requestedPerms] = useGlobalAndOperatorPermissions(EDIT_GROUPS_PERMISSIONS);
  const { loading, data, error, status } = useApiHook<IProductGroup>(
    () => getGroup(groupUuid, catalogUuid),
    [groupUuid, catalogUuid],
  );

  useEffect(() => data && setGroup(data), [data?.uuid]);

  const handleSave = (group: IProductGroup): void => setGroup(group);
  const handleDelete = (): void => navigate(`/${catalogUuid}/groups`);

  if (status === ResponseStatus.NOT_FOUND) {
    return <NotFoundPage />;
  }

  return (
    <LoadingContainer loading={loading || isPermsLoading} error={error || permsError}>
      {group && (
        <GroupForm
          group={group}
          catalogUuid={catalogUuid}
          requestedPerms={requestedPerms}
          onSave={handleSave}
          onDelete={handleDelete}
        />
      )}
    </LoadingContainer>
  );
};

export { GroupPage };
