import { SETTINGS } from 'configs/settings';
import { dataFetch, IExtendedFetchResult, RequestMethods } from 'networking/fetchConfig';

const { PRODUCT_SERVICE_URI } = SETTINGS;

export async function productCatalogFetch<T>(
  method: RequestMethods,
  path: RequestInfo,
  body?: unknown,
  options: RequestInit = {},
): Promise<IExtendedFetchResult<T>> {
  return dataFetch<T>(PRODUCT_SERVICE_URI, method, path, body, options);
}
