import identity from 'lodash/identity';
import { useEffect } from 'react';

export function useCopyEffect<Out, In = Out>(
  prop: In,
  stateSetter: React.Dispatch<React.SetStateAction<Out>>,
  transformFunc: (input: In) => Out = identity,
): void {
  return useEffect(() => {
    const newVal = transformFunc(prop);
    stateSetter(newVal);
  }, [prop]);
}
