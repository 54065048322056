import { Node } from 'react-checkbox-tree'; // eslint-disable-line import/named

import compact from 'lodash/compact';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { Tag } from '@wework/dieter-ui';
import {
  IBuildingObject,
  IGeogroupingObject,
  ILocationObject,
  ILocationTnc,
  LocationTncLocationType,
} from 'types/productCatalogTypes';

import { ILocationsMap } from 'types/mappers';
import { locationTitle } from 'utils/locationHelpers';
import { IBulkUpdateLocationTncsPayload } from '../../../networking/productCatalog/locationTncRequests';

export interface ILocationTncMap {
  [locationUuid: string]: ILocationTnc[];
}

export interface ICheckboxTreeNode extends Node {
  label: JSX.Element;
  children?: ICheckboxTreeNode[];
}

// Assembles a tree of CountryGeos > MarketGeos > Buildings
export const assembleNodes = (locations: ILocationsMap, locationTncMap: ILocationTncMap): ICheckboxTreeNode[] => {
  const { geogroupings, buildings } = locations;

  const geosByType = groupBy(geogroupings, 'type');

  const allCountryGeos = orderBy(geosByType.Countrygeo, ['is_published', 'name'], ['desc', 'asc']);
  const allMarketGeos = orderBy(geosByType.Marketgeo, ['is_published', 'name'], ['desc', 'asc']);
  const marketGeosByCountryGeo = groupBy(allMarketGeos, 'parent.id');

  const allBuildings = orderBy(buildings, ['is_published', 'is_not_physical', 'code'], ['desc', 'asc', 'asc']);
  const marketBuildings = allBuildings.filter((value) => value.marketgeo != null);
  const buildingsByMarketGeo = groupBy(marketBuildings, 'marketgeo.id');

  const countryBuildings = allBuildings.filter((value) => value.countrygeo !== null && value.marketgeo == null);
  const buildingsByCountryGeo = groupBy(countryBuildings, 'countrygeo.id');

  const nodes = allCountryGeos.map(countryGeoNode);
  return compact(nodes);

  function countryGeoNode(countryGeo: IGeogroupingObject): ICheckboxTreeNode | null {
    const childMarketGeos = marketGeosByCountryGeo[countryGeo.id] || [];
    const childMarketGeoNodes = childMarketGeos.map(marketGeoNode);

    const childBuildings = buildingsByCountryGeo[countryGeo.id] || [];
    const childBuildingNodes = childBuildings.map(buildingNode);

    const childNodes = childMarketGeoNodes.concat(childBuildingNodes);
    if (isEmpty(childNodes)) {
      return null;
    }
    return locationToNode(countryGeo, compact(childNodes));
  }

  function marketGeoNode(marketGeo: IGeogroupingObject): ICheckboxTreeNode | null {
    const childBuildings = buildingsByMarketGeo[marketGeo.id] || [];
    const childBuildingNodes = childBuildings.map(buildingNode);

    if (isEmpty(childBuildingNodes)) {
      return null;
    }
    return locationToNode(marketGeo, compact(childBuildingNodes));
  }

  function buildingNode(building: IBuildingObject): ICheckboxTreeNode | null {
    return locationToNode(building);
  }

  function locationToNode(location: ILocationObject, children?: ICheckboxTreeNode[]): ICheckboxTreeNode {
    const { id } = location;
    const title = locationTitle(location);
    const tncs = locationTncMap[id];
    return {
      showCheckbox: false,
      value: id,
      title,
      label: labelTnc(title, tncs, id),
      children,
    };
  }
};

// Opens the parent node to show all current TNCs
export const assembleExpandedIds = (locationTncs: ILocationTnc[], locationsMap: ILocationsMap): string[] => {
  return locationTncs.reduce((uuids: string[], tnc: ILocationTnc): string[] => {
    if (tnc.locationType === LocationTncLocationType.BUILDING) {
      const building = locationsMap.buildings[tnc.locationUuid];
      if (building?.countrygeo?.id) uuids.push(building.countrygeo.id);
      if (building?.marketgeo?.id) uuids.push(building.marketgeo.id);
    }
    if (tnc.locationType === LocationTncLocationType.GEOGROUPING) {
      const geo = locationsMap.geogroupings[tnc.locationUuid];
      if (geo?.parent?.id) uuids.push(geo.parent.id);
    }

    return uuids;
  }, []);
};

export function labelTnc(name: string, locationTncs: ILocationTnc[], id: string, warnMissingTnc = false): JSX.Element {
  let tncLabel;
  if (locationTncs && locationTncs.length > 0) {
    const names = locationTncs.map((tnc) => tnc.name);
    tncLabel = <Tag color="green">`{names.join(',')}`</Tag>;
  } else if (warnMissingTnc) {
    tncLabel = <Tag color="red">No TNCs specified</Tag>;
  }
  return (
    <span className="legal-tree-form--tree-text" id={id}>
      {name} {tncLabel}
    </span>
  );
}

export const locationTncsArrayToMap = (tncs: ILocationTnc[]): ILocationTncMap => groupBy(tncs, 'locationUuid');

export const locationTncsMapToArray = (tncMap: ILocationTncMap): ILocationTnc[] => {
  return Object.keys(tncMap).reduce((results: ILocationTnc[], key: string) => {
    return results.concat(Object.values(tncMap[key]));
  }, []);
};

export const bulkUpdateLocationTncsPayload = (
  oldTncs: ILocationTncMap,
  newTncs: ILocationTncMap,
): IBulkUpdateLocationTncsPayload => {
  const createList: ILocationTnc[] = [];
  const updateList: ILocationTnc[] = [];
  const deleteList: Array<ILocationTnc['id']> = [];

  const locations = [...new Set([...Object.keys(oldTncs), ...Object.keys(newTncs)])];
  for (const locationUuid of locations) {
    const oldLocTncs = oldTncs[locationUuid] || [];
    const newLocTncs = newTncs[locationUuid] || [];

    // get existing tncs for update
    // find deletions
    oldLocTncs.forEach((oldTnc) => {
      const newTnc = newLocTncs.find((match) => match.id === oldTnc.id);

      if (newTnc && tncChanged(oldTnc, newTnc)) updateList.push(newTnc);

      if (!newTnc) deleteList.push(oldTnc.id);
    });

    // get new tncs for create
    newLocTncs.forEach((newTnc) => {
      if (newTnc && !newTnc.id) {
        createList.push(newTnc);
      }
    });
  }

  return { createList, updateList, deleteList };
};

const tncChanged = (oldTnc: ILocationTnc, newTnc: ILocationTnc): boolean => {
  // for our purposes, you can't move a tnc from one location/product to another + we don't count translations
  return (
    oldTnc.name != newTnc.name ||
    oldTnc.displayName != newTnc.displayName ||
    oldTnc.locale != newTnc.locale ||
    oldTnc.url != newTnc.url
  );
};
