import { Input, Message } from '@wework/dieter-ui';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import keyBy from 'lodash/keyBy';

import { assembleLocationsMap, IGeogroupingsMap } from 'types/mappers';
import {
  assembleNodes,
  filterNodes,
  ICheckboxTreeNode,
} from 'components/shared/AvailabilityForm/availabilityFormUtils';
import { WeCheckboxTree } from 'components/shared/WeCheckboxTree/WeCheckboxTree';
import { IBuildingObject, IGeogroupingObject } from 'types/productCatalogTypes';
import { BaseUrlParamsType } from 'types/router';

interface IBuildingsTreeProps {
  geogroupings: IGeogroupingObject[];
  buildings: IBuildingObject[];
}

function isBuilding(buildings: IBuildingObject[], id: string): boolean {
  return !!buildings.find((b) => b.id === id);
}

function isMarket(geoGroupingMap: IGeogroupingsMap, id: string): boolean {
  return geoGroupingMap[id]?.type === 'Marketgeo';
}

export const BuildingsTree = ({ geogroupings, buildings }: IBuildingsTreeProps): JSX.Element => {
  const navigate = useNavigate();
  const { catalogUuid } = useParams() as BaseUrlParamsType;

  const [nodes, setNodes] = useState<ICheckboxTreeNode[]>([]);
  const [expandedIds, setExpandedIds] = useState<string[]>([]);

  const [filterQuery, setFilterQuery] = useState<string>('');
  const [filteredNodes, setFilteredNodes] = useState<ICheckboxTreeNode[]>([]);

  // Assemble all tree nodes
  useEffect(() => {
    const locationsMap = assembleLocationsMap(geogroupings, buildings);
    const allNodes = assembleNodes(locationsMap, {}, {}, false);
    setNodes(allNodes);
  }, [geogroupings, buildings]);

  // Set filtered nodes
  useEffect(() => {
    setFilteredNodes(filterNodes(nodes, filterQuery));
  }, [nodes, filterQuery]);

  const geoGroupingIdMap = useMemo(() => keyBy(geogroupings, 'id'), [geogroupings]);

  const onClickHandler = ({ value }: { checked: boolean; value: string }): void => {
    if (isBuilding(buildings, value)) {
      navigate(`/${catalogUuid}/buildings/${value}`);
    }
    if (isMarket(geoGroupingIdMap, value)) {
      navigate(`/${catalogUuid}/markets/${value}`);
    }
  };

  return (
    <>
      <Input
        value={filterQuery}
        onChange={(_event, value): void => setFilterQuery(value.value)}
        placeholder="Type to filter..."
      />
      <div className="availability-form--tree-container">
        <WeCheckboxTree
          nodes={filteredNodes}
          expanded={expandedIds}
          onExpand={setExpandedIds}
          showExpandAll
          onClick={onClickHandler}
        />
      </div>
      {filterQuery.length > 0 && filteredNodes.length === 0 && (
        <Message error>No locations match &quot;{filterQuery}&quot;</Message>
      )}
    </>
  );
};
