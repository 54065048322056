import { IExtendedFetchResult, RequestMethods } from 'networking/fetchConfig';
import { ILocationTnc, IProduct, LocationTncLocationType } from 'types/productCatalogTypes';

import { productCatalogFetch } from './productCatalogFetch';

export function getLocationTncs(product: IProduct): Promise<IExtendedFetchResult<ILocationTnc[]>> {
  return productCatalogFetch<ILocationTnc[]>(RequestMethods.GET, `/v2/products/${product.uuid}/location_tnc`);
}

export function getLocationTncsByLocationTypeAndLocationUuid(
  locationType: LocationTncLocationType,
  locationUuid: string,
): Promise<IExtendedFetchResult<ILocationTnc[]>> {
  return productCatalogFetch<ILocationTnc[]>(
    RequestMethods.GET,
    `/v2/locations/${locationType}/${locationUuid}/location_tncs`,
  );
}

export interface IBulkUpdateLocationTncsPayload {
  createList: ILocationTnc[];
  updateList: ILocationTnc[];
  deleteList: Array<ILocationTnc['id']>;
}

export function bulkUpdateLocationTncs(
  product: IProduct,
  payload: IBulkUpdateLocationTncsPayload,
): Promise<IExtendedFetchResult<ILocationTnc[]>> {
  return productCatalogFetch<ILocationTnc[]>(RequestMethods.PUT, `/v2/products/${product.uuid}/location_tnc`, payload);
}

export const createLocationTnc = (
  productUuid: string,
  tnc: ILocationTnc,
): Promise<IExtendedFetchResult<ILocationTnc>> =>
  productCatalogFetch<ILocationTnc>(RequestMethods.POST, `/v2/products/${productUuid}/location_tnc`, tnc);

export const updateLocationTnc = (
  productUuid: string,
  tnc: ILocationTnc,
): Promise<IExtendedFetchResult<ILocationTnc>> =>
  productCatalogFetch<ILocationTnc>(RequestMethods.PUT, `/v2/products/${productUuid}/location_tnc/${tnc.id}`, tnc);

export const deleteLocationTnc = (productUuid: string, tnc: ILocationTnc): Promise<IExtendedFetchResult<null>> =>
  productCatalogFetch<null>(RequestMethods.DELETE, `/v2/products/${productUuid}/location_tnc/${tnc.id}`);
