import React from 'react';
import dateFnsFormat from 'date-fns/format';

import { UserInfo, AuditActionType } from 'types/productCatalogTypes';

import './AuditTrailInfo.scss';

const UNKNOWN = 'Unknown';

const actionNameMap: Record<AuditActionType, string> = {
  [AuditActionType.CREATED]: 'Created',
  [AuditActionType.UPDATED]: 'Updated',
  [AuditActionType.REMOVED]: 'Removed',
};

const AuditTrailInfo = ({ action, date, userInfo }: { action: AuditActionType; date: string; userInfo: UserInfo }) => (
  <div className="audit">
    <div>
      <span className="audit__label">{`${actionNameMap[action]} at:`}</span>
      <span>{date ? dateFnsFormat(new Date(date), 'MM-dd-yy') : UNKNOWN}</span>
    </div>
    <div>
      <span className="audit__label">by:</span>
      <a href={userInfo?.email ? `mailto: ${userInfo?.email}` : '#'}>{userInfo ? userInfo.name : UNKNOWN}</a>
    </div>
  </div>
);

export default AuditTrailInfo;
