import { stringify } from 'query-string';

import { IExtendedFetchResult, RequestMethods } from 'networking/fetchConfig';
import { IProductGroup } from 'types/productCatalogTypes';
import { IProductGroupForm } from 'components/groups/CreateGroup/CreateGroupFormConfig';
import { productCatalogFetch } from 'networking/productCatalog/productCatalogFetch';

export const getGroups = (catalogUuid: string): Promise<IExtendedFetchResult<IProductGroup[]>> => {
  const query = stringify({ catalog_uuid: catalogUuid });
  return productCatalogFetch<IProductGroup[]>(RequestMethods.GET, `/v2/groups?${query}`);
};

export const getGroup = (groupUuid: string, catalogUuid: string): Promise<IExtendedFetchResult<IProductGroup>> => {
  const query = stringify({ catalog_uuid: catalogUuid });
  return productCatalogFetch<IProductGroup>(RequestMethods.GET, `/v2/groups/${groupUuid}?${query}`);
};

export const updateGroup = (groupUuid: string, group: IProductGroup): Promise<IExtendedFetchResult<IProductGroup>> =>
  productCatalogFetch<IProductGroup>(RequestMethods.PUT, `/v2/groups/${groupUuid}`, group);

type DeleteGroupParams = {
  cascadeToChildGroups?: boolean;
};

export const deleteGroup = (groupUuid: string, params: DeleteGroupParams = {}): Promise<IExtendedFetchResult<null>> => {
  const query = stringify(params);
  return productCatalogFetch<null>(RequestMethods.DELETE, `/v2/groups/${groupUuid}?${query}`);
};

export const appendGroupProducts = (
  groupUuid: string,
  productUuids: string[],
  catalogUuid: string,
): Promise<IExtendedFetchResult<null>> => {
  const query = stringify({ catalog_uuid: catalogUuid });
  return productCatalogFetch<null>(RequestMethods.POST, `/v2/groups/${groupUuid}/products?${query}`, productUuids);
};

export const deleteGroupProducts = (
  groupUuid: string,
  productUuids: string[],
  catalogUuid: string,
): Promise<IExtendedFetchResult<null>> => {
  const query = stringify({ catalog_uuid: catalogUuid });
  return productCatalogFetch<null>(RequestMethods.DELETE, `/v2/groups/${groupUuid}/products?${query}`, productUuids);
};

export const createGroup = (group: IProductGroupForm): Promise<IExtendedFetchResult<IProductGroup>> =>
  productCatalogFetch<IProductGroup>(RequestMethods.POST, '/v2/groups/', group);
