import { getAuthClient } from 'networking/auth/client';
import { parseJSON } from 'utils/parserHelpers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FetchFunction<A extends any[], T> = (...args: A) => Promise<IFetchResult<T>>;

export enum ResponseStatus {
  OK = 200,
  NOT_FOUND = 404,
  UNKNOWN = '',
}

export interface IFieldError {
  field: string;
  message: string;
}

export interface IFetchError {
  timestamp: string;
  status: number;
  error: string;
  message: string;
  path: string;
  fieldErrors?: IFieldError[];
}

export interface IFetchResult<T> {
  status: ResponseStatus;
  data: T | undefined;
  error?: string;
}

export interface IExtendedFetchResult<T> extends IFetchResult<T> {
  errorData?: IFetchError;
}

export enum RequestMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export async function dataFetch<T>(
  serviceUri: string,
  method: RequestMethods,
  path: RequestInfo,
  body?: unknown,
  options: RequestInit = {},
): Promise<IExtendedFetchResult<T>> {
  try {
    const authClient = getAuthClient();
    const accessToken = await authClient.getAccessTokenSilently();
    const response = await fetch(serviceUri + path, {
      method,
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      ...options,
    });

    let data;
    let error;
    let errorData;
    if (response.ok) {
      data = await parseJSON<T>(response);
    } else {
      errorData = await parseJSON<IFetchError>(response);
      if (errorData?.error && errorData?.message) {
        error = `${errorData?.error}: ${errorData?.message}`;
      } else if (errorData?.error || errorData?.message) {
        error = errorData?.error || errorData?.message;
      } else {
        error = 'Something went wrong';
      }
    }

    return { data, error, errorData, status: response.status };
  } catch (error) {
    return Promise.resolve({ error } as IExtendedFetchResult<T>);
  }
}

export interface IEmployee {
  uuid: string;
  full_name: string;
  email: string;
}

const UNKNOWN = 'unknown';

export const isKnownUserUuid = (uuid: string) => uuid && uuid.toLowerCase() !== UNKNOWN;
