import { SETTINGS } from 'configs/settings';
import { dataFetch, IExtendedFetchResult, RequestMethods } from 'networking/fetchConfig';

const { OPERATOR_SERVICE_URI } = SETTINGS;

export async function operatorServiceFetch<T>(
  method: RequestMethods,
  path: RequestInfo,
  body?: unknown,
  options: RequestInit = {},
): Promise<IExtendedFetchResult<T>> {
  return dataFetch<T>(OPERATOR_SERVICE_URI, method, path, body, options);
}
