import React from 'react';
import { Form as FormikForm, Formik, FormikHelpers as FormikActions } from 'formik';
import { Button, Form, Header, Segment } from '@wework/dieter-ui';

import { handleChange } from 'components/shared/Form/utils';
import { createFee, IUpdateFeeRequest, updateFee } from 'networking/spaceman/feeRequests';
import { SpacemanFee } from 'types/spacemanTypes';
import { ProductFormSelectInput, ProductFormTextInput } from 'components/shared/ProductForm/ProductFormInputs';
import {
  CreateFeeFormFields,
  createFeeFormInputConfig,
  feeKindOptions,
  IFeeFormValues,
  schema,
} from 'components/buildings/fees/FeeFormConfig';
import { useFlashMessageContext } from 'contexts/FlashMessageContext';
import {
  getCreateRequestParams,
  getEmptyFormValues,
  getUpdateRequestParams,
  getValuesFromFee,
} from 'components/buildings/fees/FeeFormHelpers';

interface Props {
  fee?: SpacemanFee;
  locationUuid?: string;
  onClose: () => void;
  onSuccess: () => void;
}

export const FeeForm = ({ fee, locationUuid, onClose, onSuccess }: Props): JSX.Element => {
  const { flashError, flashSuccess } = useFlashMessageContext();
  const handleSubmit = async (values: IFeeFormValues, actions: FormikActions<IFeeFormValues>): Promise<void> => {
    actions.setSubmitting(true);

    const castValues = schema.cast(values) as IUpdateFeeRequest;

    let promise, verb;
    if (fee) {
      promise = updateFee(fee.id, getUpdateRequestParams(castValues));
      verb = 'updated';
    } else if (locationUuid) {
      promise = createFee(getCreateRequestParams(castValues, locationUuid));
      verb = 'created';
    }

    const response = await promise;

    if (response?.data?.result.error) {
      const errorMessage = `An error occurred: ${response.data.result.error}`;
      actions.setErrors({ base: errorMessage });
      flashError(errorMessage);
      actions.setSubmitting(false);
    } else {
      actions.setSubmitting(false);
      flashSuccess(`Successfully ${verb} fee ${values.name}`);
      onSuccess();
      onClose();
    }
  };

  const initialFormValues = fee ? getValuesFromFee(fee) : getEmptyFormValues();

  return (
    <Formik initialValues={initialFormValues} validationSchema={schema} onSubmit={handleSubmit}>
      {({ handleSubmit: formikHandleSubmit, isSubmitting, setFieldValue, setFieldTouched, errors }): JSX.Element => (
        <Form as={FormikForm} onSubmit={formikHandleSubmit}>
          <Segment>
            <Header as="h3">Fee Details</Header>
            <ProductFormTextInput
              handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
              {...createFeeFormInputConfig[CreateFeeFormFields.NAME]}
            />
            <ProductFormSelectInput
              options={feeKindOptions}
              handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
              {...createFeeFormInputConfig[CreateFeeFormFields.KIND]}
            />
            <ProductFormTextInput
              handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
              {...createFeeFormInputConfig[CreateFeeFormFields.RATE]}
            />
          </Segment>
          <p style={{ color: 'red' }}>{errors['base']}</p>
          <Button primary type="submit" disabled={isSubmitting}>
            Save
          </Button>
          <Button onClick={onClose} data-test-id="fee-form__cancel">
            Cancel
          </Button>
        </Form>
      )}
    </Formik>
  );
};
