import { useState, useEffect } from 'react';
import compact from 'lodash/compact';

type UseCachedCollectionResult<T> = [T[], boolean];

export function useCachedCollection<T extends { uuid: string }>(
  resolver: (id: string) => Promise<T | undefined>,
  ids: string[],
): UseCachedCollectionResult<T> {
  const [loading, setLoading] = useState(false);
  const [collection, setCollectionState] = useState<T[]>([]);

  useEffect(() => {
    let canceled = false;

    const load = async (): Promise<void> => {
      setLoading(true);
      const entities = await fetchCollection(ids);
      if (canceled) return;

      setCollectionState(entities);
      setLoading(false);
    };
    load();

    return (): void => {
      canceled = true;
    };
  }, [ids.join('|')]);

  async function fetchCollection(ids: string[]): Promise<T[]> {
    const maybeEntities = await Promise.all(ids.map(getEntity));
    return compact(maybeEntities);
  }

  async function getEntity(id: string): Promise<T | undefined> {
    return collection.find((g) => g.uuid == id) || resolver(id);
  }

  return [collection, loading];
}
