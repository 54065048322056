import { Button, Table } from '@wework/dieter-ui';
import React from 'react';
import { useParams, Link } from 'react-router-dom';

import { IProduct } from 'types/productCatalogTypes';

interface IFeesListProps {
  fees: IProduct[];
  isEditable?: boolean;
  onDelete: (feeUuid: string) => Promise<void>;
}

export function FeesList({ fees, isEditable, onDelete }: IFeesListProps): JSX.Element {
  const { catalog_uuid: catalogUuid } = useParams();
  if (fees.length === 0) {
    return <span>This product has no fees.</span>;
  } else {
    return (
      <Table striped verticalAlign="middle" size="large">
        <Table.Body>
          {fees.map((fee) => (
            <Table.Row key={fee.uuid}>
              <Table.Cell verticalAlign="middle">
                <Link to={`${catalogUuid}/products/${fee.uuid}/edit/pricing`}>{fee.name}</Link>
              </Table.Cell>
              <Table.Cell verticalAlign="middle">
                <Button
                  color="red"
                  icon="x"
                  floated="right"
                  disabled={!isEditable}
                  onClick={(): Promise<void> => onDelete(fee.uuid)}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}
