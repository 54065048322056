import LanguageCode from 'iso-639-1-zh';
import { query } from 'what-country';

const REG = /^([a-z]{2})-([A-Z]{2})$/;

export interface LocaleObject {
  code: string;
  name: string;
  nativeName: string;
  [propName: string]: string;
}

export default class LocaleCode {
  /* language iso-639-1 */
  static getLanguageCode(code: string): string {
    const match = code.match(REG);
    if (!match || match.length < 1) return '';
    return match[1];
  }

  static getLanguageName(code: string): string {
    const languageCode = LocaleCode.getLanguageCode(code);
    return LanguageCode.getName(languageCode);
  }

  static getLanguageNativeName(code: string): string {
    const languageCode = LocaleCode.getLanguageCode(code);
    return LanguageCode.getNativeName(languageCode);
  }

  static getLanguageZhName(code: string): string {
    const languageCode = LocaleCode.getLanguageCode(code);
    return LanguageCode.getZhName(languageCode);
  }

  static validateLanguageCode(code: string): boolean {
    const languageCode = LocaleCode.getLanguageCode(code);
    return LanguageCode.validate(languageCode);
  }

  static getLanguages(codes: string[]): LocaleObject[] {
    const list = [];
    for (let i = 0; i < codes.length; i++) {
      list.push({
        code: codes[i],
        name: LocaleCode.getLanguageName(codes[i]),
        nativeName: LocaleCode.getLanguageNativeName(codes[i]),
        zhName: LocaleCode.getLanguageZhName(codes[i]),
      });
    }
    return list;
  }

  /* country iso-3166-1-alpha-2 */
  static getCountryCode(code: string): string {
    const match = code.match(REG);
    if (!match || match.length < 2) return '';
    return match[2];
  }

  static getCountryName(code: string): string {
    const countryCode = LocaleCode.getCountryCode(code);
    // return CountryCode.getCountry(countryCode);
    return query(countryCode)[0]?.name || '';
  }

  static getCountryFlag(code: string): string {
    const countryCode = LocaleCode.getCountryCode(code);
    return query(countryCode)[0]?.flag_emoji || '';
  }
}
