import React from 'react';
import { Tag } from '@wework/dieter-ui';

import { ChangeState } from 'types/mappers';
import { ILocationAttributesRecord } from 'types/productCatalogTypes';
import { changesStateLabelConfig } from './availabilityFormUtils';

interface IEcommerceLabelProps {
  ecommerceFlag?: ILocationAttributesRecord;
  showChangeState?: boolean;
}

export function EcommerceLabel({ ecommerceFlag, showChangeState = false }: IEcommerceLabelProps): JSX.Element | null {
  if (ecommerceFlag?.attributes.ECOMMERCE_ENABLED) {
    return (
      <Tag color={changesStateLabelConfig[ChangeState.ADDED].color}>
        {showChangeState && <>{changesStateLabelConfig[ChangeState.ADDED].icon} </>}
        Ecommerce Enabled
      </Tag>
    );
  } else if (ecommerceFlag?.attributes.ECOMMERCE_ENABLED === false) {
    return (
      <Tag color={changesStateLabelConfig[ChangeState.REMOVED].color}>
        {showChangeState && <>{changesStateLabelConfig[ChangeState.REMOVED].icon} </>}
        Ecommerce Disabled
      </Tag>
    );
  }
  return null;
}
