import { stringify } from 'query-string';

import { IExtendedFetchResult, RequestMethods } from 'networking/fetchConfig';
import { IListingGroup } from 'types/productCatalogTypes';
import { productCatalogFetch } from './productCatalogFetch';

export function getListingGroup(groupUuidOrSlug: string): Promise<IExtendedFetchResult<IListingGroup>> {
  return productCatalogFetch<IListingGroup>(RequestMethods.GET, `/v2/listings/groups/${groupUuidOrSlug}`);
}

export type GetListingsForGroupInBuildingParams = {
  lang?: string;
  includeUnpriced?: boolean;
};

export function getListingsForGroupInBuilding(
  groupUuidOrSlug: string,
  buildingUuid: string,
  catalogUuid: string,
  params: GetListingsForGroupInBuildingParams = {},
): Promise<IExtendedFetchResult<IListingGroup>> {
  const query = stringify({ ...params, catalog_uuid: catalogUuid });
  return productCatalogFetch<IListingGroup>(
    RequestMethods.GET,
    `/v2/listings/buildings/${buildingUuid}/groups/${groupUuidOrSlug}?${query}`,
  );
}
