import React, { useEffect, useState } from 'react';
import { Link, Outlet, Route, Routes } from 'react-router-dom';
import { Button, Divider, Grid, Header, Container } from '@wework/dieter-ui';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons/faPowerOff';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { faFlask } from '@fortawesome/free-solid-svg-icons/faFlask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRequestedPermissions } from '@wework/we-auth-react';
import { useAuth0 } from '@auth0/auth0-react';

import { useCatalog } from 'contexts/CatalogContext';
import { useFlashMessageContext } from 'contexts/FlashMessageContext';
import { LOGIN_ROUTE } from 'pages/LoginPage/LoginPage';
import { LOGOUT_ROUTE } from 'pages/LogoutPage/LogoutPage';
import { ADMIN_PERMISSIONS } from 'utils/auth/authUtils';
import CatalogSelector from 'components/shared/CatalogSelector/CatalogSelector';
import Loading from 'components/shared/Loading';

import 'components/shared/GlobalNavBar/GlobalNavBar.scss';

const ALCHEMIST_CONNECT_PAGE = 'https://connect.weworkers.io/display/WWKB/Alchemist+-+Product+Catalog+Overview';

const GlobalNavBar = (): JSX.Element => {
  const { isAuthenticated, isLoading } = useAuth0();
  const [isAuthNavBarVisible, setIsAuthNavBarVisible] = useState(false);
  useEffect(() => {
    !isLoading && isAuthenticated && setIsAuthNavBarVisible(true);
  }, [isAuthenticated, isLoading]);

  return (
    <>
      <Container size="massive">
        <Grid columns={2} verticalAlign="middle" padded>
          <Grid.Row>
            <Grid.Column className="narrow" textAlign="left" width={5}>
              <Header as="h1">
                <FontAwesomeIcon icon={faFlask} style={{ marginRight: '0.75rem' }} />
                Alchemist
              </Header>
            </Grid.Column>
            <Grid.Column className="narrow" textAlign="right" width={11}>
              <Routes>
                <Route path={LOGIN_ROUTE} element={<UnauthenticatedNavBarLinks />} />
                <Route path={LOGOUT_ROUTE} element={<UnauthenticatedNavBarLinks />} />
                <Route path="*" element={isAuthNavBarVisible && <AuthenticatedNavBarLinks />} />
              </Routes>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />
      </Container>
      {!isLoading && <Outlet />}
    </>
  );
};

const AuthenticatedNavBarLinks = (): JSX.Element => {
  const [isLoading, error, requestedPerms] = useRequestedPermissions(ADMIN_PERMISSIONS);
  const { flashError } = useFlashMessageContext();
  const { selectedCatalog } = useCatalog();
  const catalogUuid = selectedCatalog?.uuid;

  useEffect(() => {
    if (error?.message) {
      flashError(error.message);
    }
  }, [error]);

  return (
    <div className="nav-bar-links">
      {isLoading ? (
        <div className="nav-bar-loader">
          <Loading />
        </div>
      ) : (
        <>
          <CatalogSelector />
          {requestedPerms.alchemist_administration && (
            <Button as={Link} to={`/${catalogUuid}/admin`} disabled={!selectedCatalog}>
              Admin
            </Button>
          )}
          <Button as={Link} to={`/${catalogUuid}/products`} disabled={!selectedCatalog}>
            Products
          </Button>
          <Button as={Link} to={`/${catalogUuid}/groups`} disabled={!selectedCatalog}>
            Groups
          </Button>
          <Button as={Link} to={`/${catalogUuid}/buildings`} disabled={!selectedCatalog}>
            Buildings
          </Button>
        </>
      )}
      <Button className="icon-bttn" href={ALCHEMIST_CONNECT_PAGE} target="_blank">
        <FontAwesomeIcon icon={faQuestionCircle} />
      </Button>
      <Button as={Link} to={`/${LOGOUT_ROUTE}`} className="icon-bttn">
        <FontAwesomeIcon icon={faPowerOff} />
      </Button>
    </div>
  );
};

const UnauthenticatedNavBarLinks = (): JSX.Element => (
  <Button as={Link} to={`/${LOGIN_ROUTE}`}>
    Login
  </Button>
);

export default GlobalNavBar;
