import { ILocationObject } from 'types/productCatalogTypes';

export function locationTitle({ code, name, default_name }: ILocationObject, templateForEmptyState = ''): string {
  if (name) {
    return name;
  } else if (code && default_name) {
    return `${code} - ${default_name}`;
  } else if (default_name) {
    return default_name;
  } else if (code) {
    return code;
  } else {
    return templateForEmptyState;
  }
}
