import React from 'react';
import { Tag } from '@wework/dieter-ui';
import { ChangeState } from 'types/mappers';
import { ILocationPrice, IPrice } from 'types/productCatalogTypes';
import { formatPriceCurrency } from 'utils/displayHelpers';
import { changesStateLabelConfig } from './availabilityFormUtils';

interface IPriceLabelProps {
  locationPrice?: ILocationPrice | IPrice;
  changeState?: ChangeState;
  warnMissingPrice?: boolean;
}

export function PriceLabel({
  locationPrice,
  changeState = ChangeState.NONE,
  warnMissingPrice = false,
}: IPriceLabelProps): JSX.Element | null {
  if (locationPrice) {
    const formatedPrice = formatPriceCurrency(locationPrice.price, locationPrice.currency);
    const labelConfig = changesStateLabelConfig[changeState];

    return labelConfig ? (
      <Tag color={labelConfig.color}>
        {labelConfig.icon} {formatedPrice}
      </Tag>
    ) : (
      <Tag color="green">{formatedPrice}</Tag>
    );
  } else if (warnMissingPrice) {
    return <Tag color="red">Not for sale</Tag>;
  }
  return null;
}
