import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Dropdown,
  FormField,
  Input,
  TextArea,
  CheckboxProps,
  DropdownItemProps,
  DropdownProps,
  Container,
  Divider,
} from '@wework/dieter-ui';
import { Field, FieldProps } from 'formik';
import { useDropzone } from 'react-dropzone';
import { CarouselProvider, Slider, Slide, Image, DotGroup, CarouselContext } from 'pure-react-carousel';
import classNames from 'classnames';
import 'pure-react-carousel/dist/react-carousel.es.css';

import {
  IGenericFormInputProps,
  IDropZoneImageFormInputProps,
  ProductFormImageCarouselInputProps,
} from 'components/shared/Form/FormConfig';
import { fieldError } from 'components/shared/Form/utils';
import { imageTagsConfig, ProductFormValue } from 'components/shared/ProductForm/productFormConfig';

import 'components/shared/ProductForm/ProductFormInputs/productFormInputs.scss';

const ProductFormTextInput = ({
  handleOnChange,
  inputType,
  labelText,
  name,
  placeholder,
  disabled,
  readOnly,
}: IGenericFormInputProps<ProductFormValue>): JSX.Element => (
  <Field name={name} id={name}>
    {({ form, field }: FieldProps): JSX.Element => (
      <FormField
        {...field}
        value={field.value ?? ''}
        outline
        className="product-form__field"
        control={Input}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange={({ currentTarget }: ChangeEvent<HTMLInputElement>): void => {
          handleOnChange(currentTarget.name, currentTarget.value);
        }}
        error={fieldError(form.touched, form.errors, name)}
        label={labelText}
        type={inputType}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
      />
    )}
  </Field>
);

const ProductFormTextareaInput = ({
  inputType,
  labelText,
  name,
  placeholder,
  handleOnChange,
  disabled,
  readOnly,
}: IGenericFormInputProps<ProductFormValue>): JSX.Element => (
  <Field name={name} id={name}>
    {({ form, field }: FieldProps): JSX.Element => (
      <FormField
        {...field}
        className="product-form__field"
        control={TextArea}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange={({ currentTarget }: ChangeEvent<HTMLInputElement>): void => {
          handleOnChange(currentTarget.name, currentTarget.value);
        }}
        error={fieldError(form.touched, form.errors, name)}
        label={labelText}
        type={inputType}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
      />
    )}
  </Field>
);

const ProductFormCheckboxInput = ({
  labelText,
  name,
  handleOnChange,
  disabled,
  readOnly,
}: IGenericFormInputProps<ProductFormValue>): JSX.Element => (
  <FormField>
    <Field name={name} id={name}>
      {({ form, field }: FieldProps): JSX.Element => (
        <Checkbox
          className="product-form__field"
          error={fieldError(form.touched, form.errors, name)}
          name={name}
          id={name}
          label={labelText}
          onChange={(_, { checked = false }: CheckboxProps): void => handleOnChange(name, checked)}
          checked={Boolean(field.value)}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
    </Field>
  </FormField>
);

const ProductFormImageCarousel = ({
  imagePreviews,
  onImageRemove,
  onImageTagChange,
}: ProductFormImageCarouselInputProps): JSX.Element => {
  const renderDivider = (): JSX.Element => {
    return imagePreviews?.length != 0 ? <Divider /> : <></>;
  };

  return (
    <CarouselProvider naturalSlideWidth={1} naturalSlideHeight={1} totalSlides={imagePreviews.length}>
      {renderDivider()}
      <Slider>
        {imagePreviews.map((image, i) => {
          return (
            <Slide index={i} key={i}>
              <Image src={image.url} hasMasterSpinner={false} />
            </Slide>
          );
        })}
      </Slider>
      {renderDivider()}
      <ProductFormImageCarouselControls
        imagePreviews={imagePreviews}
        onImageRemove={onImageRemove}
        onImageTagChange={onImageTagChange}
      />
    </CarouselProvider>
  );
};

const ProductFormImageCarouselControls = ({
  imagePreviews,
  onImageRemove,
  onImageTagChange,
}: ProductFormImageCarouselInputProps): JSX.Element => {
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide);

  const onChange = (): void => setCurrentSlide(carouselContext.state.currentSlide);

  useEffect(() => {
    carouselContext.subscribe(onChange);
    return (): void => carouselContext.unsubscribe(onChange);
  }, [carouselContext, onChange]);

  return (
    <Container textAlign="center">
      <DotGroup dotNumbers />
      <div>
        <>
          <label>Image tag:</label>
          <br />
        </>
        <Dropdown
          options={imageTagsConfig}
          placeholder={'None'}
          clearable={true}
          onChange={(_, { value }: DropdownProps): void => {
            const newValue = value ? (value as string) : undefined;
            onImageTagChange(currentSlide, newValue);
          }}
          value={imagePreviews[currentSlide]?.tag ?? ''}
          disabled={imagePreviews.length === 0}
        />
      </div>
      <Button onClick={(): void => onImageRemove(currentSlide)} type="button" disabled={imagePreviews.length === 0}>
        Delete
      </Button>
    </Container>
  );
};

const ProductFormDropZoneImageInput = ({
  isCreate,
  labelText,
  createPlaceholder,
  updatePlaceholder,
  readOnly,
  onDragCreatePlaceholder,
  onDragUpdatePlaceholder,
  onDropAccepted,
  onDropRejected,
}: IDropZoneImageFormInputProps): JSX.Element => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled: readOnly,
    onDropAccepted: onDropAccepted,
    onDropRejected: onDropRejected,
    accept: { 'image/*': ['.png', '.jpg', '.jpeg'] },
  });

  return (
    <div>
      {labelText && (
        <>
          <label>{labelText}</label>
          <br />
        </>
      )}
      <div className="product-form__dropzone" {...getRootProps()}>
        <label className="product-form__dropzonetext">
          {isCreate
            ? isDragActive
              ? onDragCreatePlaceholder
              : createPlaceholder
            : isDragActive
            ? onDragUpdatePlaceholder
            : updatePlaceholder}
        </label>
        <input {...getInputProps()} />
      </div>
    </div>
  );
};

interface IProductFormSelectInputProps extends IGenericFormInputProps<ProductFormValue> {
  options: DropdownItemProps[];
  multiple?: boolean;
}

const ProductFormSelectInput = ({
  options,
  name,
  placeholder,
  handleOnChange,
  labelText,
  disabled,
  readOnly,
  multiple,
}: IProductFormSelectInputProps): JSX.Element => {
  const labelClass = classNames('product-form__label', { disabled: disabled });
  return (
    <Field name={name} id={name}>
      {({ form, field }: FieldProps): JSX.Element => (
        <div className="product-form__dropdown">
          {labelText && (
            <>
              <label className={labelClass}>{labelText}</label>
              <br />
            </>
          )}
          <Dropdown
            {...field}
            fluid
            search
            selection
            clearable
            label={labelText}
            icon="dropdown"
            openOnFocus
            closeOnBlur
            closeOnEscape
            placeholder={placeholder}
            options={options}
            onChange={(_, { value = '' }: DropdownProps): void => {
              handleOnChange(name, value);
            }}
            error={!!fieldError(form.touched, form.errors, name)}
            // sadly Dropdown doesn't support readOnly properly, so we have to disable it
            disabled={disabled || readOnly}
            readOnly={readOnly}
            multiple={multiple}
          />
        </div>
      )}
    </Field>
  );
};

export {
  ProductFormTextInput,
  ProductFormTextareaInput,
  ProductFormCheckboxInput,
  ProductFormSelectInput,
  ProductFormDropZoneImageInput,
  ProductFormImageCarousel,
};
