import React, { useMemo, useState } from 'react';
import { Form } from '@wework/dieter-ui';
import { Form as FormikForm, Formik } from 'formik';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { cloneDeep } from 'lodash';

import { getTaxSourceLabel, HeaderCell } from 'components/buildings/fees/TaxTableRows';
import { ReservableType, SpacemanFee, SpacemanResource } from 'types/spacemanTypes';
import { IProduct } from 'types/productCatalogTypes';
import { getTaxableItems, ITaxableItem, TaxApplicableType } from 'utils/taxHelpers';
import { handleChange } from 'components/shared/Form/utils';
import { updateFee } from 'networking/spaceman/feeRequests';
import {
  getInitialState,
  getUpdateRequestParams,
  ITaxFormData,
  ITaxFormDataItem,
} from 'components/buildings/fees/TaxFormHelpers';
import { useFlashMessageContext } from 'contexts/FlashMessageContext';
import { ProductFormValue } from 'components/shared/ProductForm/productFormConfig';
import { FormActionDrawer } from 'components/shared/FormActionDrawer/FormActionDrawer';
import { ProductFormCheckboxInput } from 'components/shared/ProductForm/ProductFormInputs';
import { FormInputType } from 'components/shared/Form/FormConfig';

import 'components/buildings/fees/TaxTable.scss';

const DEFAULT_COLUMN_WIDTH = 120;
const RESOURCE_COLUMN_WIDTH = 390;
const HEADER_HEIGHT = 100;
const ROW_HEIGHT = 54;

interface ITaxTableRow {
  type: TaxApplicableType;
  source: string;
  name: string;
  id: string;
  data?: ITaxableItem;
}

interface ITaxTableProps {
  fees: SpacemanFee[];
  resources: SpacemanResource[];
  products: IProduct[];
  disabled?: boolean;
  refresh: () => void;
}

export const TaxTable = ({ fees, resources, products, disabled, refresh }: ITaxTableProps): JSX.Element => {
  const [formChangesCounter, setFromChangesCounter] = useState(0);
  const { flashError, flashSuccess } = useFlashMessageContext();
  const taxItems = useMemo(() => getTaxableItems(fees, resources, products), [fees, resources, products]);
  const initFormState = useMemo(() => getInitialState(fees), [fees]);
  const rows: ITaxTableRow[] = useMemo(() => {
    return [
      ...Object.entries(ReservableType).map(([reservableTypeName, reservableTypeValue]: string[]) => ({
        type: TaxApplicableType.RESERVABLE,
        source: getTaxSourceLabel(TaxApplicableType.RESERVABLE),
        name: reservableTypeName,
        id: reservableTypeValue,
      })),
      ...taxItems.map((taxItem) => ({
        type: taxItem.type,
        source: getTaxSourceLabel(taxItem.type),
        name: taxItem.name,
        id: taxItem.uuid,
        data: taxItem,
      })),
    ];
  }, [ReservableType, taxItems]);

  const handleCountChanges = (initVal: ProductFormValue, newVal: ProductFormValue) => {
    setFromChangesCounter((prevCoutner) => (Boolean(initVal) !== newVal ? ++prevCoutner : --prevCoutner));
  };

  const handleSubmit = async (data: ITaxFormData): Promise<void> => {
    const results = await Promise.all(
      fees.map((fee) => updateFee(fee.id, getUpdateRequestParams(fee, data, products))),
    );
    const anyErrors = results.some((result) => result.error);

    results.forEach((feeResult, index) => {
      const error = feeResult.error;
      if (error) {
        flashError(`An error has occurred while updating tax ${fees[index].name}: ${error}`);
      } else {
        flashSuccess(`Successfully updated fee ${fees[index].name}`);
      }
    });

    if (!anyErrors) {
      refresh();
    }
  };

  const selectAll = (
    fee: SpacemanFee,
    _setFieldValue: (field: string, value: boolean) => void,
    setValues: (values: React.SetStateAction<ITaxFormData>, shouldValidate?: boolean | undefined) => void,
    values: ITaxFormData,
  ): void => {
    const newValue = true;

    const newFormValues = cloneDeep(values);
    taxItems.forEach((item) => {
      if (Boolean(newFormValues[fee.id][item.type][item.uuid]) !== newValue) {
        newFormValues[fee.id][item.type][item.uuid] = newValue;
        handleCountChanges(initFormState[fee.id][item.type][item.uuid], newValue);
      }
    });
    Object.values(ReservableType).forEach((reservableTypeValue) => {
      if (Boolean(newFormValues[fee.id].reservable[reservableTypeValue]) !== newValue) {
        newFormValues[fee.id].reservable[reservableTypeValue] = newValue;
        handleCountChanges(initFormState[fee.id].reservable[reservableTypeValue], newValue);
      }
    });

    setValues(newFormValues);
  };

  const handleFeeChange = (name: string, value: ProductFormValue): void => {
    const [feeUuid, type, uuid] = name.split('.');
    const initVal = initFormState[Number(feeUuid)][type as keyof ITaxFormDataItem][uuid];
    handleCountChanges(initVal, value);
  };
  // ignore the 'dirty' Formik field because it defines by strict comparison
  // so in the case when the initial form value is 'undefined'
  // and the current value is 'false' the 'dirty' field will be 'true' (which is not correct)
  const isDirty = formChangesCounter > 0;
  const tableWidth = RESOURCE_COLUMN_WIDTH + (fees.length + 1) * DEFAULT_COLUMN_WIDTH;

  return (
    <Formik initialValues={initFormState} onSubmit={handleSubmit}>
      {({
        handleSubmit: formikHandleSubmit,
        setFieldValue,
        setValues,
        values,
        setFieldTouched,
        isSubmitting,
        submitForm,
      }) => (
        <Form as={FormikForm} onSubmit={formikHandleSubmit}>
          <div className="tax-table-wrapper">
            <AutoSizer>
              {({ height }) => {
                return (
                  <Table
                    height={height}
                    width={tableWidth}
                    rowHeight={ROW_HEIGHT}
                    headerHeight={HEADER_HEIGHT}
                    rowCount={rows.length}
                    rowGetter={({ index }) => rows[index]}
                  >
                    <Column key="source" label="Source" dataKey="source" width={DEFAULT_COLUMN_WIDTH} />
                    <Column
                      key="resource"
                      label="Resource"
                      dataKey="name"
                      width={RESOURCE_COLUMN_WIDTH}
                      cellRenderer={({ rowData }) => <div data-test-fee-type={rowData.type}>{rowData.name}</div>}
                    />
                    {fees.map((fee) => (
                      <Column
                        key={fee.id}
                        dataKey=""
                        width={DEFAULT_COLUMN_WIDTH}
                        headerRenderer={() => (
                          <HeaderCell
                            fee={fee}
                            key={fee.id}
                            refresh={refresh}
                            selectAll={
                              !disabled ? (): void => selectAll(fee, setFieldValue, setValues, values) : undefined
                            }
                          />
                        )}
                        cellRenderer={({ rowData }) => (
                          <ProductFormCheckboxInput
                            key={`${fee.id}-${rowData.id}`}
                            name={`${fee.id}.${rowData.type}.${rowData.id}`}
                            disabled={disabled}
                            inputType={FormInputType.CHECKBOX}
                            handleOnChange={(name: string, value: ProductFormValue): void => {
                              handleFeeChange(name, value);
                              return handleChange(name, value, setFieldValue, setFieldTouched);
                            }}
                          />
                        )}
                      />
                    ))}
                  </Table>
                );
              }}
            </AutoSizer>
          </div>
          {!disabled && (
            <FormActionDrawer
              actions={[
                {
                  'data-test-id': 'submit',
                  key: 'submit',
                  content: 'Save',
                  onClick: submitForm,
                  primary: true,
                  loading: isSubmitting,
                  disabled: isSubmitting || !isDirty,
                },
              ]}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};
