import { Button, Modal } from '@wework/dieter-ui';
import React, { useState } from 'react';

import { CreateGroupForm } from 'components/groups/CreateGroup/CreateGroupForm';
import { IProductGroup } from 'types/productCatalogTypes';

interface Props {
  disabled?: boolean;
  existingGroups: Array<IProductGroup>;
  parentGroup?: IProductGroup;
  onSuccess: (groupUuid: string) => void;
}

export const CreateGroupModal = ({ existingGroups, parentGroup, disabled, onSuccess }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClose = (): void => setIsOpen(false);
  const onOpen = (): void => setIsOpen(true);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeIcon
      size="tiny"
      trigger={
        <Button primary disabled={disabled} onClick={onOpen}>
          Create a new Group
        </Button>
      }
    >
      <Modal.Header>Create a new group</Modal.Header>
      <CreateGroupForm
        onClose={onClose}
        onSuccess={onSuccess}
        existingGroups={existingGroups}
        parentGroup={parentGroup}
      />
    </Modal>
  );
};
