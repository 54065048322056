import React, { useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { isEmpty } from 'lodash';
import { LoadingContainer } from 'components/shared/Loading/LoadingContainer';
import { ITabConfig, VerticalTabRouter } from 'components/shared/VerticalTabRouter/VerticalTabRouter';
import { useApiHook } from 'hooks/useApiHook';
import { getProduct } from 'networking/productCatalog/productRequests';
import { IProduct } from 'types/productCatalogTypes';
import { editProductTabsConfig } from 'pages/products/editProductTabs/editProductTabsConfig';
import { NotFoundPage } from 'pages/NotFoundPage/NotFoundPage';
import { ResponseStatus } from 'networking/fetchConfig';
import { useGlobalAndOperatorPermissions } from 'hooks/useGlobalAndOperatorPermissions';
import { ALL_PERMISSIONS } from 'utils/auth/authUtils';
import { EDIT_TOGGLE_ID } from 'components/shared/EditToggle/EditToggle';
import { PageHeader } from 'components/shared/PageHeader/PageHeader';
import { BaseUrlParamsType } from 'types/router';

type ParamsType = BaseUrlParamsType & {
  uuid: string;
};

export const EditProductPage = (): JSX.Element => {
  const navigate = useNavigate();
  const [permsIsLoading, permsError, availablePerms] = useGlobalAndOperatorPermissions(ALL_PERMISSIONS);
  const { catalogUuid, uuid: productUuid } = useParams() as ParamsType;
  const {
    data: product,
    status,
    refresh: refreshProduct,
    loading,
    error: prodcutError,
  } = useApiHook<IProduct | null>(() => getProduct(productUuid, catalogUuid), [productUuid, catalogUuid]);

  const tabsConfig = useMemo((): ITabConfig[] => {
    return product ? editProductTabsConfig(product, availablePerms, catalogUuid, refreshProduct) : [];
  }, [product, availablePerms, catalogUuid]);

  const tabClickHandler = ({ path }: ITabConfig): void => {
    const goto = generatePath(path, { uuid: productUuid });
    navigate(goto);
  };

  if (status === ResponseStatus.NOT_FOUND) {
    return <NotFoundPage />;
  }

  return (
    <div className="alchemist-fade-in">
      <LoadingContainer loading={(loading && isEmpty(product)) || permsIsLoading} error={prodcutError || permsError}>
        <>
          {product && (
            <PageHeader header={product.name}>
              <>
                {/* this is anchor node for EditToggle component*/}
                <div id={EDIT_TOGGLE_ID} />
              </>
            </PageHeader>
          )}
          {product && <VerticalTabRouter tabsConfig={tabsConfig} onClick={tabClickHandler} />}
        </>
      </LoadingContainer>
    </div>
  );
};
