import { useState, useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';

type UseSortableOpts = {
  enabled?: boolean;
  initialAttr?: string;
};

interface UseSortable<T> {
  sorted: T[];
  setSort: (attr: string) => void;
  sortConfig: SortConfig;
}

// [attribute, isDescending]
export type SortConfig = [string, boolean];

const defaultOpts = {
  enabled: true,
  initialAttr: 'name',
};

export function useSortable<T>(collection: T[], opts: Partial<UseSortableOpts> = {}): UseSortable<T> {
  const { enabled, initialAttr } = { ...defaultOpts, ...opts };

  const [sortConfig, setSortConfig] = useState<SortConfig>([initialAttr, false]);
  const [sortAttr, sortDesc] = sortConfig;

  const sorted = useMemo<T[]>(() => {
    if (!enabled) return collection;

    const ascSorted = sortBy(collection, sortAttr);
    return sortDesc ? reverse(ascSorted) : ascSorted;
  }, [sortAttr, sortDesc, enabled, collection.length]);

  const setSort = (attr: string): void => {
    const newDesc = attr === sortAttr && !sortDesc;
    setSortConfig([attr, newDesc]);
  };

  return {
    sorted,
    setSort,
    sortConfig,
  };
}
