import { Form, Select, DropdownItemProps } from '@wework/dieter-ui';
import React, { useState, useEffect } from 'react';

import { getListingGroup } from 'networking/productCatalog/listingRequests';
import { FormValue } from 'components/shared/Form/FormConfig';
import { IListingGroup } from 'types/productCatalogTypes';
import { toListingGroupDropdownItems } from 'components/shared/ProductForm/productFormHelpers';

interface IGroupSelectInputProps {
  rootGroupId?: string; // group uuid or slug
  listingGroup?: IListingGroup;
  initialValue?: string;
  disabled?: boolean;
  onChange: (value: FormValue) => void;
}

export const GroupSelectInput = ({
  rootGroupId,
  listingGroup,
  initialValue,
  disabled,
  onChange,
}: IGroupSelectInputProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [options, setOptions] = useState<DropdownItemProps[]>([]);

  useEffect(() => {
    const loadOptions = async (): Promise<void> => {
      const rootListingGroup = listingGroup || (await getListingGroup(rootGroupId || 'addons')).data;
      if (rootListingGroup) {
        const newOptions = toListingGroupDropdownItems(rootListingGroup);
        setOptions(newOptions);
      }
      setLoading(false);
    };
    loadOptions();
  }, []);

  return (
    <Form.Field
      label="Product Group"
      control={Select}
      disabled={disabled}
      outline
      selection
      clearable
      scrolling
      options={options}
      onChange={(_, changeProps): void => changeProps && onChange(changeProps.value)}
      loading={loading}
      selectOnBlur={false}
      selectOnNavigation={false}
      defaultValue={initialValue}
    />
  );
};
