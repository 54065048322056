import { faCubes, faCube } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import React, { createElement, useState } from 'react';

import { ILocationListingGroup } from 'types/productCatalogTypes';
import { WeCheckboxTree } from 'components/shared/WeCheckboxTree/WeCheckboxTree';
import { listingGroupToNode } from 'utils/listingHelpers';

import 'components/groups/GroupsTree/groupsTree.scss';

const icon = (opts: FontAwesomeIconProps): JSX.Element => createElement(FontAwesomeIcon, opts);

interface IListingsTreeProps {
  listingGroup: ILocationListingGroup;
  expandAllGroups?: boolean;
  onClick?: (event: { checked: boolean; value: string }) => void;
}

const customIcons = {
  leaf: icon({ icon: faCube }),
  parentClose: icon({ icon: faCubes }),
  parentOpen: icon({ icon: faCubes }),
};

const getAllGroups = (listingGroup: ILocationListingGroup): ILocationListingGroup[] => [
  listingGroup,
  ...(listingGroup.groups || []).reduce(
    (acc: ILocationListingGroup[], group: ILocationListingGroup) => [...acc, ...getAllGroups(group)],
    [],
  ),
];

export const ListingsTree = ({ listingGroup, expandAllGroups = false, onClick }: IListingsTreeProps): JSX.Element => {
  const initialExpandedGroups = expandAllGroups
    ? getAllGroups(listingGroup).map((group) => group.uuid)
    : [listingGroup.uuid];
  const [expandedIds, setExpandedIds] = useState<string[]>(initialExpandedGroups);
  const nodes = [listingGroupToNode(listingGroup)];

  return (
    <div className="groupsTree--container">
      <WeCheckboxTree
        nodes={nodes}
        expanded={expandedIds}
        onExpand={setExpandedIds}
        showExpandAll
        icons={{ ...customIcons }}
        onClick={onClick}
      />
    </div>
  );
};
