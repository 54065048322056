import uniq from 'lodash/uniq';

import { ReservableType, SpacemanFee } from 'types/spacemanTypes';
import { IUpdateFeeRequest } from 'networking/spaceman/feeRequests';
import { Hash } from 'types/global';
import { IProduct } from 'types/productCatalogTypes';

const hashifyArray = (items: string[]): Hash<boolean> =>
  items.reduce((acc, item) => {
    acc[item] = true;
    return acc;
  }, {} as { [key: string]: boolean });

export const getInitialState = (fees: SpacemanFee[]): ITaxFormData =>
  fees.reduce((acc, fee) => {
    acc[fee.id] = {
      reservable: hashifyArray(fee.reservable_types),
      product: hashifyArray(fee.product_uuids),
      resource: hashifyArray(fee.resource_types),
    };
    return acc;
  }, {} as ITaxFormData);

const reduceToArray = (data: Hash<boolean>): string[] =>
  Object.entries(data).reduce((acc, [keyName, keyValue]) => {
    if (keyValue) {
      acc.push(keyName);
    }
    return acc;
  }, [] as string[]);

export const getUpdateRequestParams = (
  fee: SpacemanFee,
  data: ITaxFormData,
  products: IProduct[],
): IUpdateFeeRequest => {
  const productUuids = reduceToArray(data[fee.id].product);
  const resourceUuids = reduceToArray(data[fee.id].resource);
  // select products whose uuids have been included that also have a resource id configured. this should be all of them.
  const addedProducts = products.filter(
    (product) => productUuids.includes(product.uuid) && product.attributes.RESOURCE_ID,
  );
  const resourceUuidsFromProducts = addedProducts.map((product) => product.attributes.RESOURCE_ID) as string[];

  return {
    ...fee,
    reservable_types: reduceToArray(data[fee.id].reservable) as ReservableType[],
    resource_types: uniq([...resourceUuids, ...resourceUuidsFromProducts]),
    product_uuids: productUuids,
  };
};

export interface ITaxFormDataItem {
  reservable: Hash<boolean>;
  product: Hash<boolean>;
  resource: Hash<boolean>;
}

export interface ITaxFormData {
  [key: number]: ITaxFormDataItem;
}
