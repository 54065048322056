import React from 'react';
import { Message } from '@wework/dieter-ui';
import { isEmpty, map } from 'lodash';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IWorkdaySalesItems } from 'types/productCatalogTypes';
import { ExpandableText } from 'components/shared/Form/ExpandableText';
import { getNumberOfVisibleLines, getProductFieldLabel, getSalesItemName, isFieldEmpty } from './utils';
import { AttributeLabel } from './AttributeLabel';

import './styles.scss';

interface IInitialProductValuesProps {
  entityData: Record<string, string>;
  salesItems: IWorkdaySalesItems[];
}

export const InitialProductValues = ({ entityData, salesItems }: IInitialProductValuesProps): JSX.Element => {
  if (isEmpty(entityData)) {
    return (
      <Message warning>
        <p>Product was created without any data</p>
      </Message>
    );
  }
  return (
    <>
      {map(entityData, (value, key) => {
        const attrLabel = getProductFieldLabel(key) ?? key;
        const initialValue = key === 'attributes.SALES_ITEM_ID' ? getSalesItemName(salesItems, value) : value;

        return (
          <div key={key}>
            <AttributeLabel name={attrLabel} nativeName={key} />
            <div className="attr-change">
              <span className="changes to">
                {isFieldEmpty(value) ? (
                  <FontAwesomeIcon icon={faBan} color="green" size="sm" />
                ) : (
                  <ExpandableText text={initialValue} lines={getNumberOfVisibleLines(initialValue)} />
                )}
              </span>
            </div>
          </div>
        );
      })}
    </>
  );
};
