import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { ChangeState } from 'types/mappers';
import {
  ILocationAvailabilityChanges,
  AuditActionType,
  ILocationPricesChanges,
  ILocationTncsChanges,
} from 'types/productCatalogTypes';
import { PriceLabel } from 'components/shared/AvailabilityForm/PriceLabel';
import { TncLabel } from 'components/shared/AvailabilityForm/TncChangesLabel';

export interface ILabelProps<T> {
  title: string;
  changesData?: T[];
}

export const LocationAvailabilityChangeLable = ({ title, changesData }: ILabelProps<ILocationAvailabilityChanges>) => {
  return changesData && changesData[0].action ? (
    <span>
      {changesData[0].action === AuditActionType.REMOVED ? (
        <FontAwesomeIcon icon={faMinusSquare} size="lg" color="red" />
      ) : (
        <FontAwesomeIcon icon={faPlusSquare} size="lg" color="green" />
      )}{' '}
      {title}
    </span>
  ) : (
    <span>{title}</span>
  );
};

const actionMap = {
  [AuditActionType.CREATED]: ChangeState.ADDED,
  [AuditActionType.REMOVED]: ChangeState.REMOVED,
  [AuditActionType.UPDATED]: ChangeState.EDIT,
};
export const LocationPricesChangesLable = ({ title, changesData }: ILabelProps<ILocationPricesChanges>) => {
  if (!changesData?.length) {
    return <span>{title}</span>;
  }
  const { action, price, currency } = changesData[0];
  return (
    <>
      {title} <PriceLabel locationPrice={{ price, currency }} changeState={actionMap[action]} />
    </>
  );
};

export const LocationTncsChangesLable = ({ title, changesData }: ILabelProps<ILocationTncsChanges>) => {
  if (!changesData) {
    return <span>{title}</span>;
  }
  return (
    <>
      {title}{' '}
      <ul className="locationChangesList">
        {changesData.map(({ action, locale, name, url, displayName, displayNameTranslations }, idx) => {
          return (
            <li key={idx}>
              <TncLabel
                changeState={actionMap[action]}
                locale={locale}
                name={name}
                url={url}
                displayName={displayName}
                displayNameTranslations={displayNameTranslations}
              />
            </li>
          );
        })}
      </ul>
    </>
  );
};
