import { Header, Segment } from '@wework/dieter-ui';
import React, { useState } from 'react';

import { OperatorServicePermissionsMap } from '@wework/we-auth-react';
import { last } from 'lodash';
import { ILocationListingGroup, ILocationListingProduct } from 'types/productCatalogTypes';
import { ListingsTree } from 'components/listings/ListingsTree';
import { getAllListingProducts } from 'utils/listingHelpers';
import { AvailabilityPriceModal } from 'components/buildings/AvailabilityPriceModal';

export interface IAvailableAddonsProps {
  addonsGroup: ILocationListingGroup;
  disabled?: boolean;
  requestedPerms: OperatorServicePermissionsMap;
  refresh: () => void;
}

export const AvailableAddons = ({
  addonsGroup,
  disabled,
  requestedPerms,
  refresh,
}: IAvailableAddonsProps): JSX.Element => {
  const listingProducts = getAllListingProducts(addonsGroup);
  const [selectedProduct, setSelectedProduct] = useState<ILocationListingProduct | null>(null);

  const onClick = (event: { checked: boolean; value: string }): void => {
    const selectedUuid = last(event.value.split(','));
    const product = listingProducts.find((product) => product.uuid === selectedUuid);
    product && setSelectedProduct(product);
  };

  return (
    <Segment>
      <Header as="h3">Add-ons ({listingProducts.length})</Header>
      {listingProducts.length ? (
        <ListingsTree listingGroup={addonsGroup} expandAllGroups onClick={disabled ? undefined : onClick} />
      ) : (
        <div>No add-ons are currently available for sale in this building</div>
      )}
      {selectedProduct && (
        <AvailabilityPriceModal
          listing={selectedProduct}
          requestedPerms={requestedPerms}
          onClose={(): void => setSelectedProduct(null)}
          refresh={refresh}
        />
      )}
    </Segment>
  );
};
