import React, { ForwardRefExoticComponent } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDraggableProps,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import classNames from 'classnames';

interface IResourceable {
  uuid: string;
}

export interface IDraggableRowProps<T> extends DraggableProvidedDraggableProps {
  item: T;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: React.Ref<any>;
  isDragging: boolean;
}

interface IDraggableTableProps<T> {
  items: Array<T>;
  editable?: boolean;
  className?: string;
  Row: ForwardRefExoticComponent<IDraggableRowProps<T>>;
  Header: () => JSX.Element;
  onChange?: (uuids: string[]) => void;
}

export function DraggableTable<T extends IResourceable>(props: IDraggableTableProps<T>): JSX.Element {
  const { items, editable, className, Row, Header, onChange } = props;
  const handleOnDragEnd = ({ draggableId, destination }: DropResult): void => {
    if (!editable || !onChange) {
      return;
    }
    if (!destination) {
      // Drag ended outside of droppable area
      return;
    }

    // Assemble new ordering of IDs and pass to handler
    const { index } = destination;
    const idsWithoutDragged = items.map((g) => g.uuid).filter((id) => id !== draggableId);
    const newIds = [...idsWithoutDragged.slice(0, index), draggableId, ...idsWithoutDragged.slice(index)];
    onChange(newIds);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      {/* Using native DOM table elements to correctly place DND refs */}
      <table className={classNames('ui single line table', className)}>
        <Header />
        <Droppable droppableId={`table-${Math.random()}`}>
          {({ innerRef: droppableInnerRef, droppableProps, placeholder }): JSX.Element => (
            <tbody ref={droppableInnerRef} {...droppableProps}>
              {items.map((item, index) => (
                <Draggable draggableId={item.uuid} index={index} key={item.uuid} isDragDisabled={!editable}>
                  {(
                    { draggableProps, dragHandleProps = {}, innerRef: draggableInnerRef },
                    { isDragging },
                  ): JSX.Element => (
                    <Row
                      item={item}
                      ref={draggableInnerRef}
                      isDragging={isDragging}
                      {...draggableProps}
                      {...dragHandleProps}
                    />
                  )}
                </Draggable>
              ))}
              {placeholder}
            </tbody>
          )}
        </Droppable>
      </table>
    </DragDropContext>
  );
}
