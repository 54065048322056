import React from 'react';
import { Checkbox, CheckboxProps, Popup } from '@wework/dieter-ui';
import { createPortal } from 'react-dom';

interface IEditTogleProps {
  disabled?: boolean;
  isEdit?: boolean;
  onChange: (data: CheckboxProps) => void;
}

const HELP_DESK_PAGE = 'https://jira.weworkers.io/plugins/servlet/desk/portal/118/create/1808';

export const EDIT_TOGGLE_ID = 'edit-toggle-portal';

export const EditToggle = ({ disabled = false, isEdit, onChange }: IEditTogleProps): JSX.Element => {
  const handleChange = (_event: React.FormEvent<HTMLInputElement>, data: CheckboxProps): void => {
    !disabled && onChange(data);
  };
  const editTogglePortal = document.getElementById(EDIT_TOGGLE_ID);
  const toggle = (
    <Popup
      hoverable
      position="top right"
      disabled={!disabled}
      content={
        <>
          You do not have EDIT permissions. Please reach out to the{' '}
          <a href={HELP_DESK_PAGE} target="_blank" rel="noreferrer">
            Help Desk
          </a>{' '}
          if you need to increase your access.
        </>
      }
      trigger={<Checkbox slider label="Edit" disabled={disabled} checked={isEdit} onChange={handleChange} />}
    />
  );

  return editTogglePortal ? createPortal(toggle, editTogglePortal) : toggle;
};
