import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Table } from '@wework/dieter-ui';
import React, { useState } from 'react';

import noop from 'lodash/noop';
import { ILocationTnc, LocationTncLocationType } from 'types/productCatalogTypes';
import { LocationTncForm } from './LocationTncForm';
import { locationTncDefaultValues, LocationTncHeaders } from './LocationTncFormConfig';
import './LocationTncModal.scss';

interface ILocationTncModalProps {
  locationName: string | undefined;
  locationUuid: string | undefined;
  locationType: LocationTncLocationType;
  locationTncs: ILocationTnc[] | null;
  disabled?: boolean;
  onClose: () => void;
  onSave: (tnc: ILocationTnc, index: number | null) => void;
  onDelete: (tnc: ILocationTnc) => void;
}

export const LocationTncModal = ({
  locationName = '',
  locationUuid = '',
  locationType = LocationTncLocationType.GEOGROUPING,
  locationTncs,
  disabled,
  onClose,
  onSave,
  onDelete,
}: ILocationTncModalProps): JSX.Element | null => {
  const [editIndex, setEditIndex] = useState<number | undefined>(undefined);

  if (!locationName || !locationTncs) {
    return null;
  }

  const newTnc = {
    ...locationTncDefaultValues,
    locationUuid,
    locationType,
    displayNameTranslations: {},
  };

  const handleClose = (): void => {
    setEditIndex(undefined);
    onClose();
  };

  const onEdit = (index: number): void => {
    setEditIndex(index);
  };

  const handleSaved = (tnc: ILocationTnc, index: number | null): void => {
    setEditIndex(undefined);
    onSave(tnc, index);
  };

  const noResults = (
    <Table.Row>
      <Table.Cell colSpan={LocationTncHeaders.length + 1}>No Term and Conditions Found</Table.Cell>
    </Table.Row>
  );

  const resultRow = (tnc: ILocationTnc, index: number): JSX.Element => {
    return (
      <>
        {LocationTncHeaders.map((header) => (
          <Table.Cell key={tnc.id + '-' + tnc.name + '-' + header.key}>{tnc[header.key]}</Table.Cell>
        ))}
        <Table.Cell>
          <Button color="blue" size="small" disabled={disabled} onClick={(): void => onEdit(index)}>
            <FontAwesomeIcon
              icon={faPencilAlt}
              style={{ margin: '0 -3px', height: '25px', width: '16px', verticalAlign: 'middle' }}
            />
          </Button>
          <Button
            color="red"
            size="small"
            icon="x"
            disabled={disabled}
            data-test-id={`remove-button-tnc-${tnc.id}`}
            onClick={(): void => onDelete(tnc)}
          />
        </Table.Cell>
      </>
    );
  };

  const results = locationTncs.map((tnc, index) => {
    return (
      <Table.Row key={tnc.id + '-' + tnc.name}>
        {editIndex === index ? (
          <Table.Cell colSpan={LocationTncHeaders.length + 1}>
            <LocationTncForm
              disabled={disabled}
              locationTnc={tnc}
              onSave={(tnc): void => handleSaved(tnc, index)}
              onClear={(): void => setEditIndex(undefined)}
              create={false}
            />
          </Table.Cell>
        ) : (
          resultRow(tnc, index)
        )}
      </Table.Row>
    );
  });

  return (
    <Modal open onClose={handleClose} closeIcon size="fullscreen">
      <Modal.Header>Terms and Conditions for {locationName}</Modal.Header>
      <Table className="location-tnc-modal--table">
        <Table.Header>
          <Table.Row>
            {LocationTncHeaders.map((header) => (
              <Table.HeaderCell key={header.key}>{header.label}</Table.HeaderCell>
            ))}
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{locationTncs.length === 0 ? noResults : results}</Table.Body>
      </Table>
      <LocationTncForm
        disabled={disabled}
        locationTnc={newTnc}
        onSave={(tnc): void => handleSaved(tnc, null)}
        onClear={noop}
        create={true}
      />
    </Modal>
  );
};
