import React, { useEffect } from 'react';

import { ProductForm, RequestType } from 'components/shared/ProductForm';
import { LoadingContainer } from 'components/shared/Loading/LoadingContainer';
import { useApiHook } from 'hooks/useApiHook';
import { IWorkdaySalesItems } from 'types/productCatalogTypes';
import { getSalesItems } from 'utils/sessionStore';
import { useFlashMessageContext } from 'contexts/FlashMessageContext';

const diplayedSalesItemsError = 'Workday unavailable, set attribute value later';

const CreateProductPage = (): JSX.Element => {
  const { flashError } = useFlashMessageContext();
  const { data: salesItems, error: salesItemsError, loading } = useApiHook<IWorkdaySalesItems[]>(getSalesItems);

  useEffect(() => {
    salesItemsError && flashError(salesItemsError);
  }, [salesItemsError]);

  return (
    <div className="alchemist-fade-in">
      <LoadingContainer loading={loading}>
        <ProductForm
          requestType={RequestType.CREATE}
          salesItems={salesItems || []}
          salesItemsError={salesItemsError && diplayedSalesItemsError}
        />
      </LoadingContainer>
    </div>
  );
};

export { CreateProductPage };
