import { FormInputType } from 'components/shared/Form/FormConfig';
import { ProductFormField, ProductFormValue } from 'components/shared/ProductForm/productFormConfig';
import { IProductFormInputFieldConfig, ProductFormInboundTransform } from 'types/forms';

const inputLabels = {
  [ProductFormField.NAME]: 'Name',
  [ProductFormField.DESCRIPTION]: 'Description',
  [ProductFormField.NOTES]: 'Notes',
  [ProductFormField.VOLUME_LIMIT]: 'Volume Limit',
  [ProductFormField.EXTERNAL_VENDOR_URL]: 'External Vendor URL',
  [ProductFormField.IMAGES]: 'Product Image',
  [ProductFormField.ACCESS_GEOGROUPING_ID]: 'Access Geogrouping Id',
  [ProductFormField.BILLING_ENTITY_ID]: 'Billing Entity Id',
  [ProductFormField.CREDIT_ALLOTMENT]: 'Credit Allotment',
  [ProductFormField.DAILY_DESK_CREDIT_PRICE]: 'Daily Desk Credit Price',
  [ProductFormField.OVERRIDE_DAILY_DESK_CREDIT_PRICE]: 'Override Standard Daily Desk Credit Price?',
  [ProductFormField.RESERVATION_DURATION]: 'Reservation Duration',
  [ProductFormField.RESOURCE_ID]: 'Resource ID',
  [ProductFormField.PRINT_BW_ALLOTMENT]: 'Black/White Printer Allotment',
  [ProductFormField.PRINT_COLOR_ALLOTMENT]: 'Color Printer Allotment',
  [ProductFormField.WELCOME_EMAIL_TEMPLATE_SLUG]: 'Onboarding Email Slug',
  [ProductFormField.FULFILLMENT_EMAIL]: 'Fulfillment Email',
  [ProductFormField.CONSULTATION_EMAIL_TEMPLATE_SLUG]: 'Consultation Email Slug',
  [ProductFormField.FULFILLMENT_EMAIL_TEMPLATE_SLUG]: 'Fulfillment Email Slug',
  [ProductFormField.RESTRICT_PROMOCODE_KIND]: 'Available for specific promo code(s)',
  [ProductFormField.PURCHASE_IMMEDIATELY]: 'This product can be purchased immediately',
  [ProductFormField.REQUEST_CONSULTATION]: 'This product requires consultation',
  [ProductFormField.LIMITED_AVAILABILITY]: 'This product has limited availability',
  [ProductFormField.ONE_TIME]: 'One Time Charge',
  [ProductFormField.REFUNDABLE]: 'This product is Refundable',
  [ProductFormField.WAIVABLE]: 'This product is Waivable',
  [ProductFormField.TNC_URL_NAME]: 'Name',
  [ProductFormField.TNC_URL_VALUE]: 'URL',
  [ProductFormField.SALE_ITEM_GROUP]: 'Sales Item Group',
  [ProductFormField.UUID]: 'UUID',
  [ProductFormField.BANDWIDTH_CAPACITY_LOWER_BOUND]: 'Lower range of desk count to recommend this product',
  [ProductFormField.BANDWIDTH_CAPACITY_UPPER_BOUND]: 'Upper range of desk count to recommend this product',
  [ProductFormField.AVAILABILITY_CONFIGURATION]: 'Avalability Configuration UUID',
  [ProductFormField.BOOKING_CANCELLATION_POLICY]: 'Booking Cancellation Policy UUID',
  [ProductFormField.SALES_ITEM_ID]: 'Workday Sales Item ID',
};

const inputPlaceholders = {
  [ProductFormField.NAME]: 'Enter product name...',
  [ProductFormField.DESCRIPTION]: 'Enter product description...',
  [ProductFormField.NOTES]: 'Enter notes...',
  [ProductFormField.VOLUME_LIMIT]: 'Enter volume limit...',
  [ProductFormField.EXTERNAL_VENDOR_URL]: 'Enter external vendor URL...',
  [ProductFormField.IMAGES]: 'Click Or Drag Product Image Here...',
  [ProductFormField.ACCESS_GEOGROUPING_ID]: 'Enter a Geogrouping ID from Locations...',
  [ProductFormField.BILLING_ENTITY_ID]: 'Enter a billing entity id...',
  [ProductFormField.CREDIT_ALLOTMENT]: 'Enter a credit allotment...',
  [ProductFormField.DAILY_DESK_CREDIT_PRICE]: 'Enter a credit price...',
  [ProductFormField.OVERRIDE_DAILY_DESK_CREDIT_PRICE]: 'Enter a credit price...',
  [ProductFormField.RESERVATION_DURATION]: 'Enter a reservation duration...',
  [ProductFormField.RESOURCE_ID]: 'Enter a resource ID...',
  [ProductFormField.PRINT_BW_ALLOTMENT]: 'Enter a black/white printer allotment...',
  [ProductFormField.PRINT_COLOR_ALLOTMENT]: 'Enter a color printer allotment...',
  [ProductFormField.WELCOME_EMAIL_TEMPLATE_SLUG]: 'Enter a template slug from Mandrill...',
  [ProductFormField.FULFILLMENT_EMAIL]: 'Enter the email to whom you want to send for consultation and fulfillment...',
  [ProductFormField.CONSULTATION_EMAIL_TEMPLATE_SLUG]: 'Enter a template slug from Mandrill...',
  [ProductFormField.FULFILLMENT_EMAIL_TEMPLATE_SLUG]: 'Enter a template slug from Mandrill...',
  [ProductFormField.RESTRICT_PROMOCODE_KIND]: 'Enter a promocode kind...',
  [ProductFormField.TNC_URL_NAME]: 'Enter a Name...',
  [ProductFormField.TNC_URL_VALUE]: 'Enter a URL...',
  [ProductFormField.SALE_ITEM_GROUP]: 'Select a category',
  [ProductFormField.SALES_ITEM_ID]: 'Select a sale item id',
};

interface ProductFormInboundTransforms {
  [key: string]: ProductFormInboundTransform;
}

const inboundTransforms: ProductFormInboundTransforms = {
  NUMBER_TO_STRING: (value: ProductFormValue): string => value?.toString() ?? '',
};

export const productFormInputConfig: { [key: string]: IProductFormInputFieldConfig } = {
  [ProductFormField.NAME]: {
    inputType: FormInputType.TEXT,
    labelText: inputLabels[ProductFormField.NAME],
    name: ProductFormField.NAME,
    placeholder: inputPlaceholders[ProductFormField.NAME],
  },
  [ProductFormField.DESCRIPTION]: {
    inputType: FormInputType.TEXT,
    labelText: inputLabels[ProductFormField.DESCRIPTION],
    name: ProductFormField.DESCRIPTION,
    placeholder: inputPlaceholders[ProductFormField.DESCRIPTION],
  },
  [ProductFormField.NOTES]: {
    inputType: FormInputType.TEXTAREA,
    labelText: inputLabels[ProductFormField.NOTES],
    name: ProductFormField.NOTES,
    placeholder: inputPlaceholders[ProductFormField.NOTES],
  },
  [ProductFormField.VOLUME_LIMIT]: {
    inputType: FormInputType.NUMBER,
    labelText: inputLabels[ProductFormField.VOLUME_LIMIT],
    name: ProductFormField.VOLUME_LIMIT,
    placeholder: inputPlaceholders[ProductFormField.VOLUME_LIMIT],
  },
  [ProductFormField.EXTERNAL_VENDOR_URL]: {
    inputType: FormInputType.TEXT,
    labelText: inputLabels[ProductFormField.EXTERNAL_VENDOR_URL],
    name: ProductFormField.EXTERNAL_VENDOR_URL,
    placeholder: inputPlaceholders[ProductFormField.EXTERNAL_VENDOR_URL],
  },
  [ProductFormField.IMAGES]: {
    inputType: FormInputType.IMAGE,
    labelText: inputLabels[ProductFormField.IMAGES],
    name: ProductFormField.IMAGES,
    placeholder: inputPlaceholders[ProductFormField.IMAGES],
  },
  [ProductFormField.ACCESS_GEOGROUPING_ID]: {
    name: `${ProductFormField.ATTRIBUTES}.${ProductFormField.ACCESS_GEOGROUPING_ID}`,
    inputType: FormInputType.TEXT,
    labelText: inputLabels[ProductFormField.ACCESS_GEOGROUPING_ID],
    placeholder: inputPlaceholders[ProductFormField.ACCESS_GEOGROUPING_ID],
  },
  [ProductFormField.BILLING_ENTITY_ID]: {
    name: `${ProductFormField.ATTRIBUTES}.${ProductFormField.BILLING_ENTITY_ID}`,
    inputType: FormInputType.TEXT,
    labelText: inputLabels[ProductFormField.BILLING_ENTITY_ID],
    placeholder: inputPlaceholders[ProductFormField.BILLING_ENTITY_ID],
  },
  [ProductFormField.CREDIT_ALLOTMENT]: {
    name: `${ProductFormField.ATTRIBUTES}.${ProductFormField.CREDIT_ALLOTMENT}`,
    inputType: FormInputType.TEXT,
    labelText: inputLabels[ProductFormField.CREDIT_ALLOTMENT],
    placeholder: inputPlaceholders[ProductFormField.CREDIT_ALLOTMENT],
    inboundTransform: inboundTransforms.NUMBER_TO_STRING,
  },
  [ProductFormField.DAILY_DESK_CREDIT_PRICE]: {
    name: `${ProductFormField.ATTRIBUTES}.${ProductFormField.DAILY_DESK_CREDIT_PRICE}`,
    inputType: FormInputType.TEXT,
    labelText: inputLabels[ProductFormField.DAILY_DESK_CREDIT_PRICE],
    placeholder: inputPlaceholders[ProductFormField.DAILY_DESK_CREDIT_PRICE],
    inboundTransform: inboundTransforms.NUMBER_TO_STRING,
  },
  [ProductFormField.OVERRIDE_DAILY_DESK_CREDIT_PRICE]: {
    name: ProductFormField.OVERRIDE_DAILY_DESK_CREDIT_PRICE,
    inputType: FormInputType.CHECKBOX,
    labelText: inputLabels[ProductFormField.OVERRIDE_DAILY_DESK_CREDIT_PRICE],
    placeholder: inputPlaceholders[ProductFormField.OVERRIDE_DAILY_DESK_CREDIT_PRICE],
  },
  [ProductFormField.RESERVATION_DURATION]: {
    name: `${ProductFormField.ATTRIBUTES}.${ProductFormField.RESERVATION_DURATION}`,
    inputType: FormInputType.TEXT,
    labelText: inputLabels[ProductFormField.RESERVATION_DURATION],
    placeholder: inputPlaceholders[ProductFormField.RESERVATION_DURATION],
  },
  [ProductFormField.RESOURCE_ID]: {
    name: `${ProductFormField.ATTRIBUTES}.${ProductFormField.RESOURCE_ID}`,
    inputType: FormInputType.TEXT,
    labelText: inputLabels[ProductFormField.RESOURCE_ID],
    placeholder: inputPlaceholders[ProductFormField.RESOURCE_ID],
  },
  [ProductFormField.PRINT_BW_ALLOTMENT]: {
    name: `${ProductFormField.ATTRIBUTES}.${ProductFormField.PRINT_BW_ALLOTMENT}`,
    inputType: FormInputType.TEXT,
    labelText: inputLabels[ProductFormField.PRINT_BW_ALLOTMENT],
    placeholder: inputPlaceholders[ProductFormField.PRINT_BW_ALLOTMENT],
    inboundTransform: inboundTransforms.NUMBER_TO_STRING,
  },
  [ProductFormField.PRINT_COLOR_ALLOTMENT]: {
    name: `${ProductFormField.ATTRIBUTES}.${ProductFormField.PRINT_COLOR_ALLOTMENT}`,
    inputType: FormInputType.TEXT,
    labelText: inputLabels[ProductFormField.PRINT_COLOR_ALLOTMENT],
    placeholder: inputPlaceholders[ProductFormField.PRINT_COLOR_ALLOTMENT],
    inboundTransform: inboundTransforms.NUMBER_TO_STRING,
  },
  [ProductFormField.WELCOME_EMAIL_TEMPLATE_SLUG]: {
    name: `${ProductFormField.ATTRIBUTES}.${ProductFormField.WELCOME_EMAIL_TEMPLATE_SLUG}`,
    inputType: FormInputType.TEXT,
    labelText: inputLabels[ProductFormField.WELCOME_EMAIL_TEMPLATE_SLUG],
    placeholder: inputPlaceholders[ProductFormField.WELCOME_EMAIL_TEMPLATE_SLUG],
  },
  [ProductFormField.FULFILLMENT_EMAIL]: {
    name: `${ProductFormField.ATTRIBUTES}.${ProductFormField.FULFILLMENT_EMAIL}`,
    inputType: FormInputType.TEXT,
    labelText: inputLabels[ProductFormField.FULFILLMENT_EMAIL],
    placeholder: inputPlaceholders[ProductFormField.FULFILLMENT_EMAIL],
  },
  [ProductFormField.CONSULTATION_EMAIL_TEMPLATE_SLUG]: {
    name: `${ProductFormField.ATTRIBUTES}.${ProductFormField.CONSULTATION_EMAIL_TEMPLATE_SLUG}`,
    inputType: FormInputType.TEXT,
    labelText: inputLabels[ProductFormField.CONSULTATION_EMAIL_TEMPLATE_SLUG],
    placeholder: inputPlaceholders[ProductFormField.CONSULTATION_EMAIL_TEMPLATE_SLUG],
  },
  [ProductFormField.FULFILLMENT_EMAIL_TEMPLATE_SLUG]: {
    name: `${ProductFormField.ATTRIBUTES}.${ProductFormField.FULFILLMENT_EMAIL_TEMPLATE_SLUG}`,
    inputType: FormInputType.TEXT,
    labelText: inputLabels[ProductFormField.FULFILLMENT_EMAIL_TEMPLATE_SLUG],
    placeholder: inputPlaceholders[ProductFormField.FULFILLMENT_EMAIL_TEMPLATE_SLUG],
  },
  [ProductFormField.PURCHASE_IMMEDIATELY]: {
    name: `${ProductFormField.ATTRIBUTES}.${ProductFormField.PURCHASE_IMMEDIATELY}`,
    inputType: FormInputType.CHECKBOX,
    labelText: inputLabels[ProductFormField.PURCHASE_IMMEDIATELY],
  },
  [ProductFormField.REQUEST_CONSULTATION]: {
    name: `${ProductFormField.ATTRIBUTES}.${ProductFormField.REQUEST_CONSULTATION}`,
    inputType: FormInputType.CHECKBOX,
    labelText: inputLabels[ProductFormField.REQUEST_CONSULTATION],
  },
  [ProductFormField.LIMITED_AVAILABILITY]: {
    name: `${ProductFormField.ATTRIBUTES}.${ProductFormField.LIMITED_AVAILABILITY}`,
    inputType: FormInputType.CHECKBOX,
    labelText: inputLabels[ProductFormField.LIMITED_AVAILABILITY],
  },
  [ProductFormField.RESTRICT_PROMOCODE_KIND]: {
    name: `${ProductFormField.ATTRIBUTES}.${ProductFormField.RESTRICT_PROMOCODE_KIND}`,
    inputType: FormInputType.TEXT,
    labelText: inputLabels[ProductFormField.RESTRICT_PROMOCODE_KIND],
    placeholder: inputPlaceholders[ProductFormField.RESTRICT_PROMOCODE_KIND],
  },
  [ProductFormField.ONE_TIME]: {
    inputType: FormInputType.CHECKBOX,
    labelText: inputLabels[ProductFormField.ONE_TIME],
    name: ProductFormField.ONE_TIME,
  },
  [ProductFormField.REFUNDABLE]: {
    inputType: FormInputType.CHECKBOX,
    labelText: inputLabels[ProductFormField.REFUNDABLE],
    name: ProductFormField.REFUNDABLE,
  },
  [ProductFormField.WAIVABLE]: {
    inputType: FormInputType.CHECKBOX,
    labelText: inputLabels[ProductFormField.WAIVABLE],
    name: ProductFormField.WAIVABLE,
  },
  [ProductFormField.TNC_URL_NAME]: {
    inputType: FormInputType.TEXT,
    labelText: inputLabels[ProductFormField.TNC_URL_NAME],
    name: ProductFormField.TNC_URL_NAME,
    placeholder: inputPlaceholders[ProductFormField.TNC_URL_NAME],
  },
  [ProductFormField.TNC_URL_VALUE]: {
    inputType: FormInputType.TEXT,
    labelText: inputLabels[ProductFormField.TNC_URL_VALUE],
    name: ProductFormField.TNC_URL_VALUE,
    placeholder: inputPlaceholders[ProductFormField.TNC_URL_VALUE],
  },
  [ProductFormField.SALE_ITEM_GROUP]: {
    inputType: FormInputType.SELECT,
    labelText: inputLabels[ProductFormField.SALE_ITEM_GROUP],
    name: ProductFormField.SALE_ITEM_GROUP,
    placeholder: inputPlaceholders[ProductFormField.SALE_ITEM_GROUP],
  },
  [ProductFormField.UUID]: {
    inputType: FormInputType.TEXT,
    labelText: inputLabels[ProductFormField.UUID],
    name: ProductFormField.UUID,
  },
  [ProductFormField.BANDWIDTH_CAPACITY_LOWER_BOUND]: {
    inputType: FormInputType.NUMBER,
    labelText: inputLabels[ProductFormField.BANDWIDTH_CAPACITY_LOWER_BOUND],
    name: `${ProductFormField.ATTRIBUTES}.${ProductFormField.BANDWIDTH_CAPACITY_LOWER_BOUND}`,
  },
  [ProductFormField.BANDWIDTH_CAPACITY_UPPER_BOUND]: {
    inputType: FormInputType.NUMBER,
    labelText: inputLabels[ProductFormField.BANDWIDTH_CAPACITY_UPPER_BOUND],
    name: `${ProductFormField.ATTRIBUTES}.${ProductFormField.BANDWIDTH_CAPACITY_UPPER_BOUND}`,
  },
  [ProductFormField.AVAILABILITY_CONFIGURATION]: {
    inputType: FormInputType.TEXT,
    labelText: inputLabels[ProductFormField.AVAILABILITY_CONFIGURATION],
    name: `${ProductFormField.ATTRIBUTES}.${ProductFormField.AVAILABILITY_CONFIGURATION}`,
  },
  [ProductFormField.BOOKING_CANCELLATION_POLICY]: {
    inputType: FormInputType.TEXT,
    labelText: inputLabels[ProductFormField.BOOKING_CANCELLATION_POLICY],
    name: `${ProductFormField.ATTRIBUTES}.${ProductFormField.BOOKING_CANCELLATION_POLICY}`,
  },
  [ProductFormField.SALES_ITEM_ID]: {
    inputType: FormInputType.SELECT,
    labelText: inputLabels[ProductFormField.SALES_ITEM_ID],
    name: `${ProductFormField.ATTRIBUTES}.${ProductFormField.SALES_ITEM_ID}`,
    placeholder: inputPlaceholders[ProductFormField.SALES_ITEM_ID],
  },
};
