import React from 'react';
import { useParams } from 'react-router-dom';

import { LoadingContainer } from 'components/shared/Loading/LoadingContainer';
import { IProductGroup } from 'types/productCatalogTypes';
import { getGroups } from 'networking/productCatalog/groupRequests';
import { GroupsTree } from 'components/groups/GroupsTree/GroupsTree';
import { useApiHook } from 'hooks/useApiHook';
import { CreateGroupModal } from 'components/groups/CreateGroup/CreateGroupModal';
import { useGlobalAndOperatorPermissions } from 'hooks/useGlobalAndOperatorPermissions';
import { BaseUrlParamsType } from 'types/router';
import { AlchemistEmployeePermission, EDIT_GROUPS_PERMISSIONS } from 'utils/auth/authUtils';

import 'pages/groups/groupsPage.scss';

const GroupsPage = (): JSX.Element => {
  const { catalogUuid } = useParams() as BaseUrlParamsType;
  const { loading, data, error, refresh } = useApiHook<IProductGroup[] | undefined>(
    () => getGroups(catalogUuid),
    [catalogUuid],
  );
  const [permsIsLoading, permsError, requestedPerms] = useGlobalAndOperatorPermissions(EDIT_GROUPS_PERMISSIONS);
  const isLoading = loading || permsIsLoading;
  const isAdmin = requestedPerms[AlchemistEmployeePermission.alchemist_administration];
  return (
    <div>
      <div className="groups-page__header">
        <h3>Product Groups</h3>
        <CreateGroupModal disabled={!isAdmin} onSuccess={refresh} existingGroups={data || []} />
      </div>
      <LoadingContainer loading={isLoading} data={data} error={error || permsError}>
        <GroupsTree groups={data || []} />
      </LoadingContainer>
    </div>
  );
};

export { GroupsPage };
