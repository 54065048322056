import algoliasearch, { Index, QueryParameters, Response, BrowseParameters } from 'algoliasearch';
import isEmpty from 'lodash/isEmpty';

import { SETTINGS } from 'configs/settings';
import { ResponseStatus } from 'networking/fetchConfig';
import { IAlgoliaSearchError } from 'types/algoliaTypes';

// Reuse index instances to save memory and persist cached records
const clientFlyweight: { [key: string]: Index } = {};

const { ALGOLIA_APP_ID, ALGOLIA_API_KEY } = SETTINGS;

const ALGOLIA_CLIENT = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);

const getAlgoliaIndex = (index: string): Index =>
  clientFlyweight[index] || (clientFlyweight[index] = ALGOLIA_CLIENT.initIndex(index));

function searchIndex<T>(index: Index, query: string, params: QueryParameters = {}): Promise<Response<T>> {
  return index.search({ ...params, query });
}

function browseIndex<T>(index: Index, query = '', params: BrowseParameters = {}): Promise<T[]> {
  return new Promise((resolve, reject) => {
    const hits: T[] = [];
    const browser = index.browseAll(query, params);

    browser.on('result', (content): void => {
      const newHits = content.hits as T[];
      hits.push(...newHits);
    });

    browser.on('end', () => resolve(hits));
    browser.on('error', reject);
  });
}

async function fetchByIds<T>(index: Index, ids: string[]): Promise<T[]> {
  if (!ids || isEmpty(ids)) {
    return [];
  }

  const results: T[] = (await index.getObjects(ids)).results as T[];
  return results;
}

async function fetchById<T>(index: Index, id: string): Promise<T | null> {
  try {
    const result: T = (await index.getObject(id)) as T;
    return result;
  } catch (error) {
    if ((error as IAlgoliaSearchError).statusCode === ResponseStatus.NOT_FOUND) {
      return null;
    }
    throw error;
  }
}

export { getAlgoliaIndex, searchIndex, fetchByIds, fetchById, browseIndex };
