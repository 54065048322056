import React, { useEffect, useState } from 'react';
import { useAsyncEffect } from 'use-async-effect';
import { Grid, Message } from '@wework/dieter-ui';
import { isEmpty } from 'lodash';

import { useParams } from 'react-router-dom';
import { ProductsTable } from 'components/products/ProductsTable/ProductsTable';
import { LoadingContainer } from 'components/shared/Loading/LoadingContainer';
import { IFetchResult } from 'networking/fetchConfig';
import { getProducts } from 'networking/productCatalog/productRequests';
import { Filter, IFilterParams, toQueryParams } from 'components/products/Filter';
import { IProduct, ProductStatus } from 'types/productCatalogTypes';
import { CREATABLE_PERMISSIONS, hasSomePermissions } from 'utils/auth/authUtils';
import { useGlobalAndOperatorPermissions } from 'hooks/useGlobalAndOperatorPermissions';
import { BaseUrlParamsType } from 'types/router';

interface IControllerParams {
  filters?: IFilterParams;
}

const defaultFilters = {
  status: ProductStatus.ACTIVE,
};

export const ProductsPage = ({ filters: f = defaultFilters }: IControllerParams): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState<string | undefined>();
  const [filters, setFilters] = useState<IFilterParams>(f);
  const [productsIsLoading, setProductsIsLoading] = useState<boolean>(false);
  const [productsResponse, setProductsResponse] = useState<IFetchResult<IProduct[]>>();
  const [visibleProducts, setVisibleProducts] = useState<IProduct[]>([]);

  const { catalogUuid } = useParams() as BaseUrlParamsType;
  const [permsIsLoading, permsError, permissions] = useGlobalAndOperatorPermissions(CREATABLE_PERMISSIONS);
  const isCreateAvailable = hasSomePermissions(permissions);

  useAsyncEffect(
    async (isActive) => {
      if (!isActive()) {
        return;
      }
      setProductsIsLoading(true);
      const result = await getProducts({ ...toQueryParams(filters), catalog_uuid: catalogUuid });
      setProductsResponse(result);
      setProductsIsLoading(false);
    },
    [filters, catalogUuid],
  );

  useEffect(() => {
    const allProducts = productsResponse?.data ?? [];
    setVisibleProducts(searchProducts(allProducts, searchQuery));
  }, [searchQuery, productsKey(productsResponse?.data)]);

  const isLoading = productsIsLoading || permsIsLoading;

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={4} largeScreen={4}>
          <Filter onFilterChange={setFilters} onSearchChange={setSearchQuery} filters={filters} />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={12} largeScreen={12}>
          <LoadingContainer
            data-test-id="products_table"
            loading={isLoading}
            data={productsResponse?.data ?? []}
            error={productsResponse?.error || permsError}
          >
            <>
              <ProductsTable products={visibleProducts} sortable={true} createable={isCreateAvailable} />
              {isEmpty(visibleProducts) && <Message warning>No products match your filters.</Message>}
            </>
          </LoadingContainer>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

function searchProducts(products: IProduct[], searchQuery: string | undefined): IProduct[] {
  if (!searchQuery) {
    return products;
  }
  return products.filter((p) => {
    return p.uuid === searchQuery || p.name.toLowerCase().includes(searchQuery.toLowerCase());
  });
}

function productsKey(products: IProduct[] | undefined = []): string {
  return products.map((p) => p.uuid).join();
}
