import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, DropdownProps } from '@wework/dieter-ui';
import dateFnsFormat from 'date-fns/format';
import { isNumber, isString, isEmpty } from 'lodash';

import { IProduct } from 'types/productCatalogTypes';
import { formatPriceCurrency } from 'utils/displayHelpers';
import { ExpandableText } from 'components/shared/Form/ExpandableText';
import { useSortable } from 'hooks/useSortable';
import { SortArrow } from 'components/shared/SortArrow/SortArrow';
import { IProductsTableRowConfigItem, ProductsTableFormats, ProductsTableRowConfig } from './ProductsTableConfig';

import './productsTable.scss';

interface IProductsTableProps {
  products: IProduct[];
  editable?: boolean;
  createable?: boolean;
  onChange?: (productUuids: string[]) => void;
  sortable?: boolean;
}

enum ProductsTableActions {
  EDIT = 'EDIT',
  REMOVE = 'REMOVE',
}

const ProductsTable = ({
  products,
  createable,
  editable,
  onChange,
  sortable = false,
}: IProductsTableProps): JSX.Element => {
  const navigate = useNavigate();

  const { sorted, setSort, sortConfig } = useSortable<IProduct>(products, { enabled: sortable });
  const createIcon = (
    <FontAwesomeIcon className="create-button" icon={faPlusCircle} color={createable ? 'black' : 'grey'} size="lg" />
  );
  return (
    <Table selectable className="products-table">
      <Table.Header as="thead">
        <Table.Row as="tr" cellAs="th">
          <Table.HeaderCell
            as="th"
            className="products-table__title"
            colSpan={ProductsTableRowConfig.length + 1 + (editable ? 1 : 0)}
          >
            Product Catalog Products
            {createable ? (
              <Link to={'new'} className="create-button">
                {createIcon}
              </Link>
            ) : (
              createIcon
            )}
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row as="tr" cellAs="th">
          {ProductsTableRowConfig.map(
            ({ heading, columnWidth, key }): JSX.Element => (
              <Table.HeaderCell
                as="th"
                width={columnWidth}
                key={heading}
                singleLine
                onClick={(): void => setSort(key)}
                className={sortable ? 'products-table__header-link' : ''}
              >
                {heading}
                {sortable && <SortArrow attr={key} sortConfig={sortConfig} />}
              </Table.HeaderCell>
            ),
          )}
          {/* Remove column */}
          {editable && <Table.HeaderCell as="th" />}
        </Table.Row>
      </Table.Header>

      <Table.Body as="tbody">
        {sorted.map((product: IProduct) => (
          <ProductsTableRow key={product.uuid} row={product} editable={editable} onClickHandler={handleRowAction} />
        ))}
      </Table.Body>
    </Table>
  );

  function handleRowAction(product: IProduct, action: DropdownProps['value']): void {
    const { uuid } = product;

    if (action === ProductsTableActions.EDIT) {
      navigate(`${uuid}/edit`);
    } else if (action === ProductsTableActions.REMOVE) {
      onChange && onChange(products.map((p) => p.uuid).filter((puuid) => uuid != puuid));
    }
  }
};

interface IProductsTableRowProps {
  row: IProduct;
  editable?: boolean;
  onClickHandler: (row: IProduct, action: DropdownProps['value']) => void;
}

const ProductsTableRow = ({ row, editable, onClickHandler }: IProductsTableRowProps): JSX.Element => {
  return (
    <Table.Row as="tr" cellAs="td">
      {ProductsTableRowConfig.map((configItem: IProductsTableRowConfigItem) => (
        <Table.Cell as="td" key={configItem.key} singleLine={isCellSingleLine(configItem)}>
          {cellContents(row, configItem)}
        </Table.Cell>
      ))}

      {editable && (
        <Table.Cell
          as="td"
          key="remove"
          singleLine={true}
          onClick={(): void => {
            onClickHandler(row, ProductsTableActions.REMOVE);
          }}
        >
          <FontAwesomeIcon data-test-id="remove" icon={faMinusCircle} color="red" size="lg" />
        </Table.Cell>
      )}
    </Table.Row>
  );
};

const cellContents = (row: IProduct, { format, key }: IProductsTableRowConfigItem): JSX.Element | string => {
  const value = row[key];
  const strValue = value ? value.toString() : '';

  switch (format) {
    case ProductsTableFormats.PRICE_CURRENCY:
      const { price, currency } = row;
      if (isNumber(price) && !isEmpty(currency)) {
        return formatPriceCurrency(price / 100, currency);
      }
      return '';

    case ProductsTableFormats.DATE:
      return isString(value) ? dateFnsFormat(new Date(value), 'MM-dd-yy') : '';

    case ProductsTableFormats.EXPANDABLE_TEXT:
      return <ExpandableText text={strValue} lines={1}></ExpandableText>;

    case ProductsTableFormats.LINK:
      return <Link to={`${row['uuid']}/edit`}>{strValue}</Link>;
    default:
      return strValue;
  }
};

const isCellSingleLine = ({ format }: IProductsTableRowConfigItem): boolean => {
  return format !== ProductsTableFormats.TEXT && format !== ProductsTableFormats.EXPANDABLE_TEXT;
};

export { ProductsTable, ProductsTableActions };
