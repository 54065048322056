import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { AppState } from '@auth0/auth0-react/dist/auth0-provider';

import { SETTINGS } from 'configs/settings';
import { Auth0Wrapper } from 'utils/auth/Auth0Wrapper';
import { CatalogContextProvider } from 'contexts/CatalogContext';
import { UserContextProvider } from 'contexts/UserContext';
import App from 'pages/App';

import './styles/normalize.scss';
import '@wework/dieter-ui/dist/index.less';
import './styles/base.scss';
import { FlashMessageWrapper } from 'HOCs/FlashMessageWrapper/FlashMessageWrapper';

const { AUTH0_CLIENT_DOMAIN, AUTH0_CLIENT_ID } = SETTINGS;

const AppWithHistory = (): JSX.Element => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState?: AppState): void => {
    // replace the current history with the saved returnTo
    navigate(appState?.returnTo || window.location.pathname, { replace: true });
  };

  return (
    <Auth0Provider
      domain={AUTH0_CLIENT_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience="wework"
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      <Auth0Wrapper>
        <FlashMessageWrapper>
          <CatalogContextProvider>
            <UserContextProvider>
              <App />
            </UserContextProvider>
          </CatalogContextProvider>
        </FlashMessageWrapper>
      </Auth0Wrapper>
    </Auth0Provider>
  );
};

ReactDOM.render(
  <BrowserRouter>
    <AppWithHistory />
  </BrowserRouter>,
  document.getElementById('app'),
);
