import React, { useRef, useMemo } from 'react';
import { Popup, Message } from '@wework/dieter-ui';
import ago from 'ts-ago';
import dateFnsFormat from 'date-fns-tz/format';
import { isEmpty, size, groupBy } from 'lodash';
import RenderIfVisible from 'react-render-if-visible';
import cn from 'classnames';

import { IAuditEntity, IBuildingObject, IGeogroupingObject } from 'types/productCatalogTypes';
import { IEmployee } from 'networking/fetchConfig';
import { assembleLocationsMap } from 'types/mappers';
import { WeCheckboxTree } from 'components/shared/WeCheckboxTree/WeCheckboxTree';
import { Collapsible } from 'components/shared/Collapsible';
import { assembleChangesNodes, getAllNodeIds } from './utils';
import { ILabelProps } from './AuditLocationLabels';
import { AuditUserInfo } from './AuditUserInfo';

import {
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  AuditRecordPlaceholder,
  NoAuditRecordsPlaceholder,
  ESTIMATED_ITEM_HEIGHT,
  LoadMore,
  AuditColumn,
} from './AuditTable';

export interface IAuditLocationChangesTableProps<T> {
  locationAuditData: IAuditEntity<T>[];
  auditRecordsLoading: boolean;
  usersLoading: boolean;
  geogroupingsData: IGeogroupingObject[];
  buildingsData: IBuildingObject[];
  locationIdType: 'buildingUuid' | 'locationUuid';
  treeNodeLabelComponent: React.FC<ILabelProps<T>>;
  stop: boolean;
  getUserData: (userUuid: string) => IEmployee | undefined;
  loadMore: () => void;
}

export const columns = [
  {
    title: 'Date',
    key: 'date',
    size: 'md',
  },
  {
    title: 'User',
    key: 'user',
    size: 'lg',
  },
  {
    title: 'ATTRIBUTES / Changes',
    key: 'attributes',
    size: 'auto',
  },
];

export const AuditLocationChangesTable = <T,>({
  locationAuditData,
  auditRecordsLoading,
  usersLoading,
  geogroupingsData,
  buildingsData,
  locationIdType,
  treeNodeLabelComponent,
  getUserData,
  loadMore,
  stop,
}: IAuditLocationChangesTableProps<T>) => {
  const containerRef = useRef(null);
  const locationsMap = useMemo(
    () => geogroupingsData && buildingsData && assembleLocationsMap(geogroupingsData, buildingsData),
    [geogroupingsData, buildingsData],
  );

  const isLoading = auditRecordsLoading || usersLoading;

  return (
    <>
      <TableContainer>
        <TableHeader>
          {columns.map((column: AuditColumn) => (
            <TableHeaderCell key={column.key} size={column.size}>
              {column.title}
            </TableHeaderCell>
          ))}
        </TableHeader>
        <TableBody ref={containerRef}>
          {locationAuditData?.map((auditItem: IAuditEntity<T>, idx: number) => {
            const userData = getUserData(auditItem.user);
            const changeDate = dateFnsFormat(new Date(auditItem.timestamp), 'MMMM dd yyyy HH:mm zzz');
            const changesNumber = size(auditItem?.changes);
            const changesMap = groupBy(auditItem?.changes, locationIdType);
            const nodes =
              (locationsMap &&
                auditItem.changes &&
                assembleChangesNodes(locationsMap, changesMap, treeNodeLabelComponent)) ||
              [];
            const expandedId = getAllNodeIds(nodes);

            return (
              <div key={auditItem.id}>
                <RenderIfVisible key={auditItem.id} defaultHeight={ESTIMATED_ITEM_HEIGHT} placeholderElement={'div'}>
                  <TableRow>
                    <TableCell size="md">
                      <Popup
                        hoverablex
                        size="tiny"
                        position="top left"
                        content={changeDate}
                        trigger={<div>{ago(auditItem.timestamp)}</div>}
                      />
                    </TableCell>
                    <TableCell size="lg">
                      <AuditUserInfo userFullName={userData?.full_name} userEmail={userData?.email} />
                    </TableCell>
                    <TableCell size="auto">
                      <Collapsible title={`${changesNumber} ${changesNumber === 1 ? 'change' : 'changes'}`}>
                        <WeCheckboxTree nodes={nodes} expanded={expandedId} disabled={false} />
                      </Collapsible>
                    </TableCell>
                  </TableRow>
                </RenderIfVisible>
                {idx === locationAuditData.length - 1 ? (
                  <LoadMore setOffset={loadMore} stop={stop} root={containerRef} />
                ) : null}
              </div>
            );
          })}
          {isEmpty(locationAuditData) && isLoading && <AuditRecordPlaceholder />}
          {isEmpty(locationAuditData) && !isLoading && <NoAuditRecordsPlaceholder />}
        </TableBody>
      </TableContainer>
    </>
  );
};
