import React from 'react';
import { ChangeState } from 'types/mappers';
import { changesStateLabelConfig } from './availabilityFormUtils';

interface IActionCounterProps {
  changeState: ChangeState;
  counter: number;
  action: string;
}

export const ActionCounter = ({ changeState, action, counter }: IActionCounterProps) => {
  return counter == 0 ? null : (
    <>
      {changesStateLabelConfig[changeState].icon} {action} <b>{counter}</b> {'building'}
      {counter == 1 ? '' : 's'}
      {'  '}
    </>
  );
};
