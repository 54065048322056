import React, { useState } from 'react';

import { useRequestedRoles } from '@wework/we-auth-react';
import { IBuildingTabCommonProps } from 'pages/buildings/buildingTabs/buildingTabsConfig';
import { useApiHook } from 'hooks/useApiHook';
import { LoadingContainer } from 'components/shared/Loading/LoadingContainer';
import { TaxTable } from 'components/buildings/fees/TaxTable';
import { getTaxData, ITaxData } from 'components/buildings/fees/TaxActions';
import { CreateFeeModal } from 'components/buildings/fees/CreateFeeModal';
import { EditToggle } from 'components/shared/EditToggle/EditToggle';
import { hasAtLeastOnePermission, SPACEMAN_ROLES, TAXES_PERMISSIONS } from 'utils/auth/authUtils';

export const BuildingTaxesTab = ({ building, catalogUuid, requestedPerms }: IBuildingTabCommonProps): JSX.Element => {
  const [rolesIsLoading, rolesError, requestedRoles] = useRequestedRoles(SPACEMAN_ROLES);
  const isEditable = hasAtLeastOnePermission(requestedPerms, TAXES_PERMISSIONS) && requestedRoles.city_lead;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const {
    data,
    error: taxesError,
    loading: taxesIsLoading,
    refresh,
  } = useApiHook<ITaxData>(() => getTaxData(building.id, catalogUuid), [building.id]);
  const onEditToggle = (): void => setIsEdit((prevState) => !prevState);

  return (
    <LoadingContainer loading={taxesIsLoading || rolesIsLoading} error={taxesError || rolesError}>
      <>
        <EditToggle disabled={!isEditable} isEdit={isEdit} onChange={onEditToggle} />
        <CreateFeeModal locationUuid={building.id} disabled={!isEditable} onSuccess={refresh} />
        {data && <TaxTable {...data} refresh={refresh} disabled={!isEdit} />}
      </>
    </LoadingContainer>
  );
};
