import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import React, { forwardRef } from 'react';
import { Link, useParams } from 'react-router-dom';

import { IProductGroup } from 'types/productCatalogTypes';
import { DraggableTable, IDraggableRowProps } from 'components/shared/DraggableTable/DraggableTable';
import { BaseUrlParamsType } from 'types/router';

export interface IGroupsTableProps {
  groups: IProductGroup[];
  editable?: boolean;
  deliteble?: boolean;
  onChange?: (groupUuids: string[]) => void;
}

const GroupsTable = ({ groups, editable, deliteble, onChange }: IGroupsTableProps): JSX.Element => {
  const { catalogUuid } = useParams() as BaseUrlParamsType;
  const isDeleteVisible = editable && deliteble;
  const Header = (): JSX.Element => <GroupsTableHeader deliteble={isDeleteVisible} />;
  const Row = forwardRef<HTMLTableRowElement, IDraggableRowProps<IProductGroup>>(
    (props: IDraggableRowProps<IProductGroup>, rowRef) => {
      const { item, isDragging, ref: _propRef, ...rest } = props;
      return (
        <tr ref={rowRef} {...rest}>
          <td>{<Link to={`/${catalogUuid}/groups/${item.uuid}`}>{item.name}</Link>}</td>
          <td>{item.slug}</td>
          {!isDragging && (
            <>
              <td>{item.groups.length}</td>
              <td>{item.products.length}</td>
              {isDeleteVisible && (
                <td
                  key="remove"
                  onClick={(): void => {
                    handleDelete(item);
                  }}
                >
                  <FontAwesomeIcon
                    data-test-id="remove"
                    icon={faMinusCircle}
                    color="red"
                    size="lg"
                    style={{ cursor: 'pointer' }}
                  />
                </td>
              )}
            </>
          )}
        </tr>
      );
    },
  );

  Row.displayName = 'GroupsTableRow';

  function handleDelete(group: IProductGroup): void {
    const { uuid } = group;
    onChange && onChange(groups.map((g) => g.uuid).filter((guuid) => uuid != guuid));
  }

  return <DraggableTable items={groups} editable={!!editable} onChange={onChange} Row={Row} Header={Header} />;
};

interface IGroupsTableHeaderProps {
  deliteble?: boolean;
}
const GroupsTableHeader = ({ deliteble }: IGroupsTableHeaderProps): JSX.Element => (
  <thead>
    <tr>
      <th>Name</th>
      <th>Slug</th>
      <th># Child Groups</th>
      <th># Child Products</th>
      {deliteble && <th></th>}
    </tr>
  </thead>
);

export { GroupsTable };
