import React, { useState } from 'react';
import { Button, ButtonProps } from '@wework/dieter-ui';

interface AsyncButtonProps<T> extends ButtonProps {
  onClick: () => Promise<T>;
  onSuccess?: (result: T) => void;
  onError?: (result: T) => void;
  didSucceed: (result: T) => boolean;
}

export function AsyncButton<T>(props: AsyncButtonProps<T>): JSX.Element {
  const { onClick, didSucceed, onSuccess, onError, children, ...rest } = props;

  const [isActive, setIsActive] = useState<boolean>(false);

  const wrappedOnClick = async (): Promise<void> => {
    setIsActive(true);

    const result = await onClick();
    const isSuccess = didSucceed(result);

    isSuccess && onSuccess && onSuccess(result);
    !isSuccess && onError && onError(result);

    setIsActive(false);
  };

  return (
    <Button {...rest} loading={isActive} disabled={isActive} onClick={wrappedOnClick}>
      {children}
    </Button>
  );
}
