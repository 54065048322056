import React, { useContext, useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { Container } from '@wework/dieter-ui';
import { useAuth0 } from '@auth0/auth0-react';
import ReactGA from 'react-ga';

import GlobalNavBar from 'components/shared/GlobalNavBar';
import { LOGIN_ROUTE, LoginPage } from 'pages/LoginPage/LoginPage';
import { ProductsRouter } from 'pages/products/ProductsRouter';
import { Auth0User, employeeProfileFromAuth0User, IEmployeeProfile, ADMIN_PERMISSIONS } from 'utils/auth/authUtils';
import { LOGOUT_ROUTE, LogoutPage } from 'pages/LogoutPage/LogoutPage';
import { GroupsRouter } from 'pages/groups/GroupsRouter';
import { SETTINGS } from 'configs/settings';
import { BuildingsRouter } from 'pages/buildings/BuildingsRouter';
import { AdminRouter } from 'pages/admin/AdminRouter';
import { MarketPage } from 'pages/markets/MarketPage';
import { UserContext } from 'contexts/UserContext';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { RequireAuthorization } from 'helpers/RequireAuthorization/RequireAuthorization';
import { RequireAuthentication } from 'helpers/RequireAuthentication/RequireAuthentication';
import { RequireCatalog } from 'helpers/RequireCatalog/RequireCatalog';
import { NoCatalogSelected, NO_CATALOG_SELECTED_ROUTE } from './NoCatalogSelected/NoCatalogSelected';
import { UNAUTHORIZED_ROUTE, UnauthorizedPage } from './UnauthorizedPage/UnauthorizedPage';
import {
  NoAvailableCatalogsPage,
  NO_AVAILABLE_CATALOGS_ROUTE,
} from './NoAvailableCatalogsPage/NoAvailableCatalogsPage';
import { NotFoundPage } from './NotFoundPage/NotFoundPage';

const updateAnalyticsUserId = (profile: IEmployeeProfile): void => {
  const { employeeUuid } = profile;
  if (profile) {
    ReactGA.set({ userId: employeeUuid });
  }
};

if (SETTINGS.GA_TRACKINGID) {
  ReactGA.initialize(SETTINGS.GA_TRACKINGID, {
    gaOptions: {
      siteSpeedSampleRate: 100,
    },
  });
}

const App = (): JSX.Element => {
  const { isAuthenticated, user } = useAuth0<Auth0User>();
  const userContext = useContext(UserContext);
  const location = useLocation();

  useEffect(() => {
    if (!SETTINGS.GA_TRACKINGID) return;

    if (userContext.isLoaded) {
      updateAnalyticsUserId(userContext.userProfile);
    }

    // There are examples using history.listen; but couldn't get that working
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location.pathname, userContext.isLoaded]);

  useEffect(() => {
    if (isAuthenticated && user) {
      userContext.setUserProfile(employeeProfileFromAuth0User(user));
      userContext.setIsLoaded(true);
    }
  }, [isAuthenticated, user]);

  useScrollToTop();

  return (
    <section className="alchemist-container alchemist-fade-in">
      <Container>
        <Routes>
          <Route
            path="/*"
            element={
              <RequireAuthentication>
                <GlobalNavBar />
              </RequireAuthentication>
            }
          >
            <Route path={':catalogUuid/*'} element={<RequireCatalog />}>
              <Route index element={<Navigate to={'products'} replace />} />
              <Route
                path={'admin/*'}
                element={
                  <RequireAuthorization authPerms={ADMIN_PERMISSIONS}>
                    <AdminRouter />
                  </RequireAuthorization>
                }
              />
              <Route path={'products/*'} element={<ProductsRouter />} />
              <Route path={'groups/*'} element={<GroupsRouter />} />
              <Route path={'buildings/*'} element={<BuildingsRouter />} />
              <Route path={'markets/:uuid'} element={<MarketPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
            <Route path={LOGIN_ROUTE} element={<LoginPage />} />
            <Route path={LOGOUT_ROUTE} element={<LogoutPage />} />
            <Route path={NO_CATALOG_SELECTED_ROUTE} element={<NoCatalogSelected />} />
            <Route path={UNAUTHORIZED_ROUTE} element={<UnauthorizedPage />} />
            <Route path={NO_AVAILABLE_CATALOGS_ROUTE} element={<NoAvailableCatalogsPage />} />
          </Route>
        </Routes>
      </Container>
    </section>
  );
};

export default App;
