import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useCatalog } from 'contexts/CatalogContext';
import { NotFoundPage } from 'pages/NotFoundPage/NotFoundPage';
import { BaseUrlParamsType } from 'types/router';
import Loading from 'components/shared/Loading';

export const RequireCatalog = (): JSX.Element => {
  const { catalogUuid } = useParams() as BaseUrlParamsType;
  const { catalogs, isInitialized, isLoading } = useCatalog();
  if (isLoading || !isInitialized) {
    return <Loading />;
  }
  if (!catalogs.find((catalog) => catalog.uuid === catalogUuid)) {
    return <NotFoundPage />;
  }
  return <Outlet />;
};
