import { SemanticWIDTHS } from 'semantic-ui-react/src/generic';

export interface IMarketPricingTableRowConfigItem {
  column: MarketPricingTableColumns;
  heading: string;
  columnWidth: SemanticWIDTHS;
}

export enum MarketPricingTableColumns {
  NAME = 'NAME',
  MARKET_PRICE = 'MARKET_PRICE',
  REGION_PRICE = 'REGION_PRICE',
}

export const MarketPricingTableRowConfig: IMarketPricingTableRowConfigItem[] = [
  {
    column: MarketPricingTableColumns.NAME,
    heading: 'Addon Product',
    columnWidth: 1,
  },
  {
    column: MarketPricingTableColumns.MARKET_PRICE,
    heading: 'Market Price',
    columnWidth: 1,
  },
  {
    column: MarketPricingTableColumns.REGION_PRICE,
    heading: 'Region Price',
    columnWidth: 1,
  },
];
