import React from 'react';
import { Node } from 'react-checkbox-tree';
import {
  compact,
  groupBy,
  isEmpty,
  isEqual,
  keyBy,
  orderBy,
  Dictionary,
  filter,
  map,
  pickBy,
  size,
  uniq,
  concat,
} from 'lodash';
import { faSquarePlus, faSquareMinus, faSquarePen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SemanticCOLORS } from '@wework/dieter-ui';

import { IBulkUpdateLocationPricesPayload } from 'networking/productCatalog/locationPriceRequests';
import { IBulkUpdateLocationAttributesPayload } from 'networking/productCatalog/locationAttributesRequests';
import {
  ILocationPrice,
  ILocationAttributesRecord,
  IBuildingObject,
  IGeogroupingObject,
  ILocationObject,
} from 'types/productCatalogTypes';
import { formatPriceCurrency } from 'utils/displayHelpers';
import { ChangeState, IDiffMap, ILocationsMap } from 'types/mappers';
import { locationTitle } from 'utils/locationHelpers';
import { LocationLabels } from './LocationLabels';
import { LocationDiffLabels } from './LocationDiffLabels';

export const FILTERED_OUT_NODE = 'filtered-out-node';
export interface ILocationPriceMap {
  [locationUuid: string]: ILocationPrice;
}

export interface ILocationAttributesRecordMap {
  [locationUuid: string]: ILocationAttributesRecord;
}

export interface ICheckboxTreeNode extends Node {
  label: JSX.Element;
  children?: ICheckboxTreeNode[];
}

export const changesStateLabelConfig: Dictionary<{ icon: JSX.Element; color: SemanticCOLORS }> = {
  [ChangeState.ADDED]: { icon: <FontAwesomeIcon icon={faSquarePlus} color="green" size="lg" />, color: 'green' },
  [ChangeState.REMOVED]: { icon: <FontAwesomeIcon icon={faSquareMinus} color="red" size="lg" />, color: 'red' },
  [ChangeState.EDIT]: { icon: <FontAwesomeIcon icon={faSquarePen} color="orange" size="lg" />, color: 'orange' },
};

export const groupBuildingsByGeogrouipings = (locations: ILocationsMap) => {
  const { geogroupings, buildings } = locations;
  const geosByType = groupBy(geogroupings, 'type');

  const allCountryGeos = orderBy(geosByType.Countrygeo, ['is_published', 'name'], ['desc', 'asc']);
  const allMarketGeos = orderBy(geosByType.Marketgeo, ['is_published', 'name'], ['desc', 'asc']);
  const marketGeosByCountryGeo = groupBy(allMarketGeos, 'parent.id');

  const allBuildings = orderBy(buildings, ['is_published', 'is_not_physical', 'code'], ['desc', 'asc', 'asc']);
  const marketBuildings = allBuildings.filter((value) => value.marketgeo != null);
  const buildingsByMarketGeo = groupBy(marketBuildings, 'marketgeo.id');

  const countryBuildings = allBuildings.filter((value) => value.countrygeo !== null && value.marketgeo == null);
  const buildingsByCountryGeo = groupBy(countryBuildings, 'countrygeo.id');

  return {
    allCountryGeos,
    marketGeosByCountryGeo,
    buildingsByMarketGeo,
    buildingsByCountryGeo,
  };
};

// Assembles a tree of CountryGeos > MarketGeos > Buildings
export const assembleNodes = (
  locations: ILocationsMap,
  locationPriceMap: ILocationPriceMap,
  locationAttributesMap: ILocationAttributesRecordMap,
  showCheckBox: boolean,
): ICheckboxTreeNode[] => {
  const { allCountryGeos, marketGeosByCountryGeo, buildingsByMarketGeo, buildingsByCountryGeo } =
    groupBuildingsByGeogrouipings(locations);

  const nodes = allCountryGeos.map(countryGeoNode);
  return compact(nodes);

  function countryGeoNode(countryGeo: IGeogroupingObject): ICheckboxTreeNode | null {
    const childMarketGeos = marketGeosByCountryGeo[countryGeo.id] || [];
    const childMarketGeoNodes = childMarketGeos.map(marketGeoNode);

    const childBuildings = buildingsByCountryGeo[countryGeo.id] || [];
    const childBuildingNodes = childBuildings.map(buildingNode);

    const childNodes = childMarketGeoNodes.concat(childBuildingNodes);
    if (isEmpty(childNodes)) {
      return null;
    }
    return locationToNode(countryGeo, compact(childNodes));
  }

  function marketGeoNode(marketGeo: IGeogroupingObject): ICheckboxTreeNode | null {
    const childBuildings = buildingsByMarketGeo[marketGeo.id] || [];
    const childBuildingNodes = childBuildings.map(buildingNode);

    if (isEmpty(childBuildingNodes)) {
      return null;
    }
    return locationToNode(marketGeo, compact(childBuildingNodes));
  }

  function buildingNode(building: IBuildingObject): ICheckboxTreeNode | null {
    return locationToNode(building);
  }

  function locationToNode(location: ILocationObject, children?: ICheckboxTreeNode[]): ICheckboxTreeNode {
    const { id } = location;
    const title = locationTitle(location);
    const price = locationPriceMap[id];
    const ecommerceAttribute = locationAttributesMap[id];
    return {
      showCheckbox: showCheckBox,
      value: id,
      title,
      label: <LocationLabels title={title} price={price} ecommerceFlag={ecommerceAttribute} />,
      children,
    };
  }
};

export const assembleDiffNodes = (
  locations: ILocationsMap,
  locationPriceDiffMap: IDiffMap<ILocationPrice>,
  locationAttributesDiffMap: IDiffMap<ILocationAttributesRecord>,
  availabilityChanges: Dictionary<ChangeState.ADDED | ChangeState.REMOVED>,
  changedLocationIds: string[],
): ICheckboxTreeNode[] => {
  const { geogroupings, buildings } = locations;
  const changedBuildingMap = keyBy(
    filter(buildings, ({ id }) => changedLocationIds.includes(id)),
    'id',
  );
  const changedMarketgeoIds = filter(
    changedLocationIds,
    (locationId) => geogroupings[locationId] && geogroupings[locationId].type === 'Marketgeo',
  );

  const changedCountrygeogeoIds = filter(
    changedLocationIds,
    (locationId) => geogroupings[locationId] && geogroupings[locationId].type === 'Countrygeo',
  );

  const allBuildings = orderBy(changedBuildingMap, ['is_published', 'is_not_physical', 'code'], ['desc', 'asc', 'asc']);
  const marketgeoIds = uniq(concat(map(allBuildings, 'marketgeo.id'), changedMarketgeoIds));
  const countrygeoIds = uniq(
    concat(
      map(allBuildings, 'countrygeo.id'),
      map(changedMarketgeoIds, (marketgeoId) => geogroupings[marketgeoId].parent.id),
      changedCountrygeogeoIds,
    ),
  );

  const changedGeogroupingIds = concat(marketgeoIds, countrygeoIds);
  const changedGeogroupingMap = keyBy(
    map(changedGeogroupingIds, (geoId) => geogroupings[geoId]),
    'id',
  );

  const geosByType = groupBy(changedGeogroupingMap, 'type');
  const allCountryGeos = filter(orderBy(geosByType.Countrygeo, ['is_published', 'name'], ['desc', 'asc']), ({ id }) =>
    countrygeoIds.includes(id),
  );
  const allMarketGeos = filter(orderBy(geosByType.Marketgeo, ['is_published', 'name'], ['desc', 'asc']), ({ id }) =>
    marketgeoIds.includes(id),
  );
  const marketGeosByCountryGeo = groupBy(allMarketGeos, 'parent.id');

  const marketBuildings = allBuildings.filter((value) => value.marketgeo != null);
  const buildingsByMarketGeo = pickBy(groupBy(marketBuildings, 'marketgeo.id'), (mBuildings) => size(mBuildings) > 0);

  const countryBuildings = allBuildings.filter((value) => value.countrygeo !== null && value.marketgeo == null);
  const buildingsByCountryGeo = groupBy(countryBuildings, 'countrygeo.id');

  const nodes = allCountryGeos.map(countryGeoNode);

  return compact(nodes);

  function countryGeoNode(countryGeo: IGeogroupingObject): ICheckboxTreeNode | null {
    const childMarketGeos = marketGeosByCountryGeo[countryGeo.id] || [];
    const childMarketGeoNodes = childMarketGeos.map(marketGeoNode);

    const childBuildings = buildingsByCountryGeo[countryGeo.id] || [];
    const childBuildingNodes = childBuildings.map((building) => locationToNode(building));

    const childNodes = childMarketGeoNodes.concat(childBuildingNodes);

    return locationToNode(countryGeo, compact(childNodes));
  }

  function marketGeoNode(marketGeo: IGeogroupingObject): ICheckboxTreeNode | null {
    const childBuildings = buildingsByMarketGeo[marketGeo.id] || [];
    const childBuildingNodes = childBuildings.map((building) => locationToNode(building));

    return locationToNode(marketGeo, compact(childBuildingNodes));
  }

  function locationToNode<T extends ILocationObject>(location: T, children?: ICheckboxTreeNode[]): ICheckboxTreeNode {
    const { id } = location;
    const title = locationTitle(location);
    const locationPriceDiff = locationPriceDiffMap[id];
    const locationAttributesDiff = locationAttributesDiffMap[id];
    return {
      showCheckbox: false,
      value: id,
      title,
      label: (
        <LocationDiffLabels
          title={title}
          availabilityChangeState={availabilityChanges[id]}
          priceDiff={locationPriceDiff}
          locationAttributesDiff={locationAttributesDiff}
        />
      ),
      children,
    };
  }
};

export const filterNodes = (nodes: ICheckboxTreeNode[], query: string): ICheckboxTreeNode[] => {
  if (isEmpty(nodes) || isEmpty(query)) {
    return nodes;
  }

  query = query.toLowerCase();
  return nodes.reduce((acc: ICheckboxTreeNode[], node: ICheckboxTreeNode) => {
    // If title matches, show this node and any/all children
    if (node.title && node.title.toLowerCase().includes(query)) {
      acc.push(node);

      // If title does not match, recursively filter children and show if any children match
    } else if (node.children) {
      const children = filterNodes(node.children, query);
      if (children.some((child) => !child.className?.includes(FILTERED_OUT_NODE))) {
        acc.push({ ...node, children });
      } else {
        acc.push({ ...node, className: FILTERED_OUT_NODE, children });
      }
    } else {
      acc.push({ ...node, className: FILTERED_OUT_NODE });
    }
    return acc;
  }, []);
};

export const locationAttributesArrayToMap = (attributes: ILocationAttributesRecord[]): ILocationAttributesRecordMap =>
  keyBy(attributes, 'locationUuid');

export const locationAttributesMapToArray = (
  attributesMap: ILocationAttributesRecordMap,
): ILocationAttributesRecord[] => Object.values(attributesMap);

export const locationPricesArrayToMap = (prices: ILocationPrice[]): ILocationPriceMap => keyBy(prices, 'locationUuid');

export const locationPricesMapToArray = (priceMap: ILocationPriceMap): ILocationPrice[] => Object.values(priceMap);

export const bulkUpdateLocationAttributesPayload = (
  oldAttributes: ILocationAttributesRecordMap,
  newAttributes: ILocationAttributesRecordMap,
): IBulkUpdateLocationAttributesPayload => {
  const createList: ILocationAttributesRecord[] = [];
  const updateList: ILocationAttributesRecord[] = [];

  for (const locationUuid of Object.keys(newAttributes)) {
    const oldEcommerceValue = oldAttributes[locationUuid];
    const newEcommerceValue = newAttributes[locationUuid];
    if (oldEcommerceValue && oldEcommerceValue.id) {
      if (!isEqual(oldEcommerceValue, newEcommerceValue)) {
        updateList.push({ ...newEcommerceValue, id: oldEcommerceValue.id });
      }
    } else {
      createList.push({ ...newEcommerceValue });
    }
  }

  return { createList, updateList };
};

export const bulkUpdateLocationPricesPayload = (
  oldPrices: ILocationPriceMap,
  newPrices: ILocationPriceMap,
): IBulkUpdateLocationPricesPayload => {
  const createList: ILocationPrice[] = [];
  const updateList: ILocationPrice[] = [];
  const deleteList: Array<ILocationPrice['id']> = [];

  // get new prices for create
  // get existing prices for update
  for (const locationUuid of Object.keys(newPrices)) {
    const oldPrice = oldPrices[locationUuid];
    const newPrice = newPrices[locationUuid];
    if (oldPrice && oldPrice.id) {
      if (locationPriceChanged(oldPrice, newPrice)) {
        updateList.push({ ...newPrice, id: oldPrice.id });
      }
    } else {
      createList.push({ ...newPrice });
    }
  }

  // find deletions
  for (const locationUuid in oldPrices) {
    if (!newPrices[locationUuid]) {
      deleteList.push(oldPrices[locationUuid].id);
    }
  }

  return { createList, updateList, deleteList };
};

const locationPriceChanged = (oldPrice: ILocationPrice, newPrice: ILocationPrice): boolean =>
  formatPriceCurrency(oldPrice.price, oldPrice.currency) !== formatPriceCurrency(newPrice.price, newPrice.currency);
