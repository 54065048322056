import * as React from 'react';
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const LOGOUT_ROUTE = 'logout';

export const LogoutPage = (): JSX.Element => {
  const auth0 = useAuth0();

  useEffect(() => {
    if (auth0.isAuthenticated) {
      auth0.logout();
      localStorage.clear();
    }
  }, []);

  return <h3>You are being logged out.</h3>;
};
