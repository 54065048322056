import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Accordion } from 'semantic-ui-react';

import './styles.scss';

interface ICollapsibleProps {
  title: string;
  children: JSX.Element | JSX.Element[];
  isOpenByDefault?: boolean;
}
export const Collapsible = ({ title, children, isOpenByDefault = true }: ICollapsibleProps) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault);
  const handleOnClick = () => setIsOpen((prevState) => !prevState);
  return (
    <Accordion>
      <Accordion.Title active={isOpen} className="collapsible-title" onClick={handleOnClick}>
        <p>{title}</p>
        <FontAwesomeIcon pull="right" icon={isOpen ? faChevronUp : faChevronDown} size="sm" />
      </Accordion.Title>

      <Accordion.Content active={isOpen}>{children}</Accordion.Content>
    </Accordion>
  );
};
