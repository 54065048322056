import { subDays } from 'date-fns';

import { IWorkdaySalesItems } from 'types/productCatalogTypes';
import { getAllWorkdaySalesItems } from 'networking/productCatalog/workdaySalesItemsRequests';
import { IExtendedFetchResult, ResponseStatus } from 'networking/fetchConfig';
import { resetCachedPermissions } from 'hooks/usePermissionsByOperator';

// Singleton class to cache static apis like work day sales items
class SessionStore {
  workdaySalesItems: IWorkdaySalesItems[] = [];
  lastFetchDate: Date | null = null;

  async getSalesItems(): Promise<IExtendedFetchResult<IWorkdaySalesItems[]>> {
    const OneDayAgo = subDays(new Date(), 1);
    if (this.workdaySalesItems.length && this.lastFetchDate && this.lastFetchDate.getTime() > OneDayAgo.getTime()) {
      return { data: this.workdaySalesItems, status: ResponseStatus.OK };
    }

    const resp = await getAllWorkdaySalesItems();
    if (resp.data) {
      this.workdaySalesItems = resp.data;
      this.lastFetchDate = new Date();
    }
    return resp;
  }

  clear(): void {
    this.workdaySalesItems = [];
  }
}
const singleton = new SessionStore();

export function getSalesItems(): Promise<IExtendedFetchResult<IWorkdaySalesItems[]>> {
  return singleton.getSalesItems();
}

export function clear(): void {
  singleton.clear();
  resetCachedPermissions();
}

//currently for testing only
export function setLastFetched(date: Date): void {
  singleton.lastFetchDate = date;
}
