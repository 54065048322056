import { getAvailableBuildings } from 'networking/productCatalog/availableBuildingsRequests';
import { fetchByIds, getAlgoliaIndex } from 'clients/algoliaClient';
import { SETTINGS } from 'configs/settings';
import { getBuildingLocaleCode } from 'configs/locales';
import { IBuildingObject } from 'types/productCatalogTypes';

export async function getLocalesForProduct(productUuid: string): Promise<string[]> {
  const { data } = await getAvailableBuildings(productUuid);
  if (!data) {
    return [];
  }

  const buildings = await fetchByIds<IBuildingObject>(getAlgoliaIndex(SETTINGS.ALGOLIA_BUILDING_INDEX), data);
  return buildings.map(getBuildingLocaleCode);
}
