import { DropdownItemProps } from 'semantic-ui-react';
import Fuse from 'fuse.js';
import compact from 'lodash/compact';

import { IListingGroup, IProduct, IProductGroup, IWorkdaySalesItems, ProductStatus } from 'types/productCatalogTypes';

export const toProductDropdownItemEnableOnlyActive = (obj: IProduct): DropdownItemProps => ({
  key: obj.uuid,
  value: obj.uuid,
  text: obj.name,
  disabled: obj.status !== ProductStatus.ACTIVE,
});

const toProductGroupDropdownItem = ({ uuid, name }: IProductGroup | IListingGroup, indent = 0): DropdownItemProps => ({
  key: uuid,
  value: uuid,
  text: `${'  '.repeat(indent)}${indent ? '- ' : ''}${name}`,
});

export const toListingGroupDropdownItems = (listingGroup: IListingGroup): DropdownItemProps[] => {
  const recursiveListingGroupToDropdownItems = (
    options: DropdownItemProps[],
    innerListingGroup: IListingGroup,
    indent: number,
  ): DropdownItemProps[] => {
    options = [...options, toProductGroupDropdownItem(innerListingGroup, indent)];

    const { groups } = innerListingGroup;
    groups?.forEach((group) => (options = recursiveListingGroupToDropdownItems(options, group, indent + 1)));

    return options;
  };
  return recursiveListingGroupToDropdownItems([], listingGroup, 0);
};

export const toWorkdaySalesItemsDropdownItem = (obj: IWorkdaySalesItems): DropdownItemProps => ({
  key: obj.id,
  value: obj.id,
  text: obj.name,
});

export const salesItemSearchQueryResolver = async (
  salesItems: IWorkdaySalesItems[],
  query: string,
  limit = 10,
): Promise<DropdownItemProps[]> => {
  const fuse = new Fuse(salesItems, {
    keys: ['name'],
  });
  const results = fuse.search(query);
  const items = results.slice(0, limit).map((result) => result.item);
  return items.map(toWorkdaySalesItemsDropdownItem);
};

export const salesItemIdsResolver = async (
  salesItems: IWorkdaySalesItems[],
  ids: string[],
): Promise<DropdownItemProps[]> => {
  const items = ids.map((id) => {
    const item = salesItems.find((item) => item.id === id);
    if (item) return toWorkdaySalesItemsDropdownItem(item);
  });
  return compact(items);
};
