import { Modal } from '@wework/dieter-ui';
import React from 'react';

import { IProductGroup } from 'types/productCatalogTypes';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  group: IProductGroup;
}

export const DeleteGroupModal = ({ open, onClose, onSuccess, group }: Props): JSX.Element => (
  <Modal
    open={open}
    onClose={onClose}
    size="tiny"
    header="Delete Group?"
    content={
      <Modal.Content>
        Are you sure you want to permanently delete <strong>{group.name}</strong> and all of its child groups?
      </Modal.Content>
    }
    actions={[
      { key: 'cancel', content: 'Cancel' },
      { key: 'ok', content: 'Permanently Delete', onClick: onSuccess, color: 'red' },
    ]}
  />
);
