import React, { useEffect, useState } from 'react';

import { Button, CheckboxProps, Divider, Header, Segment, Table } from '@wework/dieter-ui';
import { useApiHook } from 'hooks/useApiHook';
import { getLocationTncs } from 'networking/productCatalog/locationTncRequests';
import { IBuildingObject, IGeogroupingObject, ILocationTnc } from 'types/productCatalogTypes';
import { getAllPublishedBuildings, getAllPublishedGeogroupings } from 'networking/productCatalog/buildingRequests';
import { IEditProductTabCommonProps } from 'pages/products/editProductTabs/editProductTabsConfig';

import { LegalForm } from 'components/products/LegalForm/LegalForm';
import { useFlashMessageContext } from 'contexts/FlashMessageContext';
import { LegalTreeForm } from 'components/products/LegalTree/LegalTreeForm';
import { EditToggle } from 'components/shared/EditToggle/EditToggle';
import { updateTnc } from 'networking/productCatalog/tncRequests';

const EditLegalTab = ({ product, isEditable }: IEditProductTabCommonProps): JSX.Element => {
  // top level product terms & conditions
  const urls = product.tncUrls;
  const urlCount = Object.keys(product.tncUrls).length;
  const { flashError, flashSuccess } = useFlashMessageContext();
  const [geogroupingData, setGeogroupingData] = useState<IGeogroupingObject[]>([]);
  const [publishedBuildingsData, setPublishedBuildingsData] = useState<IBuildingObject[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const handleRemoveUrl = async (keyToRemove: string): Promise<void> => {
    // user can not submit form if product catalog does not selected
    const newTncUrls: { [key: string]: string } = product.tncUrls;
    delete newTncUrls[keyToRemove];
    const { error } = await updateTnc(product.uuid, newTncUrls);

    if (error) {
      flashError(`Error removing legal details: ${error}`);
      return;
    }

    flashSuccess('Legal details saved');
  };

  const geogroupingResponse = useApiHook<IGeogroupingObject[]>(getAllPublishedGeogroupings, [], []);
  const publishedBuildingsResponse = useApiHook<IBuildingObject[]>(getAllPublishedBuildings, [], []);
  const {
    data: locationTncs,
    loading: locationTncsLoading,
    refresh: refreshlocationTncs,
  } = useApiHook<ILocationTnc[]>(() => getLocationTncs(product), [product.uuid], []);

  useEffect(() => {
    setGeogroupingData(geogroupingResponse.data || []);
  }, [geogroupingResponse.data]);

  useEffect(() => {
    setPublishedBuildingsData(publishedBuildingsResponse.data || []);
  }, [publishedBuildingsResponse.data]);

  return (
    <>
      <Header as="h3">Terms and Conditions URLs</Header>
      <EditToggle
        disabled={!isEditable}
        isEdit={isEdit}
        onChange={({ checked }: CheckboxProps): void => setIsEdit(Boolean(checked))}
      />
      {urlCount === 0 && <span>No urls added.</span>}
      <Table>
        <Table.Body>
          {Object.keys(urls).map((key) => {
            return (
              <Table.Row key={key}>
                <Table.Cell verticalAlign="middle">
                  {key}: {urls[key]}
                </Table.Cell>
                <Table.Cell verticalAlign="middle">
                  <Button
                    color="red"
                    icon="x"
                    floated="right"
                    data-test-id={`remove-button-${key}`}
                    disabled={!isEdit}
                    onClick={(): Promise<void> => handleRemoveUrl(key)}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <Segment>
        <Header as="h3">Add terms to this product</Header>
        <LegalForm product={product} disabled={!isEdit} />
      </Segment>

      <Divider />

      <Header as="h3">Location Specific Terms and Conditions URLs</Header>
      <LegalTreeForm
        loading={locationTncsLoading || geogroupingResponse.loading || publishedBuildingsResponse.loading}
        isEditable={isEdit}
        product={product}
        locationTncs={locationTncs || []}
        geogroupings={geogroupingData}
        buildings={publishedBuildingsData}
        onLocationTncsSave={refreshlocationTncs}
      />
    </>
  );
};

export { EditLegalTab };
