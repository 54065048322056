import { IFetchResult, RequestMethods } from 'networking/fetchConfig';
import { FeeKind, ReservableType, SpacemanFee } from 'types/spacemanTypes';
import { spacemanFetch } from 'networking/spaceman/spacemanFetch';

export interface ICreateFeeRequest {
  location_uuid: string;
  kind: FeeKind;
  name: string;
  rate: string;
}

export interface IUpdateFeeRequest {
  kind: FeeKind;
  name: string;
  rate: string;
  reservable_types?: ReservableType[];
  resource_types?: string[];
  product_uuids?: string[];
}

interface SpacemanFeeResponse {
  meta: {
    success: boolean;
  };
  result: {
    data?: SpacemanFee;
    error?: string;
  };
}

export const createFee = (createFeeRequest: ICreateFeeRequest): Promise<IFetchResult<SpacemanFeeResponse>> =>
  spacemanFetch<SpacemanFeeResponse>(RequestMethods.POST, '/api/v5/fees', createFeeRequest);

export const updateFee = (
  feeId: number,
  updateFeeRequest: IUpdateFeeRequest,
): Promise<IFetchResult<SpacemanFeeResponse>> =>
  spacemanFetch<SpacemanFeeResponse>(RequestMethods.PUT, `/api/v5/fees/${feeId}`, updateFeeRequest);
