import { SemanticWIDTHS } from 'semantic-ui-react/src/generic';

interface IProductsTableRowConfigItem {
  key: string;
  heading: string;
  columnWidth: SemanticWIDTHS;
  format: ProductsTableFormats;
}

enum ProductsTableFormats {
  STRING = 'STRING',
  LINK = 'LINK',
  TEXT = 'TEXT',
  EXPANDABLE_TEXT = 'EXPANDABLE_TEXT',
  DATE = 'DATE',
  PRICE_CURRENCY = 'PRICE_CURRENCY',
}

const ProductsTableRowConfig: IProductsTableRowConfigItem[] = [
  {
    key: 'name',
    heading: 'Name',
    columnWidth: 2,
    format: ProductsTableFormats.LINK,
  },
  {
    key: 'saleItemGroup',
    heading: 'Sale Item Group',
    columnWidth: 2,
    format: ProductsTableFormats.STRING,
  },
  {
    key: 'status',
    heading: 'Status',
    columnWidth: 1,
    format: ProductsTableFormats.STRING,
  },
  {
    key: 'description',
    heading: 'Description',
    columnWidth: 5,
    format: ProductsTableFormats.EXPANDABLE_TEXT,
  },
];

export { IProductsTableRowConfigItem, ProductsTableFormats, ProductsTableRowConfig };
