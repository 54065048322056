import { stringify } from 'query-string';

import { IExtendedFetchResult, RequestMethods } from 'networking/fetchConfig';
import { ILocationPrice, IProduct, LocationPriceLocationType } from 'types/productCatalogTypes';

import { productCatalogFetch } from './productCatalogFetch';

export function getLocationPrices(product: IProduct): Promise<IExtendedFetchResult<ILocationPrice[]>> {
  return productCatalogFetch<ILocationPrice[]>(RequestMethods.GET, `/v2/products/${product.uuid}/location_price`);
}

export function getLocationPricesByLocationTypeAndLocationUuid(
  locationType: LocationPriceLocationType,
  locationUuid: string,
  catalogUuid: string,
): Promise<IExtendedFetchResult<ILocationPrice[]>> {
  const query = stringify({ catalog_uuid: catalogUuid });
  return productCatalogFetch<ILocationPrice[]>(
    RequestMethods.GET,
    `/v2/locations/${locationType}/${locationUuid}/location_prices?${query}`,
  );
}

export interface IBulkUpdateLocationPricesPayload {
  createList: ILocationPrice[];
  updateList: ILocationPrice[];
  deleteList: Array<ILocationPrice['id']>;
}

export function bulkUpdateLocationPrices(
  product: IProduct,
  payload: IBulkUpdateLocationPricesPayload,
): Promise<IExtendedFetchResult<ILocationPrice[]>> {
  return productCatalogFetch<ILocationPrice[]>(
    RequestMethods.PUT,
    `/v2/products/${product.uuid}/location_price`,
    payload,
  );
}

export const createLocationPrice = (
  productUuid: string,
  price: ILocationPrice,
): Promise<IExtendedFetchResult<ILocationPrice>> =>
  productCatalogFetch<ILocationPrice>(RequestMethods.POST, `/v2/products/${productUuid}/location_price`, price);

export const updateLocationPrice = (
  productUuid: string,
  price: ILocationPrice,
): Promise<IExtendedFetchResult<ILocationPrice>> =>
  productCatalogFetch<ILocationPrice>(
    RequestMethods.PUT,
    `/v2/products/${productUuid}/location_price/${price.id}`,
    price,
  );

export const deleteLocationPrice = (productUuid: string, price: ILocationPrice): Promise<IExtendedFetchResult<null>> =>
  productCatalogFetch<null>(RequestMethods.DELETE, `/v2/products/${productUuid}/location_price/${price.id}`);
