import * as Yup from 'yup';
import { DropdownItemProps } from 'semantic-ui-react';

import { IProductFormInputFieldConfig } from 'types/forms';
import { FormInputType } from 'components/shared/Form/FormConfig';
import { formatPercent } from 'utils/displayHelpers';
import { divideBy100 } from 'utils/transformHelpers';
import { FeeKind } from 'types/spacemanTypes';

export const schema = Yup.object().shape({
  kind: Yup.string().required('Please choose a kind for the fee.'),
  name: Yup.string().required('Please enter a name for the fee.'),
  rate: Yup.string().transform(divideBy100).required('Please enter a rate for the fee.'),
});

export interface IFeeFormValues {
  kind: string;
  name: string;
  rate: string;
  base?: string;
}

export enum CreateFeeFormFields {
  KIND = 'kind',
  NAME = 'name',
  RATE = 'rate',
}

export const createFeeFormInputConfig: { [key in CreateFeeFormFields]: IProductFormInputFieldConfig } = {
  [CreateFeeFormFields.NAME]: {
    inputType: FormInputType.TEXT,
    labelText: 'Fee Name',
    name: CreateFeeFormFields.NAME,
    placeholder: 'Enter a fee name',
  },
  [CreateFeeFormFields.KIND]: {
    inputType: FormInputType.SELECT,
    labelText: 'Fee Kind',
    name: CreateFeeFormFields.KIND,
    placeholder: 'Choose a fee kind',
  },
  [CreateFeeFormFields.RATE]: {
    inputType: FormInputType.NUMBER,
    labelText: 'Fee Rate (%)',
    name: CreateFeeFormFields.RATE,
    placeholder: 'Enter a fee rate',
    inboundTransform: formatPercent,
  },
};

export const feeKindOptions = Object.entries(FeeKind).map(
  ([name, value]): DropdownItemProps => ({
    key: value,
    value,
    text: name,
  }),
);
