import React from 'react';
import { Container, Divider } from '@wework/dieter-ui';

export const NO_AVAILABLE_CATALOGS_ROUTE = 'no-available-catalogs';
const helpCenterLink = 'https://jira.weworkers.io/plugins/servlet/desk/site/0';

export const NoAvailableCatalogsPage = (): JSX.Element => {
  return (
    <Container size={'massive'}>
      <Divider hidden />
      <h3>{'To use Alchemist you should have at least one assigned catalog.'}</h3>
      <a href={helpCenterLink} target="_blank" rel="noreferrer">
        WeWork Help Center
      </a>
    </Container>
  );
};
