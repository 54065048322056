import React from 'react';

import { LoadingContainer } from 'components/shared/Loading/LoadingContainer';
import { BuildingsTree } from 'components/buildings/BuildingsTree';
import { getAllPublishedBuildings, getAllPublishedGeogroupings } from 'networking/productCatalog/buildingRequests';
import { IBuildingObject, IGeogroupingObject } from 'types/productCatalogTypes';
import { useApiHook } from 'hooks/useApiHook';

export const BuildingsPage = (): JSX.Element => {
  const geogroupingResponse = useApiHook<IGeogroupingObject[]>(getAllPublishedGeogroupings, []);
  const publishedBuildingsResponse = useApiHook<IBuildingObject[]>(getAllPublishedBuildings, []);

  return (
    <LoadingContainer loading={geogroupingResponse.loading || publishedBuildingsResponse.loading}>
      <>
        <h3>Buildings</h3>
        <BuildingsTree
          geogroupings={geogroupingResponse.data ?? []}
          buildings={publishedBuildingsResponse.data ?? []}
        />
      </>
    </LoadingContainer>
  );
};
