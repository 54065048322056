import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import { IProductGroup } from 'types/productCatalogTypes';
import { getGroup } from 'networking/productCatalog/groupRequests';
import { LoadingContainer } from 'components/shared/Loading/LoadingContainer';
import { GroupsTable } from 'components/groups/GroupsTable';
import { useCachedCollection } from 'hooks/useCachedCollection';
import { BaseUrlParamsType } from 'types/router';

interface IGroupsListInputProps {
  groupUuids: string[];
  editable?: boolean;
  deliteble?: boolean;
  onChange?: (groupUuids: string[]) => void;
}

const GroupsListInput = ({ groupUuids, editable, deliteble, onChange }: IGroupsListInputProps): JSX.Element => {
  const [error, setError] = useState<string | undefined>(undefined);
  const { catalogUuid } = useParams() as BaseUrlParamsType;

  const groupLoader = async (uuid: string): Promise<IProductGroup | undefined> => {
    const { data, error } = await getGroup(uuid, catalogUuid);
    if (error || !data) {
      setError(error || 'Failed to retrieve group details');
    }
    return data;
  };

  const [groups, loading] = useCachedCollection<IProductGroup>(groupLoader, groupUuids);

  return (
    <LoadingContainer loading={loading} data={groups} error={error}>
      <GroupsTable groups={groups} editable={editable} deliteble={deliteble} onChange={onChange} />
    </LoadingContainer>
  );
};

export { GroupsListInput };
