import { Button, Form, Modal } from '@wework/dieter-ui';
import { Form as FormikForm, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';

import { formCurrencyConfig, FormInputType, FormValue } from 'components/shared/Form/FormConfig';
import { handleChange } from 'components/shared/Form/utils';
import Loading from 'components/shared/Loading';
import { ILocationPrice } from 'types/productCatalogTypes';
import { LocationPriceFormSchema } from './locationPriceFormConfig';
import { LocationPriceFormPriceInput, LocationPriceFormSelectInput } from './LocationPriceFormInputs';

interface ILocationPriceFormProps {
  locationPrice: ILocationPrice;
  disabled?: boolean;
  onSave: (price: ILocationPrice) => void;
  onDelete: (price: ILocationPrice) => void;
  onClose: () => void;
}

export const LocationPriceForm = ({
  locationPrice,
  disabled,
  onSave,
  onDelete,
  onClose,
}: ILocationPriceFormProps): JSX.Element => {
  const [formPrice, setFormPrice] = useState<ILocationPrice | null>(null);

  const isNew = isEmpty(locationPrice.price);

  useEffect(() => {
    const formattedPrice = {
      ...locationPrice,
      price: isNew ? '' : locationPrice.price,
    };
    setFormPrice(formattedPrice);
  }, [locationPrice]);

  // This form does not submit prices to the API
  const handleSubmit = (values: ILocationPrice): void => {
    const castPrices = LocationPriceFormSchema.cast(values) as ILocationPrice;
    onSave(castPrices);
  };

  if (!formPrice) {
    return <Loading />;
  }

  return (
    <>
      <Formik initialValues={formPrice} validationSchema={LocationPriceFormSchema} onSubmit={handleSubmit}>
        {({ handleSubmit: formikHandleSubmit, setFieldValue, setFieldTouched, values, dirty }): JSX.Element => {
          const isValid = LocationPriceFormSchema.isValidSync(values);
          return (
            <>
              <Modal.Content>
                <Form as={FormikForm} onSubmit={formikHandleSubmit} className="location-price-modal--form">
                  <LocationPriceFormPriceInput
                    handleOnChange={(name, value): void => {
                      handleChange<FormValue>(name, value, setFieldValue, setFieldTouched);
                    }}
                    name="price"
                    inputType={FormInputType.TEXT}
                    placeholder="Price"
                    disabled={disabled}
                  />
                  <LocationPriceFormSelectInput
                    options={formCurrencyConfig}
                    handleOnChange={(name, value): void => {
                      handleChange<FormValue>(name, value, setFieldValue, setFieldTouched);
                    }}
                    name="currency"
                    inputType={FormInputType.SELECT}
                    placeholder="Currency"
                    disabled={disabled}
                  />
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <>
                  <Button onClick={onClose}>Cancel</Button>
                  {isNew || (
                    <Button negative disabled={disabled} onClick={(): void => onDelete(locationPrice)}>
                      Clear Price
                    </Button>
                  )}
                  <Button primary disabled={disabled || !dirty || !isValid} onClick={(): void => handleSubmit(values)}>
                    Set Price
                  </Button>
                </>
              </Modal.Actions>
            </>
          );
        }}
      </Formik>
    </>
  );
};
