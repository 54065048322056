import { ILocationListingGroup, LocationPriceLocationType } from 'types/productCatalogTypes';
import {
  getListingsForGroupInBuilding,
  GetListingsForGroupInBuildingParams,
} from 'networking/productCatalog/listingRequests';
import { getProductsAvailableInLocation } from 'networking/productCatalog/buildingRequests';
import { listingGroupToLocationListingGroup } from 'types/mappers';
import { IExtendedFetchResult, ResponseStatus } from 'networking/fetchConfig';

export const getListingsWithAvailability = async (
  groupUuidOrSlug: string,
  buildingUuid: string,
  catalogUuid: string,
  params: GetListingsForGroupInBuildingParams = {},
): Promise<IExtendedFetchResult<ILocationListingGroup>> => {
  const [{ data: listingGroup, error: listingError }, { data: availableProductIds, error: availableProductsError }] =
    await Promise.all([
      getListingsForGroupInBuilding(groupUuidOrSlug, buildingUuid, catalogUuid, params),
      getProductsAvailableInLocation(buildingUuid, catalogUuid, LocationPriceLocationType.BUILDING),
    ]);

  const error = [listingError, availableProductsError].filter((error) => !!error).join(', ');

  if (error || !listingGroup) {
    return {
      error,
      data: undefined,
      status: ResponseStatus.NOT_FOUND,
    };
  }

  return {
    data: listingGroupToLocationListingGroup(listingGroup, new Set(availableProductIds)),
    status: ResponseStatus.OK,
  };
};
