import { IExtendedFetchResult, RequestMethods } from 'networking/fetchConfig';
import { IWorkdaySalesItems } from 'types/productCatalogTypes';
import { productCatalogFetch } from './productCatalogFetch';

export function getAllWorkdaySalesItems(): Promise<IExtendedFetchResult<IWorkdaySalesItems[]>> {
  return productCatalogFetch<IWorkdaySalesItems[]>(RequestMethods.GET, '/v2/sales_items');
}

export function clearAllWorkdaySalesItems(): Promise<IExtendedFetchResult<null>> {
  return productCatalogFetch<null>(RequestMethods.DELETE, '/v2/sales_items');
}
