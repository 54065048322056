import { Message } from '@wework/dieter-ui';
import React, { useState } from 'react';

import { FlashMessageContext } from 'contexts/FlashMessageContext';
import './flashMessageWrapper.scss';

enum FlashMessageType {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
}

interface IFlashMessage {
  id: string;
  type: FlashMessageType;
  text: string;
  fadeOut?: boolean;
}

interface FlashMessageWrapperProps {
  children: JSX.Element;
}

let idIncrement = 0;
const newMessageId = (): string => (++idIncrement).toString();

const FLASH_TIMEOUT = 15_000;

// This should match the duration of .alchemist-fade-out
const FADE_OUT_DURATION = 200;

const startFadeOut = (messages: IFlashMessage[], id: string): IFlashMessage[] =>
  messages.map((message) => (message.id === id ? { ...message, fadeOut: true } : message));

const removeMessage = (messages: IFlashMessage[], id: string): IFlashMessage[] =>
  messages.filter((message) => message.id !== id);

export const FlashMessageWrapper = ({ children }: FlashMessageWrapperProps): JSX.Element => {
  const [messages, setMessages] = useState<IFlashMessage[]>([]);

  const addFlash = (text: string, type: FlashMessageType): void => {
    const id = newMessageId();
    const newMessage = { text, type, id };
    setMessages((currentMessages) => [...currentMessages, newMessage]);
    setTimeout(() => remove(id), FLASH_TIMEOUT);
  };

  const remove = (id: string): void => {
    setMessages((messages) => startFadeOut(messages, id));
    setTimeout(() => {
      setMessages((messages) => removeMessage(messages, id));
    }, FADE_OUT_DURATION);
  };

  const contextValue = {
    flashInfo: (text: string): void => addFlash(text, FlashMessageType.INFO),
    flashSuccess: (text: string): void => addFlash(text, FlashMessageType.SUCCESS),
    flashError: (text: string): void => addFlash(text, FlashMessageType.ERROR),
  };

  return (
    <FlashMessageContext.Provider value={contextValue}>
      {children}

      {messages.length > 0 && (
        <div className="flash-message-wrapper--container">
          {messages.map(({ id, type, text, fadeOut }) => (
            <Message
              key={id}
              className={fadeOut ? 'alchemist-fade-out' : 'alchemist-fade-in'}
              onDismiss={(): void => remove(id)}
              {...{ [type]: true }}
            >
              {text}
            </Message>
          ))}
        </div>
      )}
    </FlashMessageContext.Provider>
  );
};
