import compact from 'lodash/compact';
import { OperatorServicePermissionsMap } from '@wework/we-auth-react';

import { ILocationPrice, LocationPriceLocationType } from 'types/productCatalogTypes';
import { BUILDING_AVAILABILITY_PERMISSIONS, hasAtLeastOnePermission, PRICING_PERMISSIONS } from 'utils/auth/authUtils';
import { updateBuildingAvailability, updateLocationPrice } from 'networking/productCatalog/buildingRequests';
import { IExtendedFetchResult, ResponseStatus } from 'networking/fetchConfig';

export interface IUpdateAvailabilityAndPricingRequest {
  locationUuid: string;
  productUuid: string;
  locationType: LocationPriceLocationType;
  currency: string;
  price: string;
  available: boolean;
}

export const updateAvailabilityAndPricing = async (
  updateRequest: IUpdateAvailabilityAndPricingRequest,
  requestedPerms: OperatorServicePermissionsMap,
): Promise<IExtendedFetchResult<IUpdateAvailabilityAndPricingRequest>> => {
  const { locationType, available, price, currency, locationUuid, productUuid } = updateRequest;
  const locationPrice = {
    price: price,
    currency: currency,
    locationUuid: locationUuid,
    locationType: locationType,
  };

  const hasBuildingAvailabilityPerm = hasAtLeastOnePermission(requestedPerms, BUILDING_AVAILABILITY_PERMISSIONS);
  const hasPricingPerm = hasAtLeastOnePermission(requestedPerms, PRICING_PERMISSIONS);

  const getAvailabilityResult = (): Promise<IExtendedFetchResult<boolean | undefined>> =>
    hasBuildingAvailabilityPerm
      ? updateBuildingAvailability(productUuid, locationUuid, available)
      : Promise.resolve({ data: available, status: ResponseStatus.OK });

  const getLocationPriceResult = (): Promise<IExtendedFetchResult<ILocationPrice>> =>
    hasPricingPerm
      ? updateLocationPrice(productUuid, locationPrice)
      : Promise.resolve({ data: locationPrice, status: ResponseStatus.OK });

  const [{ data: availabilityData, error: availabilityError }, { data: locationPriceData, error: locationPriceError }] =
    await Promise.all([getAvailabilityResult(), getLocationPriceResult()]);

  const errors = compact([availabilityError, locationPriceError]);

  return {
    data: {
      available: !!availabilityData,
      locationType: locationPriceData?.locationType ?? locationType,
      locationUuid: locationPriceData?.locationUuid ?? locationUuid,
      currency: locationPriceData?.currency ?? currency,
      price: locationPriceData?.price ?? price,
      productUuid: productUuid,
    },
    error: errors.length ? errors.join(', ') : undefined,
    status: ResponseStatus.OK,
  };
};
