import { Form as FormikForm, Formik, FormikHelpers as FormikActions } from 'formik';
import { Form, Header, Segment } from '@wework/dieter-ui';
import React, { useState } from 'react';
import * as Yup from 'yup';
import compact from 'lodash/compact';
import difference from 'lodash/difference';
import { OperatorServicePermissionsMap } from '@wework/we-auth-react';

import { isEmpty } from 'lodash';
import { FormInputType } from 'components/shared/Form/FormConfig';
import { IProduct, IProductGroup, ProductStatus } from 'types/productCatalogTypes';
import { ProductFormTextInput } from 'components/shared/ProductForm/ProductFormInputs';
import { GroupsListInput } from 'components/groups/GroupsListInput';
import { ProductsListInput } from 'components/products/ProductsListInput';
import { FormActionDrawer } from 'components/shared/FormActionDrawer/FormActionDrawer';
import { formatErrors, handleChange } from 'components/shared/Form/utils';
import {
  appendGroupProducts,
  deleteGroup,
  deleteGroupProducts,
  getGroup,
  updateGroup,
} from 'networking/productCatalog/groupRequests';
import { useFlashMessageContext } from 'contexts/FlashMessageContext';
import { Autocomplete } from 'components/shared/Autocomplete/Autocomplete';
import { getProducts } from 'networking/productCatalog/productRequests';
import { CreateGroupModal } from 'components/groups/CreateGroup/CreateGroupModal';
import { IExtendedFetchResult, IFetchResult } from 'networking/fetchConfig';
import { toProductDropdownItemEnableOnlyActive } from 'components/shared/ProductForm/productFormHelpers';
import { AlchemistEmployeePermission } from 'utils/auth/authUtils';
import { PageHeader } from 'components/shared/PageHeader/PageHeader';
import { EditToggle } from 'components/shared/EditToggle/EditToggle';
import { DeleteGroupModal } from './DeleteGroupModal';

import 'components/groups/GroupForm/groupForm.scss';

interface IGroupFormProps {
  group: IProductGroup;
  catalogUuid: string;
  requestedPerms: OperatorServicePermissionsMap;
  onSave: (group: IProductGroup) => void;
  onDelete: () => void;
}

const GroupFormSchema = Yup.object().shape({
  name: Yup.string().required('Please enter a name for the group.'),
  uuid: Yup.string(),
  slug: Yup.string(),
  description: Yup.string(),
  parentUuid: Yup.string(),
  groups: Yup.array(Yup.string()),
  products: Yup.array(Yup.string()),
});

const GroupForm = ({ group, catalogUuid, requestedPerms, onSave, onDelete }: IGroupFormProps): JSX.Element => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { flashError, flashSuccess } = useFlashMessageContext();

  const hasAdminPerm = requestedPerms[AlchemistEmployeePermission.alchemist_administration];
  const hasEditGroupPerm = requestedPerms[AlchemistEmployeePermission.alchemist_groups_management];
  const isEditable = hasAdminPerm || hasEditGroupPerm;
  const isGroupFieldsEditable = isEdit && hasAdminPerm;

  const handleSubmit = async (values: IProductGroup, action: FormikActions<IProductGroup>): Promise<void> => {
    action.setSubmitting(true);
    if (hasAdminPerm) {
      await performAdministrationSubmit(values, action);
    } else if (hasEditGroupPerm) {
      await performGroupsManagementSubmit(values);
    }
    action.setSubmitting(false);
  };

  const handleDelete = async (): Promise<void> => {
    setIsDeleting(true);

    const { error } = await deleteGroup(group.uuid, { cascadeToChildGroups: true });

    if (error) {
      flashError(`Error deleting ${group.name}: ${error}`);
      setIsDeleting(false);
      return;
    }

    flashSuccess(`Group ${group.name} has been deleted`);
    onDelete();
  };

  const deleteRemovedChildGroups = async (newChildGroups: string[]): Promise<IExtendedFetchResult<null>[]> => {
    const childGroupsToDelete = difference(group.groups, newChildGroups);
    const deletePromises = childGroupsToDelete.map((uuid) => deleteGroup(uuid, { cascadeToChildGroups: true }));
    return Promise.all(deletePromises);
  };

  const performGroupsManagementSubmit = async (values: IProductGroup): Promise<void> => {
    const castValues = GroupFormSchema.cast(values) as IProductGroup;
    const newChildProducts = castValues.products;
    const childProductsToDelete = difference(group.products, newChildProducts);
    const childProductsToAdd = difference(newChildProducts, group.products);
    if (!isEmpty(childProductsToDelete)) {
      const { error } = await deleteGroupProducts(group.uuid, childProductsToDelete, catalogUuid);
      error && flashError(error);
    }
    if (!isEmpty(childProductsToAdd)) {
      const { error } = await appendGroupProducts(group.uuid, childProductsToAdd, catalogUuid);
      error && flashError(error);
    }

    if (!isEmpty(childProductsToDelete) || !isEmpty(childProductsToAdd)) {
      const { data } = await getGroup(group.uuid, catalogUuid);
      if (data) {
        flashSuccess(`"${data?.name}" saved`);
        onSave(data);
      }
    }
  };

  const performAdministrationSubmit = async (
    values: IProductGroup,
    action: FormikActions<IProductGroup>,
  ): Promise<void> => {
    const castValues = GroupFormSchema.cast(values) as IProductGroup;
    // first delete any removed child groups
    const deleteResults = await deleteRemovedChildGroups(castValues.groups);
    const deleteErrors = compact(deleteResults.map((res) => res.error));

    if (deleteErrors.length > 0) {
      flashError(`Error removing child groups: ${deleteErrors.join('; ')}`);
      return;
    }

    // update group state
    const { data, error, errorData } = await updateGroup(group.uuid, castValues);
    if (errorData) {
      if (errorData.fieldErrors) {
        const errors = formatErrors<IProductGroup>(errorData.fieldErrors);
        action.setErrors(errors);
      }

      error && flashError(error);
      return;
    }

    if (data) {
      const { name } = data;
      flashSuccess(`"${name}" saved`);
      onSave(data);
    }
  };

  // TODO: explore refactoring ProductFormInput widgets to generic FormInput widgets
  //  which can also take in any React component as input
  return (
    <>
      <Formik
        initialValues={group}
        enableReinitialize={true}
        validationSchema={GroupFormSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit: formikHandleSubmit,
          values,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          resetForm,
          submitForm,
        }): JSX.Element => (
          <>
            <PageHeader header={group.name}>
              <EditToggle
                data-test-id="edit_toggle"
                disabled={!isEditable}
                isEdit={isEdit}
                onChange={(): void => {
                  setIsEdit((prevState) => !prevState);
                  resetForm();
                }}
              />
            </PageHeader>
            <Form as={FormikForm} onSubmit={formikHandleSubmit}>
              <Segment>
                <Header as="h3">Description</Header>

                <ProductFormTextInput
                  name="name"
                  labelText="Name"
                  inputType={FormInputType.TEXT}
                  handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                  disabled={!isGroupFieldsEditable}
                />
                <ProductFormTextInput
                  name="slug"
                  labelText="Slug"
                  inputType={FormInputType.TEXT}
                  handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                  disabled={!isGroupFieldsEditable}
                />
                <ProductFormTextInput
                  name="description"
                  labelText="Description"
                  inputType={FormInputType.TEXT}
                  handleOnChange={(name, value): void => handleChange(name, value, setFieldValue, setFieldTouched)}
                  disabled={!isGroupFieldsEditable}
                />
              </Segment>

              <Segment>
                <Header as="h3">Parent Group</Header>
                <GroupsListInput data-test-id="group_parent" groupUuids={compact([values.parentUuid])} />
              </Segment>

              <Segment>
                <div className="group-form__child-group-header">
                  <Header as="h3">Child Groups</Header>
                  <CreateGroupModal
                    disabled={!(hasAdminPerm && isEdit)}
                    existingGroups={[group]}
                    parentGroup={group}
                    onSuccess={(groupUuid: string): void =>
                      handleChange('groups', [...values.groups, groupUuid], setFieldValue, setFieldTouched)
                    }
                  />
                </div>
                <GroupsListInput
                  data-test-id="group_groups"
                  groupUuids={values.groups}
                  editable={isEdit}
                  deliteble={isGroupFieldsEditable}
                  onChange={(ids): void => handleChange('groups', ids, setFieldValue, setFieldTouched)}
                />
              </Segment>

              <Segment>
                <div className="group-form__child-group-header">
                  <Header as="h3">Child Products</Header>
                  <Autocomplete
                    disabled={!isEdit}
                    data-test-id="products_autocomplete"
                    placeholder="Select Product"
                    pointing="top right"
                    skipKeys={values.products}
                    entityToOption={toProductDropdownItemEnableOnlyActive}
                    fetchFunc={(): Promise<IFetchResult<IProduct[]>> =>
                      getProducts({
                        status: `${ProductStatus.ACTIVE},${ProductStatus.PENDING}`,
                        catalog_uuid: catalogUuid,
                      })
                    }
                    onSelectionChange={(id: string): void =>
                      handleChange('products', [...values.products, id], setFieldValue, setFieldTouched)
                    }
                  />
                </div>
                <ProductsListInput
                  data-test-id="group_products"
                  productUuids={values.products}
                  editable={isEdit}
                  onChange={(ids): void => handleChange('products', ids, setFieldValue, setFieldTouched)}
                />
              </Segment>

              {isEdit && (
                <FormActionDrawer
                  actions={[
                    {
                      key: 'cancel',
                      content: 'Cancel',
                      onClick: (): void => {
                        setIsEdit((prevState) => !prevState);
                        resetForm();
                      },
                      disabled: isSubmitting || isDeleting,
                    },
                    {
                      key: 'delete',
                      content: 'Delete',
                      onClick: (): void => setOpenDeleteModal(true),
                      color: 'red',
                      disabled: isSubmitting || !hasAdminPerm,
                      loading: isDeleting,
                      'data-test-id': 'delete_btn',
                    },
                    {
                      key: 'submit',
                      content: 'Submit',
                      onClick: submitForm,
                      primary: true,
                      disabled: isDeleting,
                      loading: isSubmitting,
                      'data-test-id': 'submit',
                    },
                  ]}
                />
              )}

              <DeleteGroupModal
                open={openDeleteModal}
                onClose={(): void => setOpenDeleteModal(false)}
                group={group}
                onSuccess={handleDelete}
              />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export { GroupForm };
