import { Card } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

import { IProduct } from 'types/productCatalogTypes';
import { LocaleWithCountry } from 'configs/locales';
import { ProductFormTextInput, ProductFormTextareaInput } from 'components/shared/ProductForm/ProductFormInputs';
import { FormInputType } from 'components/shared/Form/FormConfig';
import { ProductFormValue } from 'components/shared/ProductForm/productFormConfig';

interface ILanguageCardProps {
  locale: LocaleWithCountry;
  values: IProduct;
  recommendedLocaleCodeSet: Set<string>;
  disabled?: boolean;
  onChange: (name: string, value: ProductFormValue) => void;
}

export function LanguageCard({
  locale,
  values,
  recommendedLocaleCodeSet,
  disabled,
  onChange,
}: ILanguageCardProps): JSX.Element {
  const { code, name, countryName, countryFlag } = locale;

  return (
    <Card key={code} color={cardColor(code, values)}>
      <Card.Content>
        {isRecommended(code) &&
          (isPresent(code, values) ? (
            <FontAwesomeIcon icon={faCheckCircle} size="lg" pull="right" color="green" />
          ) : (
            <FontAwesomeIcon icon={faExclamationTriangle} size="lg" pull="right" color="orange" />
          ))}
        <Card.Header>{name}</Card.Header>
        <Card.Meta>
          {countryName}
          <span>{countryFlag}</span>
          <span className="right floated">{code}</span>
        </Card.Meta>
        <ProductFormTextInput
          inputType={FormInputType.TEXT}
          name={`nameTranslations.${code}`}
          handleOnChange={onChange}
          labelText="Name"
          disabled={disabled}
        />
        <ProductFormTextareaInput
          inputType={FormInputType.TEXTAREA}
          name={`descriptionTranslations.${code}`}
          handleOnChange={onChange}
          labelText="Description"
          disabled={disabled}
        />
      </Card.Content>
    </Card>
  );

  function isRecommended(localeCode: string): boolean {
    return recommendedLocaleCodeSet.has(localeCode);
  }

  function cardColor(localeCode: string, values: IProduct): 'green' | 'orange' | undefined {
    if (isRecommended(localeCode)) {
      return isPresent(localeCode, values) ? 'green' : 'orange';
    }
  }

  function isPresent(localeCode: string, values: IProduct): boolean {
    return !!(values.nameTranslations[localeCode] && values.descriptionTranslations[localeCode]);
  }
}
