import { Input, Message } from '@wework/dieter-ui';
import React, { useEffect, useState } from 'react';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { isEmpty, isEqual } from 'lodash';

import { assembleLocationsMap, ILocationsMap } from 'types/mappers';
import { useFlashMessageContext } from 'contexts/FlashMessageContext';
import { useCopyEffect } from 'hooks/useCopyEffect';
import {
  IBuildingObject,
  IGeogroupingObject,
  ILocationObject,
  ILocationTnc,
  IProduct,
  LocationTncLocationType,
} from 'types/productCatalogTypes';
import { LocationTncModal } from 'components/shared/LocationTncForm/LocationTncModal';
import { WeCheckboxTree } from 'components/shared/WeCheckboxTree/WeCheckboxTree';
import { bulkUpdateLocationTncs } from 'networking/productCatalog/locationTncRequests';
import { FormActionDrawer } from 'components/shared/FormActionDrawer/FormActionDrawer';
import { LoadingContainer } from 'components/shared/Loading/LoadingContainer';
import { IExtendedFetchResult } from 'networking/fetchConfig';
import {
  assembleExpandedIds,
  assembleNodes,
  bulkUpdateLocationTncsPayload,
  ICheckboxTreeNode,
  ILocationTncMap,
  locationTncsArrayToMap,
  locationTncsMapToArray,
} from './LegalTreeFormUtils';

import './LegalTreeForm.scss';
//TODO move these to a shared util
import { filterNodes } from '../../shared/AvailabilityForm/availabilityFormUtils';

interface ILegalTreeFormProps {
  product: IProduct;
  locationTncs: ILocationTnc[];
  geogroupings: IGeogroupingObject[];
  buildings: IBuildingObject[];
  loading: boolean;
  isEditable?: boolean;
  onLocationTncsSave: (tncs: ILocationTnc[]) => void;
}

export const LegalTreeForm = ({
  product,
  locationTncs = [],
  geogroupings,
  buildings,
  loading,
  isEditable,
  onLocationTncsSave,
}: ILegalTreeFormProps): JSX.Element => {
  // Objects to look up tncs by location ID
  const [locationsMap, setLocationsMap] = useState<ILocationsMap>(assembleLocationsMap(geogroupings, buildings));
  const [tncsMap, setTncsMap] = useState<ILocationTncMap>(locationTncsArrayToMap(locationTncs));

  // tree nodes
  const [nodes, setNodes] = useState<ICheckboxTreeNode[]>([]);
  const [filteredNodes, setFilteredNodes] = useState<ICheckboxTreeNode[]>([]);
  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const [filterQuery, setFilterQuery] = useState<string>('');

  // Form state
  const [formTncs, setFormTncs] = useState<ILocationTncMap>({});
  const [submitting, setSubmitting] = useState<boolean>(false);

  // Modal state
  const [showTncModal, setShowTncModal] = useState<boolean>(false);
  const [activeModalLocationTncs, setActiveModalLocationTncs] = useState<ILocationTnc[]>([]);
  const [activeModalLocation, setActiveModalLocation] = useState<ILocationObject | null>(null);

  const { flashError, flashSuccess } = useFlashMessageContext();
  const isFromTncTouched = !isEqual(tncsMap, formTncs);

  // Assemble locations in object for lookup by location ID
  useEffect(() => {
    const locations = assembleLocationsMap(geogroupings, buildings);
    setLocationsMap(locations);
  }, [geogroupings, buildings]);

  // Assemble tncs in object for lookup by location ID
  // and hydrate form with existing tncs
  useCopyEffect(locationTncs, setTncsMap, locationTncsArrayToMap);
  useCopyEffect(locationTncs, setFormTncs, locationTncsArrayToMap);

  // Assemble all tree nodes
  useEffect(() => {
    const allNodes = assembleNodes(locationsMap, formTncs);
    setNodes(allNodes);
  }, [locationsMap, formTncs]);

  // Set filtered nodes
  useEffect(() => {
    const newFilteredNodes = filterNodes(nodes, filterQuery);
    setFilteredNodes(newFilteredNodes);
  }, [nodes, filterQuery]);

  // Set expanded Nodes
  useEffect(() => {
    setExpandedIds(assembleExpandedIds(locationTncs, locationsMap));
  }, [locationTncs, locationsMap]);

  function updateTncsRequest(): Promise<IExtendedFetchResult<ILocationTnc[]> | null> {
    const tncsPayload = bulkUpdateLocationTncsPayload(tncsMap, formTncs);
    const { createList, updateList, deleteList } = tncsPayload;
    if (isEmpty(createList) && isEmpty(updateList) && isEmpty(deleteList)) {
      return Promise.resolve(null);
    }
    return bulkUpdateLocationTncs(product, tncsPayload);
  }

  const handleSubmit = async (): Promise<void> => {
    setSubmitting(true);
    const tncsResult = await updateTncsRequest();
    setSubmitting(false);

    if (tncsResult) {
      if (tncsResult.error) {
        flashError(tncsResult.errorData ? tncsResult.errorData.message : 'An error has occurred');
      } else {
        flashSuccess('TNCs saved');
        const { data } = tncsResult;
        if (data) {
          // Save IDs of newly created tncs
          const createdTncs = locationTncsArrayToMap(data);
          const newTncsMap = {
            ...formTncs,
            ...createdTncs,
          };
          const newTncs = locationTncsMapToArray(newTncsMap);
          onLocationTncsSave(newTncs);
        }
      }
    }
  };

  const showFilterError = (): boolean => filteredNodes.length === 0 && filterQuery.length > 0;

  const getLocation = (id: string): ILocationObject => locationsMap.geogroupings[id] || locationsMap.buildings[id];

  const getLocationType = (locationUuid: string): LocationTncLocationType => {
    return locationsMap.geogroupings[locationUuid]
      ? LocationTncLocationType.GEOGROUPING
      : LocationTncLocationType.BUILDING;
  };

  const getLocationTncs = (locationUuid: string): ILocationTnc[] => formTncs[locationUuid] || [];

  const openTncModal = (locationUuid: string): void => {
    const location = getLocation(locationUuid);
    const locationTncs = getLocationTncs(locationUuid);
    setActiveModalLocation(location);
    setActiveModalLocationTncs(locationTncs);
    setShowTncModal(true);
  };

  const closeTncModal = (): void => {
    setShowTncModal(false);
  };

  const handleTncSave = (tnc: ILocationTnc, index: number | null): void => {
    const locTncs = formTncs[tnc.locationUuid] || [];
    // edit or create
    if (index !== null) {
      locTncs[index] = tnc;
    } else {
      locTncs.push(tnc);
    }

    setFormTncs({
      ...formTncs,
      [tnc.locationUuid]: locTncs,
    });

    setActiveModalLocationTncs(locTncs);
  };

  const handleTncDelete = (tnc: ILocationTnc): void => {
    const newTncs = { ...formTncs };
    const locTncs = newTncs[tnc.locationUuid];
    const index = locTncs.findIndex((match) => match.name === tnc.name);
    locTncs.splice(index, 1);
    const newFormTncs = { ...formTncs };
    if (isEmpty(locTncs)) {
      delete newFormTncs[tnc.locationUuid];
    } else {
      newFormTncs[tnc.locationUuid] = locTncs;
    }

    setFormTncs(newFormTncs);
    setActiveModalLocationTncs(locTncs);
  };

  return (
    <>
      <LoadingContainer loading={loading}>
        <>
          <Input
            value={filterQuery}
            onChange={(event): void => setFilterQuery(event.target.value)}
            placeholder="Type to filter..."
          />
          <div className="availability-form--tree-container">
            <WeCheckboxTree
              nodes={filteredNodes}
              expanded={expandedIds}
              onExpand={setExpandedIds}
              showExpandAll
              onClick={({ value }): void => openTncModal(value)}
            />
          </div>
          {showFilterError() && <Message error>No locations match &quot;{filterQuery}&quot;</Message>}

          {showTncModal && (
            <LocationTncModal
              locationName={activeModalLocation?.name || activeModalLocation?.default_name || ''}
              locationUuid={activeModalLocation?.id}
              locationType={getLocationType(activeModalLocation?.id ?? '')}
              locationTncs={activeModalLocationTncs}
              disabled={!isEditable}
              onClose={closeTncModal}
              onSave={handleTncSave}
              onDelete={handleTncDelete}
            />
          )}
        </>
      </LoadingContainer>

      {isEditable && (
        <FormActionDrawer
          actions={[
            {
              key: 'submit',
              primary: true,
              onClick: (): void => {
                handleSubmit();
              },
              loading: submitting,
              disabled: !isFromTncTouched,
              content: 'Submit',
              'data-test-id': 'submit',
            },
          ]}
        />
      )}
    </>
  );
};
