import React, { useEffect, useState } from 'react';

import { Form as FormikForm, Formik } from 'formik';
import { Button, Form } from '@wework/dieter-ui';
import Loading from 'components/shared/Loading';

import { ILocationTnc } from 'types/productCatalogTypes';
import { LOCALES_AS_OPTIONS } from 'configs/locales';
import { LocationTncFormSchema } from './LocationTncFormConfig';
import { LocationTncFormTncInput, LocationTncFormSelectInput } from './LocationTncFormInputs';
import { handleChange } from '../Form/utils';
import { FormInputType, FormValue } from '../Form/FormConfig';

interface ILocationTncFormProps {
  locationTnc: ILocationTnc;
  onSave: (tnc: ILocationTnc) => void;
  onClear: () => void;
  create: boolean;
  disabled?: boolean;
}

export const LocationTncForm = ({
  locationTnc,
  onSave,
  onClear,
  create,
  disabled,
}: ILocationTncFormProps): JSX.Element => {
  const [formTnc, setFormTnc] = useState<ILocationTnc | null>(null);

  // This form does not submit tncs to the API
  const handleSubmit = (values: ILocationTnc, resetForm: () => void): void => {
    const castValues = LocationTncFormSchema.cast(values);
    onSave({
      ...castValues,
      displayNameTranslations: values.displayNameTranslations,
    } as ILocationTnc);
    resetForm();
  };

  const handleClear = (resetForm: () => void): void => {
    resetForm();
    onClear();
  };

  useEffect(() => setFormTnc(locationTnc), [locationTnc]);

  if (!formTnc) {
    return <Loading />;
  }

  return (
    <>
      <Formik
        initialValues={formTnc}
        validationSchema={LocationTncFormSchema}
        onSubmit={(values, { resetForm }): void => handleSubmit(values, resetForm)}
      >
        {({
          handleSubmit: formikHandleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
          isValid,
          dirty,
          resetForm,
        }): JSX.Element => (
          <div className="location-tnc-modal--form-wrapper">
            <Form as={FormikForm} onSubmit={formikHandleSubmit} className="location-tnc-modal--form">
              <LocationTncFormTncInput
                handleOnChange={(name, value): void => {
                  handleChange<FormValue>(name, value, setFieldValue, setFieldTouched);
                }}
                name="name"
                inputType={FormInputType.TEXT}
                placeholder="Name"
                disabled={disabled}
              />
              <LocationTncFormTncInput
                handleOnChange={(name, value): void => {
                  handleChange<FormValue>(name, value, setFieldValue, setFieldTouched);
                }}
                name="displayName"
                inputType={FormInputType.TEXT}
                placeholder="Display Name"
                disabled={disabled}
              />
              <LocationTncFormSelectInput
                options={LOCALES_AS_OPTIONS}
                handleOnChange={(name, value): void => {
                  handleChange<FormValue>(name, value, setFieldValue, setFieldTouched);
                }}
                name="locale"
                inputType={FormInputType.SELECT}
                placeholder="Locale"
                disabled={disabled}
              />
              <LocationTncFormTncInput
                handleOnChange={(name, value): void => {
                  handleChange<FormValue>(name, value, setFieldValue, setFieldTouched);
                }}
                name="url"
                inputType={FormInputType.TEXT}
                placeholder="URL"
                disabled={disabled}
              />
            </Form>
            <Button className="location-tnc-modal--items" onClick={(): void => handleClear(resetForm)}>
              {create ? 'Clear' : 'Close'}
            </Button>
            <Button
              className="location-tnc-modal--items"
              primary
              onClick={(): void => handleSubmit(values, resetForm)}
              disabled={disabled || !isValid || !dirty}
            >
              {create ? 'Add' : 'Edit'}
            </Button>
          </div>
        )}
      </Formik>
    </>
  );
};
